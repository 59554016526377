import React from "react";
import { AlertTriangle } from "lucide-react";
import { Link } from "react-router-dom";

const PaymentFailed = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4 py-8">
      <div className="bg-white shadow-2xl rounded-xl p-8 max-w-md w-full text-center space-y-6">
        <div className="bg-red-100 rounded-full p-4 w-24 h-24 flex items-center justify-center mx-auto">
          <AlertTriangle className="text-red-500 w-16 h-16" strokeWidth={1.5} />
        </div>

        <div>
          <h2 className="text-3xl font-bold text-gray-800 mb-4">
            Payment Failed
          </h2>
          <p className="text-gray-600 mb-6">
            We're sorry, but there was an issue processing your payment. Please
            contact our support team for assistance.
          </p>
        </div>

        <div>
          <Link
            to="/"
            className="w-full inline-block bg-sky-500 text-white py-3 rounded-lg hover:bg-sky-600 transition-colors"
          >
            Back to Home
          </Link>
        </div>

        <div className="text-sm text-gray-500 mt-4">
          Need help? Contact our support team at {" "}
          <a
            href="mailto:info@alleshealth.com"
            className="text-blue-500 underline"
          >
            info@alleshealth.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;
