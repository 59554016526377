import React, { useEffect } from "react";
import { Check } from "lucide-react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSubscription } from "../services/operations/membershipAPI";

const PaymentSuccessful = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const stripeResponse = JSON.parse(localStorage.getItem("stripeResponse"));

  const updatePaymentStatus = () => {
    const data = {
      session_id: stripeResponse,
    };
    dispatch(createSubscription(token, data));
  };

  useEffect(() => {
    if (!stripeResponse) {
      return;
    }
    updatePaymentStatus();
  }, [stripeResponse]);

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center px-4 py-8">
      <div className="bg-white shadow-2xl rounded-xl p-8 max-w-md w-full text-center space-y-6">
        <div className="bg-green-100 rounded-full p-4 w-24 h-24 flex items-center justify-center mx-auto">
          <Check className="text-green-500 w-16 h-16" strokeWidth={2} />
        </div>

        <div>
          <h2 className="text-3xl font-bold text-gray-800 mb-4">
            Payment Successful
          </h2>
          <p className="text-gray-600 mb-6">
            Thank you for your payment! Your transaction has been completed
            successfully.
          </p>
        </div>

        <div className="space-y-4">
          <Link
            to="/"
            className="w-full inline-block bg-green-500 text-white py-3 rounded-lg hover:bg-green-600 transition-colors"
          >
            Back to Home
          </Link>
        </div>

        <div className="text-sm text-gray-500 mt-4">
          We appreciate your business! Questions? Contact us at{" "}
          <a
            href="mailto:support@example.com"
            className="text-blue-500 underline"
          >
            info@alleshealth.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessful;
