import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IoMaleSharp } from "react-icons/io5";
import { IoFemaleSharp } from "react-icons/io5";
import { LiaTransgenderSolid } from "react-icons/lia";
import PhoneInput from "react-phone-input-2";
import { MultiSelect } from "react-multi-select-component";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import register from "../assets/Images/MasterDataManagement/register.png";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addNewDoctor,
  addNewNurse,
  updateDoctor,
  updateNurse,
  uploadOrganizationLogo,
} from "../services/operations/masterDataAPI";
import imageCompression from "browser-image-compression";
import uploadProfile from "../assets/Images/MasterDataManagement/uploadImage.png";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { FaChevronLeft } from "react-icons/fa6";
import { languageList } from "../data/language";
import Breadcrumb from "../components/Breadcrumb";
import { Checkbox, Steps, Switch } from "antd";
import MultiSelectDropdown from "../components/MultiSelectDropdown";
import Dropdown from "../components/Dropdown";
import { countries } from "../data/country";
import { TimePicker } from "antd";
import cross_icon from "../assets/Images/MasterDataManagement/cross_icon.svg";
import add_icon from "../assets/Images/MasterDataManagement/add_icon.svg";
import { doctorSpecialityList } from "../data/doctorSpeciality";
import addNurseBackground from "../assets/Images/MasterDataManagement/addNurseBackground.png";
import { nurseSpecialityList } from "../data/nurseSpeciality";

const Form = ({
  formData,
  setFormData,
  errors,
  setErrors,
  current,
  setCurrent,
  isViewing,
  image,
  setImage,
  weekdays,
  timeIntervals,
  setTimeIntervals,
  nurse,
}) => {
  const { user } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [applyToAll, setApplyToAll] = useState(false);
  const [globalFromTime, setGlobalFromTime] = useState(null);
  const [globalToTime, setGlobalToTime] = useState(null);
  const [totalWorkingHours, setTotalWorkingHours] = useState(0);

  const validateStepFields = () => {
    let isValid = true;
    const newErrors = {};

    // Define required fields for each step
    const requiredFields = {
      0: [
        "ImageLocation",
        "FullName",
        "PhoneNumber",
        "Email",
        "JoiningDate",
        "Experience",
        "Speciality",
        "Languages",
      ],
      1: ["StreetName", "HouseNumber", "PinCode", "City", "State", "Country"],
      2: [],
    };

    if (requiredFields[current]) {
      requiredFields[current].forEach((field) => {
        // Ensure formData[field] is a string before calling trim()
        if (
          !formData[field] ||
          (typeof formData[field] === "string" && !formData[field].trim())
        ) {
          newErrors[field] = "This field is required";
          isValid = false;
        } else if (
          typeof formData[field] !== "string" &&
          !String(formData[field]).trim()
        ) {
          newErrors[field] = "This field is required";
          isValid = false;
        }
      });
    }

    // Update the errors state
    setErrors(newErrors);
    // Show error toast only if there is an error
    if (!isValid) {
      toast.error("Please fill all the required fields");
    }
    return isValid;
  };

  function handleSelect(name, option) {
    setFormData((prev) => ({
      ...prev,
      [name]: option,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  const [timeErrors, setTimeErrors] = useState({});
  const [globalTimeError, setGlobalTimeError] = useState(false);

  const validateTimeInterval = (fromTime, toTime) => {
    if (fromTime && toTime) {
      const from = moment(fromTime, "h:mm a");
      const to = moment(toTime, "h:mm a");
      return to.isAfter(from);
    }
    return true;
  };

  const isTimeEqual = (time1, time2) => {
    if (!time1 || !time2) return false;
    return moment(time1).format("HH:mm") === moment(time2).format("HH:mm");
  };

  // Toggle Button
  const handleAvailabilityChange = (day, checked) => {
    const newIntervals = { ...timeIntervals };
    newIntervals[day].available = checked;

    if (!checked) {
      newIntervals[day].intervals = [];
    } else {
      if (applyToAll && globalFromTime && globalToTime) {
        newIntervals[day].intervals = [
          { from: globalFromTime, to: globalToTime },
        ];
      } else if (newIntervals[day].intervals.length === 0) {
        newIntervals[day].intervals = [{ from: null, to: null }];
      }
    }

    // Remove the corresponding errors for the day
    const newTimeErrors = { ...timeErrors };
    Object.keys(newTimeErrors).forEach((key) => {
      if (key.startsWith(`${day}-`)) {
        delete newTimeErrors[key];
      }
    });
    setTimeErrors(newTimeErrors);

    setTimeIntervals(newIntervals);
  };

  const handleTimeChange = (day, index, field, time) => {
    const newIntervals = { ...timeIntervals };
    newIntervals[day].intervals[index][field] = time;

    let isValid = true;
    if (field === "from") {
      isValid = validateTimeInterval(
        time,
        newIntervals[day].intervals[index].to
      );
    } else {
      isValid = validateTimeInterval(
        newIntervals[day].intervals[index].from,
        time
      );
    }

    setTimeIntervals(newIntervals);
    setApplyToAll(false);

    const newTimeErrors = { ...timeErrors };
    if (!isValid) {
      newTimeErrors[`${day}-${index}`] = true;
      toast.error("Invalid time interval");
    } else {
      delete newTimeErrors[`${day}-${index}`];
    }
    setTimeErrors(newTimeErrors);

    // Check if the changed time is different from the global time
    if (
      applyToAll &&
      ((field === "from" && !isTimeEqual(time, globalFromTime)) ||
        (field === "to" && !isTimeEqual(time, globalToTime)))
    ) {
      setApplyToAll(false);
    }
  };

  const addTimeSlot = (day) => {
    const newIntervals = { ...timeIntervals };
    newIntervals[day].intervals.push({ from: null, to: null });
    setTimeIntervals(newIntervals);
    setApplyToAll(false);
  };

  const removeTimeSlot = (day, index) => {
    const newIntervals = { ...timeIntervals };
    newIntervals[day].intervals.splice(index, 1);
    setTimeIntervals(newIntervals);

    // Remove the corresponding error
    const newTimeErrors = { ...timeErrors };
    delete newTimeErrors[`${day}-${index}`];
    setTimeErrors(newTimeErrors);
  };

  const handleApplyToAllChange = (checked) => {
    if (!globalFromTime || !globalToTime) {
      setGlobalTimeError(true);
      toast.error("Please select global time first");
      return;
    }
    if (globalTimeError) {
      toast.error("Time interval is invalid.");
      return;
    }
    setApplyToAll(checked);
    if (checked) {
      applyGlobalTime(globalFromTime, globalToTime);
    }
  };

  const applyGlobalTime = (fromTime, toTime) => {
    const newIntervals = { ...timeIntervals };
    weekdays.forEach((day) => {
      if (newIntervals[day].available) {
        newIntervals[day].intervals = [{ from: fromTime, to: toTime }];
      }
    });
    setTimeIntervals(newIntervals);
    setTimeErrors({});
  };

  const handleGlobalTimeChange = (field, time) => {
    // Set the time first
    if (field === "from") {
      setGlobalFromTime(time);
      // Validate the time interval
      const isValid = validateTimeInterval(time, globalToTime);
      // Set the error state
      setGlobalTimeError(!isValid);
      // Apply the global time if valid and applyToAll is true
      if (isValid && applyToAll) {
        applyGlobalTime(time, globalToTime);
      }
    } else {
      setGlobalToTime(time);
      // Validate the time interval
      const isValid = validateTimeInterval(globalFromTime, time);
      // Set the error state
      setGlobalTimeError(!isValid);
      // Apply the global time if valid and applyToAll is true
      if (isValid && applyToAll) {
        applyGlobalTime(globalFromTime, time);
      }
    }
  };

  const renderInputFields = () => {
    switch (current) {
      case 0:
        return (
          <>
            {/* Profile Picture */}
            <div className="mb-6">
              <label
                className={` border-dashed ${
                  errors.ImageLocation ? "border-red-500" : "border-zinc-500"
                }  border-2 rounded-md p-4 flex flex-col items-center justify-center cursor-pointer`}
              >
                {formData?.ImageLocation ? (
                  <>
                    <div className="w-20 h-20 shadow-lg rounded-full shadow-neutral-400">
                      <img
                        src={image}
                        alt="upload icon"
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                    {!isViewing && (
                      <div className="px-3 py-1.5 bg-sky-100 rounded-2xl justify-center items-center inline-flex cursor-pointer mt-3">
                        <p className="text-sky-400 text-xs font-semibold font-open">
                          Change Image
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="w-14 h-14 bg-slate-100 rounded-full flex justify-center items-center">
                      <img
                        src={uploadProfile}
                        alt=""
                        className="w-6 h-9 object-contain cursor-pointer"
                      />
                    </div>
                    <div className="text-center text-stone-950 text-sm font-medium font-poppins leading-none mt-2">
                      Profile Photo
                    </div>
                    <div className="text-center text-neutral-600 text-xs font-normal font-poppins leading-3 mt-1">
                      (Click to Add)
                    </div>
                  </>
                )}
                <input
                  type="file"
                  accept="image/*"
                  name="Image"
                  disabled={isViewing}
                  value={undefined}
                  onChange={(e) => handleImageChange(e)}
                  style={{ display: "none" }}
                />
              </label>
            </div>
            {/* Full Name */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Full Name
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="FullName"
                  value={formData.FullName}
                  onChange={handleOnChange}
                  maxLength={25}
                  disabled={isViewing}
                  placeholder="Enter name of nurse"
                  className={`w-full ${
                    errors.FullName ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>

            {/* Gender */}
            <div className="mb-6">
              <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2 ">
                Gender
                <sup className="text-red-600">*</sup>
              </p>
              <div className="flex space-x-2">
                <button
                  disabled={isViewing}
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } flex flex-row items-center justify-center space-x-1 rounded-lg p-4 border ${
                    formData.Gender === "Male"
                      ? "border-sky-100 text-white bg-sky-400"
                      : "border-sky-500 text-sky-400  bg-white"
                  }`}
                  onClick={() => setFormData({ ...formData, Gender: "Male" })}
                >
                  <IoMaleSharp />
                  <div className="text-sm font-medium font-poppins">Male</div>
                </button>
                <button
                  disabled={isViewing}
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } flex flex-row items-center justify-center space-x-1 rounded-lg p-4 border ${
                    formData.Gender === "Female"
                      ? "border-sky-100 text-white bg-rose-500"
                      : "border-rose-500 text-rose-500  bg-white"
                  }`}
                  onClick={() => setFormData({ ...formData, Gender: "Female" })}
                >
                  <IoFemaleSharp />
                  <div className="text-sm font-semibold font-poppins">
                    Female
                  </div>
                </button>
                <button
                  disabled={isViewing}
                  className={`w-full ${
                    isViewing && "cursor-not-allowed"
                  } flex flex-row items-center justify-center space-x-1 text-xl rounded-lg p-4 border ${
                    formData.Gender === "Diverse"
                      ? "border-sky-100 text-white bg-lime-500"
                      : "border-lime-500  text-lime-500  bg-white"
                  }`}
                  onClick={() =>
                    setFormData({ ...formData, Gender: "Diverse" })
                  }
                >
                  <LiaTransgenderSolid />
                  <div className="text-sm font-semibold font-poppins">
                    Diverse
                  </div>
                </button>
              </div>
            </div>

            <div className="mb-6 flex space-x-4">
              {/* Email */}
              <label className="flex-1">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Email
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="email"
                  name="Email"
                  disabled={isViewing}
                  value={formData.Email}
                  onChange={handleOnChange}
                  placeholder="Input your email here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.Email ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
              <label className="flex-1">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Phone Number
                  <sup className="text-red-600">*</sup>
                </p>
                <PhoneInput
                  className="number"
                  country={"ch"}
                  disabled={isViewing}
                  value={formData.PhoneNumber}
                  onChange={(value) => {
                    setFormData({ ...formData, PhoneNumber: value });
                    setErrors({ ...errors, PhoneNumber: "" });
                  }}
                  inputClass={`w-full text-black text-xs font-normal font-poppins rounded-lg p-3 border ${
                    errors.PhoneNumber ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  inputStyle={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#F3F5F7",
                    border: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    outline: "none",
                    borderRadius: 6,
                  }}
                  buttonStyle={{
                    borderLeft:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderTop: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderBottom:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderRight: errors.PhoneNumber && "1px solid transparent",
                    borderTopLeftRadius: 6,
                    borderBottomLeftRadius: 6,
                  }}
                />
              </label>
            </div>

            <div className="mb-6 flex space-x-4">
              <label className="flex-1">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Date of Joining
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="date"
                  name="JoiningDate"
                  value={moment(formData.JoiningDate).format("YYYY-MM-DD")}
                  placeholder="Select Date of Birth"
                  onChange={handleOnChange}
                  min={moment().format("YYYY-MM-DD")}
                  className={`w-full accent-sky-500 text-sky-400 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.JoiningDate ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
              {/* Years of Experience */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Enter yrs of experience ( in yrs)
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  name="Experience"
                  type="number"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  value={formData.Experience}
                  onChange={handleOnChange}
                  disabled={isViewing}
                  placeholder="ex: 3"
                  className={`w-full ${
                    errors.Experience ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Speciality  */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                  Speciality
                  <sup className="text-red-600">*</sup>
                </p>
                <Dropdown
                  disabled={isViewing}
                  placeholder={"Select"}
                  error={errors.Speciality}
                  name="Speciality"
                  options={nurseSpecialityList}
                  onSelect={handleSelect}
                  value={formData.Speciality}
                  labelKey={"label"}
                  valueKey={"value"}
                />
              </label>
            </div>
            {/* Languages */}
            <div className="mb-6">
              <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                Languages Known
                <sup className="text-red-600">*</sup>
              </p>
              <MultiSelectDropdown
                disabled={isViewing}
                placeholder="Select Languages"
                error={errors.Languages}
                name="Languages"
                options={languageList}
                onSelect={handleSelect}
                value={formData.Languages}
                labelKey="name"
                valueKey="id"
              />
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div className="mb-6 flex space-x-4">
              {/* Street Name */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Street Name
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="StreetName"
                  value={formData.StreetName}
                  onChange={handleOnChange}
                  disabled={isViewing}
                  placeholder="Input your street name here"
                  className={`w-full ${
                    errors.StreetName ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  House Number
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  name="HouseNumber"
                  type="number"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  value={formData.HouseNumber}
                  onChange={handleOnChange}
                  disabled={isViewing}
                  placeholder="Input your House No."
                  className={`w-full ${
                    errors.HouseNumber ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            <div className="mb-6 flex space-x-4">
              {/* PinCode */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Pin Code
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  name="PinCode"
                  type="number"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  value={formData.PinCode}
                  onChange={handleOnChange}
                  disabled={isViewing}
                  placeholder="Input your pin code here"
                  className={`w-full ${
                    errors.PinCode ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
              {/* City */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  City
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="City"
                  value={formData.City}
                  onChange={handleOnChange}
                  disabled={isViewing}
                  placeholder="Input your city here"
                  className={`w-full ${
                    errors.City ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            <div className="mb-6 flex space-x-4">
              {/* Region / State / Canton * */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Region / State / Canton
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="State"
                  value={formData.State}
                  onChange={handleOnChange}
                  disabled={isViewing}
                  placeholder="Input your Region here"
                  className={`w-full ${
                    errors.State ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
              {/* Country */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Country
                  <sup className="text-red-600">*</sup>
                </p>
                <Dropdown
                  placeholder={"Select Country"}
                  error={errors.Country}
                  name="Country"
                  options={countries}
                  onSelect={handleSelect}
                  value={formData.Country}
                  labelKey="label"
                  valueKey="value"
                  enableSearch={true}
                />
              </label>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div>
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-4">
                Working hours
                <sup className="text-red-600">*</sup>
              </p>

              {/* Global Time */}
              <div className="flex items-center space-x-4">
                <Checkbox
                  checked={applyToAll}
                  onChange={(e) => handleApplyToAllChange(e.target.checked)}
                  className="mr-4"
                >
                  <div className=" text-black text-sm font-normal font-poppins leading-tight">
                    Apply to all
                  </div>
                </Checkbox>
                <p className="text-black text-xs font-normal font-poppins leading-none">
                  From
                </p>
                <TimePicker
                  use12Hours
                  format="h:mm a"
                  value={
                    globalFromTime ? moment(globalFromTime, "h:mm a") : null
                  }
                  onChange={(time, timeString) =>
                    handleGlobalTimeChange("from", timeString)
                  }
                  onOk={(time) =>
                    handleGlobalTimeChange("from", time.format("h:mm a"))
                  }
                  size="large"
                  minuteStep={30}
                  changeOnScroll={false}
                  status={globalTimeError ? "error" : ""}
                />
                <p className="text-black text-xs font-normal font-poppins leading-none">
                  To
                </p>
                <TimePicker
                  use12Hours
                  format="h:mm a"
                  value={globalToTime ? moment(globalToTime, "h:mm a") : null}
                  onChange={(time, timeString) =>
                    handleGlobalTimeChange("to", timeString)
                  }
                  onOk={(time) =>
                    handleGlobalTimeChange("to", time.format("h:mm a"))
                  }
                  size="large"
                  minuteStep={30}
                  changeOnScroll={false}
                  status={globalTimeError ? "error" : ""}
                />
              </div>
            </div>
            <div className="h-px bg-gray-200 my-4"></div>
            {weekdays.map((day) => (
              <div key={day} className="flex items-baseline mb-4">
                <div className="flex items-center mb-2 w-28">
                  <Switch
                    checked={timeIntervals[day].available}
                    onChange={(checked) =>
                      handleAvailabilityChange(day, checked)
                    }
                    className="mr-2"
                  />
                  <div className="text-black text-sm font-normal font-poppins">
                    {day}
                  </div>
                </div>
                <div className="flex flex-col">
                  {timeIntervals[day].available ? (
                    timeIntervals[day].intervals.map((interval, index) => (
                      <div
                        key={index}
                        className="flex items-center space-x-4 mb-2 ml-8"
                      >
                        <p className="w-8 text-black text-xs font-normal font-poppins leading-none">
                          {index === 0 ? "From" : ""}
                        </p>
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          value={
                            interval.from
                              ? moment(interval.from, "h:mm a")
                              : null
                          }
                          onChange={(time, timeString) =>
                            handleTimeChange(day, index, "from", timeString)
                          }
                          onOk={(time) =>
                            handleTimeChange(
                              day,
                              index,
                              "from",
                              time.format("h:mm a")
                            )
                          }
                          size="large"
                          minuteStep={30}
                          changeOnScroll={false}
                          status={timeErrors[`${day}-${index}`] ? "error" : ""}
                        />

                        <p className="text-black text-xs font-normal font-poppins leading-none">
                          To
                        </p>
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          value={
                            interval.to ? moment(interval.to, "h:mm a") : null
                          }
                          onChange={(time, timeString) =>
                            handleTimeChange(day, index, "to", timeString)
                          }
                          onOk={(time) =>
                            handleTimeChange(
                              day,
                              index,
                              "to",
                              time.format("h:mm a")
                            )
                          }
                          size="large"
                          minuteStep={30}
                          changeOnScroll={false}
                          status={timeErrors[`${day}-${index}`] ? "error" : ""}
                        />
                        {timeIntervals[day].intervals.length > 1 && (
                          <button onClick={() => removeTimeSlot(day, index)}>
                            <img src={cross_icon} alt="remove" />
                          </button>
                        )}
                        {index === timeIntervals[day].intervals.length - 1 && (
                          <button onClick={() => addTimeSlot(day)}>
                            <img src={add_icon} alt="remove" />
                          </button>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="ml-20 w-80 p-4 bg-neutral-50 rounded-lg text-neutral-600 text-sm font-normal font-poppins">
                      Not available
                    </div>
                  )}
                </div>
              </div>
            ))}
          </>
        );
      default:
        return null;
    }
  };

  function handleOnChange(event) {
    const { name, value } = event.target;
    let maxLength;

    // Set the maxLength based on the field name
    switch (name) {
      case "Experience":
        maxLength = 2;
        break;
      case "StartTime":
        maxLength = 2;
        break;
      case "EndTime":
        maxLength = 2;
        break;
      default:
        maxLength = Infinity; // No maxLength for other fields
    }

    // For other types, handle validation without removing non-numeric characters
    if (value.length <= maxLength) {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  }

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const toastId = toast.loading("Compressing Image...");
    const compressedFile = await imageCompression(file, options);
    toast.dismiss(toastId);
    uploadImage(compressedFile);
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("ProfileImage", file);
    try {
      const res = await uploadOrganizationLogo(token, formData);
      setFormData((prev) => ({
        ...prev,
        ImageLocation: res?.ProfileImageUrl,
      }));
      setErrors((prev) => ({
        ...prev,
        ImageLocation: "",
      }));
      setImage(URL.createObjectURL(file));
    } catch (error) {
      console.log(error);
    }
  };

  const next = () => {
    if (current < steps.length - 1 && validateStepFields()) {
      setCurrent(current + 1);
    } else if (current === steps.length - 1 && validateStepFields()) {
      submitHandler();
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const calculateTotalWorkingHours = (timeIntervals) => {
    const totalHours = Object.keys(timeIntervals).reduce((acc, day) => {
      if (timeIntervals[day].available) {
        const dailyHours = timeIntervals[day].intervals.reduce(
          (dayAcc, interval) => {
            if (interval.from && interval.to) {
              const fromTime = moment(interval.from, "h:mm a");
              const toTime = moment(interval.to, "h:mm a");
              const duration = moment.duration(toTime.diff(fromTime));
              const hours = duration.asHours();
              return dayAcc + hours;
            }
            return dayAcc;
          },
          0
        );
        return acc + dailyHours;
      }
      return acc;
    }, 0);
    return totalHours;
  };

  useEffect(() => {
    const totalHours = calculateTotalWorkingHours(timeIntervals);
    setTotalWorkingHours(totalHours);
  }, [timeIntervals]);

  // Add this new function to validate time intervals
  const validateTimeIntervals = () => {
    for (const day in timeIntervals) {
      if (timeIntervals[day].available) {
        if (timeIntervals[day].intervals.length === 0) {
          return `${day} is marked as available but has no time intervals set.`;
        }
        for (const interval of timeIntervals[day].intervals) {
          if (!interval.from || !interval.to) {
            return `${day} has an incomplete time interval.`;
          }
          if (!validateTimeInterval(interval.from, interval.to)) {
            return `${day} has an invalid time interval.`;
          }
        }
      }
    }

    if (Object.values(timeErrors).some((error) => error)) {
      return "There are time-related errors. Please correct them before submitting.";
    }

    return null; // No errors found
  };

  const submitHandler = async () => {
    // Validate time intervals
    const timeValidationError = validateTimeIntervals();
    if (timeValidationError) {
      toast.error(timeValidationError);
      return;
    }

    const availability = Object.keys(timeIntervals).reduce((acc, day) => {
      if (timeIntervals[day].available) {
        acc[day.toUpperCase()] = timeIntervals[day].intervals.map(
          (interval) => {
            const fromTime = moment(interval.from, "h:mm a").isValid()
              ? moment(interval.from, "h:mm a").format("HH:mm:ss")
              : null;
            const untilTime = moment(interval.to, "h:mm a").isValid()
              ? moment(interval.to, "h:mm a").format("HH:mm:ss")
              : null;

            return {
              Available_From: fromTime,
              Available_Until: untilTime,
            };
          }
        );
      } else {
        acc[day.toUpperCase()] = [];
      }
      return acc;
    }, {});

    const data = {
      Name: formData.FullName,
      Gender: formData.Gender,
      Email: formData.Email,
      PhoneNumber: formData.PhoneNumber,
      ORGID: user.ORGID,
      Speciality: [formData.Speciality],
      PreferredLanguage: formData.Languages.map((item) => item),
      JoiningDate: formData.JoiningDate,
      WorkingHours: totalWorkingHours,
      Experience: parseInt(formData.Experience),
      Consultations: 0,
      ProfileImageUrl: formData.ImageLocation,
      TimeZone: moment.tz.guess(),
      Availability: availability,
      HouseNo: formData.HouseNumber,
      Street: formData.StreetName,
      PinCode: formData.PinCode,
      City: formData.City,
      State: formData.State,
      Country: formData.Country,
    };

    if (nurse.DID) {
      dispatch(updateNurse(token, nurse.DID, data, navigate));
    } else {
      dispatch(addNewNurse(token, data, navigate));
    }
  };

  return (
    <div className="flex gap-8 p-8">
      <div className="w-1/2">
        <div className="bg-white rounded-lg shadow-md p-6 border border-sky-500">
          {renderInputFields(current)}
        </div>
        <div className="flex flex-row items-center justify-center mt-6 space-x-4">
          {current > 0 && (
            <button
              onClick={prev}
              className="flex-1 px-5 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500"
            >
              <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
                Previous
              </div>
            </button>
          )}
          <button
            onClick={next}
            className="flex-1 px-5 py-3 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-500"
          >
            <div className="text-white text-base font-semibold font-poppins leading-normal">
              {current === steps.length - 1 ? "Save Details" : "Next"}
            </div>
          </button>
        </div>
      </div>
      <div className="w-1/2 flex flex-col justify-between">
        {/* Hours Per Week  */}
        {current === 2 && (
          <div className="mb-6 flex items-center space-x-2">
            <label className="flex-1">
              <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                Hours per Week (Auto Calculated)
              </p>
              <input
                type="text"
                name="HoursPerWeek"
                maxLength={3}
                value={totalWorkingHours + " Hrs"}
                disabled={true}
                placeholder="Auto Calculated"
                className={`w-2/3 cursor-not-allowed text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                  errors.HoursPerWeek ? "border-red-500" : ""
                }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
            <div className="flex-1">
              <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                Time Zone
              </p>
              <p
                className={`w-2/3 cursor-not-allowed text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              >
                {moment.tz.guess()}
              </p>
            </div>
          </div>
        )}
        <div className="flex-grow flex items-end justify-end">
          <img
            src={addNurseBackground}
            alt="register"
            className="w-96 h-96 object-contain"
          />
        </div>
      </div>
    </div>
  );
};
function AddNewNurse() {
  const location = useLocation();
  const nurse = location.state || {};
  const [current, setCurrent] = useState(0);
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const [isViewing, setIsViewing] = useState(false);
  const [image, setImage] = useState("");
  const [formData, setFormData] = useState({
    ImageLocation: "",
    FullName: "",
    Gender: "Male",
    Email: "",
    PhoneNumber: "",
    JoiningDate: "",
    Experience: "",
    Speciality: [""],
    Languages: [""],
    StreetName: "",
    HouseNumber: "",
    PinCode: "",
    City: "",
    State: "",
    Country: "",
    Availability: [],
    HoursPerWeek: "",
  });
  const [errors, setErrors] = useState({});
  const weekdays = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
  const [timeIntervals, setTimeIntervals] = useState(
    weekdays.reduce((acc, day) => {
      acc[day] = {
        available: true,
        intervals: [{ from: null, to: null }],
      };
      return acc;
    }, {})
  );

  const onChange = (value) => {
    setCurrent(value);
  };

  useEffect(() => {
    if (nurse?.DID) {
      setFormData({
        ImageLocation: nurse?.ProfileImageUrl || "",
        FullName: nurse?.Name || "",
        Gender: nurse?.Gender || "",
        Email: nurse?.Email || "",
        PhoneNumber: nurse?.Phone || "",
        JoiningDate: nurse?.JoiningDate || "",
        Experience: nurse?.Experience || "",
        Speciality: nurse?.RelatedSpecialities?.[0]?.SID || null,
        Languages:
          nurse?.RelatedLanguages?.map((language) => language.LanguageID) || [],
        StreetName: nurse.Street,
        HouseNumber: nurse.HouseNo,
        PinCode: nurse.PinCode,
        City: nurse.City,
        State: nurse.State,
        Country: nurse.Country,
      });
      setImage(nurse?.Image || "");
      const newTimeIntervals = weekdays.reduce((acc, day) => {
        const dayAvailability = nurse?.AvailabilityTimes?.[day];
        acc[day] = {
          available: !!dayAvailability && dayAvailability.length > 0,
          intervals: dayAvailability?.map((interval) => {
            return {
              from: interval.Available_From,
              to: interval.Available_Until,
            };
          }) || [{ from: null, to: null }],
        };
        return acc;
      }, {});
      setTimeIntervals(newTimeIntervals);
    }
  }, [nurse?.DID]);
  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      {/* Header */}
      {isViewing ? (
        <div className="text-zinc-900 text-2xl font-medium font-poppins">
          Nurse Details
        </div>
      ) : (
        <div className="text-zinc-900 text-2xl font-medium font-poppins">
          {nurse?.DID ? "Edit" : "Add"} Nurse
        </div>
      )}
      <Breadcrumb />
      <div className="w-1/2 mt-5">
        <Steps
          size="small"
          labelPlacement="vertical"
          current={current}
          items={items}
          className="custom-steps"
          onChange={nurse?.DID ? onChange : undefined}
        />
      </div>
      <Form
        formData={formData}
        setFormData={setFormData}
        errors={errors}
        setErrors={setErrors}
        current={current}
        setCurrent={setCurrent}
        isViewing={isViewing}
        image={image}
        setImage={setImage}
        weekdays={weekdays}
        timeIntervals={timeIntervals}
        setTimeIntervals={setTimeIntervals}
        nurse={nurse}
      />
    </div>
  );
}
export default AddNewNurse;

const steps = [
  {
    title: "Basic Details",
  },
  {
    title: "Address Details",
  },
  {
    title: "Working Hours",
  },
];
