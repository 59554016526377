// src/slices/sidebarSlice.js
import { createSlice } from '@reduxjs/toolkit';

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    expanded: true, // default state can be true or false
  },
  reducers: {
    toggleSidebar: (state) => {
      state.expanded = !state.expanded;
    },
    setSidebarExpanded: (state, action) => {
      state.expanded = action.payload;
    },
  },
});

export const { toggleSidebar, setSidebarExpanded } = sidebarSlice.actions;
export default sidebarSlice.reducer;
