import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  policies: [],
  loading: false,
};

const platformManagementSlice = createSlice({
  name: "platformManagement",
  initialState: initialState,
  reducers: {
    setPolicies(state, action) {
      state.policies = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { setPolicies, setLoading } = platformManagementSlice.actions;

export default platformManagementSlice.reducer;
