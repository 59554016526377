import React from 'react'
import './style.css'

const Checkbox = ({ name, id, value, onChange, checked, text, textColor }) => {
  return (
    <label htmlFor={id} className="checkbox-label flex flex-row justify-center items-center mb-1">
      <input
        className="checkbox-input"
        type="checkbox"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className="custom-checkbox" />
      <h1 className={`${textColor ? textColor : "text-[#070808]"} text-xs font-semibold font-poppins text-left`}>
        {text}
      </h1>
    </label>
  )
}

export default Checkbox