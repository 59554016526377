const environment = "DEV";
// const environment = "PROD";
// const environment = "STAGE";

let AWS_BUCKET_NAME, DEFAULT_APP_URL;

switch (environment) {
  case "PROD":
    AWS_BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET_NAME_PROD;
    DEFAULT_APP_URL = process.env.REACT_APP_BASE_URL_PROD;
    break;
  case "STAGE":
    AWS_BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET_NAME_STAGE;
    DEFAULT_APP_URL = process.env.REACT_APP_BASE_URL_STAGE;
    break;
  default:
    AWS_BUCKET_NAME = process.env.REACT_APP_AWS_BUCKET_NAME_DEV;
    DEFAULT_APP_URL = process.env.REACT_APP_BASE_URL_DEV;
}

export default {
  AWS_KEY: process.env.REACT_APP_AWS_KEY,
  AWS_SECRET_KEY: process.env.REACT_APP_AWS_SECRET_KEY,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
  AWS_BUCKET_NAME,
  DEFAULT_APP_URL,
};
