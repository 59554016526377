import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus, FaMinus, FaChevronLeft, FaHeartPulse } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import iphoneIsland from "../../assets/Images/OurServices/iphoneIsland.png";
import NotificationPreviewBg from "../../assets/Images/TelehealthData/NotificationPreviewBg.png";
import notificationBackLogoIcon from "../../assets/Images/TelehealthData/notificationBackLogoIcon.png";
import BackViewNotification from "../../assets/Images/TelehealthData/BackViewNotification.jpg";
import FrontViewNotification from "../../assets/Images/TelehealthData/FrontViewNotification.png";
import rightArrowIcon from "../../assets/Images/OurServices/rightArrowIcon.png";
import durationIcon from "../../assets/Images/OurServices/durationIcon.png";
import notificationLogoIcon from "../../assets/Images/TelehealthData/notificationLogoIcon.png";
import Breadcrumb from "../../components/Breadcrumb";
import toast from "react-hot-toast";
import iphoneFrame from "../../assets/Images/MedicalConcierge/iphoneFrame.svg";
import Dropdown from "../../components/Dropdown";
import CircularRadioButton from "../../components/RadioButton/CircularRadioButton";
import moment from "moment/moment";
import { Space, TimePicker } from 'antd';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { createNewCustomNotification, getNotificationCategories, getPatientsCount, updateCustomNotification } from "../../services/operations/masterDataAPI";

function CustomNotification() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { token } = useSelector((state) => state.auth);
    const { allDoctors } = useSelector((state) => state.masterData);
    const notificationToEdit = location?.state?.notification || null;
    const navigateTo = location?.state?.navigateTo || "/serviceManagement/notificationManagement";
    const [showBackView, setShowBackView] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [image, setImage] = useState("");
    const [doctorsList, setDoctorsList] = useState([]);
    const [patientCountData, setPatientCountData] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [updating, setUpdating] = useState(false);
    const [notificationID, setNotificationID] = useState(null);

    const [formData, setFormData] = useState({
        NotificationCategory: "",
        NotificationID: "",
        Title: "",
        Description: "",
        Audience: "",
        notifyAt: "Send Now",
        date: "",
        time: "",
    });
    const [errors, setErrors] = useState({
        NotificationCategory: "",
        NotificationID: "",
        Title: "",
        Description: "",
        Audience: "",
        notifyAt: "",
        date: "",
        time: "",
    });

    useEffect(() => {
        const doctorOptions = allDoctors.map((doctor) => ({
            id: doctor.DID,
            name: doctor.Name,
            speciality: doctor.RelatedSpecialities
                ? doctor.RelatedSpecialities.map(speciality => speciality.Speciality).join(', ')
                : 'N/A',
            image: doctor.Image
        }));
        setDoctorsList(doctorOptions);
    }, [allDoctors]);

    useEffect(() => {
        const fetchPatientsCount = async () => {
            if (token) {
                try {
                    const res = await dispatch(getPatientsCount(token));
                    setPatientCountData(res);
                } catch (error) {
                    console.error("Failed to fetch patient count", error);
                }
            }
        };

        const fetchNotificationCategory = async () => {
            if (token) {
                try {
                    const res = await dispatch(getNotificationCategories(token));
                    setCategoryList(res);
                } catch (error) {
                    console.error("Failed to fetch Notification Categories", error);
                }
            }
        };

        fetchPatientsCount();
        fetchNotificationCategory();
        setIsLoading(false);
    }, [token, dispatch]);

    useEffect(() => {
        if (notificationToEdit) {
            setUpdating(true);
            setNotificationID(notificationToEdit?.ID);
            setFormData({
                NotificationCategory: notificationToEdit?.AnnouncementCategory?.category_name,
                NotificationID: notificationToEdit?.AnnouncementCategory?.category_id,
                Title: notificationToEdit?.title,
                Description: notificationToEdit?.message,
                Audience: notificationToEdit?.Audience,
                notifyAt: notificationToEdit?.NotifyUsers,
                date: notificationToEdit?.CustomDate,
                time: notificationToEdit?.CustomTime,
            });
        }
    }, [notificationToEdit]);

    if (isLoading) {
        return (
            <div className="h-screen flex items-center justify-center">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="bg-white min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
            {/* HEADER */}
            <div className="flex flex-row justify-between items-center">
                {/* TITLE */}
                <div className="flex flex-row items-center">
                    <div className="flex flex-col justify-center items-start gap-1">
                        <h1 className="text-[#1C1C1C] text-2xl font-medium font-poppins leading-9">
                            Notification Management
                        </h1>
                        <Breadcrumb />
                    </div>
                </div>
            </div>

            {/* Content */}
            <div className="flex gap-8 p-8">
                <NotificationForm
                    formData={formData}
                    setFormData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                    patientCountData={patientCountData}
                    categoryList={categoryList}
                    navigateTo={navigateTo}
                    updating={updating}
                    setUpdating={setUpdating}
                    notificationID={notificationID}
                />
                <PreviewSection
                    formData={formData}
                    showBackView={showBackView}
                    setShowBackView={setShowBackView}
                />
            </div>
        </div>
    );
}

const NotificationForm = ({
    formData,
    setFormData,
    errors,
    setErrors,
    patientCountData,
    categoryList,
    navigateTo,
    updating,
    setUpdating,
    notificationID,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useSelector((state) => state.auth);

    // Generate dropdown options with patient counts
    const audienceOptions = [
        { label: `All Patients (${patientCountData["All Patients"]})`, value: "All Patients" },
        { label: `Medical Concierge Members Only (${patientCountData["Medical Concierge Members Only"]})`, value: "Medical Concierge Members Only" },
        { label: `All Patients except Concierge members (${patientCountData["All Patients except Concierge members"]})`, value: "All Patients except Concierge members" },
    ];

    // Generate category options from categoryList
    const categoryOptions = categoryList.map(category => ({
        label: category.category_name,
        value: category.category_id,
    }));

    const handleTimeChange = (time, timeString) => {
        setFormData((prev) => ({
            ...prev,
            time: timeString,
        }));
        setErrors((prev) => ({
            ...prev,
            time: "",
        }));
    };

    function handleOnChange(event) {
        const { name, value } = event.target;
        let maxLength;

        // Set the maxLength based on the field name
        switch (name) {
            case "MinDuration":
                maxLength = 2;
                break;
            default:
                maxLength = Infinity; // No maxLength for other fields
        }

        if (value.length <= maxLength) {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
            setErrors((prev) => ({
                ...prev,
                [name]: "",
            }));
        }
    }

    const handleSelect = (name, selectedOption) => {
        if (name === "NotificationCategory") {
            const selectedCategory = categoryOptions.find(
                option => option.label === selectedOption
            );

            if (selectedCategory) {
                setFormData({
                    ...formData,
                    NotificationCategory: selectedCategory.label,
                    NotificationID: selectedCategory.value,
                });
            }
        } else {
            setFormData({
                ...formData,
                [name]: selectedOption,
            });
        }
    };

    // Function to get the disabled time options
    const getDisabledTimeOptions = () => {
        const now = moment();
        const selectedDate = moment(formData.date);
        const isToday = selectedDate.isSame(now, 'day');

        if (!isToday) {
            // If the date is not today, no time should be disabled
            return {
                disabledHours: () => [],
                disabledMinutes: () => [],
                disabledSeconds: () => [],
            };
        }

        const currentHour = now.hour();
        const currentMinute = now.minute();

        return {
            disabledHours: () => {
                // Disable hours before the current hour
                return Array.from({ length: currentHour }, (_, i) => i);
            },
            disabledMinutes: (hour) => {
                if (hour === currentHour) {
                    // Disable minutes before the next available slot
                    if (currentMinute < 30) {
                        return Array.from({ length: currentMinute + 1 }, (_, i) => i);
                    }
                    return [];
                }
                return [];
            },
            disabledSeconds: () => [],
        };
    };

    const { disabledHours, disabledMinutes, disabledSeconds } = getDisabledTimeOptions();

    const submitHandler = async (event) => {
        // event.preventDefault();
        const mandatoryFields = [
            "NotificationID",
            "Title",
            "Description",
            "Audience",
            "notifyAt",
        ];
        const newErrors = {};

        mandatoryFields.forEach((key) => {
            const value = formData[key];

            if (!value) {
                newErrors[key] = "This field is required";
            } else if (typeof value === "string" && value.trim() === "") {
                newErrors[key] = "This field cannot be empty";
            }
        });

        // If notifyAt is "Custom", validate date and time fields
        if (formData.notifyAt === "Custom") {
            if (!formData.date || formData.date.trim() === "") {
                newErrors.date = "Please select a custom date";
            }
            if (!formData.time || formData.time.trim() === "") {
                newErrors.time = "Please select a custom time";
            }
        }

        if (Object.keys(newErrors).length > 0) {
            toast.error("Please fill all the required fields");
            setErrors(newErrors);
            console.log("ERRORS", errors);
            return;
        }

        if (!updating) {
            const body = {
                "category_ID": formData?.NotificationID,
                "title": formData?.Title,
                "message": formData?.Description,
                "Audience": formData?.Audience,
                "NotifyUsers": formData?.notifyAt,
                "CustomDate": formData?.notifyAt === "Custom" ? moment(formData?.date).format("YYYY-MM-DD") : "",
                "CustomTime": formData?.notifyAt === "Custom" ? moment(formData?.time, 'hh:mm A').format('HH:mm:ss') : "",
            }

            try {
                dispatch(createNewCustomNotification(token, body, navigate, navigateTo));
            } catch (error) {
                console.error("Error adding Custom Notification:", error);
            }
        } else {
            if (notificationID) {
                const body = {
                    "category_ID": formData?.NotificationID,
                    "title": formData?.Title,
                    "message": formData?.Description,
                    "Audience": formData?.Audience,
                    "NotifyUsers": formData?.notifyAt,
                    "CustomDate": formData?.notifyAt === "Custom" ? moment(formData?.date).format("YYYY-MM-DD") : "",
                    "CustomTime": formData?.notifyAt === "Custom" ? moment(formData?.time, 'hh:mm A').format('HH:mm:ss') : "",
                }

                try {
                    dispatch(updateCustomNotification(token, notificationID, body, navigate, navigateTo));
                    setUpdating(false);
                } catch (error) {
                    console.error("Error adding Custom Notification:", error);
                }
            }
        }
    };

    return (
        <div className="w-1/2">
            <div className="bg-white rounded-lg shadow-md p-6 border border-sky-500">
                {/* Audience */}
                <div className="mb-4">
                    <label>
                        <div className="flex items-center justify-between">
                            <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                                Audience
                                <sup className="text-red-600">*</sup>
                            </p>
                        </div>
                        <Dropdown
                            placeholder="Select Audience"
                            error={errors.Audience}
                            name="Audience"
                            options={audienceOptions}
                            onSelect={handleSelect}
                            value={formData.Audience}
                            labelKey="label"
                            valueKey="value"
                        />
                    </label>
                </div>

                {/* Select Notification Category */}
                <div className="mb-6">
                    <label>
                        <div className="flex items-center justify-between">
                            <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                                Select Notification Category
                                <sup className="text-red-600">*</sup>
                            </p>
                        </div>
                        <Dropdown
                            placeholder="Select Category"
                            error={errors.NotificationCategory}
                            name="NotificationCategory"
                            options={categoryOptions}
                            onSelect={handleSelect}
                            value={formData.NotificationCategory}
                            labelKey="label"
                            valueKey="label"
                        />
                    </label>
                </div>

                {/* Title */}
                <div className="mb-6">
                    <label>
                        <div className="flex items-center justify-between">
                            <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                                Add Title
                                <sup className="text-red-600">*</sup>
                            </p>
                        </div>
                        <input
                            type="text"
                            name="Title"
                            value={formData.Title}
                            onChange={handleOnChange}
                            maxLength={30}
                            style={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                            placeholder="Max Character limit : 30 words"
                            className={`w-full ${errors.Title ? "border-red-500" : ""
                                }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent break-words`}
                        />
                    </label>
                </div>

                {/* Add Description */}
                <div className="mb-4">
                    <label>
                        <div className="flex items-center justify-between">
                            <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                                Add Description
                                <sup className="text-red-600">*</sup>
                            </p>
                            <p className="text-right text-neutral-600 text-xs font-normal font-poppins">
                                {formData?.Description.length}/80
                            </p>
                        </div>
                        <textarea
                            type="text"
                            name="Description"
                            value={formData.Description}
                            onChange={handleOnChange}
                            maxLength={80}
                            placeholder="Max Character limit : 80 words"
                            className={`w-full h-30 ${errors.Description ? "border-red-500" : ""
                                }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        />
                    </label>
                </div>

                {/* Notify Users At */}
                <div className="mb-4">
                    <label>
                        <div className="flex items-center justify-between">
                            <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                                Notify Users At
                                <sup className="text-red-600">*</sup>
                            </p>
                        </div>
                        <div className="flex flex-row justify-start items-center w-full gap-2">
                            <div className="flex flex-row justify-center items-center w-1/2">
                                <CircularRadioButton
                                    name="notifyAt"
                                    id="NowNotificationRadioButtom"
                                    value="Send Now"
                                    text="Send Now"
                                    textFont="font-medium"
                                    onChange={handleOnChange}
                                    checked={formData?.notifyAt === "Send Now"}
                                />
                                <CircularRadioButton
                                    name="notifyAt"
                                    id="Draft"
                                    value="Draft"
                                    text="Draft"
                                    textFont="font-medium"
                                    onChange={handleOnChange}
                                    checked={formData?.notifyAt === "Draft"}
                                />
                                <CircularRadioButton
                                    name="notifyAt"
                                    id="CustomNotificationRadioButtom"
                                    value="Custom"
                                    text="Custom"
                                    textFont="font-medium"
                                    onChange={handleOnChange}
                                    checked={formData?.notifyAt === "Custom"}
                                />
                            </div>
                            {formData?.notifyAt === "Custom" && (
                                <div className="flex flex-row justify-end items-center w-1/2 gap-2">
                                    <label>
                                        <input
                                            type="date"
                                            name="date"
                                            value={moment(formData.date).format("YYYY-MM-DD")}
                                            placeholder="Select Date"
                                            onChange={handleOnChange}
                                            min={moment().format("YYYY-MM-DD")}
                                            className={`w-28 accent-sky-500 text-sky-400 text-xs font-normal font-poppins bg-[#F3F5F7] rounded-lg p-2 border ${errors.date ? "border-red-500" : " border-[#3CA5DC]"
                                                }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                        />
                                    </label>
                                    <label>
                                        <TimePicker
                                            use12Hours
                                            format="h:mm a"
                                            name="time"
                                            onChange={handleTimeChange}
                                            className={`w-28 accent-sky-500 text-sky-400 text-xs font-normal font-poppins bg-[#F3F5F7] rounded-lg p-2 py-[6.2px] border ${errors.time ? "border-red-500" : " border-[#3CA5DC]"} focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                            value={formData.time ? moment(formData.time, "h:mm a") : null}
                                            disabledTime={() => ({
                                                disabledHours,
                                                disabledMinutes,
                                                disabledSeconds,
                                            })}
                                        />
                                    </label>
                                </div>
                            )}
                        </div>
                    </label>
                </div>
            </div>
            <div className="flex flex-row items-center justify-center mt-6 space-x-4">
                <button
                    onClick={submitHandler}
                    className="flex-1 px-5 py-3 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-500 active:scale-95"
                >
                    <div className="text-white text-base font-semibold font-poppins leading-normal">
                        Add Notification
                    </div>
                </button>
            </div>
        </div>
    );
};

const PhoneMockup = ({ children, showBackView, setShowBackView }) => {
    return (
        <div
            onClick={() => setShowBackView && setShowBackView(!showBackView)}
            className="relative mx-auto w-[300px] h-[600px]"
        >
            <img
                src={iphoneFrame}
                alt="iPhone Frame"
                className="w-full h-full object-contain"
            />
            <div className="absolute inset-y-[1.6%] left-[5%] right-[5.8%] overflow-hidden rounded-[40px]">{children}</div>
        </div>
    );
};

const PreviewSection = ({
    formData,
    showBackView,
    setShowBackView,
}) => {
    return (
        <div className="w-1/2">
            <div className="bg-white rounded-lg shadow-md p-6">
                <div className="text-center text-zinc-900 text-xl font-semibold font-poppins mb-7">
                    Preview for Patient
                </div>
                <div className="relative flex items-center justify-center h-[650px]">
                    <div>
                        <div
                            className={`absolute top-1 left-1/3 transition-all duration-300`}
                        >
                            <PhoneMockup
                                showBackView={showBackView}
                                setShowBackView={setShowBackView}
                            >
                                {showBackView ? (
                                    <FrontView
                                        formData={formData}
                                        showBackView={showBackView}
                                        setShowBackView={setShowBackView}
                                    />
                                ) : (
                                    <BackView
                                        formData={formData}
                                        showBackView={showBackView}
                                        setShowBackView={setShowBackView}
                                    />
                                )}
                            </PhoneMockup>
                        </div>
                        <div
                            className={`absolute top-12 left-1/4 transition-all duration-300`}
                        >
                            <PhoneMockup>
                                {showBackView ? (
                                    <BackView
                                        formData={formData}
                                        showBackView={showBackView}
                                        setShowBackView={setShowBackView}
                                    />
                                ) : (
                                    <FrontView
                                        formData={formData}
                                        showBackView={showBackView}
                                        setShowBackView={setShowBackView}
                                    />
                                )}
                            </PhoneMockup>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center space-x-10 mt-5">
                    <div
                        onClick={() => setShowBackView(false)}
                        className={`w-10 h-10 ${!showBackView
                            ? "bg-white border border-neutral-300"
                            : "bg-sky-100 border border-sky-400"
                            } rounded-full flex items-center justify-center`}
                    >
                        <IoChevronBack
                            className={`${!showBackView
                                ? "text-black cursor-not-allowed"
                                : "text-sky-400 cursor-pointer"
                                } `}
                        />
                    </div>
                    <div
                        onClick={() => setShowBackView(true)}
                        className={`w-10 h-10 ${showBackView
                            ? "bg-white border border-neutral-300"
                            : "bg-sky-100 border border-sky-400"
                            } rounded-full flex items-center justify-center`}
                    >
                        <IoChevronForward
                            className={`${showBackView
                                ? "text-black cursor-not-allowed"
                                : "text-sky-400 cursor-pointer"
                                } `}
                        />
                    </div>
                </div>
                <div className="w-full bg-gray-100 rounded-lg mt-7 p-2.5 flex items-center justify-center">
                    <div className="w-96 text-center text-black text-xl font-normal font-poppins">
                        This is How Notification will look to Users
                    </div>
                </div>
            </div>
        </div>
    );
};

const FrontView = ({ formData }) => (
    <div className="h-full w-full flex flex-col justify-between relative bg-cover mt-2"
        style={{ backgroundImage: `url(${FrontViewNotification})` }}>
        {/* <div className="absolute inset-0 bg-black opacity-50"></div> */}
        <img
            src={iphoneIsland}
            alt="iphone Island"
            className="w-16 h-6 object-contain absolute top-4 left-[6.5rem]"
        />

        {/* Card Content */}
        <div className="absolute left-0 top-[257px] w-[96%] ml-[6px] flex flex-row justify-center items-center bg-[#FFFFFF] rounded-[9.87px] p-[5.55px] pt-[8.64px]">
            <img
                src={notificationLogoIcon}
                alt="Logo"
                className="w-[23.45px] h-[23.45px] object-cover"
            />
            <div className="overflow-hidden flex flex-col justify-center items-start w-full ml-[5px]">
                <div className="flex flex-row justify-between items-center w-full">
                    <p className="text-[#222222] text-[9.26px] font-medium font-poppins">
                        {formData?.NotificationCategory}
                    </p>
                    <p className="text-[#3F3F3F] text-[7.41px] font-medium font-poppins">
                        now
                    </p>
                </div>
                <p className="max-w-full truncate text-[#3F3F3F] text-[9.26px] font-normal font-poppins">
                    {formData?.Description}
                </p>
            </div>
        </div>
    </div>
);

const BackView = ({ formData, showBackView, setShowBackView }) => (
    <div className="h-full w-full flex flex-col justify-between relative bg-cover"
        style={{ backgroundImage: `url(${BackViewNotification})` }}>
        {/* <div className="absolute inset-0 bg-black opacity-50"></div> */}
        <img
            src={iphoneIsland}
            alt="iphone Island"
            className="w-16 h-6 object-contain absolute top-4 left-[6.5rem]"
        />

        {/* Card Content */}
        <div
            style={{
                background: 'linear-gradient(124.11deg, rgba(59, 149, 196, 0.88) -4.43%, rgba(108, 228, 255, 0.61) 112.92%)',
                padding: '0.62px',  // Simulates the thickness of the border
                borderRadius: '2.47px',  // Rounded corners for the border
                boxSizing: 'border-box',  // Ensures padding is part of the element's size
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '65.5%',
                position: 'absolute',
                right: '0',
                top: '191px',
                marginRight: '9px',
            }}
        >
            <div
                style={{
                    background: '#FCFCFC',
                    margin: '0.2px',
                    borderRadius: 'inherit',  // Match the outer border's rounded corners
                    padding: '8.66px 7.42px 9.9px 7.42px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={notificationBackLogoIcon}
                    alt="Notification Logo"
                    className="w-[23.45px] h-[23.45px] object-contain"
                />
                <div className="overflow-hidden flex flex-col justify-center items-start w-full ml-[5px]">
                    <div className="flex flex-row justify-between items-center w-full">
                        <p className="text-[#000000] text-[9px] font-medium font-poppins truncate">
                            {formData?.NotificationCategory}
                        </p>
                        <p className="text-[#3CA5DC] text-[6.19px] font-normal font-poppins w-3">
                            1 h
                        </p>
                    </div>
                    <p className="max-w-full truncate text-[#8A8A8A] text-[8px] font-normal font-poppins">
                        {formData?.Description}
                    </p>
                </div>
            </div>
        </div>
    </div>
);


export default CustomNotification;
