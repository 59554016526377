export const USER_TYPE = {
  DOCTOR: "Doctor",
  NURSE: "Nurse",
  LAB_PARTNER: "Lab Partner",
  HEALTH_MANAGER: "Health Manager",
};

export const ACCESS_ROLES = {
  ADMIN: "Admin",
  DOCTOR: "Doctor",
  TELEHEALTH_DOCTOR: "Telehealth Doctor",
  NURSE: "Nurse",
  HEALTH_MANAGER: "Health Manager",
};

export const hasAccess = (userRoles, requiredRoles) => {
  return requiredRoles.some((role) => userRoles.includes(role));
};
