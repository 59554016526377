import React, { useState } from "react";
import RadioIcon from "./RadioIcon";
import toast from "react-hot-toast";
import Breadcrumb from "./Breadcrumb";

const ConsultationFormSetting = () => {
  const [preConsultation, setPreConsultation] = useState("12");
  const [postConsultation, setPostConsultation] = useState("3");

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      {/* Header */}
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        Form Settings
      </div>
      <Breadcrumb />

      <div className="max-w-3xl mt-6">
        <div className="p-6 rounded-lg shadow-md mt-6">
          <div className="text-gray-900 text-sm font-medium font-poppins leading-tight">
            Select Preferences to send consultation forms to your patients
          </div>
          <div className="border border-b-1 border-gray-100 my-3" />
          <h2 className="text-gray-900 text-sm font-medium font-poppins leading-tight">
            Pre-Consultation Form (In-App Notification)
          </h2>
          <p className="text-zinc-500 text-sm font-normal font-poppins leading-tight mt-1">
            Patients will receive the Pre-Consultation notification in the App
          </p>

          <div className="space-y-2 my-4">
            {["12", "24", "48"].map((option) => (
              <label key={option} className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  className="hidden"
                  checked={preConsultation === option}
                  onChange={() => setPreConsultation(option)}
                />
                <RadioIcon selected={preConsultation === option} />
                <span className="text-zinc-900 text-sm font-normal font-poppins leading-none">
                  {option} hrs before consultation
                </span>
              </label>
            ))}
          </div>

          <div className="border border-b-1 border-gray-100 my-3" />

          <h2 className="text-gray-900 text-sm font-medium font-poppins leading-tight">
            Post-Consultation Form (In-App Notification)
          </h2>
          <p className="text-zinc-500 text-sm font-normal font-poppins leading-tight mt-1">
            Patients will receive the Post-Consultation notification in the App
          </p>

          <div className="space-y-2 my-4">
            {["3", "5", "7"].map((option) => (
              <label key={option} className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  className="hidden"
                  checked={postConsultation === option}
                  onChange={() => setPostConsultation(option)}
                />
                <RadioIcon selected={postConsultation === option} />
                <span className="text-zinc-900 text-sm font-normal font-poppins leading-none">
                  {option} days after consultation
                </span>
              </label>
            ))}
          </div>

          <div className="border border-b-1 border-gray-100 my-3" />

          <button
            onClick={() => toast.success("Settings updated successfully")}
            className="w-64 px-4 py-2 bg-sky-400 rounded-lg text-white text-base font-semibold font-poppins leading-tight active:scale-95"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConsultationFormSetting;
