import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import Breadcrumb from "../components/Breadcrumb";
import Pagination from "../components/Pagination";
import {
  createClinicBranch,
  deleteClinicBranches,
  updateClinicBranches,
} from "../services/operations/masterDataAPI";
import ModalComponent from "../components/Modal";
import toast from "react-hot-toast";
import Tooltip from "../components/Tooltip";
import deleteIcon from "../assets/Images/MedicalConcierge/deleteRedIcon.svg";
import Dropdown from "../components/Dropdown";

function BranchManagement() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { allBranches, loading: masterDataLoading } = useSelector(
    (state) => state.masterData
  );
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const [showAddBranchModal, setShowAddBranchModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [formData, setFormData] = useState({
    Name: "",
    Type: "",
    Address: "",
    Latitude: "",
    Longitude: "",
  });

  const [errors, setErrors] = useState({
    Name: "",
    Type: "",
    Address: "",
    Latitude: "",
    Longitude: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  function handleSelect(name, option) {
    setFormData((prev) => ({
      ...prev,
      [name]: option,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  const filteredBranches = allBranches.filter((p) => {
    return p?.Name?.toLowerCase().includes(searchInput?.toLowerCase());
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentBranches = filteredBranches.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getGeolocation = async (address) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=AIzaSyC3yqZu_m7_y_T58JWIPMrOb3ohqyHEgks`
      );
      const data = await response.json();

      if (data.results && data.results[0]?.geometry?.location) {
        const { lat, lng } = data.results[0].geometry.location;
        console.log("Geolocation:", lat, lng);
        return { lat, lng };
      } else {
        console.error("Geolocation data not found in API response");
        return null;
      }
    } catch (error) {
      console.error("Error fetching geolocation:", error);
      return null;
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const mandatoryFields = ["Name", "Type", "Address"];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      return;
    }

    const geolocation = await getGeolocation(formData.Address);
    if (geolocation !== null) {
      const { lat, lng } = geolocation;
      const updatedFormData = {
        ...formData,
        Latitude: lat,
        Longitude: lng,
      };

      setFormData(updatedFormData);

      const basicData = {
        Name: updatedFormData.Name,
        Type: updatedFormData.Type,
        Address: updatedFormData.Address,
        Latitude: updatedFormData.Latitude,
        Longitude: updatedFormData.Longitude,
      };

      if (selectedBranch?.BranchID) {
        dispatch(
          updateClinicBranches(token, selectedBranch?.BranchID, basicData)
        );
      } else {
        dispatch(createClinicBranch(token, basicData));
      }
      setShowAddBranchModal(false);
      setFormData({
        Name: "",
        Type: "",
        Address: "",
        Latitude: "",
        Longitude: "",
      });
      setErrors({
        Name: "",
        Type: "",
        Address: "",
        Latitude: "",
        Longitude: "",
      });
    } else {
      toast.error("Please provide a valid address.");
      setErrors((prev) => ({
        ...prev,
        Address: "Invalid address",
      }));
    }
  };

  const handleDeleteService = (id) => {
    dispatch(deleteClinicBranches(token, id));
    setShowDeleteModal(false);
    setSelectedBranch(null);
  };

  const handleCloseModal = () => {
    setSelectedBranch(null);
    setShowAddBranchModal(false);
    setFormData({
      Name: "",
      Type: "",
      Address: "",
      Latitude: "",
      Longitude: "",
    });
    setErrors({
      Name: "",
      Type: "",
      Address: "",
      Latitude: "",
      Longitude: "",
    });
  };

  if (masterDataLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      <ModalComponent
        show={showAddBranchModal}
        handleClose={handleCloseModal}
        outSideModalPress={false}
      >
        <div className="w-[30vw]">
          <div className="text-slate-800 text-xl font-medium font-poppins leading-normal">
            {selectedBranch?.BranchID ? "Edit Branch" : "Add New Branch"}
          </div>
          <div className="border border-b-1 border-gray-100 my-3" />
          {/* Plan Name */}
          <div className="mb-6">
            <label>
              <div className="flex items-center justify-between">
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Name of Clinic
                  <sup className="text-red-600">*</sup>
                </p>
              </div>
              <input
                type="text"
                name="Name"
                value={formData.Name}
                onChange={handleInputChange}
                maxLength={20}
                placeholder="Enter name of clinic here"
                className={`w-full ${
                  errors.Name ? "border-red-500" : ""
                }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>

          {/* Type of Clinic */}
          <div className="mb-6">
            <label>
              <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                Type of Clinic
                <sup className="text-red-600">*</sup>
              </p>
              <Dropdown
                error={errors.Type}
                name="Type"
                placeholder={"Select Type"}
                options={[
                  "Pediatric Clinics",
                  "Community Health Centers",
                  "Urgent Care Clinics",
                  "Dental Clinics",
                ]}
                onSelect={handleSelect}
                value={formData.Type}
              />
            </label>
          </div>

          {/* Address */}
          <div className="mb-6">
            <label>
              <div className="flex items-center justify-between">
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Address
                  <sup className="text-red-600">*</sup>
                </p>
              </div>
              <textarea
                type="text"
                name="Address"
                value={formData.Address}
                onChange={handleInputChange}
                maxLength={100}
                placeholder="Enter address here"
                className={`w-full ${
                  errors.Address ? "border-red-500" : ""
                }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>
        </div>

        <div className="flex flex-row items-center justify-center mt-6 space-x-4">
          <button
            onClick={handleCloseModal}
            className="flex-1 px-5 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
          >
            <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
              Cancel
            </div>
          </button>
          <button
            onClick={submitHandler}
            className="flex-1 px-5 py-3 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-500 active:scale-95"
          >
            <div className="text-white text-base font-semibold font-poppins leading-normal">
              {selectedBranch?.BranchID ? "Update Branch" : "Add Branch"}
            </div>
          </button>
        </div>
      </ModalComponent>

      <ModalComponent
        show={showDeleteModal}
        handleClose={() => {
          setShowDeleteModal(false);
          setSelectedBranch(null);
        }}
        outSideModalPress={false}
        showCloseButton={false}
      >
        <div className="flex flex-col items-center">
          <div className="w-14 h-14 p-3 bg-red-50 rounded-full mb-6">
            <img src={deleteIcon} alt="" />
          </div>
          <div className="text-center text-slate-800 text-base font-semibold font-poppins leading-tight">
            Remove '{selectedBranch?.Name}'?
          </div>
          <div className="text-center max-w-sm mx-auto text-zinc-500 text-sm font-normal font-poppins leading-tight mt-4">
            Once Removed, the branch will be permanently deleted.
          </div>
          <div className="flex flex-row items-center justify-end mt-6 space-x-4">
            <button
              onClick={() => {
                setShowDeleteModal(false);
                setSelectedBranch(null);
              }}
              className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
                Cancel
              </div>
            </button>
            <button
              onClick={() => handleDeleteService(selectedBranch?.BranchID)}
              className="w-48 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-white text-base font-semibold font-poppins leading-normal">
                Remove
              </div>
            </button>
          </div>
        </div>
      </ModalComponent>

      {/* Header */}
      <div className="flex flex-row items-center justify-between">
        <div className="text-zinc-900 text-2xl font-medium font-poppins">
          Branch Management
        </div>
        <button
          onClick={() => setShowAddBranchModal(true)}
          className="px-3 py-2 bg-sky-400 rounded shadow justify-center items-center gap-2.5 inline-flex cursor-pointer active:scale-95"
        >
          <FaPlus className="text-white text-sm" />
          <div className="text-white text-sm font-normal font-poppins">
            Add New Branch
          </div>
        </button>
      </div>
      <Breadcrumb />

      {/* Search Bar */}
      <label className="w-full mx-auto flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2 mt-6">
        <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
        <input
          type="text"
          placeholder="Search for Branch"
          value={searchInput}
          onChange={handleSearchChange}
          className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
        />
      </label>

      <div className="w-full mx-auto mt-4 shadow rounded-md">
        <table className="min-w-full">
          <thead>
            <tr className="text-left bg-gray-50 border-b border-gray-200">
              <th className="p-4 text-zinc-900 text-xs font-medium font-poppins leading-none">
                Name of Branch
              </th>
              <th className="p-4 text-zinc-900 text-xs font-medium font-poppins leading-none">
                Type of Branch
              </th>
              <th className="p-4 text-zinc-900 text-xs font-medium font-poppins leading-none">
                Address
              </th>
              <th className="p-4 text-zinc-900 text-xs font-medium font-poppins leading-none">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {currentBranches?.map((branch) => (
              <tr
                key={branch?.BranchID}
                className="border-b border-gray-200 hover:bg-sky-50"
              >
                <td className="p-4">
                  <p className="text-gray-900 text-sm font-medium font-poppins leading-tight">
                    {branch?.Name}
                  </p>
                  <p className="text-zinc-500 text-sm font-normal font-poppins leading-tight">
                    ID: {branch?.BranchID}
                  </p>
                </td>
                <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                  {branch?.Type}
                </td>
                <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                  {branch?.Address}
                </td>
                <td className="p-4">
                  <div className="flex flex-row items-center space-x-2">
                    <Tooltip message="Edit">
                      <button
                        onClick={() => {
                          setSelectedBranch(branch);
                          setFormData({
                            Name: branch.Name,
                            Type: branch.Type,
                            Address: branch.Address,
                            Latitude: branch.Latitude,
                            Longitude: branch.Longitude,
                          });
                          setShowAddBranchModal(true);
                        }}
                        className="p-1 rounded-full group group-hover:bg-sky-100"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            stroke-linecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>

                    <Tooltip message="Delete">
                      <button
                        onClick={() => {
                          setSelectedBranch(branch);
                          setShowDeleteModal(true);
                        }}
                        className="p-1 rounded-full group group-hover:bg-sky-100"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M19.3008 5.5L18.6811 15.5251C18.5227 18.0864 18.4436 19.3671 17.8016 20.2879C17.4841 20.7431 17.0755 21.1273 16.6015 21.416C15.6429 22 14.3598 22 11.7935 22C9.2239 22 7.93908 22 6.97983 21.4149C6.50558 21.1257 6.09678 20.7408 5.77946 20.2848C5.13766 19.3626 5.06023 18.0801 4.90539 15.5152L4.30078 5.5"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M2.80078 5.5H20.8008M15.8565 5.5L15.1739 4.09173C14.7204 3.15626 14.4936 2.68852 14.1025 2.39681C14.0158 2.3321 13.9239 2.27454 13.8278 2.2247C13.3947 2 12.8749 2 11.8353 2C10.7696 2 10.2368 2 9.79646 2.23412C9.69888 2.28601 9.60576 2.3459 9.51807 2.41317C9.12242 2.7167 8.90141 3.20155 8.45939 4.17126L7.8537 5.5"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M9.30078 16.5V10.5"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M14.3008 16.5V10.5"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        totalItems={filteredBranches.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default BranchManagement;
