import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import bgImage from "../assets/Images/LabPartner/noLabPartnerFound.png";
import Checkbox from "../assets/Images/LabPartner/Checkbox.png";
import CheckboxFilled from "../assets/Images/LabPartner/CheckboxFilled.png";
import moment from "moment";
import { useLocation } from "react-router-dom";
import "react-day-picker/dist/style.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaChevronLeft } from "react-icons/fa6";
import { useLoadScript } from "@react-google-maps/api";
import {
  addLabPartners,
  updateLabPartners,
} from "../services/operations/masterDataAPI";
import { FiEdit } from "react-icons/fi";
import Dropdown from "../components/Dropdown";
import { countries } from "../data/country";
import Breadcrumb from "../components/Breadcrumb";

const libraries = ["places"];

function AddLabPartner() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const labPartner = location.state || {};
  const { token } = useSelector((state) => state.auth);
  const { labTypes } = useSelector((state) => state.masterData);
  const [isViewing, setIsViewing] = useState(false);

  const [formData, setFormData] = useState({
    LabName: "",
    Email: "",
    StreetName: "",
    HouseNumber: "",
    PinCode: "",
    State: "",
    City: "",
    Country: "",
    PhoneNumber: "",
    TypeofLab: [],
    Latitude: "",
    Longitude: "",
  });

  const [errors, setErrors] = useState({
    LabName: "",
    Email: "",
    StreetName: "",
    HouseNumber: "",
    PinCode: "",
    State: "",
    City: "",
    Country: "",
    PhoneNumber: "",
    TypeofLab: "",
  });

  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC3yqZu_m7_y_T58JWIPMrOb3ohqyHEgks",
    libraries,
  });
  const handleChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    getPlacePredictions(value);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  function handleSelect(name, option) {
    setFormData((prev) => ({
      ...prev,
      [name]: option,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  const getPlacePredictions = (input) => {
    if (!input) {
      setSuggestions([]);
      return;
    }

    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input }, (predictions, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        console.error(status);
        return;
      }

      setSuggestions(predictions);
    });
  };

  const handleSuggestionClick = (placeId) => {
    const service = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    service.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const addressComponents = place.address_components.reduce(
          (acc, component) => {
            const types = component.types;
            if (types.includes("sublocality_level_2"))
              acc.streetNumber = component.long_name;
            if (types.includes("sublocality")) acc.street = component.long_name;
            if (types.includes("postal_code"))
              acc.pinCode = component.long_name;
            if (types.includes("administrative_area_level_1"))
              acc.state = component.long_name;
            if (types.includes("country")) acc.country = component.long_name;
            if (types.includes("locality")) acc.city = component.long_name;
            return acc;
          },
          {}
        );
        const { lat, lng } = place.geometry.location;
        setFormData((prevFormData) => ({
          ...prevFormData,
          StreetName: `${addressComponents.streetNumber} ${addressComponents.street}`,
          PinCode: addressComponents.pinCode,
          State: addressComponents.state,
          Country: addressComponents.country.toUpperCase(),
          City: addressComponents.city,
          Latitude: lat(),
          Longitude: lng(),
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          StreetName: "",
          PinCode: "",
          State: "",
          Country: "",
          City: "",
        }));

        setInputValue(place.formatted_address);
        setSuggestions([]);
      } else {
        console.error("Place details request failed:", status);
      }
    });
  };

  const handleTypeOfLabSelection = (id) => {
    setFormData((prevFormData) => {
      const newTypeofLab = prevFormData.TypeofLab.includes(id)
        ? prevFormData.TypeofLab.filter((lab) => lab !== id)
        : [...prevFormData.TypeofLab, id];

      return { ...prevFormData, TypeofLab: newTypeofLab };
    });
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    const mandatoryFields = [
      "LabName",
      "Email",
      "StreetName",
      // "HouseNumber",
      "PinCode",
      "State",
      "City",
      "Country",
      "PhoneNumber",
      "TypeofLab",
    ];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      console.log("ERRORS", errors);
      return;
    }

    const body = {
      Name: formData.LabName,
      Email: formData.Email,
      Country: formData.Country,
      Pincode: formData.PinCode,
      City: formData.City,
      State: formData.State,
      Street: formData.StreetName ? formData.StreetName : "",
      Phone: formData.PhoneNumber,
      LabTypes: formData.TypeofLab,
      Latitude: formData.Latitude,
      Longitude: formData.Longitude,
    };

    // if (body.Street.includes(undefined)) {
    //   toast.error("Please provide a precise address");
    //   return;
    // }

    if (formData?.TypeofLab.length === 0) {
      toast.error("Please select at least one type of lab");
      return;
    }

    if (labPartner.LPID) {
      delete body.Email;
    }

    if (labPartner.LPID) {
      dispatch(updateLabPartners(token, labPartner.LPID, body, navigate));
    } else {
      dispatch(addLabPartners(token, body, navigate));
    }
  };
  useEffect(() => {
    if (labPartner.LPID) {
      setFormData({
        LabName: labPartner.Name,
        Email: labPartner.Email,
        StreetName: labPartner.Street,
        PinCode: labPartner.Pincode,
        State: labPartner.State,
        City: labPartner.City,
        Country: labPartner.Country,
        PhoneNumber: labPartner.Phone,
        TypeofLab: labPartner.LabTypes.map((type) => type.LTID),
        Latitude: labPartner.Latitude,
        Longitude: labPartner.Longitude,
      });
    }
  }, [labPartner]);

  useEffect(() => {
    if (labPartner.isViewing) setIsViewing(true);
  }, [labPartner]);

  return (
    <div className="bg-white p-6 min-h-full">
      {/* Header */}
      <div className="flex flex-row items-center justify-between">
        {!isViewing ? (
          <>
            <div className="flex flex-row items-center space-x-4">

              <div>
                <h1 className="text-black text-lg font-bold font-poppins">
                  {labPartner.LPID
                    ? "Edit Lab Partner"
                    : " Add New Lab Partner"}
                </h1>
                <p className="text-zinc-500 text-xs font-normal font-poppins">
                  {labPartner.LPID
                    ? "Here is the form to edit lab partner"
                    : " Here is the form to add a new lab partner"}
                </p>
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-row items-center space-x-4">
            <div>
              <h1 className="text-black text-lg font-bold font-poppins">
                {labPartner.Name}
              </h1>
              <p className="text-zinc-500 text-xs font-normal font-poppins">
                ID : {labPartner.LPID}
              </p>
            </div>
          </div>
        )}
        {isViewing && (
          <button
            onClick={() => setIsViewing(false)}
            className="bg-sky-400 rounded-lg  active:scale-95 active:shadow-none text-center text-stone-50 text-sm font-semibold font-poppins flex flex-row items-center justify-center px-5 py-2"
          >
            <FiEdit className="mr-1 w-4 h-4" />
            Edit
          </button>
        )}
      </div>

      <Breadcrumb />

      <div className="flex flex-row items-center justify-between mt-5">
        {/* Form */}
        <div className="flex-1">
          <div className="w-1/2">
            {/* Lab Name */}
            <div className="mb-3">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Lab Name
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  disabled={isViewing}
                  name="LabName"
                  maxLength={20}
                  value={formData.LabName}
                  onChange={handleOnChange}
                  placeholder="Input lab name here"
                  className={`${
                    isViewing && "cursor-not-allowed"
                  } w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.LabName ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>

            {/* Email */}
            <div className="mb-3">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Email
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  disabled={isViewing || labPartner.LPID}
                  type="email"
                  name="Email"
                  // maxLength={20}
                  value={formData.Email}
                  onChange={handleOnChange}
                  placeholder="Abc@gmail.com"
                  className={`${
                    isViewing && "cursor-not-allowed"
                  } w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.Email ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>

            <div className="mb-3 flex space-x-4">
              <label className="flex-1 relative">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Street Name
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  disabled={isViewing}
                  type="text"
                  name="StreetName"
                  maxLength={50}
                  // value={formData.StreetName}
                  // onChange={handleOnChange}
                  value={labPartner.LPID ? formData.StreetName : inputValue}
                  onChange={labPartner.LPID ? handleOnChange : handleChange}
                  placeholder="Input street name here"
                  className={`${
                    isViewing && "cursor-not-allowed"
                  } w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.StreetName ? "border-red-500" : ""
                  } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
                {suggestions.length > 0 && (
                  <div className="absolute left-0 mt-2 w-[25vw] bg-white border rounded-lg p-2 space-y-2 shadow-lg z-10">
                    {suggestions?.slice(0, 4).map((x, i) => (
                      <button
                        key={i}
                        onClick={() => handleSuggestionClick(x.place_id)}
                        className={`block w-full text-left px-4 py-2 text-xs rounded-lg bg-gray-100 text-black hover:bg-sky-400 hover:text-white duration-200`}
                      >
                        {x.description}
                      </button>
                    ))}
                  </div>
                )}
              </label>
              <label className="flex-1">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Building Number
                  {/* <sup className="text-red-600">*</sup> */}
                </p>
                <input
                  disabled={isViewing}
                  type="text"
                  name="HouseNumber"
                  maxLength={10}
                  value={formData.HouseNumber}
                  onChange={handleOnChange}
                  placeholder="Input building number here"
                  className={`${
                    isViewing && "cursor-not-allowed"
                  } w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.HouseNumber ? "border-red-500" : ""
                  } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>

            <div className="mb-3 flex space-x-4">
              <label className="flex-1">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Pin Code
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  disabled={isViewing}
                  type="text"
                  name="PinCode"
                  maxLength={6}
                  value={formData.PinCode}
                  onChange={handleOnChange}
                  placeholder="Input pin code here"
                  className={`${
                    isViewing && "cursor-not-allowed"
                  } w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.PinCode ? "border-red-500" : ""
                  } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
              <label className="flex-1">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  City
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  disabled={isViewing}
                  type="text"
                  name="City"
                  maxLength={50}
                  value={formData.City}
                  onChange={handleOnChange}
                  placeholder="Input city here"
                  className={`${
                    isViewing && "cursor-not-allowed"
                  } w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.City ? "border-red-500" : ""
                  } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            <div className="mb-3 flex space-x-4">
              <label className="flex-1">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Region / State / Canton
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  disabled={isViewing}
                  type="text"
                  name="State"
                  maxLength={50}
                  value={formData.State}
                  onChange={handleOnChange}
                  placeholder="Input state here"
                  className={`${
                    isViewing && "cursor-not-allowed"
                  } w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.State ? "border-red-500" : ""
                  } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
              <label className="flex-1">
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Country
                  <sup className="text-red-600">*</sup>
                </p>
                <Dropdown
                  disabled={isViewing}
                  placeholder={"Select Country"}
                  error={errors.Country}
                  name="Country"
                  options={countries}
                  onSelect={handleSelect}
                  value={formData.Country}
                  labelKey="label"
                  valueKey="value"
                  enableSearch={true}
                />
              </label>
            </div>

            <div className="mb-3">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Phone Number
                  <sup className="text-red-600">*</sup>
                </p>
                <PhoneInput
                  disabled={isViewing}
                  className="number"
                  country={"ch"}
                  value={formData.PhoneNumber}
                  onChange={(value) => {
                    setFormData({ ...formData, PhoneNumber: value });
                    setErrors({ ...errors, PhoneNumber: "" });
                  }}
                  inputClass={`w-full text-black text-xs font-normal font-poppins rounded-lg p-3 border ${
                    errors.PhoneNumber ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  inputStyle={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#F3F5F7",
                    border: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    outline: "none",
                    borderRadius: 6,
                  }}
                  buttonStyle={{
                    borderLeft:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderTop: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderBottom:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderRight: errors.PhoneNumber && "1px solid transparent",
                    borderTopLeftRadius: 6,
                    borderBottomLeftRadius: 6,
                  }}
                />
              </label>
            </div>
          </div>
          <div className="w-3/5">
            <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
              Type of Lab
              <sup className="text-red-600">*</sup>
            </p>
            <div className="flex flex-row items-center flex-wrap gap-3">
              {labTypes?.map((item) => (
                <button
                  disabled={isViewing}
                  key={item?.LTID}
                  className={`${
                    formData.TypeofLab.includes(item.LTID)
                      ? "bg-sky-100 text-sky-400"
                      : "bg-gray-100 text-zinc-500"
                  } ${
                    isViewing ? "cursor-not-allowed" : "cursor-pointer"
                  } px-2.5 py-1  text-sm font-semibold font-poppins rounded-xl flex flex-row items-center space-x-2`}
                  onClick={() => handleTypeOfLabSelection(item.LTID)}
                >
                  <img
                    src={
                      formData.TypeofLab.includes(item.LTID)
                        ? CheckboxFilled
                        : Checkbox
                    }
                    alt="Checkbox"
                    className="w-4 h-4 object-contain"
                  />
                  <div>{item?.Name}</div>
                </button>
              ))}
            </div>
          </div>
          {!isViewing && (
            <div className="flex flex-row items-center space-x-8 mt-8">
              <button
                onClick={submitHandler}
                className="w-52 h-11 shadow-md shadow-sky-400 bg-sky-400 rounded-lg  active:scale-95 active:shadow-none text-center text-stone-50 text-sm font-semibold font-poppins "
              >
                {labPartner.LPID ? "Update" : "Finish"}
              </button>
              <button
                onClick={() => {
                  navigate(-1);
                }}
                className="w-52 h-11 shadow-md shadow-neutral-400 bg-neutral-400 rounded-lg  active:scale-95 active:shadow-none  text-center text-stone-50 text-sm font-semibold font-poppins "
              >
                Back
              </button>
            </div>
          )}
        </div>

        {/* Image */}
        <div className="">
          <img src={bgImage} alt="" className="w-96 h-96 object-contain" />
        </div>
      </div>
    </div>
  );
}

export default AddLabPartner;

const TYPESOFLAB = [
  {
    id: 0,
    text: "Hematology",
  },
  {
    id: 1,
    text: "Molecular Diagnostics",
  },
  {
    id: 2,
    text: "Urinalysis",
  },
  {
    id: 3,
    text: "Microbiology",
  },
  {
    id: 4,
    text: "Coagulation",
  },
  {
    id: 5,
    text: "Immunology",
  },
];
