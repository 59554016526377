import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  preConsultationForm: [],
  postConsultationForm: [],
  signedForm: [],
  scheduledForm: [],
};

const formSlice = createSlice({
  name: "form",
  initialState: initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setPreConsultationForm(state, action) {
      state.preConsultationForm = action.payload;
    },
    setPostConsultationForm(state, action) {
      state.postConsultationForm = action.payload;
    },
    setSignedForm(state, action) {
      state.signedForm = action.payload;
      console.log("called with payload:", action.payload);
      console.log("Updated state:", state.signedForm);
    },
    setScheduledForm(state, action) {
      state.scheduledForm = action.payload;
    },
  },
});

export const {
  setLoading,
  setPreConsultationForm,
  setPostConsultationForm,
  setSignedForm,
  setScheduledForm,
} = formSlice.actions;

export default formSlice.reducer;
