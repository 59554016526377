import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { adherence } from "../data/adherence";
import moment from "moment";
import completed from "../assets/Images/PatientProfile/DosageAdherence/completed.svg";
import missed from "../assets/Images/PatientProfile/DosageAdherence/missed.svg";
import delayed from "../assets/Images/PatientProfile/DosageAdherence/delayed.svg";
import future from "../assets/Images/PatientProfile/DosageAdherence/future.svg";
import discontinued from "../assets/Images/PatientProfile/DosageAdherence/discontinued.svg";
import Tooltip from "./Tooltip";

const DosageAdherence = () => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [dosageData, setDosageData] = useState(adherence);
  const [timeRanges, setTimeRanges] = useState([]);
  const [viewMode, setViewMode] = useState("monthly");

  useEffect(() => {
    updateTimeRanges(currentDate, viewMode);
  }, [currentDate, viewMode]);

  const updateTimeRanges = (date, mode) => {
    if (mode === "monthly") {
      calculateWeekRanges(date);
    } else {
      calculateMonthRanges(date);
    }
  };

  const calculateWeekRanges = (date) => {
    const startOfMonth = date.clone().startOf("month");
    const endOfMonth = date.clone().endOf("month");
    const ranges = [];

    let weekStart = startOfMonth.clone().startOf("week");
    while (weekStart.isBefore(endOfMonth)) {
      const weekEnd = weekStart.clone().endOf("week");
      ranges.push({
        start: weekStart.format("D MMM"),
        end: weekEnd.format("D MMM"),
        isCurrent: moment().isBetween(weekStart, weekEnd, null, "[]"),
      });
      weekStart.add(1, "week");
    }

    setTimeRanges(ranges);
  };

  // const calculateMonthRanges = (date) => {
  //   const startOfYear = date.clone().startOf("year");
  //   const ranges = [];

  //   for (let i = 0; i < 12; i++) {
  //     const monthStart = startOfYear.clone().add(i, "months");
  //     const monthEnd = monthStart.clone().endOf("month");
  //     const weekRanges = [];

  //     let weekStart = monthStart.clone().startOf("week");
  //     while (weekStart.isBefore(monthEnd)) {
  //       const weekEnd = weekStart.clone().endOf("week");
  //       weekRanges.push({
  //         start: weekStart.format("D"),
  //         end: weekEnd.format("D"),
  //         isCurrent: moment().isBetween(weekStart, weekEnd, null, "[]"),
  //       });
  //       weekStart.add(1, "week");
  //     }

  //     ranges.push({
  //       name: monthStart.format("MMMM"),
  //       weeks: weekRanges,
  //       isCurrent: moment().isSame(monthStart, "month"),
  //     });
  //   }

  //   setTimeRanges(ranges);
  // };

  const calculateMonthRanges = (date) => {
    const startOfYear = date.clone().startOf("year");
    const ranges = [];

    for (let i = 0; i < 12; i++) {
      const monthStart = startOfYear.clone().add(i, "months").startOf("month");
      const monthEnd = monthStart.clone().endOf("month");
      const weekRanges = [];

      let weekStart = monthStart.clone().startOf("week");
      while (weekStart.isSameOrBefore(monthEnd)) {
        const weekEnd = weekStart.clone().endOf("week");
        const adjustedStart = moment.max(weekStart, monthStart);
        const adjustedEnd = moment.min(weekEnd, monthEnd);

        weekRanges.push({
          start: adjustedStart.format("D"),
          end: adjustedEnd.format("D"),
          isCurrent: moment().isBetween(adjustedStart, adjustedEnd, null, "[]"),
        });

        weekStart.add(1, "week");
      }

      ranges.push({
        name: monthStart.format("MMMM"),
        weeks: weekRanges,
        isCurrent: moment().isSame(monthStart, "month"),
      });
    }

    setTimeRanges(ranges);
  };

  const changeDate = (direction) => {
    setCurrentDate((prevDate) =>
      prevDate.clone().add(direction, viewMode === "monthly" ? "month" : "year")
    );
  };

  const toggleViewMode = (mode) => {
    setViewMode(mode);
    updateTimeRanges(currentDate, mode);
  };

  return (
    <div>
      <div className="text-black text-lg font-medium font-poppins leading-relaxed">
        Dosage Adherence
      </div>

      {/* Info */}
      <div className="flex items-center justify-between mt-2">
        <div className="flex items-center space-x-3">
          {INFO.map((info, i) => (
            <div key={i} className="flex items-center space-x-2">
              <img src={info.icon} alt={info.title} className="w-5 h-5" />
              <p className="text-black text-sm font-medium font-poppins">
                {info.title}
              </p>
            </div>
          ))}
        </div>

        <div className="flex items-center space-x-10">
          {/* Toggle Date */}
          <div className="w-64 px-4 py-2.5 bg-white rounded-lg border border-neutral-300 flex flex-row items-center justify-between">
            <button onClick={() => changeDate(-1)}>
              <ChevronLeft className="w-6 h-6" />
            </button>
            <div className="text-center text-black text-base font-semibold font-poppins">
              {viewMode === "monthly"
                ? currentDate.format("MMM YYYY")
                : currentDate.format("YYYY")}
            </div>
            <button onClick={() => changeDate(1)}>
              <ChevronRight className="w-6 h-6" />
            </button>
          </div>

          {/* Toggle View */}
          <div className="flex flex-row items-center">
            <button
              onClick={() => toggleViewMode("monthly")}
              className={`px-4 py-3 ${
                viewMode === "monthly"
                  ? "bg-sky-100 text-sky-700 border-blue-200"
                  : "bg-white text-neutral-600 border-neutral-300"
              } rounded-tl-lg rounded-bl-lg border-l border-t border-b text-sm font-medium font-poppins leading-tight`}
            >
              Monthly
            </button>
            <button
              onClick={() => toggleViewMode("yearly")}
              className={`px-4 py-3 ${
                viewMode === "yearly"
                  ? "bg-sky-100 text-sky-700 border-blue-200"
                  : "bg-white text-neutral-600 border-neutral-300"
              } rounded-tr-lg rounded-br-lg border-r border-t border-b text-sm font-medium font-poppins leading-tight`}
            >
              Yearly
            </button>
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-6 shadow rounded-md overflow-x-auto scroll-smooth scrollbar-thin scrollbar-thumb-neutral-300 scrollbar-track-neutral-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
        <table className=" min-w-full ">
          <thead>
            <tr className="text-left bg-gray-50 border-b border-gray-200">
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Medicine Name
              </th>
              {viewMode === "monthly"
                ? timeRanges.map((range, index) => (
                    <th
                      key={index}
                      className="p-4 text-xs font-medium font-poppins leading-none text-neutral-600"
                    >
                      <div className="flex items-center space-x-3">
                        <span>
                          {range.start} - {range.end}
                        </span>
                        {range.isCurrent && (
                          <Tooltip message="Current Week">
                            <div className="w-2.5 h-2.5 bg-sky-400 rounded-full animate-pulse" />
                          </Tooltip>
                        )}
                      </div>
                    </th>
                  ))
                : timeRanges.map((month, index) => (
                    <th
                      key={index}
                      className="p-4 text-xs font-medium font-poppins leading-none text-neutral-600"
                      colSpan={month.weeks.length}
                    >
                      <div className="flex items-center justify-center space-x-3">
                        <span>{month.name}</span>
                        {month.isCurrent && (
                          <Tooltip message="Current Week">
                            <div className="w-2.5 h-2.5 bg-sky-400 rounded-full animate-pulse" />
                          </Tooltip>
                        )}
                      </div>
                    </th>
                  ))}
            </tr>
            {viewMode === "yearly" && (
              <tr className="text-left bg-gray-50 border-b border-gray-200">
                <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none"></th>
                {timeRanges.flatMap((month) =>
                  month.weeks.map((week, weekIndex) => (
                    <th
                      key={`${month.name}-${weekIndex}`}
                      className="p-4 text-xs font-medium font-poppins leading-none text-neutral-600"
                    >
                      <div className="flex items-center justify-center">
                        <span>
                          {week.start}-{week.end}
                        </span>
                      </div>
                    </th>
                  ))
                )}
              </tr>
            )}
          </thead>
          <tbody>
            {dosageData?.map((data, i) => (
              <tr key={i} className="border-b border-gray-200">
                <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight col-span-2">
                  {data?.medicineName}
                </td>
                {viewMode === "monthly"
                  ? timeRanges.map((range, i) => (
                      <td
                        key={i}
                        className={`relative ${
                          range.isCurrent ? "bg-sky-100" : ""
                        } `}
                      >
                        <div className="absolute inset-0 flex items-center justify-center">
                          {i % 3 === 0 ? (
                            <>
                              <img
                                src={completed}
                                alt="completed"
                                className="w-5 h-5"
                              />
                              <div className="h-px w-full bg-green-500"></div>
                            </>
                          ) : i % 3 === 1 ? (
                            <>
                              <img
                                src={missed}
                                alt="missed"
                                className="w-5 h-5"
                              />
                              <div className="h-px w-full bg-red-500"></div>
                            </>
                          ) : (
                            <>
                              <img
                                src={delayed}
                                alt="delayed"
                                className="w-5 h-5"
                              />
                              <div className="h-px w-full bg-yellow-500"></div>
                            </>
                          )}
                        </div>
                      </td>
                    ))
                  : timeRanges.flatMap((month) =>
                      month.weeks.map((week, weekIndex) => (
                        <td
                          key={`${month.name}-${weekIndex}`}
                          className="relative"
                        >
                          <div className="absolute inset-0 flex items-center justify-center">
                            {weekIndex % 3 === 0 ? (
                              <>
                                <img
                                  src={completed}
                                  alt="completed"
                                  className="w-5 h-5"
                                />
                                <div className="h-px w-full bg-green-500"></div>
                              </>
                            ) : weekIndex % 3 === 1 ? (
                              <>
                                <img
                                  src={missed}
                                  alt="missed"
                                  className="w-5 h-5"
                                />
                                <div className="h-px w-full bg-red-500"></div>
                              </>
                            ) : (
                              <>
                                <img
                                  src={delayed}
                                  alt="delayed"
                                  className="w-5 h-5"
                                />
                                <div className="h-px w-full bg-yellow-500"></div>
                              </>
                            )}
                          </div>
                        </td>
                      ))
                    )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DosageAdherence;

const INFO = [
  {
    title: "Taken on time",
    icon: completed,
  },
  {
    title: "Missed",
    icon: missed,
  },
  {
    title: "Delayed",
    icon: delayed,
  },
  {
    title: "Future",
    icon: future,
  },
  {
    title: "Discontinued",
    icon: discontinued,
  },
];
