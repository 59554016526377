import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import tile1 from "../assets/Images/OperationsManagement/tile1.png";
import tile2 from "../assets/Images/OperationsManagement/tile2.png";
import tile3 from "../assets/Images/OperationsManagement/tile3.png";
import tile4 from "../assets/Images/OperationsManagement/tile4.png";
import tile5 from "../assets/Images/OperationsManagement/tile5.png";
import Breadcrumb from "../components/Breadcrumb";

function OperationsManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      {/* HEADER */}
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        Operations Management
      </div>
      {/* <Breadcrumb /> */}

      {/* Content */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-5">
        {TILES.map((tile) => (
          <div
            key={tile.id}
            className="relative h-40 bg-sky-100 p-6 rounded-2xl shadow-lg cursor-pointer hover:scale-105 duration-200 transition-all active:scale-100"
            onClick={() => navigate(tile.route)}
          >
            <div className="text-[#011370] text-base font-bold font-poppins relative z-10">
              {tile.title}
            </div>
            <ul className="list-disc mt-5 relative z-10">
              {tile.moreInfo.map((info, index) => (
                <li
                  key={index}
                  className="text-black text-xs font-normal font-poppins"
                >
                  {info}
                </li>
              ))}
            </ul>
            <img
              src={tile.image}
              alt=""
              className="h-24 object-contain absolute bottom-5 right-4"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default OperationsManagement;

const TILES = [
  {
    id: 1,
    image: tile1,
    route: "/comingSoon",
    title: "Reports Management",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 2,
    image: tile2,
    route: "/comingSoon",
    title: "Feedback Management",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 3,
    image: tile3,
    route: "/comingSoon",
    title: "Appointment Management",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 4,
    image: tile4,
    route: "/operationsManagement/rewardManagement",
    title: "Rewards Management",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 5,
    image: tile5,
    route: "medicalConcierge",
    title: "Medical Concierge",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
  {
    id: 6,
    image: tile5,
    route: "platformManagement",
    title: "Platform Management",
    moreInfo: ["Manage In App notifications", "Web Portal notifications"],
  },
];
