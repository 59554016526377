import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
import { rewardTypeList } from "../data/rewardType";
import check_mark_green from "../assets/Icons/check_mark_green.svg";
import check_mark_green_square from "../assets/Icons/check_mark_green_square.svg";
import pending_icon from "../assets/Icons/pending_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getRewardCustomerList,
  getRewardSalesReport,
  markSaleAsComplete,
} from "../services/operations/rewardManagementAPI";
import moment from "moment";
import noCustomerList from "../assets/Images/RewardManagement/noCustomerList.png";
import SalesChart from "../components/SalesChart";
import noSalesData from "../assets/Images/RewardManagement/noSalesData.png";

function RewardSalesDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const offer = location.state || {};
  const { token } = useSelector((state) => state.auth);
  const { rewardSalesCustomerList, loading: salesLoading } = useSelector(
    (state) => state.reward
  );
  const [filter, setFilter] = useState("all");
  const [currentDate, setCurrentDate] = useState(moment());
  const currentYear = moment().year();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const years = [
    currentYear,
    currentYear - 1,
    currentYear - 2,
    currentYear - 3,
  ];
  const [isFetching, setIsFetching] = useState(false);
  const [salesStats, setSalesStats] = useState({});
  const rewardType = rewardTypeList.find(
    (type) => type.value === offer.TypeOfReward
  );

  const [showSalesChart, setShowSalesChart] = useState(false);

  const [showYearSelector, setShowYearSelector] = useState(false);

  const toggleSalesChart = () => setShowSalesChart(!showSalesChart);

  const toggleDropdown = () => setShowYearSelector(!showYearSelector);

  const handleYearSelect = (year) => {
    setSelectedYear(year);
    setShowYearSelector(false);
  };

  const changeMonth = (increment) => {
    setCurrentDate((prevDate) => prevDate.clone().add(increment, "month"));
  };

  const formatDate = (date) => {
    return date.format("MMMM YYYY");
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const filterUserList = rewardSalesCustomerList.filter((offer) => {
    if (filter === "all") return true;
    return offer.SalesStatus === filter;
  });

  const fetchCustomerList = async () => {
    try {
      const year = currentDate.year();
      const month = currentDate.month() + 1;
      dispatch(getRewardCustomerList(token, offer.RewardID, year, month));
    } catch (error) {
      console.log(error);
    }
  };

  const markAsComplete = async (ID) => {
    let body = {
      SalesStatus: "COMPLETED",
    };
    dispatch(markSaleAsComplete(token, ID, body, offer.RewardID, navigate));
  };

  useEffect(() => {
    fetchCustomerList();
  }, [offer, currentDate]);

  const fetchRewardStats = async () => {
    setIsFetching(true);
    try {
      const res = await getRewardSalesReport(
        token,
        offer.RewardID,
        selectedYear
      );
      setSalesStats(res?.data);
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    fetchRewardStats();
  }, [selectedYear]);

  const isFutureMonth = currentDate.isSameOrAfter(moment(), "month");

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      {/* Header */}
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        {offer.Title} Sales Details
      </div>
      <Breadcrumb />

      {/* Basic Details */}
      <div className="flex flex-row items-center mt-6 space-x-6">
        <img src={offer.Image} alt="" className="w-14 h-14 rounded-full" />
        <div>
          <div className="text-black text-sm font-normal font-poppins line-clamp-1">
            {offer.Title}
          </div>
          <div className="text-neutral-600 text-xs font-normal font-poppins">
            (Reward Id: {offer.RewardID})
          </div>
        </div>
        <div>
          <div className="text-neutral-600 text-xs font-normal font-poppins">
            Reward Type
          </div>
          <div className="text-zinc-900 text-xs font-medium font-poppins">
            {rewardType ? rewardType.label : offer.TypeOfReward}
          </div>
        </div>
        <div
          className={`h-8 px-2 py-2.5 my-4 rounded-3xl justify-center items-center gap-2 inline-flex ${
            offer.Status === "ACTIVE" ? "bg-emerald-100" : "bg-gray-100"
          }`}
        >
          {offer.Status === "ACTIVE" && (
            <img src={check_mark_green} alt="" className="w-4 h-4" />
          )}
          <div
            className={`text-center text-xs font-normal font-poppins leading-none ${
              offer.Status === "ACTIVE" ? "text-lime-700" : "text-zinc-500"
            }`}
          >
            {offer.Status.toLowerCase().charAt(0).toUpperCase() +
              offer.Status.toLowerCase().slice(1)}
          </div>
        </div>
      </div>

      {/* Chart Header */}
      <div className="flex flex-row items-center space-x-2 mt-6">
        <div className="w-56 text-black text-base font-medium font-poppins leading-normal ">
          View Sales Over Time
        </div>
        <div className="h-px w-full border border-neutral-300" />
        <button onClick={toggleSalesChart}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`w-6 h-6 ${
              showSalesChart
                ? "transform rotate-180 transition-transform duration-200"
                : "transform rotate-0 transition-transform duration-200"
            }`}
          >
            <path
              d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
              stroke="black"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>

      {/* SALES CHART */}
      {showSalesChart && (
        <div className="p-6 mt-4 bg-white rounded shadow border border-zinc-100">
          <div className="flex justify-end mb-4">
            <div className="relative inline-block text-left">
              <div>
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-sky-200 "
                  onClick={toggleDropdown}
                >
                  {selectedYear}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`w-5 h-5 ml-2 -mr-1 ${
                      showYearSelector
                        ? "transform rotate-180 transition-transform duration-200"
                        : "transform rotate-0 transition-transform duration-200"
                    }`}
                  >
                    <path
                      d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>

              {showYearSelector && (
                <div className="absolute left-0 mt-1 w-full bg-white border rounded-lg py-2 shadow-lg z-10">
                  <div className="py-1">
                    {years.map((year) => (
                      <button
                        key={year}
                        className="block w-full text-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        onClick={() => handleYearSelect(year)}
                      >
                        {year}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          {isFetching ? (
            <div className="flex items-center justify-center mt-10">
              <div className="spinner"></div>
            </div>
          ) : salesStats?.monthlySalesPercentage ? (
            <SalesChart salesData={salesStats?.monthlySalesPercentage} />
          ) : (
            <div className="flex items-center justify-center">
              <div className="flex flex-col items-center justify-center">
                <img
                  src={noSalesData}
                  alt="No Sales Data"
                  className="w-44 h-44 object-contain"
                />
                <div className="text-center text-zinc-900 text-lg font-medium font-poppins leading-snug">
                  No Sales Data Yet
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Customer List */}
      <div className="flex flex-row items-center space-x-4 mt-6">
        <div className="text-zinc-900 text-lg font-medium font-poppins">
          Customer List
        </div>
        {/* {offer.PendingSales > 0 && (
          <div className="flex flex-row items-center space-x-2">
            <img src={pending_icon} alt="" className="w-4 h-4" />
            <div className="text-center text-amber-800 text-xs font-medium font-poppins leading-tight">
              {offer.PendingSales} pending sales
            </div>
          </div>
        )} */}
      </div>

      {/* Month change button and filters  */}
      <div className="flex flex-row items-center justify-between mt-2">
        <div className="h-11 px-4 py-2 bg-white rounded-lg border border-neutral-300 justify-start items-center gap-2 inline-flex">
          <button onClick={() => changeMonth(-1)} className="w-6 h-6">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-1"
            >
              <path
                d="M15 6C15 6 9 10.4189 9 12C9 13.5812 15 18 15 18"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <div className="text-center text-zinc-900 text-base font-medium font-poppins">
            {formatDate(currentDate)}
          </div>
          <button
            onClick={() => changeMonth(1)}
            className={`w-6 h-6 ${
              isFutureMonth ? "cursor-not-allowed" : "cursor-pointer"
            }`}
            disabled={isFutureMonth}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-1"
            >
              <path
                d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>

        {/* Filters */}
        <div className="flex flex-row items-center">
          <button
            onClick={() => handleFilterChange("all")}
            className={`px-4 py-2.5 ${
              filter === "all"
                ? "bg-sky-100 text-sky-700 border-blue-200"
                : "bg-white text-neutral-600 border-neutral-300"
            }  rounded-tl-lg rounded-bl-lg border-l border-t border-b text-sm font-medium font-poppins leading-tight`}
          >
            All
          </button>
          <button
            onClick={() => handleFilterChange("COMPLETED")}
            className={`px-4 py-2.5 ${
              filter === "COMPLETED"
                ? "bg-sky-100 text-sky-700 border-blue-200"
                : "bg-white text-neutral-600 border-neutral-300"
            }  border text-sm font-medium font-poppins leading-tight`}
          >
            Completed
          </button>
          <button
            onClick={() => handleFilterChange("PENDING")}
            className={`px-4 py-2.5 ${
              filter === "PENDING"
                ? "bg-sky-100 text-sky-700 border-blue-200"
                : "bg-white text-neutral-600 border-neutral-300"
            }  rounded-tr-lg rounded-br-lg border-r border-t border-b text-sm font-medium font-poppins leading-tight`}
          >
            Pending
          </button>
        </div>
      </div>

      {salesLoading ? (
        <div className="flex items-center justify-center mt-40">
          <div className="spinner"></div>
        </div>
      ) : filterUserList.length === 0 ? (
        <div className="flex items-center justify-center mt-40">
          <div className="flex flex-col items-center justify-center">
            <img
              src={noCustomerList}
              alt="No Customer Data"
              className="w-44 h-44 object-contain"
            />
            <div className="text-center text-zinc-900 text-lg font-medium font-poppins leading-snug">
              No Data Yet
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full mx-auto mt-4 shadow rounded-md">
          <table className="min-w-full">
            <thead>
              <tr className="text-left bg-gray-50 border-b border-gray-200">
                <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                  Customer Name
                </th>
                <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                  Date of redemption
                </th>
                <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                  Order ID
                </th>
                <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                  Total Amount Spent
                </th>
                <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {filterUserList?.map((user) => (
                <tr
                  key={user.AHUID}
                  className="border-b border-gray-200 hover:bg-sky-50"
                >
                  <td className="p-4">
                    <div
                      onClick={() => {
                        navigate("/patientDashboard/patientProfile", {
                          state: user,
                        });
                      }}
                      className="flex items-center cursor-pointer"
                    >
                      <div
                        className={`${
                          user?.IsMedicalConciergeMember &&
                          " bg-gradient-to-r from-[#F6C86E] to-[#FFEE54] rounded-full p-0.5"
                        } mr-3`}
                      >
                        <img
                          src={user?.Image}
                          alt="PatientPic"
                          className="w-10 h-10 rounded-full"
                        />
                      </div>
                      <div>
                        <p className="text-gray-900 text-sm font-medium font-poppins leading-tight">
                          {user?.FirstName + " " + user?.LastName}
                        </p>
                        <p className="text-zinc-500 text-sm font-normal font-poppins leading-tight">
                          Patient ID: {user?.AHUID}
                        </p>
                      </div>
                    </div>
                  </td>
                  <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                    {moment(user?.RedemptionDate).format("DD/MM/YYYY")}
                  </td>
                  <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                    000000{user?.OrderID}
                  </td>
                  <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                    {user?.Currency + " " + user?.TotalPrice}
                  </td>
                  <td className="p-4">
                    {user?.SalesStatus === "PENDING" ? (
                      <button
                        onClick={() => markAsComplete(user?.OrderID)}
                        className="h-11 px-5 py-3 bg-white rounded-lg border border-sky-500 flex flex-row items-center space-x-2 active:scale-95"
                      >
                        <div className="border border-sky-500 w-5 h-5 rounded-md"></div>
                        <div className="text-sky-500 text-sm font-semibold font-poppins leading-tight">
                          Mark as Complete
                        </div>
                      </button>
                    ) : (
                      <div className="w-52 h-11 px-5 py-3 bg-emerald-50 rounded-lg flex flex-row items-center space-x-2 ">
                        <img
                          src={check_mark_green_square}
                          alt=""
                          className="w-6 h-6"
                        />
                        <div className="text-lime-700 text-sm font-medium font-poppins leading-tight">
                          Completed
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default RewardSalesDetails;