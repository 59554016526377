import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { toast } from "react-hot-toast";
import RadioButton from "../../components/RadioButton/RadioButton";
import { getAllTreatmentProgramWeekData } from "../../services/operations/masterDataAPI";

function AddTreatmentProgram() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.auth);
    const [treatmentProgramWeekData, setTreatmentProgramWeekData] = useState([]);

    const [formData, setFormData] = useState({
        Diagnosis: "",
        TargetAudience: "",
        Diagnosis1: "",
        TreatmentProgram: "",
    })

    const [errors, setErrors] = useState({
        Diagnosis: "",
        TargetAudience: "",
        Diagnosis1: "",
        TreatmentProgram: "",
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors((prevData) => ({
            ...prevData,
            [name]: "",
        }));
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        const mandatoryFields = [
            "Diagnosis",
            "TargetAudience",
            "Diagnosis1",
            "TreatmentProgram",
        ];
        const newErrors = {};

        mandatoryFields.forEach((key) => {
            const value = formData[key];

            if (!value) {
                newErrors[key] = "This field is required";
            } else if (typeof value === "string" && value.trim() === "") {
                newErrors[key] = "This field cannot be empty";
            }
        });

        if (Object.keys(newErrors).length > 0) {
            toast.error("Please fill all the required fields");
            setErrors(newErrors);
            return;
        }

        navigate("/serviceManagement/treatmentProgram");
    };

    useEffect(() => {
        const fetchTreatmentProgramWeekData = async () => {
            if (token) {
                try {
                    const res = await dispatch(getAllTreatmentProgramWeekData(token));
                    setTreatmentProgramWeekData(res || []);
                } catch (error) {
                    console.error("Failed to fetch Treatment Programs", error);
                }
            }
        };

        fetchTreatmentProgramWeekData();
    }, [token, dispatch]);

    return (
        <div className="bg-[#F3F3F3] min-h-screen py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
            <div className="bg-white rounded-lg shadow-lg p-6">
                {/* HEADER */}
                <div className="flex flex-row justify-between items-center">
                    {/* TITLE */}
                    <div className="flex flex-col justify-center items-start gap-2">
                        <h1 className="text-black text-xl font-bold font-poppins">
                            Add Treatment Program
                        </h1>
                        <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins">
                            Here are form for Treatment Program
                        </h1>
                    </div>
                    <button onClick={() => { navigate("createTreatmentProgram") }} className="ml-auto flex flex-row justify-center items-center rounded-md bg-[#3CA5DC] px-4 py-2 cursor-pointer hover:scale-105 duration-500">
                        <FaPlus className="text-white mr-2 text-sm" />
                        <h1 className="text-white text-xs font-regular font-poppins">
                            Create New Treatment Program
                        </h1>
                    </button>
                </div>

                {/* Content */}
                <div className="flex flex-col justify-center items-center w-3/4 mt-5">
                    <form className="w-full">
                        <div className="flex flex-row justify-center items-center w-full gap-10 mb-4">
                            {/* Diagnosis */}
                            <div className="w-1/2">
                                <label>
                                    <p className="text-[#070808] text-xs font-semibold font-poppins mb-2 text-left">
                                        Diagnosis
                                        <sup className="text-red-600">*</sup>
                                    </p>
                                    <input
                                        type="text"
                                        name="Diagnosis"
                                        value={formData.Diagnosis}
                                        onChange={handleChange}
                                        placeholder="Input diagnosis here"
                                        className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.Diagnosis ? "border-red-500" : ""
                                            }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                    />
                                </label>
                            </div>

                            {/* Target Audience */}
                            <div className="w-1/2">
                                <label>
                                    <p className="text-[#070808] text-xs font-semibold font-poppins mb-2 text-left">
                                        Target Audience
                                        <sup className="text-red-600">*</sup>
                                    </p>
                                    <select
                                        id="TargetAudience"
                                        name="TargetAudience"
                                        onChange={handleChange}
                                        className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.TargetAudience ? "border-red-500" : ""
                                            }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent cursor-pointer`}>
                                        <option value="">Select Target Audience</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Both">Both</option>
                                    </select>
                                </label>
                            </div>
                        </div>

                        <div className="flex flex-row justify-start items-center w-full gap-10 mb-4">
                            {/* Diagnosis1 */}
                            <div className="w-1/2 pr-5">
                                <label>
                                    <p className="text-[#070808] text-xs font-semibold font-poppins mb-2 text-left">
                                        Diagnosis
                                        <sup className="text-red-600">*</sup>
                                    </p>
                                    <input
                                        type="text"
                                        name="Diagnosis1"
                                        value={formData.Diagnosis1}
                                        onChange={handleChange}
                                        placeholder="Input diagnosis here"
                                        className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.Diagnosis1 ? "border-red-500" : ""
                                            }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                    />
                                </label>
                            </div>
                        </div>

                        <div className="grid grid-cols-2 gap-x-10 gap-y-4 justify-center items-start w-full mb-4 mt-5">
                            {treatmentProgramWeekData.map(prog => (
                                <div key={prog.ID} className="flex flex-col justify-center items-start w-full gap-2">
                                    <RadioButton
                                        name="TreatmentProgram"
                                        id={prog.RelatedWeekData.Name}
                                        value={prog.RelatedWeekData.Name}
                                        text={prog.RelatedWeekData.Name}
                                        onChange={handleChange}
                                        checked={formData.TreatmentProgram === prog.RelatedWeekData.Name}
                                    />
                                    {Object.entries(prog).map(([week, value]) => {
                                        if (week.startsWith("Week") && value !== null) {
                                            return (
                                                <div key={week} className="flex flex-row justify-start items-center rounded-md bg-[#E5F2FF] px-4 py-3 w-full">
                                                    <h1 className="text-[#3CA5DC] text-sm font-regular font-poppins text-left">
                                                        {value}
                                                    </h1>
                                                </div>
                                            );
                                        } else {
                                            return null; // Skip rendering if the week is null
                                        }
                                    })}
                                </div>
                            ))}
                        </div>

                        {/* Submit Button */}
                        <button
                            type="submit"
                            style={{ backgroundColor: "#3CA5DC" }}
                            className="mt-4 w-full bg-sky-400 rounded-lg text-center text-white text-sm font-semibold font-poppins p-4 sm:px-44 hover:bg-sky-400 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300"
                            onClick={submitHandler}
                        >
                            Finish
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddTreatmentProgram;

const TREATMENTPROG = [
    {
        id: 0,
        name: 'Chronic Back Pain',
        items: [
            {
                id: 1,
                text: 'Week 1: Physio Therapy',
            },
            {
                id: 2,
                text: 'Week 2: Physio Therapy',
            },
            {
                id: 3,
                text: 'Week 3: Physical Check-up',
            },
            {
                id: 4,
                text: 'Week 4: Physio Therapy',
            },
            {
                id: 5,
                text: 'Week 5: MRI Scan',
            },
            {
                id: 6,
                text: 'Week 6: Check-up and Treatment Complete',
            },
        ],
    },
    {
        id: 1,
        name: 'Treatment Program 2',
        items: [
            {
                id: 1,
                text: 'Week 1: Physio Therapy',
            },
            {
                id: 2,
                text: 'Week 2: Physio Therapy',
            },
            {
                id: 3,
                text: 'Week 3: Physical Check-up',
            },
            {
                id: 4,
                text: 'Week 4: Physio Therapy',
            },
            {
                id: 5,
                text: 'Week 5: MRI Scan',
            },
            {
                id: 6,
                text: 'Week 6: Check-up and Treatment Complete',
            },
        ],
    },
    {
        id: 2,
        name: 'Treatment Program 3',
        items: [
            {
                id: 1,
                text: 'Week 1: Physio Therapy',
            },
            {
                id: 2,
                text: 'Week 2: Physio Therapy',
            },
            {
                id: 3,
                text: 'Week 3: Physical Check-up',
            },
            {
                id: 4,
                text: 'Week 4: Physio Therapy',
            },
            {
                id: 5,
                text: 'Week 5: MRI Scan',
            },
            {
                id: 6,
                text: 'Week 6: Check-up and Treatment Complete',
            },
        ],
    },
    {
        id: 3,
        name: 'Treatment Program 4',
        items: [
            {
                id: 1,
                text: 'Week 1: Physio Therapy',
            },
            {
                id: 2,
                text: 'Week 2: Physio Therapy',
            },
            {
                id: 3,
                text: 'Week 3: Physical Check-up',
            },
            {
                id: 4,
                text: 'Week 4: Physio Therapy',
            },
            {
                id: 5,
                text: 'Week 5: MRI Scan',
            },
            {
                id: 6,
                text: 'Week 6: Check-up and Treatment Complete',
            },
        ],
    },
]