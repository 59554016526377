import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus, FaMinus, FaChevronLeft, FaHeartPulse } from "react-icons/fa6";
import Vertical_switch_light from "../../assets/Images/TreatmentProgram/Vertical_switch_light.png";
import Tile1 from "../../assets/Images/OurServices/Tile1.png";
import Tile2 from "../../assets/Images/OurServices/Tile2.png";
import Tile3 from "../../assets/Images/OurServices/Tile3.png";
import Tile4 from "../../assets/Images/OurServices/Tile4.png";
import noServiceBg from "../../assets/Images/OurServices/noServiceBg.png";
import cameraIcon from "../../assets/Images/OurServices/serviceCameraIcon.png";
import deleteModalIcon from "../../assets/Images/OurServices/deleteModalIcon.png";
import editIcon from "../../assets/Images/OurServices/editIcon.png";
import arrowRightIcon from "../../assets/Images/OurServices/arrowRightIcon.png";
import binIcon from "../../assets/Images/OurServices/binIcon.png";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AHlogo from "../../assets/Images/logo.png";
import Breadcrumb from "../../components/Breadcrumb";
import qrCodeImg from "../../assets/Images/Invoicing/qrCodeImg.png";
import { IoIosSearch } from "react-icons/io";
import { HiOutlineDotsVertical } from "react-icons/hi";
import ModalComponent from "../../components/Modal";
import { deleteTreatmentService } from "../../services/operations/ourServicesAPI";
import Tooltip from "../../components/Tooltip";

function ServiceDetails() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const treatmentCategory = location.state.item;
    const { token } = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [editingId, setEditingId] = useState(null);
    const [editedTitle, setEditedTitle] = useState("");
    const [specificService, setSpecificService] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const deleteHandler = async (event, id) => {
        event.preventDefault();
        // Call API to Delete Treatment Service
        try {
            await dispatch(deleteTreatmentService(token, id, navigate));
        } catch (error) {
            console.error("Error Deleting Treatment Service:", error);
        } finally {
            setDeleteItem(null);
        }
    };

    const filteredData = treatmentCategory.TreatmentServices
        .filter(service =>
            service.ServiceName.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .map(service => ({
            ...service,
            categoryTitle: treatmentCategory.ServiceName
        }));

    useEffect(() => {
        const editService = async () => {
            if (token && specificService) {
                // const id = specificService.id;
                navigate("addServices", { state: { specificService: specificService } });
            }
        };

        editService();
    }, [token, specificService, dispatch]);

    const RenderServiceBlocks = () => {

        return (
            <div className="grid grid-cols-2 w-full justify-start items-stretch gap-6">
                {filteredData.map((item) => (
                    <div key={item.OSTSID} className="w-full relative shadow rounded-lg p-6">
                        {/* Header */}
                        <div className="flex justify-between items-center">
                            <h2 className="text-[#101828] text-[16px] font-medium font-poppins">{item.ServiceName}</h2>
                            <div className="flex space-x-2">
                                <Tooltip message="Edit">
                                    <button onClick={() => { setSpecificService(item); }} className="">
                                        <img src={editIcon} alt="Edit" className="w-10 h-10" />
                                    </button>
                                </Tooltip>
                                <Tooltip message="Remove">
                                    <button onClick={(e) => { setDeleteItem(item); setShowDeleteModal(true); }} className="">
                                        <img src={binIcon} alt="Delete" className="w-10 h-10" />
                                    </button>
                                </Tooltip>
                            </div>
                        </div>

                        {/* Treatment Details */}
                        <div className="flex mt-4">
                            {/* Treatment Image */}
                            <div className="w-24 flex flex-col items-center">
                                <button onClick={() => { }} className="flex flex-col justify-center items-center">
                                    <img
                                        src={item.Image || cameraIcon} // Fallback to camera icon if no image is available
                                        alt={item.ServiceName}
                                        className="w-14 h-14 object-cover rounded-full"
                                    />
                                    {!item.Image && (
                                        <>
                                            <span className="flex items-center justify-center text-sm text-[#160C0C] font-medium font-poppins mt-2">
                                                Add Photo
                                            </span>
                                            <span className="flex items-center justify-center text-[10px] text-[#555252] font-normal font-poppins mt-1">
                                                (Click to Add)
                                            </span>
                                        </>
                                    )}
                                </button>
                            </div>

                            {/* Treatment Details */}
                            <div className="w-full pl-4">
                                <div className="flex flex-col gap-4">
                                    <div className="flex flex-col justify-between">
                                        <span className="text-[#8A8A8A] font-poppins font-normal text-xs leading-[18px]">Duration of Treatment</span>
                                        <span className="text-[#1C1C1C] font-poppins font-medium text-xs leading-[18px] mt-1">{item.MinDuration} to {item.MaxDuration} {item.DurationType}s</span>
                                    </div>
                                    <div className="flex flex-col justify-between mt-2">
                                        <span className="text-[#8A8A8A] font-poppins font-normal text-xs leading-[18px]">Cost of Treatment</span>
                                        <span className="text-[#1C1C1C] font-poppins font-medium text-xs leading-[18px] mt-1">Starting from {item.StartPrice} CHF</span>
                                    </div>
                                    <div className="flex flex-col justify-between mt-2">
                                        <span className="text-[#8A8A8A] font-poppins font-normal text-xs leading-[18px]">Details</span>
                                        <span className="text-[#1C1C1C] font-poppins font-medium text-xs leading-[18px] mt-1">{item.Description}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Delete Treatment Category MODAL */}
                        <ModalComponent
                            show={showDeleteModal}
                            handleClose={() => { setDeleteItem(null); setShowDeleteModal(false); }}
                            outSideModalPress={false}
                        >
                            <div className="w-[431px]">
                                <div className="flex flex-col items-center justify-between">
                                    <img
                                        src={deleteModalIcon}
                                        alt="Delete Icon"
                                        className="w-14 h-14 object-cover"
                                    />
                                    <div className="text-black text-[16px] font-semibold font-poppins text-center mt-6">
                                        Delete Treatment Service '{deleteItem?.ServiceName}' ?
                                    </div>
                                    <div className="flex flex-row items-center justify-center mt-6 space-x-4 w-full">
                                        <button
                                            onClick={() => { setDeleteItem(null); setShowDeleteModal(false); }}
                                            className="flex-1 w-32 px-2 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
                                        >
                                            <div className="text-[#007AAE] text-sm font-semibold font-poppins leading-normal">
                                                Cancel
                                            </div>
                                        </button>
                                        <button
                                            onClick={(e) => { deleteHandler(e, item?.OSTSID); }}
                                            className="flex-1 w-32 px-2 py-3 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-500 active:scale-95"
                                        >
                                            <div className="text-white text-sm font-semibold font-poppins leading-normal">
                                                Delete Service
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ModalComponent>
                    </div>
                ))}
            </div>
        );
    };

    if (isLoading) {
        return (
            <div className="h-screen flex items-center justify-center">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="bg-white min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
            {/* HEADER */}
            <div className="flex flex-row justify-between items-center">
                {/* TITLE */}
                <div className="flex flex-row items-center">
                    <div className="flex flex-col justify-center items-start gap-1">
                        <h1 className="text-[#1C1C1C] text-2xl font-medium font-poppins leading-9">
                            Service Details
                        </h1>
                        <Breadcrumb currentState={location.state} />
                    </div>
                </div>
            </div>

            {/* Search and Add treatment Btn */}
            <div className="flex flex-row justify-between items-center w-full gap-4 mt-6">
                <label
                    className={`flex flex-row items-center justify-start bg-[#F2F3F4] rounded-[20px] w-full py-2 px-4 gap-4`}
                >
                    <IoIosSearch className="text-[#8A8A8A] w-5 h-5" />
                    <input
                        type="text"
                        placeholder="Search services"
                        onChange={handleSearch}
                        className={`bg-[#F2F3F4] text-sm w-full text-[#8A8A8A] font-normal font-poppins focus:outline-none focus:border-transparent`}
                    />
                </label>

                <button
                    onClick={() => { navigate("addServices", { state: { OSTCID: treatmentCategory?.OSTCID } }) }}
                    className="flex flex-row justify-center items-center rounded-md bg-[#3CA5DC] px-3 py-2 cursor-pointer hover:scale-110 duration-500 w-52"
                >
                    <FaPlus className="text-white mr-2 text-sm" />
                    <h1 className="text-[#E5F2FF] text-sm font-semibold font-poppins">
                        Add New Service
                    </h1>
                </button>
            </div>

            {/* Content */}
            {treatmentCategory?.TreatmentServices?.length === 0 ? (
                <>
                    <h1 className="text-[#000000] text-[18px] font-medium font-poppins mt-6 leading-9">
                        {treatmentCategory?.Name} : Treatments
                    </h1>
                    <div className="flex flex-col justify-center items-center w-full">
                        <img
                            src={noServiceBg}
                            alt="No New Service Available"
                            className="w-[42rem] h-[30rem] object-contain"
                        />
                    </div>
                </>
            ) : (
                <>
                    <h1 className="text-[#000000] text-[18px] font-medium font-poppins mt-6 leading-9">
                        {treatmentCategory?.Name} : Treatments
                    </h1>
                    <div className="flex flex-wrap flex-row justify-center items-center w-full mt-4">
                        <RenderServiceBlocks />
                    </div>
                </>
            )
            }
        </div >
    );
}

export default ServiceDetails;
