import { toast } from "react-hot-toast";
import {
  setAccessRoles,
  setDoctorDetails,
  setLoading,
  setLogo,
  setUser,
  setUserType,
} from "../../slices/profileSlice";
import { apiConnector } from "../apiConnector";
import { userEndpoints } from "../apis";
import { logout } from "./authAPI";
// import { USER_TYPE } from "../../utils/constants";
import { getBucketURL } from "../../utils/regex";

const {
  GET_USER_DETAILS_API,
  GET_ORGANIZATION_LOGO,
  CHANGE_USER_PASSWORD_API,
  UPDATE_USER_DETAILS_API,
} = userEndpoints;

// VALIDATE USER
export function getUserDetails(token, navigate) {
  return async (dispatch) => {
    try {
      const response = await apiConnector("GET", GET_USER_DETAILS_API, null, {
        Authorization: `Bearer ${token}`,
      });
      console.log("Validating User 🥷🏻", response?.data);
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      const userImage = response?.data?.user?.ProfileImageUrl
        ? await getBucketURL(response?.data?.user?.ProfileImageUrl)
        : `https://api.dicebear.com/5.x/initials/svg?seed=${response.data.user.Name}`;
      dispatch(setUser({ ...response?.data?.user, image: userImage }));
      dispatch(setAccessRoles(response?.data?.user?.AccessRoles));
      dispatch(setDoctorDetails(response?.data?.doctorDetails));
    } catch (error) {
      dispatch(logout(navigate));
      console.log("GET_USER_DETAILS API ERROR............", error);
      toast.error("Session Expired");
    }
  };
}

//GET ORGANIZATION LOGO
export function getOrganizationLogo() {
  return async (dispatch) => {
    try {
      const response = await apiConnector("GET", GET_ORGANIZATION_LOGO, null, {
        // Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setLogo(response?.data?.LogoURL));
    } catch (error) {
      console.log("GET_ORGANIZATION_LOGO API ERROR............", error);
    }
  };
}

// CHANGE USER PASSWORD
export const changeUserPassword = async (body) => {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector("PUT", CHANGE_USER_PASSWORD_API, body);

    if (!response?.status === 200) {
      throw new Error(response?.data?.message);
    }
    result = response?.data;
  } catch (error) {
    console.log("CHANGE_PASSWORD API ERROR............", error);
    toast.error(
      error?.response?.data?.message ||
        error?.response?.data?.msg ||
        "Error While Changing Password"
    );
  }
  toast.dismiss(toastId);
  return result;
};

// UPDATE USER PROFILE
export function updateUserProfile(token, ID, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_USER_DETAILS_API + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Profile Updated Successfully");
      navigate("/");
    } catch (error) {
      console.log("UPDATE_USER_DETAILS_API API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Updating profile"
      );
    }
    toast.dismiss(toastId);
  };
}
