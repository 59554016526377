import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoIosSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Weather from "./Weather";
import { toggleSidebar } from "../slices/sidebarSlice";
import navbarCollapseIcon from "../assets/Images/Sidebar/navbarCollapseIcon.png";
import navbarExpandIcon from "../assets/Images/Sidebar/navbarExpandIcon.png";
import ProfileDropDown from "./ProfileDropDown";

function Navbar({ weatherData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const expanded = useSelector((state) => state.sidebar.expanded);
  const { settings } = useSelector((state) => state.masterData);
  const { logo, user } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);

  const [searchInput, setSearchInput] = useState("");
  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  return (
    <nav className="flex h-16 items-center justify-between px-10 pl-3 py-2 w-full bg-white border-b-2 shadow-lg">
      {/* Sidebar Toggle Button */}
      <button
        onClick={() => dispatch(toggleSidebar())}
        className="flex items-center justify-center p-2 w-12 h-10 bg-[#F3F5F7] hover:bg-[#F0F8FF] border-none rounded-md mr-4"
      >
        {expanded ? (
          <img
            src={navbarCollapseIcon}
            alt="Collapse Sidebar"
            className="w-5 h-5"
          />
        ) : (
          <img
            src={navbarExpandIcon}
            alt="Expand Sidebar"
            className="w-5 h-5"
          />
        )}
      </button>
      <div className="p-2 flex justify-between items-center">
        <img
          src={settings?.OrgImage ? settings?.OrgImage : logo}
          className={`overflow-hidden transition-all w-40 h-11 object-contain cursor-pointer`}
          alt=""
          onClick={() => navigate("/")}
        />
      </div>
      <label
        className={`flex flex-row items-center justify-center bg-zinc-100 rounded-full py-2 px-4 gap-2 ml-auto mr-12`}
      >
        <IoIosSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
        <input
          disabled
          type="text"
          placeholder="Search here"
          autoComplete="off"
          className={`bg-zinc-100 text-sm placeholder:text-zinc-500 text-zinc-500] font-normal font-poppins focus:outline-none focus:border-transparent`}
        />
      </label>
      <div className="mr-16">
        {weatherData && weatherData?.length !== 0 && (
          <Weather weatherData={weatherData} />
        )}
      </div>
      {token !== null && user !== null && <ProfileDropDown />}
    </nav>
  );
}

export default Navbar;
