import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { consultationFormEndpoints } from "../apis";
import { getBucketURL } from "../../utils/regex";
import {
  setPostConsultationForm,
  setPreConsultationForm,
  setSignedForm,
  setScheduledForm,
} from "../../slices/formSlice";

const {
  GET_CONSULTATION_FORMS,
  ADD_CONSULTATION_FORM,
  UPDATE_CONSULTATION_FORM,
  DELETE_CONSULTATION_FORM,
  UPLOAD_CONSULTATION_FORM,
  GET_ALL_SIGNED_CONSULTATION_FORM,
  GET_ALL_SCHEDULED_CONSULTATION_FORM,
  UPLOAD_SIGNED_CONSULTATION_FORM,
  SCHEDULE_CONSULTATION_FORM,
  RESCHEDULE_CONSULTATION_FORM,
  DELETE_SIGNED_FORM,
} = consultationFormEndpoints;

// GET CONSULTATION FORMS
export function getConsultationForms(token) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_CONSULTATION_FORMS, null, {
        Authorization: `Bearer ${token}`,
      });
      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }
      console.log("GET_CONSULTATION_FORMS API RESPONSE:", response?.data);

      // Separate the forms by type
      const preConsultationForms = response.data.Forms.filter(
        (form) => form.Type === "PRE_CONSULTATION"
      );
      const postConsultationForms = response.data.Forms.filter(
        (form) => form.Type === "POST_CONSULTATION"
      );

      // Set the forms in the respective states
      dispatch(setPreConsultationForm(preConsultationForms));
      dispatch(setPostConsultationForm(postConsultationForms));
    } catch (error) {
      console.log("GET_CONSULTATION_FORMS API ERROR:", error);
    }
    // dispatch(setLoading(false));
  };
}

// ADD CONSULTATION FORM
export function addConsultationForm(token, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_CONSULTATION_FORM, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Form Created Successfully");
      dispatch(getConsultationForms(token));
      navigate(-1);
    } catch (error) {
      console.log("ADD_CONSULTATION_FORM API ERROR............", error);
      toast.error(error?.response?.data?.message || "Error while adding form");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// UPDATE CONSULTATION FORM
export function updateConsultationForm(token, ID, body) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_CONSULTATION_FORM + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // toast.success("Form Updated Successfully");
      dispatch(getConsultationForms(token));
    } catch (error) {
      console.log("UPDATE_CONSULTATION_FORM API ERROR............", error);
      toast.error(
        error?.response?.data?.message || "Error while updating form"
      );
    }
    // dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// DELETE CONSULTATION FORM
export function deleteConsultationForm(token, ID) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_CONSULTATION_FORM + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Form Deleted Successfully");
      dispatch(getConsultationForms(token));
    } catch (error) {
      console.log("DELETE_CONSULTATION_FORM API ERROR............", error);
      toast.error(
        error?.response?.data?.message || "Error while deleting form"
      );
    }
    // dispatch(setLoading(false));
  };
}

// UPLOAD CONSULTATION FORM
export function uploadConsultationForm(token, body) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        UPLOAD_CONSULTATION_FORM,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Form Uploaded Successfully");
      dispatch(getConsultationForms(token));
    } catch (error) {
      console.log("UPLOAD_CONSULTATION_FORM API ERROR............", error);
      toast.error(
        error?.response?.data?.message || "Error while uploading form"
      );
    }
    // dispatch(setLoading(false));
  };
}

// GET ALL SIGNED CONSULTATION FORMS
export function getSignedConsultationForms(token, ID) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_ALL_SIGNED_CONSULTATION_FORM + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setSignedForm(response?.data?.SignedConsultationForms));
    } catch (error) {
      console.log("GET_ALL_SIGNED_CONSULTATION_FORM API ERROR:", error);
    }
    // dispatch(setLoading(false));
  };
}

// GET ALL SCHEDULED CONSULTATION FORMS
export function getScheduledConsultationForms(token, ID) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_ALL_SCHEDULED_CONSULTATION_FORM + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (response?.status !== 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setScheduledForm(response?.data?.NotificationData));
    } catch (error) {
      console.log("GET_ALL_SCHEDULED_CONSULTATION_FORM API ERROR:", error);
    }
    // dispatch(setLoading(false));
  };
}

// UPLOAD SIGNED CONSULTATION FORM
export function uploadSignedConsultationForm(token, body, ID) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        UPLOAD_SIGNED_CONSULTATION_FORM,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Form Uploaded Successfully");
      dispatch(getSignedConsultationForms(token, ID));
    } catch (error) {
      console.log("UPLOAD_CONSULTATION_FORM API ERROR............", error);
      toast.error(
        error?.response?.data?.message || "Error while uploading form"
      );
    }
    // dispatch(setLoading(false));
  };
}

// SCHEDULE CONSULTATION FORM
export function scheduleConsultationForm(token, body, ID) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        SCHEDULE_CONSULTATION_FORM,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Form Scheduled Successfully");
      dispatch(getScheduledConsultationForms(token, ID));
    } catch (error) {
      console.log("SCHEDULE_CONSULTATION_FORM API ERROR............", error);
      toast.error(
        error?.response?.data?.message || "Error while scheduling form"
      );
    }
    // dispatch(setLoading(false));
  };
}

// RESCHEDULE CONSULTATION FORM
export function rescheduleConsultationForm(token, ID, body, AHUID) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        RESCHEDULE_CONSULTATION_FORM + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Form Rescheduled Successfully");
      dispatch(getScheduledConsultationForms(token, AHUID));
    } catch (error) {
      console.log("RESCHEDULE_CONSULTATION_FORM API ERROR............", error);
      toast.error(
        error?.response?.data?.message || "Error while rescheduling form"
      );
    }
    // dispatch(setLoading(false));
  };
}

// DELETE CONSULTATION FORM
export function deleteSignedForm(token, ID, AHUID) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_SIGNED_FORM + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Form Deleted Successfully");
      dispatch(getSignedConsultationForms(token, AHUID));
      dispatch(getScheduledConsultationForms(token, AHUID));
    } catch (error) {
      console.log("DELETE_CONSULTATION_FORM API ERROR............", error);
      toast.error(
        error?.response?.data?.message || "Error while deleting form"
      );
    }
    // dispatch(setLoading(false));
  };
}
