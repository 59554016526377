import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { sendForgotPasswordOtp } from "../services/operations/authAPI";
import logo from "../assets/Images/logo.png";
import emailIcon from "../assets/Images/icon/email.png";
import phoneIcon from "../assets/Images/icon/phone.png";
import toast from "react-hot-toast";
import reqDemoIcon from "../assets/Images/reqDemoIcon.png";
import emailSelectedIcon from "../assets/Images/emailSelectedIcon.png";
import emailUnselectedIcon from "../assets/Images/emailUnselectedIcon.png";
import callSelectedIcon from "../assets/Images/callSelectedIcon.png";
import callUnselectedIcon from "../assets/Images/callUnselectedIcon.png";
import { SlLogin } from "react-icons/sl";

function ForgotPasswordForm({ type }) {
  const savedInfo = localStorage.getItem("savedInfo")
    ? JSON.parse(localStorage.getItem("savedInfo"))
    : "";
  const [loginType, setLoginType] = useState("0");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    Email: savedInfo.Email ? savedInfo.Email : "",
    Phone: savedInfo.Phone ? savedInfo.Phone : "",
  });

  const handleLoginTypeChange = (type) => {
    setLoginType(type);
  };

  function handleOnChange(event) {
    const { name, value, type } = event.target;
    let maxLength;
    switch (name) {
      case "Phone":
        maxLength = 10;
        break;

      default:
        maxLength = Infinity;
    }

    if (type === "number") {
      const numericValue = value.replace(/\D/g, "");

      if (!isNaN(numericValue) && numericValue.length <= maxLength) {
        setFormData((prev) => ({
          ...prev,
          [name]: numericValue,
        }));
      }
    } else {
      if (value.length <= maxLength) {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();

    // add validation on email and phone numbber and show toast
    if (loginType === "0") {
      if (!formData.Email.includes("@")) {
        toast.error("Please enter a valid email");
        return;
      }
    } else if (loginType === "1") {
      if (formData.Phone.length !== 10) {
        toast.error("Please enter a valid phone number");
        return;
      }
    }

    console.log('====================================');
    console.log("Forgot Password Form Data: ", formData);
    console.log('====================================');

    // navigate("/verify-forgot-password-otp")
    // Check the loginType and update the formData accordingly
    if (loginType === "0") {
      dispatch(sendForgotPasswordOtp(formData.Email, loginType, navigate, type));
    } else if (loginType === "1") {
      dispatch(sendForgotPasswordOtp(formData.Phone, loginType, navigate, type));
    }
  };

  return (
    <div className="bg-white w-full md:w-[37vw] h-[90vh] rounded-[22px] py-10 px-20 flex flex-col justify-start">
      <div className="flex items-center justify-center gap-3 mt-6 w-full">
        <Link to={type === 'clinic' ? "/partnerLogin" : "/login"} className="block w-44 bg-[#3ca5dc] rounded-2xl py-2 px-6 flex flex-row justify-between items-center gap-2">
          <h1 className="text-[#FFFFFF] text-[13px] font-semibold font-open font-poppins">
            {type === 'clinic' ? "Partner Login" : "Clinic Login"}
          </h1>
          <SlLogin className="text-[#FFFFFF] font-semibold text-lg" />
        </Link>
        <button onClick={() => window.open("https://alleshealth.com/contactus", "_blank")} className="block w-44 bg-white border border-[#3ca5dc] rounded-2xl py-2 px-4 flex flex-row justify-between items-center gap-2">
          <h1 className="text-black text-[13px] font-semibold font-open font-poppins">
            Request a Demo
          </h1>
          <div className="">
            <img
              src={reqDemoIcon}
              alt="Demo Icon"
              className="w-5 h-5 object-contain"
            />
          </div>
        </button>
      </div>

      <h1 className="text-black text-[32px] font-semibold font-open font-poppins leading-relaxed mt-28">
        Forgot Password
      </h1>
      <h1 className="text-[#acacac] text-[10px] font-normal font-open font-poppins leading-relaxed mt-3">
        Input your Verified Email or Phone Number
      </h1>

      {/* login type - email/phone number  */}
      <div className="justify-start items-start gap-3.5 inline-flex mt-14">
        <button
          className={`py-1 px-10 rounded-3xl ${loginType === "0"
            ? "bg-sky-400 text-[#e5f2ff]"
            : "bg-sky-100 text-[#35abf4]"
            } justify-center items-center inline-flex w-[7.4rem] gap-2`}
          onClick={() => handleLoginTypeChange("0")}
        >
          <img
            src={loginType === "0" ? emailSelectedIcon : emailUnselectedIcon}
            alt="Email Icon"
            className="w-5 h-5 object-contain"
          />
          <div className="text-[10.49px] font-medium font-poppins">Email</div>
        </button>
        <button
          className={`py-1 px-5 rounded-3xl ${loginType === "1"
            ? "bg-sky-400 text-[#e5f2ff]"
            : "bg-sky-100 text-[#35abf4]"
            } justify-center items-center inline-flex w-[7.4rem] gap-2`}
          onClick={() => handleLoginTypeChange("1")}
        >
          <img
            src={loginType === "1" ? callSelectedIcon : callUnselectedIcon}
            alt="Call Icon"
            className="w-5 h-5 object-contain"
          />
          <div className="text-[10.49px] font-medium font-poppins">Phone</div>
        </button>
      </div>

      {/* login form */}
      <div className="mt-6">
        <form onSubmit={handleOnSubmit}>
          {loginType === "0" ? (
            <>
              {/* Email */}
              <div className="mb-4">
                <label>
                  <div className="flex items-center">
                    <div className="bg-[#ffffff] rounded-l-2xl p-3 border border-r-0 border-[#CFCFCF]">
                      <img
                        src={emailIcon}
                        alt="email"
                        className="w-6 h-6 object-contain"
                      />
                    </div>
                    <input
                      required
                      type="text"
                      name="Email"
                      value={loginType === "0" ? formData.Email : ""}
                      onChange={handleOnChange}
                      placeholder="Input your Email here"
                      className={`w-full text-black text-xs font-medium font-poppins bg-[#ffffff] rounded-r-2xl p-4 border border-l-0 border-[#CFCFCF] focus:outline-none`}
                    />
                  </div>
                </label>
              </div>
            </>
          ) : (
            <>
              {/* Phone Number */}
              <div className="mb-4">
                <label>
                  <div className="flex items-center">
                    <div className="bg-[#ffffff] rounded-l-2xl p-3 border border-r-0 border-[#CFCFCF]">
                      <img
                        src={phoneIcon}
                        alt="phone Icon"
                        className="w-6 h-6 object-contain"
                      />
                    </div>
                    <input
                      required
                      type="number"
                      inputMode="number"
                      maxLength={10}
                      name="Phone"
                      value={loginType === "1" ? formData.Phone : ""}
                      onChange={handleOnChange}
                      placeholder="Input your Phone Number here"
                      className={`w-full text-black text-xs font-medium font-poppins bg-[#ffffff] rounded-r-2xl p-4 border border-l-0 border-[#CFCFCF] focus:outline-none`}
                    />
                  </div>
                </label>
              </div>
            </>
          )}

          {/*back to login */}
          <div className="mt-2">
            <Link
              to={type === 'clinic' ? "/login" : "/partnerLogin"}
              className="text-[#3ca5dc] text-xs font-normal font-poppins underline"
            >
              Back to Log In
            </Link>
          </div>

          {/* login button */}
          <div className="mt-16">
            <button
              type="submit"
              className="w-full bg-sky-400 hover:bg-sky-400 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 rounded-2xl py-3 text-sky-100 text-sm font-medium font-poppins"
            >
              Send OTP
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPasswordForm;