import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import Breadcrumb from "../components/Breadcrumb";
import Pagination from "../components/Pagination";
import { deleteLabPartners } from "../services/operations/masterDataAPI";
import ModalComponent from "../components/Modal";
import Tooltip from "../components/Tooltip";
import deleteIcon from "../assets/Images/MedicalConcierge/deleteRedIcon.svg";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { updateOrganizationUserDetails } from "../services/operations/masterDataAPI";
function AccessManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { allOrganizationUsers, loading: masterDataLoading } = useSelector(
    (state) => state.masterData
  );
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showManageAccessModal, setShowManageAccessModal] = useState(false);
  const [selectedClinician, setSelectedClinician] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const roles = [
    { name: "Admin", checked: false },
    { name: "Doctor", checked: false },
    { name: "TeleHealth Doctor", checked: false },
    { name: "Nurse", checked: false },
    { name: "Health Manager", checked: false },
  ];
  const [accessLevels, setAccessLevels] = useState(roles);
  const [status, setStatus] = useState("Pending");

  const filteredClinicians = allOrganizationUsers.filter((p) => {
    return p?.Name?.toLowerCase().includes(searchInput?.toLowerCase());
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentClinicians = filteredClinicians.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleCheckbox = (index) => {
    const newAccessLevels = [...accessLevels];
    newAccessLevels[index].checked = !newAccessLevels[index].checked;
    setAccessLevels(newAccessLevels);
  };
  const selectedRoles = accessLevels.filter((role) => role.checked);

  const handleShowManageAccessModal = (user) => {
    setSelectedClinician(user);
    setStatus(user?.Status);

    const updatedAccessLevels = roles.map((role) => ({
      ...role,
      checked: user?.AccessRoles.includes(role.name) ? true : role.checked,
    }));

    setAccessLevels(updatedAccessLevels);
    setShowManageAccessModal(true);
  };

  const handleCloseManageAccessModal = () => {
    setStatus("Pending");
    setShowMenu(false);
    setAccessLevels(roles);
    setShowManageAccessModal(false);
    setSelectedClinician(null);
  };

  const submitHandler = async () => {
    const roles = accessLevels && accessLevels.filter((role) => role.checked);
    const accessRoles = roles.map((role) => role.name);
    const data = {
      AccessRoles: accessRoles,
      Status: status,
    };
    dispatch(
      updateOrganizationUserDetails(token, selectedClinician?.DCID, data)
    );
    handleCloseManageAccessModal();
  };

  const handleDeleteService = (id) => {
    dispatch(deleteLabPartners(token, id));
    setShowDeleteModal(false);
    setSelectedClinician(null);
  };

  if (masterDataLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      <ModalComponent
        show={showDeleteModal}
        handleClose={() => {
          setShowDeleteModal(false);
          setSelectedClinician(null);
        }}
        outSideModalPress={false}
        showCloseButton={false}
      >
        <div className="flex flex-col items-center">
          <div className="w-14 h-14 p-3 bg-red-50 rounded-full mb-6">
            <img src={deleteIcon} alt="" />
          </div>
          <div className="text-center text-slate-800 text-base font-semibold font-poppins leading-tight">
            Remove '{selectedClinician?.Name}'?
          </div>
          <div className="text-center max-w-sm mx-auto text-zinc-500 text-sm font-normal font-poppins leading-tight mt-4">
            Once Removed, they will be not access to their dashboard.
          </div>
          <div className="flex flex-row items-center justify-end mt-6 space-x-4">
            <button
              onClick={() => {
                setShowDeleteModal(false);
                setSelectedClinician(null);
              }}
              className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
                Cancel
              </div>
            </button>
            <button
              onClick={() => handleDeleteService(selectedClinician?.LPID)}
              className="w-48 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-white text-base font-semibold font-poppins leading-normal">
                Remove
              </div>
            </button>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        show={showManageAccessModal}
        handleClose={() => handleCloseManageAccessModal()}
        outSideModalPress={false}
      >
        <div className="w-[40vw]">
          {/* Header */}
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-black text-lg font-bold font-poppins">
                Manage Access Levels
              </h1>
              <p className="text-zinc-500 text-xs font-normal font-poppins">
                Here you can provide access to the employees at your
                Organization
              </p>
            </div>
          </div>
          <div className="border border-b-1 border-gray-200 my-6"></div>
          <div className="flex space-x-5">
            <div className="flex flex-col items-center justify-center space-y-2">
              <img
                src={selectedClinician?.Image}
                alt="PatientPic"
                className="w-12 h-12 rounded-full object-cover shadow-lg"
              />
              <div className="text-neutral-400 text-xs font-semibold font-poppins">
                ID - {selectedClinician?.DID}
              </div>
            </div>
            <div className="flex flex-col mt-2">
              <div className="text-black text-sm font-semibold font-poppins">
                {selectedClinician?.Name}
              </div>
              <div className="text-zinc-500 text-xs font-normal font-poppins">
                {selectedClinician?.Email}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-[1fr,2fr,1fr] gap-4 mt-6">
            <div className="flex items-center space-x-2">
              <div className="text-black text-sm font-semibold font-poppins">
                Speciality
              </div>
            </div>
            <div className="flex items-center justify-center space-x-2">
              <div className="text-black text-sm font-semibold font-poppins">
                Access Levels
              </div>
            </div>
            <div className="flex items-center justify-end space-x-8">
              <div className="text-black text-sm font-semibold font-poppins">
                Status
              </div>
              <div className="relative">
                <button
                  onClick={() => setShowMenu(!showMenu)}
                  className="p-1 bg-zinc-100 text-sky-400 rounded-full active:bg-sky-100 active:scale-95 cursor-pointer"
                >
                  <HiOutlineDotsVertical />
                </button>
                {showMenu && (
                  <div className="absolute right-0 top-8 w-56 bg-white border rounded-lg p-2 space-y-2 shadow-lg z-10">
                    <div className="flex flex-col space-y-2">
                      <button
                        onClick={() => {
                          setStatus("Inactive");
                          setShowMenu(false);
                        }}
                        className="flex flex-row items-center justify-center hover:bg-gray-100 rounded py-1"
                      >
                        <div className="text-red-400 text-xs font-normal font-poppins">
                          Revoke Access - Unauthorized
                        </div>
                      </button>
                      <button
                        onClick={() => {
                          setStatus("Active");
                          setShowMenu(false);
                        }}
                        className="flex flex-row items-center justify-center hover:bg-gray-100 rounded py-1"
                      >
                        <div className="text-black text-xs font-normal font-poppins">
                          Provide Access - Authorized
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-[1fr,2fr,1fr] gap-4 items-center mt-2">
            <div className="text-black text-xs font-normal font-poppins">
              {selectedClinician?.RelatedSpecialities[0]?.Speciality}
            </div>
            <div className="flex items-center justify-center">
              <div className="flex flex-row flex-wrap gap-2">
                {selectedRoles?.map((role, index) => (
                  <div
                    key={index}
                    className="px-2.5 py-1 bg-sky-100 rounded-xl"
                  >
                    <div className="text-sky-400 text-xs font-semibold font-poppins">
                      {role?.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-center">
              <div
                className={`w-32 p-2 ${
                  status === "Active"
                    ? "bg-lime-100 text-lime-500"
                    : status === "Pending"
                    ? "bg-neutral-300 text-zinc-500"
                    : "bg-red-200 text-rose-500"
                } rounded-lg`}
              >
                <div className="text-center text-xs font-normal font-poppins">
                  {status === "Active"
                    ? "Authorized"
                    : status === "Inactive"
                    ? "Unauthorized"
                    : "Pending"}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="my-4 relative">
              <div className="flex flex-col">
                <div className="text-black text-sm font-semibold font-poppins">
                  Change Access Level
                </div>
                <div className="absolute top-4 left-2 border-l border-dashed border-neutral-400 h-40" />
              </div>
              <ul>
                {accessLevels.map((role, index) => (
                  <li key={index} className="flex items-center space-y-2">
                    <div className="flex items-center justify-start mt-3 ml-2">
                      <div className="w-10 border-t border-dashed border-neutral-400" />
                      <label
                        className="relative flex cursor-pointer items-center"
                        htmlFor="rememberMe"
                        // data-ripple-dark="true"
                      >
                        <input
                          type="checkbox"
                          checked={role.checked}
                          onChange={() => toggleCheckbox(index)}
                          className="before:content[''] peer relative h-4 w-4 border-2 border-neutral-400
             cursor-pointer appearance-none transition-all before:absolute
              before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8
               before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-lg 
               before:bg-blue-gray-500 before:opacity-0 before:transition-opacity
                checked:border-sky-400 checked:bg-sky-400 checked:before:bg-sky-400 
                hover:before:opacity-10"
                        />
                        <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3.5 w-3.5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth={1}
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      </label>
                      <p className="text-black text-sm font-normal font-poppins ml-2">
                        {role.name}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex flex-col">
              <div className="flex items-center justify-start mt-3 ml-2">
                <label
                  className="relative flex cursor-pointer items-center"
                  htmlFor="rememberMe"
                  // data-ripple-dark="true"
                >
                  <input
                    type="checkbox"
                    defaultChecked={true}
                    // onChange={handleOnChange}
                    className="before:content[''] peer relative h-6 w-6 border-2 border-neutral-400
        cursor-pointer appearance-none transition-all before:absolute
        before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10
        before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-lg 
        before:bg-blue-gray-500 before:opacity-0 before:transition-opacity
        checked:border-sky-400 checked:bg-sky-400 checked:before:bg-sky-400 
        hover:before:opacity-10"
                  />
                  <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth={1}
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </label>

                <p className="text-center text-black text-sm font-semibold font-poppins ml-2">
                  Send Email
                </p>
              </div>
              <div className="w-44 text-neutral-400 text-xs font-normal font-poppins ml-10">
                Send the mail to the Selected Employee with new Access Level for
                your Organization
              </div>
            </div>
          </div>
        </div>

        <button
          onClick={() => submitHandler()}
          className="w-52 h-11 mt-5 ml-3 shadow-md shadow-sky-500 bg-sky-400 rounded-lg  active:scale-95 active:shadow-none text-center text-stone-50 text-sm font-semibold font-poppins "
        >
          Save
        </button>
      </ModalComponent>

      {/* Header */}
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        Access Management
      </div>
      <Breadcrumb />

      {/* Search Bar */}
      <label className="w-full mx-auto flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2 mt-6">
        <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
        <input
          type="text"
          placeholder="Search for Clinician"
          value={searchInput}
          onChange={handleSearchChange}
          className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
        />
      </label>

      <div className="w-full mx-auto mt-4 shadow rounded-md">
        <table className="min-w-full">
          <thead>
            <tr className="text-left bg-gray-50 border-b border-gray-200">
              <th className="p-4 text-zinc-900 text-xs font-medium font-poppins leading-none">
                Name
              </th>
              <th className="p-4 text-zinc-900 text-xs font-medium font-poppins leading-none">
                Speciality
              </th>
              <th className="p-4 text-zinc-900 text-xs font-medium font-poppins leading-none">
                Access Level
              </th>
              <th className="p-4 text-zinc-900 text-xs font-medium font-poppins leading-none">
                Status
              </th>
              <th className="p-4 text-zinc-900 text-xs font-medium font-poppins leading-none">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {currentClinicians?.map((user) => (
              <tr
                key={user?.LPID}
                className="border-b border-gray-200 hover:bg-sky-50"
              >
                <td className="p-4">
                  <div
                    // onClick={() => {
                    //   navigate("/addNewHealthManager", {
                    //     state: { ...user, isViewing: true },
                    //   });
                    // }}
                    className="flex items-center"
                  >
                    <img
                      src={user?.Image}
                      alt={user?.Name}
                      className="w-10 h-10 rounded-full mr-3 object-cover"
                    />
                    <div>
                      <p className="text-gray-900 text-sm font-medium font-poppins leading-tight">
                        {user?.Name}
                      </p>
                      <p className="text-zinc-500 text-sm font-normal font-poppins leading-tight">
                        ID: {user?.DID}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                  {user?.RelatedSpecialities[0]?.Speciality}...
                </td>
                <td className="p-4">
                  <div className="flex flex-row flex-wrap gap-2">
                    {user?.AccessRoles?.map((item, i) => (
                      <div
                        key={i}
                        className="px-2.5 py-1 bg-sky-100 rounded-xl"
                      >
                        <div className="text-sky-400 text-sm font-semibold font-poppins">
                          {item}
                        </div>
                      </div>
                    ))}
                  </div>
                </td>
                <td className="p-4">
                  <div
                    className={`w-32 p-2 ${
                      user?.Status === "Active"
                        ? "bg-lime-100 text-lime-500"
                        : user?.Status === "Pending"
                        ? "bg-neutral-300 text-zinc-500"
                        : "bg-red-200 text-rose-500"
                    } rounded-lg`}
                  >
                    <div className="text-center  text-xs font-semibold font-poppins">
                      {user?.Status}
                    </div>
                  </div>
                </td>
                <td className="p-4">
                  <div className="flex flex-row items-center space-x-2">
                    <Tooltip message="Manage">
                      <button
                        onClick={() => {
                          handleShowManageAccessModal(user);
                        }}
                        className="p-1 rounded-full group group-hover:bg-sky-100"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            stroke-linecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        totalItems={filteredClinicians.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default AccessManagement;
