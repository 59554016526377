import React from "react";
import Tooltip from "./Tooltip";
import { Switch } from "antd";
import check_mark_green from "../assets/Icons/check_mark_green.svg";
import pending_icon from "../assets/Icons/pending_icon.svg";
import { useNavigate } from "react-router-dom";
import { rewardTypeList } from "../data/rewardType";
import { useDispatch, useSelector } from "react-redux";
import { updateRewardOffer } from "../services/operations/rewardManagementAPI";

function RewardCard({ offer }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const rewardType = rewardTypeList.find(
    (type) => type.value === offer.TypeOfReward
  );

  const toggleRewardStatus = (checked) => {
    const newStatus = checked ? "ACTIVE" : "DEACTIVE";
    dispatch(
      updateRewardOffer(
        token,
        offer.RewardID,
        // 52,
        {
          Status: newStatus,
        }
      )
    );
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow border">
      {/* Basic Info */}
      <div className="flex flex-col space-y-3">
        {/* Image, Edit and Activate/Deactivate Button */}
        <div className="flex flex-row items-center justify-between">
          <img
            src={offer.Image || "https://via.placeholder.com/44x44"}
            alt={offer.Title}
            className="w-11 h-11 rounded-full"
          />
          <div className="flex flex-row items-center space-x-4">
            <Tooltip message="Edit">
              <button
                onClick={() => {
                  navigate("createReward", {
                    state: offer,
                  });
                }}
                className="p-1 rounded-full group group-hover:bg-sky-100"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                >
                  <path
                    d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    stroke-linecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </button>
            </Tooltip>
            <Switch
              size="small"
              checked={offer.Status === "ACTIVE"}
              onChange={toggleRewardStatus}
            />
          </div>
        </div>

        {/* Reward Name and Reward ID */}
        <div>
          <h2 className="text-black text-sm font-normal font-poppins line-clamp-1">
            {offer.Title}
          </h2>
          <div className="text-neutral-600 text-xs font-normal font-poppins">
            (Reward Id: {offer.RewardID})
          </div>
        </div>

        {/* Reward Description */}
        <p className="h-11 text-neutral-600 text-xs font-normal font-poppins line-clamp-2">
          {offer.Description}
        </p>

        {/* Type of Offer */}
        <div className="flex flex-row items-center space-x-2">
          <div className="text-neutral-600 text-xs font-normal font-poppins">
            Type of Offer:
          </div>
          <div className="text-zinc-900 text-xs font-medium font-poppins">
            {offer.TypeOfOffer.toLowerCase().charAt(0).toUpperCase() +
              offer.TypeOfOffer.toLowerCase().slice(1)}
          </div>
        </div>

        {/* Reward Type */}
        <div className="flex flex-row items-center space-x-2">
          <div className="text-neutral-600 text-xs font-normal font-poppins">
            Reward Type
          </div>
          <div className="text-zinc-900 text-xs font-medium font-poppins">
            {rewardType ? rewardType.label : offer.TypeOfReward}
          </div>
        </div>
      </div>

      {/* Status */}
      <div
        className={`h-8 px-2 py-2.5 my-4 rounded-3xl justify-center items-center gap-2 inline-flex ${
          offer.Status === "ACTIVE" ? "bg-emerald-100" : "bg-gray-100"
        }`}
      >
        {offer.Status === "ACTIVE" && (
          <img src={check_mark_green} alt="" className="w-4 h-4" />
        )}
        <div
          className={`text-center text-xs font-normal font-poppins leading-none ${
            offer.Status === "ACTIVE" ? "text-lime-700" : "text-zinc-500"
          }`}
        >
          {offer.Status.toLowerCase().charAt(0).toUpperCase() +
            offer.Status.toLowerCase().slice(1)}
        </div>
      </div>

      {/* Divider */}
      <div className="h-px bg-gray-200" />

      {/* View Customer List Button */}
      <button
        onClick={() => {
          if (offer.Status === "DRAFT") {
            dispatch(
              updateRewardOffer(token, offer.RewardID, {
                Status: "ACTIVE",
              })
            );
          } else {
            navigate("salesDetails", { state: offer });
          }
        }}
        className="w-full px-4 py-2 mt-2 bg-white rounded-lg border border-sky-400 active:scale-95"
      >
        <div className="text-sky-700 text-center text-sm font-semibold font-poppins leading-tight">
          {offer.Status === "DRAFT" ? "Publish" : "View Customer List"}
        </div>
      </button>

      {/* Pending Customer Count */}
      {offer.PendingSales > 0 ? (
        <div className="flex flex-row items-center space-x-2 mt-1">
          <img src={pending_icon} alt="" className="w-4 h-4" />
          <div className="text-center text-amber-800 text-xs font-medium font-poppins leading-tight">
            {offer.PendingSales} pending sales
          </div>
        </div>
      ) : (
        <div className="h-4 mt-1" />
      )}

      {/* Price of the offer */}
      <div className="flex flex-row items-center space-x-2 mt-4">
        <div className="text-black text-xs font-normal font-poppins">
          Price of the offer:
        </div>
        <div className="text-center text-neutral-600 text-sm font-normal font-poppins">
          {offer.Currency} {offer.TotalPrice}
        </div>
      </div>

      {/* Discount Offered */}
      <div className="flex flex-row items-center space-x-2 mt-2">
        <div className="text-black text-xs font-normal font-poppins">
          Discount Offered:
        </div>
        <div className="text-center text-neutral-600 text-sm font-normal font-poppins">
          {offer.TypeOfDiscount === "PERCENTAGE"
            ? offer.DiscountInput + " %"
            : offer.TypeOfDiscount === "REDUCTION"
            ? offer.Currency + " " + offer.DiscountInput
            : "Free"}
        </div>
      </div>
    </div>
  );
}

export default RewardCard;
