import React, { useEffect, useState } from "react";
import { LuPen } from "react-icons/lu";
import ModalComponent from "./Modal";
import PhoneInput from "react-phone-input-2";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";
import { updatePatientProfile } from "../services/operations/patientManagementAPI";
import Tooltip from "./Tooltip";
import { Steps } from "antd";
import { IoMaleSharp } from "react-icons/io5";
import { IoFemaleSharp } from "react-icons/io5";
import { LiaTransgenderSolid } from "react-icons/lia";
import {
    MdOutlineKeyboardArrowLeft,
    MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import moment from "moment";

const steps = [
    {
        title: "Personal Details",
    },
    {
        title: "Address",
    },
    {
        title: "Administration",
    },
    {
        title: "Others",
    },
];

function EditUserDetailsModal({ patient, patientProfile, navigateTo, setShowEditComp }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const { token } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [current, setCurrent] = useState(0);
    const { allBranches } = useSelector((state) => state.masterData);
    const [inputValue, setInputValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const items = steps?.map((item) => ({
        key: item.title,
        title: item.title,
    }));

    const [formData, setFormData] = useState({
        Gender: "",
        DateofBirth: "",
        PhoneNumber: "",
        // Email: "",
        StreetName: "",
        HouseNumber: "",
        PinCode: "",
        State: "",
        City: "",
        Country: "",
        BranchID: "",
        Occupation: "",
        LivingArrangement: "",
        RelationshipStatus: "",
        Employment: "",
        InsuranceProvider: "",
        PolicyNumber: "",
        AHVNo: "",
        Latitude: "",
        Longitude: "",
        Details: "",
    });
    const [errors, setErrors] = useState({
        Gender: "",
        DateofBirth: "",
        PhoneNumber: "",
        // Email: "",
        StreetName: "",
        HouseNumber: "",
        PinCode: "",
        State: "",
        City: "",
        Country: "",
        BranchID: "",
        Occupation: "",
        LivingArrangement: "",
        RelationshipStatus: "",
        Employment: "",
        InsuranceProvider: "",
        PolicyNumber: "",
        AHVNo: "",
        Details: "",
    });

    const handleChange = (e) => {
        const value = e.target.value;
        setInputValue(value);
        getPlacePredictions(value);
    };

    const getPlacePredictions = (input) => {
        if (!input) {
            setSuggestions([]);
            return;
        }

        const service = new window.google.maps.places.AutocompleteService();
        service.getPlacePredictions({ input }, (predictions, status) => {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                console.error(status);
                return;
            }

            setSuggestions(predictions);
        });
    };

    const handleSuggestionClick = (placeId) => {
        const service = new window.google.maps.places.PlacesService(
            document.createElement("div")
        );
        service.getDetails({ placeId }, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const addressComponents = place.address_components.reduce(
                    (acc, component) => {
                        const types = component.types;
                        if (types.includes("sublocality_level_2"))
                            acc.streetNumber = component.long_name;
                        if (types.includes("sublocality")) acc.street = component.long_name;
                        if (types.includes("postal_code"))
                            acc.pinCode = component.long_name;
                        if (types.includes("administrative_area_level_1"))
                            acc.state = component.long_name;
                        if (types.includes("country")) acc.country = component.long_name;
                        if (types.includes("locality")) acc.city = component.long_name;
                        return acc;
                    },
                    {}
                );
                const { lat, lng } = place.geometry.location;
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    StreetName: `${addressComponents.streetNumber} ${addressComponents.street}`,
                    PinCode: addressComponents.pinCode,
                    State: addressComponents.state,
                    Country: addressComponents.country.toUpperCase(),
                    City: addressComponents.city,
                    Latitude: lat(),
                    Longitude: lng(),
                }));
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    StreetName: "",
                    PinCode: "",
                    State: "",
                    Country: "",
                    City: "",
                }));

                setInputValue(place.formatted_address);
                setSuggestions([]);
                // assignNearestBranch();
            } else {
                console.error("Place details request failed:", status);
            }
        });
    };

    const next = () => {
        setCurrent(current + 1);
        setSuggestions([]);
    };

    const prev = () => {
        setCurrent(current - 1);
        setSuggestions([]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let data = {
            DOB: moment(formData.DateofBirth).format("YYYY-MM-DD"),
            Gender: formData?.Gender,
            Phone: formData.PhoneNumber,
            // Email: formData.Email,
            Address: formData?.HouseNumber + " " + formData.StreetName,
            PinCode: formData.PinCode,
            State: formData.State,
            City: formData.City,
            Country: formData.Country,
            InsuranceProvider: formData.InsuranceProvider,
            PolicyNumber: formData.PolicyNumber,
            AHVNumber: formData?.AHVNo,
            Occupation: formData.Occupation,
            Employment: formData.Employment,
            LivingArrangement: formData.LivingArrangement,
            RelationshipStatus: formData.RelationshipStatus,
            BranchID: parseInt(formData.BranchID),
            Latitude: formData.Latitude || 0,
            Longitude: formData.Longitude || 0,
        };

        let ID = patient?.AHUID;

        dispatch(updatePatientProfile(token, ID, data, navigate, patient, navigateTo));
        handleConfirmCloseModal();
    };

    const submitHandler = async (event) => {
        event.preventDefault();

        const mandatoryFields = [
            "DateofBirth",
            "PhoneNumber",
            "Email",
            "Occupation",
            "Employment",
            "LivingArrangement",
            "RelationshipStatus",
        ];
        const newErrors = {};

        mandatoryFields.forEach((key) => {
            const value = formData[key];

            if (!value) {
                newErrors[key] = "This field is required";
            } else if (typeof value === "string" && value.trim() === "") {
                newErrors[key] = "This field cannot be empty";
            }
        });

        if (Object.keys(newErrors).length > 0) {
            toast.error("Please fill all the required fields");
            setErrors(newErrors);
            console.log("ERRORS", errors);
            return;
        }

        let ID = patient?.AHUID;

        let data = {
            DateofBirth: moment(formData.DateofBirth).format("YYYY-MM-DD"),
            PhoneNumber: formData.PhoneNumber,
            Email: formData.Email,
            Occupation: formData.Occupation,
            Employment: formData.Employment,
            LivingArrangement: formData.LivingArrangement,
            RelationshipStatus: formData.RelationshipStatus,
            Details: formData.Details,
        };
        dispatch(updatePatientProfile(token, ID, data, navigate, patient));
        handleCloseModal();
    };

    function handleOnChange(event) {
        const { name, value } = event.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
        setErrors((prev) => ({
            ...prev,
            [name]: "",
        }));
    }

    const handleConfirmCloseModal = () => {
        setIsConfirmModalOpen(false);
        setIsModalOpen(false);
        setShowEditComp(false);
        setFormData({
            Gender: "",
            DateofBirth: "",
            PhoneNumber: "",
            // Email: "",
            StreetName: "",
            HouseNumber: "",
            PinCode: "",
            State: "",
            City: "",
            Country: "",
            BranchID: "",
            Occupation: "",
            LivingArrangement: "",
            RelationshipStatus: "",
            Employment: "",
            InsuranceProvider: "",
            PolicyNumber: "",
            AHVNo: "",
            Latitude: "",
            Longitude: "",
            Details: "",
        });
        setErrors({
            Gender: "",
            DateofBirth: "",
            PhoneNumber: "",
            // Email: "",
            StreetName: "",
            HouseNumber: "",
            PinCode: "",
            State: "",
            City: "",
            Country: "",
            BranchID: "",
            Occupation: "",
            LivingArrangement: "",
            RelationshipStatus: "",
            Employment: "",
            InsuranceProvider: "",
            PolicyNumber: "",
            AHVNo: "",
            Latitude: "",
            Longitude: "",
            Details: "",
        });
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setShowEditComp(false);
        setFormData({
            Gender: "",
            DateofBirth: "",
            PhoneNumber: "",
            // Email: "",
            StreetName: "",
            HouseNumber: "",
            PinCode: "",
            State: "",
            City: "",
            Country: "",
            BranchID: "",
            Occupation: "",
            LivingArrangement: "",
            RelationshipStatus: "",
            Employment: "",
            InsuranceProvider: "",
            PolicyNumber: "",
            AHVNo: "",
            Latitude: "",
            Longitude: "",
            Details: "",
        });
        setErrors({
            Gender: "",
            DateofBirth: "",
            PhoneNumber: "",
            // Email: "",
            StreetName: "",
            HouseNumber: "",
            PinCode: "",
            State: "",
            City: "",
            Country: "",
            BranchID: "",
            Occupation: "",
            LivingArrangement: "",
            RelationshipStatus: "",
            Employment: "",
            InsuranceProvider: "",
            PolicyNumber: "",
            AHVNo: "",
            Latitude: "",
            Longitude: "",
            Details: "",
        });
    };

    const handleOpenModal = () => {
        setCurrent(0);
        setIsModalOpen(true);
        const HouseNumber = patientProfile?.Address?.split(" ")[0] || "";
        const StreetName = patientProfile?.Address?.substring(HouseNumber.length + 1) || "";
        setInputValue(StreetName);
        setFormData({
            Gender: patientProfile?.Gender,
            DateofBirth: patientProfile?.DOB,
            PhoneNumber: patientProfile?.Phone,
            StreetName: StreetName || "",
            HouseNumber: HouseNumber || "",
            PinCode: patientProfile?.PinCode,
            State: patientProfile?.State,
            City: patientProfile?.City,
            Country: patientProfile?.Country,
            BranchID: patientProfile?.BranchID.toString(),
            InsuranceProvider: patientProfile?.InsuranceProvider,
            PolicyNumber: patientProfile?.PolicyNumber,
            AHVNo: patientProfile?.AHVNumber,
            // Email: patientProfile?.Email,
            Occupation: patientProfile?.Occupation,
            Employment: patientProfile?.Employment,
            LivingArrangement: patientProfile?.LivingArrangement,
            RelationshipStatus: patientProfile?.RelationshipStatus,
            Details: patientProfile?.Details,
            Latitude: patientProfile?.Latitude,
            Longitude: patientProfile?.Longitude,
        });
    };

    const renderInputFields = () => {
        switch (current) {
            case 0:
                return (
                    <>
                        {/* DOB */}
                        <div className="mb-3">
                            <label>
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    Date of Birth
                                </p>
                                <input
                                    type="date"
                                    name="DateofBirth"
                                    value={moment(formData.DateofBirth).format("YYYY-MM-DD")}
                                    placeholder="Select Date of Birth"
                                    onChange={handleOnChange}
                                    max={moment().format("YYYY-MM-DD")}
                                    className={`w-full accent-sky-500 text-sky-400 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.DateofBirth ? "border-red-500" : ""
                                        }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                />
                            </label>
                        </div>

                        {/* Phone */}
                        <div className="mb-3">
                            <label>
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    Phone Number
                                </p>
                                <PhoneInput
                                    className="number"
                                    country={"ch"}
                                    value={formData.PhoneNumber}
                                    onChange={(value) => {
                                        setFormData({ ...formData, PhoneNumber: value });
                                        setErrors({ ...errors, PhoneNumber: "" });
                                    }}
                                    inputClass={`w-full text-black text-xs font-normal font-poppins rounded-lg p-3 border ${errors.PhoneNumber ? "border-red-500" : ""
                                        }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                    inputStyle={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: "#F3F5F7",
                                        border: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                                        outline: "none",
                                        borderRadius: 6,
                                    }}
                                    buttonStyle={{
                                        borderLeft:
                                            errors.PhoneNumber && "1px solid rgb(239 68 68)",
                                        borderTop: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                                        borderBottom:
                                            errors.PhoneNumber && "1px solid rgb(239 68 68)",
                                        borderRight: errors.PhoneNumber && "1px solid transparent",
                                        borderTopLeftRadius: 6,
                                        borderBottomLeftRadius: 6,
                                    }}
                                />
                            </label>
                        </div>

                        {/* Email */}
                        {/* <div className="mb-3">
                            <label>
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    Email
                                </p>
                                <input
                                    type="email"
                                    name="Email"
                                    // maxLength={50}
                                    value={formData.Email}
                                    onChange={handleOnChange}
                                    placeholder="Input your email here"
                                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.Email ? "border-red-500" : ""
                                        }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                />
                            </label>
                        </div> */}

                        {/* Gender */}
                        <div className="mb-3">
                            <label>
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    Gender
                                </p>
                                <div className="flex space-x-2">
                                    <button
                                        className={`w-full flex flex-row items-center justify-center space-x-1 rounded-lg p-3 border ${formData.Gender === "Male"
                                            ? "border-sky-100 text-white bg-sky-400"
                                            : "border-sky-500 text-sky-400  bg-white"
                                            }`}
                                        onClick={() => setFormData({ ...formData, Gender: "Male" })}
                                    >
                                        <IoMaleSharp />
                                        <div className="text-sm font-medium font-poppins">Male</div>
                                    </button>
                                    <button
                                        className={`w-full flex flex-row items-center justify-center space-x-1 rounded-lg p-3 border ${formData.Gender === "Female"
                                            ? "border-sky-100 text-white bg-rose-500"
                                            : "border-rose-500 text-rose-500  bg-white"
                                            }`}
                                        onClick={() =>
                                            setFormData({ ...formData, Gender: "Female" })
                                        }
                                    >
                                        <IoFemaleSharp />
                                        <div className="text-sm font-semibold font-poppins">
                                            Female
                                        </div>
                                    </button>
                                    <button
                                        className={`w-full flex flex-row items-center justify-center space-x-1 text-xl rounded-lg p-3 border ${formData.Gender === "Diverse"
                                            ? "border-sky-100 text-white bg-lime-600"
                                            : "border-lime-600 text-lime-600  bg-white"
                                            }`}
                                        onClick={() =>
                                            setFormData({ ...formData, Gender: "Diverse" })
                                        }
                                    >
                                        <LiaTransgenderSolid />
                                        <div className="text-sm font-semibold font-poppins">
                                            Diverse
                                        </div>
                                    </button>
                                </div>
                            </label>
                        </div>
                    </>
                );
            case 1:
                return (
                    <>
                        {/* Street Name | House Number */}
                        <div className="mb-3 flex space-x-4">
                            <label className="flex-1 relative">
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    Street Name
                                </p>
                                <input
                                    type="text"
                                    name="StreetName"
                                    maxLength={50}
                                    // value={formData.StreetName}
                                    // onChange={handleOnChange}
                                    value={inputValue}
                                    onChange={handleChange}
                                    placeholder="Input your street name here"
                                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.StreetName ? "border-red-500" : ""
                                        } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                />
                                {suggestions.length > 0 && (
                                    <div className="absolute left-0 mt-2 w-[25vw] bg-white border rounded-lg p-2 space-y-2 shadow-lg z-10">
                                        {suggestions?.slice(0, 4)?.map((x, i) => (
                                            <button
                                                key={i}
                                                onClick={() => handleSuggestionClick(x.place_id)}
                                                className={`block w-full text-left px-4 py-2 text-xs rounded-lg bg-gray-100 text-black hover:bg-sky-400 hover:text-white duration-200`}
                                            >
                                                {x.description}
                                            </button>
                                        ))}
                                    </div>
                                )}
                            </label>
                            <label className="flex-1">
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    House Number
                                </p>
                                <input
                                    type="text"
                                    name="HouseNumber"
                                    maxLength={10}
                                    value={formData.HouseNumber}
                                    onChange={handleOnChange}
                                    placeholder="Input your house number here"
                                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.HouseNumber ? "border-red-500" : ""
                                        } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                />
                            </label>
                        </div>

                        {/* Pin code | City */}
                        <div className="mb-3 flex space-x-4">
                            <label className="flex-1">
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    Pin Code
                                </p>
                                <input
                                    type="text"
                                    name="PinCode"
                                    maxLength={6}
                                    value={formData.PinCode}
                                    onChange={handleOnChange}
                                    placeholder="Input your pin code here"
                                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.PinCode ? "border-red-500" : ""
                                        } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                />
                            </label>
                            <label className="flex-1">
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    City
                                </p>
                                <input
                                    type="text"
                                    name="City"
                                    maxLength={50}
                                    value={formData.City}
                                    onChange={handleOnChange}
                                    placeholder="Input your city here"
                                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.City ? "border-red-500" : ""
                                        } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                />
                            </label>
                        </div>

                        {/* Region | Country */}
                        <div className="mb-3 flex space-x-4">
                            <label className="flex-1">
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    Region / State / Canton
                                </p>
                                <input
                                    type="text"
                                    name="State"
                                    maxLength={50}
                                    value={formData.State}
                                    onChange={handleOnChange}
                                    placeholder="Input your state here"
                                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.State ? "border-red-500" : ""
                                        } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                />
                            </label>
                            <label className="flex-1">
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    Country
                                </p>
                                <select
                                    id="Country"
                                    name="Country"
                                    value={formData.Country}
                                    className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${errors.Country ? "border-red-500" : ""
                                        }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                    onChange={handleOnChange}
                                >
                                    <option value="">Select Country</option>
                                    <option value="AFGHANISTAN">Afghanistan</option>
                                    <option value="ALBANIA">Albania</option>
                                    <option value="ALGERIA">Algeria</option>
                                    <option value="ANDORRA">Andorra</option>
                                    <option value="ANGOLA">Angola</option>
                                    <option value="ANTIGUA AND BARBUDA">
                                        Antigua and Barbuda
                                    </option>
                                    <option value="ARGENTINA">Argentina</option>
                                    <option value="ARMENIA">Armenia</option>
                                    <option value="AUSTRALIA">Australia</option>
                                    <option value="AUSTRIA">Austria</option>
                                    <option value="AZERBAIJAN">Azerbaijan</option>
                                    <option value="BAHAMAS">Bahamas</option>
                                    <option value="BAHRAIN">Bahrain</option>
                                    <option value="BANGLADESH">Bangladesh</option>
                                    <option value="BARBADOS">Barbados</option>
                                    <option value="BELARUS">Belarus</option>
                                    <option value="BELGIUM">Belgium</option>
                                    <option value="BELIZE">Belize</option>
                                    <option value="BENIN">Benin</option>
                                    <option value="BHUTAN">Bhutan</option>
                                    <option value="BOLIVIA">Bolivia</option>
                                    <option value="BOSNIA AND HERZEGOVINA">
                                        Bosnia and Herzegovina
                                    </option>
                                    <option value="BOTSWANA">Botswana</option>
                                    <option value="BRAZIL">Brazil</option>
                                    <option value="BRUNEI">Brunei</option>
                                    <option value="BULGARIA">Bulgaria</option>
                                    <option value="BURKINA FASO">Burkina Faso</option>
                                    <option value="BURUNDI">Burundi</option>
                                    <option value="CABO VERDE">Cabo Verde</option>
                                    <option value="CAMBODIA">Cambodia</option>
                                    <option value="CAMEROON">Cameroon</option>
                                    <option value="CANADA">Canada</option>
                                    <option value="CENTRAL AFRICAN REPUBLIC">
                                        Central African Republic
                                    </option>
                                    <option value="CHAD">Chad</option>
                                    <option value="CHILE">Chile</option>
                                    <option value="CHINA">China</option>
                                    <option value="COLOMBIA">Colombia</option>
                                    <option value="COMOROS">Comoros</option>
                                    <option value="CONGO, DEMOCRATIC REPUBLIC OF THE">
                                        Congo, Democratic Republic of the
                                    </option>
                                    <option value="CONGO, REPUBLIC OF THE">
                                        Congo, Republic of the
                                    </option>
                                    <option value="COSTA RICA">Costa Rica</option>
                                    <option value="CROATIA">Croatia</option>
                                    <option value="CUBA">Cuba</option>
                                    <option value="CYPRUS">Cyprus</option>
                                    <option value="CZECH REPUBLIC">Czech Republic</option>
                                    <option value="DENMARK">Denmark</option>
                                    <option value="DJIBOUTI">Djibouti</option>
                                    <option value="DOMINICA">Dominica</option>
                                    <option value="DOMINICAN REPUBLIC">Dominican Republic</option>
                                    <option value="ECUADOR">Ecuador</option>
                                    <option value="EGYPT">Egypt</option>
                                    <option value="EL SALVADOR">El Salvador</option>
                                    <option value="EQUATORIAL GUINEA">Equatorial Guinea</option>
                                    <option value="ERITREA">Eritrea</option>
                                    <option value="ESTONIA">Estonia</option>
                                    <option value="ESWATINI">Eswatini</option>
                                    <option value="ETHIOPIA">Ethiopia</option>
                                    <option value="FIJI">Fiji</option>
                                    <option value="FINLAND">Finland</option>
                                    <option value="FRANCE">France</option>
                                    <option value="GABON">Gabon</option>
                                    <option value="GAMBIA">Gambia</option>
                                    <option value="GEORGIA">Georgia</option>
                                    <option value="GERMANY">Germany</option>
                                    <option value="GHANA">Ghana</option>
                                    <option value="GREECE">Greece</option>
                                    <option value="GRENADA">Grenada</option>
                                    <option value="GUATEMALA">Guatemala</option>
                                    <option value="GUINEA">Guinea</option>
                                    <option value="GUINEA-BISSAU">Guinea-Bissau</option>
                                    <option value="GUYANA">Guyana</option>
                                    <option value="HAITI">Haiti</option>
                                    <option value="HONDURAS">Honduras</option>
                                    <option value="HUNGARY">Hungary</option>
                                    <option value="ICELAND">Iceland</option>
                                    <option value="INDIA">India</option>
                                    <option value="INDONESIA">Indonesia</option>
                                    <option value="IRAN">Iran</option>
                                    <option value="IRAQ">Iraq</option>
                                    <option value="IRELAND">Ireland</option>
                                    <option value="ISRAEL">Israel</option>
                                    <option value="ITALY">Italy</option>
                                    <option value="JAMAICA">Jamaica</option>
                                    <option value="JAPAN">Japan</option>
                                    <option value="JORDAN">Jordan</option>
                                    <option value="KAZAKHSTAN">Kazakhstan</option>
                                    <option value="KENYA">Kenya</option>
                                    <option value="KIRIBATI">Kiribati</option>
                                    <option value="KOREA, NORTH">Korea, North</option>
                                    <option value="KOREA, SOUTH">Korea, South</option>
                                    <option value="KOSOVO">Kosovo</option>
                                    <option value="KUWAIT">Kuwait</option>
                                    <option value="KYRGYZSTAN">Kyrgyzstan</option>
                                    <option value="LAOS">Laos</option>
                                    <option value="LATVIA">Latvia</option>
                                    <option value="LEBANON">Lebanon</option>
                                    <option value="LESOTHO">Lesotho</option>
                                    <option value="LIBERIA">Liberia</option>
                                    <option value="LIBYA">Libya</option>
                                    <option value="LIECHTENSTEIN">Liechtenstein</option>
                                    <option value="LITHUANIA">Lithuania</option>
                                    <option value="LUXEMBOURG">Luxembourg</option>
                                    <option value="MADAGASCAR">Madagascar</option>
                                    <option value="MALAWI">Malawi</option>
                                    <option value="MALAYSIA">Malaysia</option>
                                    <option value="MALDIVES">Maldives</option>
                                    <option value="MALI">Mali</option>
                                    <option value="MALTA">Malta</option>
                                    <option value="MARSHALL ISLANDS">Marshall Islands</option>
                                    <option value="MAURITANIA">Mauritania</option>
                                    <option value="MAURITIUS">Mauritius</option>
                                    <option value="MEXICO">Mexico</option>
                                    <option value="MICRONESIA">Micronesia</option>
                                    <option value="MOLDOVA">Moldova</option>
                                    <option value="MONACO">Monaco</option>
                                    <option value="MONGOLIA">Mongolia</option>
                                    <option value="MONTENEGRO">Montenegro</option>
                                    <option value="MOROCCO">Morocco</option>
                                    <option value="MOZAMBIQUE">Mozambique</option>
                                    <option value="MYANMAR">Myanmar</option>
                                    <option value="NAMIBIA">Namibia</option>
                                    <option value="NAURU">Nauru</option>
                                    <option value="NEPAL">Nepal</option>
                                    <option value="NETHERLANDS">Netherlands</option>
                                    <option value="NEW ZEALAND">New Zealand</option>
                                    <option value="NICARAGUA">Nicaragua</option>
                                    <option value="NIGER">Niger</option>
                                    <option value="NIGERIA">Nigeria</option>
                                    <option value="NORTH MACEDONIA">North Macedonia</option>
                                    <option value="NORWAY">Norway</option>
                                    <option value="OMAN">Oman</option>
                                    <option value="PAKISTAN">Pakistan</option>
                                    <option value="PALAU">Palau</option>
                                    <option value="PALESTINE">Palestine</option>
                                    <option value="PANAMA">Panama</option>
                                    <option value="PAPUA NEW GUINEA">Papua New Guinea</option>
                                    <option value="PARAGUAY">Paraguay</option>
                                    <option value="PERU">Peru</option>
                                    <option value="PHILIPPINES">Philippines</option>
                                    <option value="POLAND">Poland</option>
                                    <option value="PORTUGAL">Portugal</option>
                                    <option value="QATAR">Qatar</option>
                                    <option value="ROMANIA">Romania</option>
                                    <option value="RUSSIA">Russia</option>
                                    <option value="RWANDA">Rwanda</option>
                                    <option value="SAINT KITTS AND NEVIS">
                                        Saint Kitts and Nevis
                                    </option>
                                    <option value="SAINT LUCIA">Saint Lucia</option>
                                    <option value="SAINT VINCENT AND THE GRENADINES">
                                        Saint Vincent and the Grenadines
                                    </option>
                                    <option value="SAMOA">Samoa</option>
                                    <option value="SAN MARINO">San Marino</option>
                                    <option value="SAO TOME AND PRINCIPE">
                                        Sao Tome and Principe
                                    </option>
                                    <option value="SAUDI ARABIA">Saudi Arabia</option>
                                    <option value="SENEGAL">Senegal</option>
                                    <option value="SERBIA">Serbia</option>
                                    <option value="SEYCHELLES">Seychelles</option>
                                    <option value="SIERRA LEONE">Sierra Leone</option>
                                    <option value="SINGAPORE">Singapore</option>
                                    <option value="SLOVAKIA">Slovakia</option>
                                    <option value="SLOVENIA">Slovenia</option>
                                    <option value="SOLOMON ISLANDS">Solomon Islands</option>
                                    <option value="SOMALIA">Somalia</option>
                                    <option value="SOUTH AFRICA">South Africa</option>
                                    <option value="SOUTH SUDAN">South Sudan</option>
                                    <option value="SPAIN">Spain</option>
                                    <option value="SRI LANKA">Sri Lanka</option>
                                    <option value="SUDAN">Sudan</option>
                                    <option value="SURINAME">Suriname</option>
                                    <option value="SWEDEN">Sweden</option>
                                    <option value="SWITZERLAND">Switzerland</option>
                                    <option value="SYRIA">Syria</option>
                                    <option value="TAIWAN">Taiwan</option>
                                    <option value="TAJIKISTAN">Tajikistan</option>
                                    <option value="TANZANIA">Tanzania</option>
                                    <option value="THAILAND">Thailand</option>
                                    <option value="TIMOR-LESTE">Timor-Leste</option>
                                    <option value="TOGO">Togo</option>
                                    <option value="TONGA">Tonga</option>
                                    <option value="TRINIDAD AND TOBAGO">
                                        Trinidad and Tobago
                                    </option>
                                    <option value="TUNISIA">Tunisia</option>
                                    <option value="TURKEY">Turkey</option>
                                    <option value="TURKMENISTAN">Turkmenistan</option>
                                    <option value="TUVALU">Tuvalu</option>
                                    <option value="UGANDA">Uganda</option>
                                    <option value="UKRAINE">Ukraine</option>
                                    <option value="UNITED ARAB EMIRATES">
                                        United Arab Emirates
                                    </option>
                                    <option value="UNITED KINGDOM">United Kingdom</option>
                                    <option value="UNITED STATES">United States</option>
                                    <option value="URUGUAY">Uruguay</option>
                                    <option value="UZBEKISTAN">Uzbekistan</option>
                                    <option value="VANUATU">Vanuatu</option>
                                    <option value="VATICAN CITY">Vatican City</option>
                                    <option value="VENEZUELA">Venezuela</option>
                                    <option value="VIETNAM">Vietnam</option>
                                    <option value="YEMEN">Yemen</option>
                                    <option value="ZAMBIA">Zambia</option>
                                    <option value="ZIMBABWE">Zimbabwe</option>
                                </select>
                            </label>
                        </div>

                        {/* Assigned Branch */}
                        <div className="">
                            <p className="text-zinc-950 text-xs font-semibold font-poppins mb-4">
                                Assigned Main Branch
                            </p>
                            <div className="grid grid-cols-2 gap-4 max-h-[17vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                                {allBranches?.map((branch) => (
                                    <label
                                        key={branch.BranchID}
                                        className="inline-flex items-center"
                                    >
                                        <input
                                            type="radio"
                                            name="BranchID"
                                            value={branch.BranchID}
                                            checked={formData.BranchID === branch.BranchID.toString()}
                                            onChange={handleOnChange}
                                            className="form-radio"
                                        />
                                        <span className="ml-2 text-black text-xs font-normal font-poppins">
                                            {branch.Name}
                                        </span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        {/* Insurance Provider */}
                        <div className="mb-5">
                            <label>
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    Insurance Provider
                                    {/* <sup className="text-red-600">*</sup> */}
                                </p>
                                <select
                                    id="InsuranceProvider"
                                    name="InsuranceProvider"
                                    value={formData.InsuranceProvider}
                                    onChange={handleOnChange}
                                    className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${errors.InsuranceProvider ? "border-red-500" : ""
                                        }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                >
                                    <option value="">Select Insurance Provider</option>
                                    <option value="Agrisano">Agrisano</option>
                                    <option value="Aquilana">Aquilana</option>
                                    <option value="Concordia">Concordia</option>
                                    <option value="Sanitas">Sanitas</option>
                                    <option value="Swica">Swica</option>
                                    <option value="Atupri">Atupri</option>
                                    <option value="Helsana">Helsana</option>
                                    <option value="KPT">KPT</option>
                                    <option value="ÖKK">ÖKK</option>
                                    <option value="Sana24">Sana24</option>
                                    <option value="Visana">Visana</option>
                                    <option value="Vivacare">Vivacare</option>
                                    <option value="CSS">CSS</option>
                                    <option value="EGK-Gesundheitskasse">
                                        EGK-Gesundheitskasse
                                    </option>
                                    <option value="Sympany">Sympany</option>
                                    <option value="Avenir">Avenir</option>
                                    <option value="Easy Sana">Easy Sana</option>
                                    <option value="Mutuel Assurance">Mutuel Assurance</option>
                                    <option value="Philos">Philos</option>
                                    <option value="Supra">Supra</option>
                                    <option value="Assura">Assura</option>
                                </select>
                            </label>
                        </div>
                        {/* Policy Number */}
                        <div className="mb-5">
                            <label>
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    Policy Number
                                    {/* <sup className="text-red-600">*</sup> */}
                                </p>
                                <input
                                    type="number"
                                    name="PolicyNumber"
                                    onKeyDown={(e) => {
                                        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                            e.preventDefault();
                                        }
                                    }}
                                    onWheel={(e) => {
                                        e.preventDefault();
                                        e.target.blur();
                                    }}
                                    value={formData.PolicyNumber}
                                    onChange={handleOnChange}
                                    placeholder="Input your Policy Number here"
                                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.PolicyNumber ? "border-red-500" : ""
                                        }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                />
                            </label>
                        </div>
                        {/* AHV No. */}
                        <div className="mb-5">
                            <label>
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    AHV No.
                                    <sup className="text-red-600">*</sup>
                                </p>
                                <input
                                    type="text"
                                    name="AHVNo"
                                    value={formData.AHVNo}
                                    onChange={handleOnChange}
                                    placeholder="Input your AHV No. here"
                                    maxLength={16}
                                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.AHVNo ? "border-red-500" : ""
                                        }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                />
                                <p className="text-[#3CA5DC] text-xs font-semibold font-poppins my-2 ">
                                    Maximum 13 character
                                </p>
                            </label>
                        </div>
                    </>
                );
            case 3:
                return (
                    <>
                        {/* Occupation */}
                        <div className="mb-6">
                            <label>
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    Occupation
                                    {/* <sup className="text-red-600">*</sup> */}
                                </p>
                                <input
                                    type="text"
                                    name="Occupation"
                                    maxLength={20}
                                    value={formData.Occupation}
                                    onChange={handleOnChange}
                                    placeholder="Enter occupation here"
                                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${errors.Occupation ? "border-red-500" : ""
                                        }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                />
                            </label>
                        </div>
                        {/* Employment */}
                        <div className="mb-6">
                            <label>
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    Employment
                                    {/* <sup className="text-red-600">*</sup> */}
                                </p>
                                <select
                                    id="Employment"
                                    name="Employment"
                                    value={formData.Employment}
                                    onChange={handleOnChange}
                                    className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${errors.Employment ? "border-red-500" : ""
                                        }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                >
                                    <option value="">Select Employment Status</option>
                                    <option value="Employed">Employed</option>
                                    <option value="Unemployed">Unemployed</option>
                                    <option value="Self-Employed">Self-Employed</option>
                                    <option value="Freelancer">Freelancer</option>
                                    <option value="Retired">Retired</option>
                                    <option value="Student">Student</option>
                                    <option value="Homemaker">Homemaker</option>
                                    {/* <option value="Intern">Intern</option>
                        <option value="Apprentice">Apprentice</option>
                        <option value="Part-time">Part-time</option>
                        <option value="Full-time">Full-time</option>
                        <option value="Contract">Contract</option>
                        <option value="Temporary">Temporary</option>
                        <option value="Seasonal">Seasonal</option>
                        <option value="On Leave">On Leave</option> */}
                                </select>
                            </label>
                        </div>
                        {/* Relationship Status */}
                        <div className="mb-6">
                            <label>
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    Relationship Status
                                    {/* <sup className="text-red-600">*</sup> */}
                                </p>
                                <select
                                    id="RelationshipStatus"
                                    name="RelationshipStatus"
                                    value={formData.RelationshipStatus}
                                    onChange={handleOnChange}
                                    className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${errors.RelationshipStatus ? "border-red-500" : ""
                                        } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                >
                                    <option value="">Select Relationship Status</option>
                                    <option value="Married">Married</option>
                                    <option value="Single">Single</option>
                                    <option value="Engaged">Engaged</option>
                                    <option value="Separated">Separated</option>
                                    <option value="Divorced">Divorced</option>
                                    <option value="Widowed">Widowed</option>
                                </select>
                            </label>
                        </div>
                        {/* Living Arrangement */}
                        <div className="mb-6">
                            <label>
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    Living Arrangement
                                    {/* <sup className="text-red-600">*</sup> */}
                                </p>
                                <select
                                    id="LivingArrangement"
                                    name="LivingArrangement"
                                    value={formData.LivingArrangement}
                                    className={`w-full text-black text-xs bg-gray-100 rounded-md p-4 border ${errors.LivingArrangement ? "border-red-500" : ""
                                        } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                    onChange={handleOnChange}
                                >
                                    <option value="">Select Living Arrangement</option>
                                    <option value="Own House">Own House</option>
                                    <option value="Rent">Rent</option>
                                    <option value="Living with Parents">
                                        Living with Parents
                                    </option>
                                    <option value="Living with Relatives">
                                        Living with Relatives
                                    </option>
                                    <option value="Living with Friends">
                                        Living with Friends
                                    </option>
                                    {/* <option value="Student Housing">Student Housing</option>
                        <option value="Military Housing">Military Housing</option>
                        <option value="Homeless">Homeless</option>
                        <option value="Temporary Shelter">Temporary Shelter</option>
                        <option value="Assisted Living">Assisted Living</option>
                        <option value="Nursing Home">Nursing Home</option>
                        <option value="Shared Accommodation">
                          Shared Accommodation
                        </option>
                        <option value="Cohabiting">Cohabiting</option>
                        <option value="Hostel">Hostel</option>
                        <option value="Boarding House">Boarding House</option>
                        <option value="Retirement Community">
                          Retirement Community
                        </option>
                        <option value="Hotel/Motel">Hotel/Motel</option> */}
                                </select>
                            </label>
                        </div>
                        {/* Details */}
                        <div className="mb-6">
                            <label>
                                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                                    Details
                                </p>
                                <textarea
                                    id="Details"
                                    name="Details"
                                    value={formData.Details}
                                    onChange={handleOnChange}
                                    placeholder="Enter details here"
                                    className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                ></textarea>
                            </label>
                        </div>
                    </>
                );
            default:
                return <></>;
        }
    };

    useEffect(() => {
        handleOpenModal()
    }, [])


    return (
        <>
            <ModalComponent
                show={isModalOpen}
                handleClose={handleCloseModal}
                outSideModalPress={false}
            >
                <div className="w-[50vw] h-[80vh] flex flex-col">
                    <div className="flex flex-col items-center justify-center mb-3">
                        <img
                            src={patient.Image}
                            alt="PatientPic"
                            className="w-16 h-w-16 rounded-full"
                        />
                        <div className="text-center text-[#344054] text-base font-semibold font-poppins mt-2">
                            {patient.Name || patient.FirstName + " " + patient.LastName}
                        </div>
                        <div className="text-center text-[#8A8A8A] text-xs font-normal font-poppins mt-[1px]">
                            No : {patient.AHUID}
                        </div>
                        <div className="text-[#344054] text-xs font-normal font-poppins mt-[1px]">
                            {new Date().getFullYear() -
                                new Date(patientProfile.DOB).getFullYear()}{" "}
                            Years Old
                        </div>
                    </div>

                    <div className="flex justify-center items-center w-full">
                        <Steps
                            size="small"
                            labelPlacement="vertical"
                            current={current}
                            items={items}
                            className="custom-steps w-2/3"
                        // onChange={(current) => setCurrent(current)}
                        />
                    </div>

                    <div className="my-5 flex justify-between h-[60vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                        {/* Previous Button */}
                        {current > 0 ? (
                            <div className="w-10 h-10">
                                <button
                                    className="fixed bg-sky-100 border border-sky-400 active:bg-sky-200 w-10 h-10 mt-32 rounded-full flex items-center justify-center"
                                    onClick={() => prev()}
                                >
                                    <MdOutlineKeyboardArrowLeft
                                        className={`w-6 h-6 ${current > 0 ? "text-sky-400" : "text-gray-400"
                                            }`}
                                    />
                                </button>
                            </div>
                        ) : (
                            <div className="w-10 h-10"></div>
                        )}

                        <div className="w-2/3">
                            {renderInputFields()}
                        </div>

                        {/* Next Button */}
                        {current < steps.length - 1 ? (
                            <button
                                className="bg-sky-100 border border-sky-400 active:bg-sky-200 w-10 h-10 mt-32 rounded-full flex items-center justify-center"
                                onClick={() => next()}
                            >
                                <MdOutlineKeyboardArrowRight
                                    className={`w-6 h-6 ${current < steps.length ? "text-sky-400" : "text-gray-400"
                                        }`}
                                />
                            </button>
                        ) : (
                            <div className="w-10 h-10"></div>
                        )}
                    </div>

                    <div className="flex justify-center items-center w-full">
                        <div className="flex flex-row justify-center items-center w-2/3 gap-2">
                            {current < steps.length - 1 && (
                                <button
                                    onClick={() => next()}
                                    className={`w-full p-3 bg-sky-100 rounded-lg justify-center items-center gap-2 inline-flex active:scale-95`}
                                >
                                    <div className={`text-sky-400 text-base font-semibold font-poppins`}>
                                        Next
                                    </div>
                                </button>
                            )}
                            <button
                                onClick={() => { setIsConfirmModalOpen(true); setIsModalOpen(false); }}
                                className={`w-full p-3 bg-sky-400 rounded-lg justify-center items-center gap-2 inline-flex active:scale-95`}
                            >
                                <div className={`text-white text-base font-semibold font-poppins`}>
                                    Update Details
                                </div>
                            </button>
                            {/* )} */}
                        </div>
                    </div>
                </div>
            </ModalComponent>

            {/* Confirm Modal */}
            <ModalComponent
                show={isConfirmModalOpen}
                handleClose={() => { setIsConfirmModalOpen(false); setIsModalOpen(true); }}
                outSideModalPress={false}
            >
                <div className="w-[40vw] max-h-[80vh] flex flex-col items-center">
                    <h1 className="text-[#344054] text-xl font-semibold font-poppins leading-normal">
                        Are you Sure?
                    </h1>
                    <h1 className="text-[#344054] text-lg mt-11 font-normal font-poppins leading-7">
                        This will change the Patient Details of:
                    </h1>
                    <h1 className="text-[#344054] text-lg font-poppins leading-7 font-semibold">{patient.Name || patient.FirstName + " " + patient.LastName}</h1>

                    <div className="flex justify-center items-center w-full mt-11">
                        <div className="flex flex-row justify-center items-center w-full gap-2">
                            <button
                                onClick={() => { setIsConfirmModalOpen(false); setIsModalOpen(true); }}
                                className={`w-full p-3 bg-[#D9D9D9] rounded-lg justify-center items-center gap-2 inline-flex active:scale-95`}
                            >
                                <div className={`text-[#344054] text-[13px] font-medium font-poppins`}>
                                    Back
                                </div>
                            </button>
                            <button
                                onClick={(e) => { handleSubmit(e) }}
                                className={`w-full p-3 bg-[#85D114] rounded-lg justify-center items-center gap-2 inline-flex active:scale-95`}
                            >
                                <div className={`text-white text-[13px] font-medium font-poppins`}>
                                    Save Changes
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </ModalComponent>
        </>
    );
}

export default EditUserDetailsModal;
