import { createSlice } from "@reduxjs/toolkit";
import { set } from "date-fns";

const initialState = {
  isSubscribed: false,
  currentPlan: null,
  perks: [],
  subscriptionPlans: [],
  loading: false,
};

const membershipSlice = createSlice({
  name: "membership",
  initialState: initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setCurrentPlan(state, action) {
      state.currentPlan = action.payload;
    },
    setIsSubscribed(state, action) {
      state.isSubscribed = action.payload;
    },
    setPerks(state, action) {
      state.perks = action.payload;
    },
    setSubscriptionPlans(state, action) {
      state.subscriptionPlans = action.payload;
    },
  },
});

export const {
  setCurrentPlan,
  setIsSubscribed,
  setPerks,
  setSubscriptionPlans,
  setLoading,
} = membershipSlice.actions;

export default membershipSlice.reducer;
