import React from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import bannerBackground from "../assets/Images/OperationsManagement/PlatformManagement/bannerBackground.png";
import subs_right_small_icon from "../assets/Images/OperationsManagement/PlatformManagement/subs_right_small_icon.svg";
import subs_right_big_icon from "../assets/Images/OperationsManagement/PlatformManagement/subs_right_big_icon.svg";
import policies_big_icon from "../assets/Images/PlatformManagement/policies_big_icon.svg";
import policies_small_icon from "../assets/Images/PlatformManagement/policies_small_icon.svg";
import crown from "../assets/Images/OperationsManagement/PlatformManagement/crown.svg";
import { FaArrowRight } from "react-icons/fa6";
import SubscriptionBanner from "../components/SubscriptionBanner";

function PlatformManagement() {
  const navigate = useNavigate();

  const renderCard = (data) => {
    return (
      <div className="bg-gradient-to-bl from-sky-100 to-sky-300 rounded-2xl flex justify-between items-center bg-blue-100 p-8 shadow-lg w-full max-w-md relative">
        {/* Left Content */}
        <div className="space-y-4 mt-9">
          <div className="text-gray-800 text-xl font-bold font-poppins">
            {data.title}
          </div>
          <ul className="list-disc ml-5">
            {data.description.map((info, index) => (
              <li
                key={index}
                className="text-gray-800 text-xs font-normal font-poppins"
              >
                {info}
              </li>
            ))}
          </ul>
          <button
            onClick={() => navigate(data.route)}
            className="flex flex-row-reverse items-center justify-between py-2 px-4 bg-white rounded-xl shadow-md hover:shadow-lg"
          >
            <FaArrowRight className="ml-2" />
            <div className="text-gray-800 text-xs font-semibold font-poppins">
              {data.btnTxt}
            </div>
          </button>
        </div>

        {/* Right Content */}
        <div>
          <div className="w-14 h-14 flex items-center justify-center bg-slate-50 rounded-full absolute top-5 right-5">
            <img src={data.icon} alt="" className="w-9 h-9 object-contain" />
          </div>
          <img
            src={data.image}
            alt=""
            className="w-36 h-28 object-contain absolute bottom-5 right-0"
          />
        </div>
      </div>
    );
  };
  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        Platform Management
      </div>
      <Breadcrumb />

      {/* Banner */}
      <SubscriptionBanner />

      <div className=" text-neutral-600 text-base font-medium font-poppins mt-4">
        Here are all the Company/Organization essentials you need to maintain
        regularly
      </div>

      <div className="grid grid-cols-3 gap-10 mt-5">
        {DATA.map((data) => (
          <div key={data.id}>{renderCard(data)}</div>
        ))}
      </div>
    </div>
  );
}

export default PlatformManagement;

const DATA = [
  {
    id: 1,
    title: "Subscription Plans",
    description: ["loreum ipsum", "loreum ipsum", "loreum ipsum"],
    btnTxt: "Explore all Plans",
    icon: subs_right_small_icon,
    image: subs_right_big_icon,
    route: "subscriptionPlans",
  },
  {
    id: 2,
    title: "Platform Policies",
    description: ["loreum ipsum", "loreum ipsum", "loreum ipsum"],
    btnTxt: "View / Edit Policies",
    icon: policies_small_icon,
    image: policies_big_icon,
    route: "platformPolicy",
  },
];
