import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaMinus, FaChevronLeft } from "react-icons/fa6";
import Vertical_switch_light from "../../assets/Images/TreatmentProgram/Vertical_switch_light.png";
import editIcon from "../../assets/Images/TreatmentProgram/editIcon.png";
import delIcon from "../../assets/Images/TreatmentProgram/delIcon.png";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTreatmentProgram,
  getAllTreatmentProgramWeekData,
} from "../../services/operations/masterDataAPI";
import Breadcrumb from "../../components/Breadcrumb";

function TreatmentProgram() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useSelector((state) => state.auth);
  const [treatmentPrograms, setTreatmentPrograms] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [lastIncrement, setLastIncrement] = useState(8);
  const [originalPrograms, setOriginalPrograms] = useState([]);
  const [isSorted, setIsSorted] = useState(false);

  const increaseItemsPerPage = () => {
    const remainingItems = (treatmentPrograms || []).length - itemsPerPage;
    const increment = Math.min(remainingItems, 8);
    setItemsPerPage((prev) => prev + increment);
    setLastIncrement(increment);
  };

  const decreaseItemsPerPage = () => {
    setItemsPerPage((prev) => (prev > 8 ? prev - lastIncrement : 8));
    setLastIncrement(8); // Reset to default decrement value
  };

  const toggleSortByName = () => {
    if (isSorted) {
      setTreatmentPrograms([...originalPrograms]);
    } else {
      const sortedPrograms = [...treatmentPrograms].sort((a, b) =>
        a.Name.localeCompare(b.Name)
      );
      setTreatmentPrograms(sortedPrograms);
    }
    setIsSorted(!isSorted);
  };

  const deleteHandler = async (event, id) => {
    event.preventDefault();
    // Call API to Delete Treatment Program
    try {
      await dispatch(deleteTreatmentProgram(token, id, navigate));
      await fetchTreatmentPrograms();
    } catch (error) {
      console.error("Error Deleting Treatment Program:", error);
    }
  };

  const fetchTreatmentPrograms = async () => {
    if (token) {
      try {
        const res = await dispatch(getAllTreatmentProgramWeekData(token));
        setTreatmentPrograms(res || []);
        setOriginalPrograms(res || []); // Keep a copy of the original data
      } catch (error) {
        console.error("Failed to fetch Treatment Programs", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchTreatmentPrograms();
  }, [token, dispatch]);

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="bg-white min-h-full shadow-lg p-6">
      {/* HEADER */}
      <div className="flex flex-row justify-between items-center">
        {/* TITLE */}
        <div className="flex flex-col justify-center items-start gap-3">
          <div className="flex flex-row items-center space-x-4">
            <div>
              <h1 className="text-black text-xl font-bold font-poppins">
                Treatment Program
              </h1>
              <p className="text-[#8A8A8A] text-xs font-regular font-poppins">
                Here are the list of Treatment Programs
              </p>
            </div>
          </div>
          <Breadcrumb />
        </div>
        <div className="flex flex-row justify-between items-center ml-auto gap-2">
          <button
            onClick={() => {
              navigate("createTreatmentProgram");
            }}
            className="flex flex-row justify-center items-center rounded-md bg-[#3CA5DC] px-4 py-2 cursor-pointer hover:scale-105 duration-500"
          >
            <FaPlus className="text-white mr-2 text-sm" />
            <h1 className="text-white text-xs font-regular font-poppins">
              Create New Treatment Program
            </h1>
          </button>
        </div>
      </div>

      {/* Content */}
      <div className="flex flex-col justify-center items-center w-full mt-5">
        {/* Table Header */}
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-row justify-start items-center gap-1 w-1/5">
            <h1 className="text-black text-md font-semibold font-poppins">
              Name of Program
            </h1>
            <button
              onClick={toggleSortByName}
              className="flex flex-col justify-center items-center w-[20px] h-[20px]"
            >
              <img
                src={Vertical_switch_light}
                className="w-full h-full object-contain"
                alt="Vertical Arrows Icon"
              />
            </button>
          </div>
          <div className="flex flex-row justify-start items-center gap-1 w-1/5">
            <h1 className="text-black text-md font-semibold font-poppins">
              Disease Type
            </h1>
            {/* <div className="flex flex-col justify-center items-center w-[20px] h-[20px]">
                                <img
                                    src={Vertical_switch_light}
                                    className="w-full h-full object-contain"
                                    alt="Vertical Arrows Icon"
                                />
                            </div> */}
          </div>
          <div className="flex flex-row justify-start items-center gap-1 w-1/5">
            <h1 className="text-black text-md font-semibold font-poppins">
              Number of Weeks
            </h1>
            {/* <div className="flex flex-col justify-center items-center w-[20px] h-[20px]">
                                <img
                                    src={Vertical_switch_light}
                                    className="w-full h-full object-contain"
                                    alt="Vertical Arrows Icon"
                                />
                            </div> */}
          </div>
          <div className="flex flex-row justify-start items-center gap-1 w-1/5">
            <h1 className="text-black text-md font-semibold font-poppins">
              Target Audience
            </h1>
            {/* <div className="flex flex-col justify-center items-center w-[20px] h-[20px]">
                                <img
                                    src={Vertical_switch_light}
                                    className="w-full h-full object-contain"
                                    alt="Vertical Arrows Icon"
                                />
                            </div> */}
          </div>
          <div className="flex flex-row justify-start items-center gap-1 w-1/5">
            <h1 className="text-black text-md font-semibold font-poppins">
              Perform Actions
            </h1>
            {/* <div className="flex flex-col justify-center items-center w-[20px] h-[20px]">
                                <img
                                    src={Vertical_switch_light}
                                    className="w-full h-full object-contain"
                                    alt="Vertical Arrows Icon"
                                />
                            </div> */}
          </div>
        </div>

        <div className="w-[102%] bg-[#F3F5F6] h-[1.5px] mt-5 my-3"></div>

        {/* Table Content */}
        {treatmentPrograms &&
          treatmentPrograms.slice(0, itemsPerPage).map((prog) => (
            <React.Fragment key={prog.TPID}>
              <div className="flex flex-row justify-start items-center w-full">
                <h1 className="text-black text-sm font-regular font-poppins w-1/5">
                  {prog.Name}
                </h1>
                <h1 className="text-black text-sm font-regular font-poppins w-1/5">
                  {prog.DiagnosisType}
                </h1>
                <div className="flex flex-row justify-start items-center w-1/5">
                  <div className="flex flex-row justify-center items-center bg-[#E5F2FF] rounded-2xl px-4 py-1">
                    <h1 className="text-[#3CA5DC] text-sm font-regular font-poppins">
                      {prog.WeekNumber} Weeks
                    </h1>
                  </div>
                </div>
                <h1 className="text-black text-sm font-regular font-poppins w-1/5">
                  {prog.TargetAudience}
                </h1>
                <div className="flex flex-row justify-start items-center w-1/5 space-x-2">
                  <button
                    onClick={() => {
                      navigate("createTreatmentProgram", {
                        state: { prog },
                      });
                    }}
                    className="flex flex-row justify-center items-center space-x-1"
                  >
                    <img
                      src={editIcon}
                      className="w-4 h-4 object-contain"
                      alt="Edit Icon"
                    />
                    <h1 className="text-sky-400 text-xs font-regular font-poppins">
                      Edit Program
                    </h1>
                  </button>
                  <div className="border border-r-0.5 border-gray-100 h-10"></div>
                  <button
                    onClick={(e) => {
                      deleteHandler(e, prog.TPID);
                    }}
                    className="flex flex-row justify-center items-center space-x-1"
                  >
                    <img
                      src={delIcon}
                      className="w-4 h-4 object-contain"
                      alt="Delete Icon"
                    />
                    <h1 className="text-[#DF535F] text-xs font-regular font-poppins">
                      Delete Program
                    </h1>
                  </button>
                </div>
              </div>

              <div className="w-[102%] bg-[#F3F5F6] h-[1.5px] my-3"></div>
            </React.Fragment>
          ))}

        <div className="flex flex-row justify-start items-center w-[102%]">
          <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins w-1/4">
            Showing {itemsPerPage} items per page
          </h1>
          <div className="flex flex-row justify-center items-center ml-auto gap-1">
            <button
              onClick={increaseItemsPerPage}
              disabled={itemsPerPage >= (treatmentPrograms || []).length}
              className={`flex flex-row justify-center items-center rounded-md bg-[#E5F2FF] p-2 ${itemsPerPage >= (treatmentPrograms || []).length
                  ? "opacity-50 cursor-not-allowed"
                  : "cursor-pointer hover:scale-110 duration-500"
                }`}
            >
              <FaPlus className={`text-[#3CA5DC] text-sm`} />
            </button>
            <div className="flex flex-row justify-center items-center rounded-md bg-[#F3F5F6] p-2 px-4">
              <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins">
                1-{itemsPerPage}
              </h1>
            </div>
            <button
              onClick={decreaseItemsPerPage}
              className={`flex flex-row justify-center items-center rounded-md bg-[#E5F2FF] p-2 ${itemsPerPage <= 8
                  ? "opacity-50 cursor-not-allowed"
                  : "cursor-pointer hover:scale-110 duration-500"
                }`}
            >
              <FaMinus className="text-[#3CA5DC] text-sm" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TreatmentProgram;
