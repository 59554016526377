import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { patientManagementEndpoints } from "../apis";
import {
  setLoading,
  setAllPatients,
  setPatientProfile,
  setMentalHealthDetails,
  setConsultationHistory,
  setFamilyMembers,
  setMedicalCondition,
  setDosageAdherence,
  setAllMedicines,
  setDiagnosisType,
  setHealthProgram,
  setAllergies,
  setHealthReports,
  setHealthJourney,
  setCurrentConciergePlan,
  setMedicalCommittee,
  setArchivedPatients,
  updateLifestyle,
} from "../../slices/patientSlice";
import { getBucketURL } from "../../utils/regex";
import { getAllConciergeUser, getHealthManagers } from "./medicalConciergeAPI";

const {
  GET_ALL_PATIENTS_API,
  GET_ARCHIVED_PATIENTS_API,
  ADD_NEW_PATIENT_API,
  GET_PATIENT_PROFILE,
  UPDATE_PATIENT_PROFILE,
  GET_MENTAL_HEALTH_ASSESSMENT_SCORE,
  GET_MENTAL_HEALTH_QUESTION_ANSWERS,
  GET_FAMILY_MEMBER,
  ADD_FAMILY_MEMBER,
  GET_CONSULTATION_HISTORY,
  GET_PRE_MEDICAL_CONDITIONS,
  GET_HEALTH_REPORTS,
  UPLOAD_HEALTH_REPORT,
  SAVE_BIOMARKERS,
  DECRYPT_REPORT,
  GET_HEALTH_JOURNEY,
  GET_PRESCRIPTION,
  GET_ALL_MEDICINES,
  GET_ALLERGIES_PER_PATIENT,
  GET_DIAGNOSIS_TYPE,
  ADD_NOTES,
  ADD_AUDIO_EXTRACTED_DATA,
  ADD_PRESCRIPTION,
  UPATE_PRESCRIPTION,
  DELETE_PRESCRIPTION,
  ASSIGN_TREATMENT_PROGRAM,
  GET_ASSIGN_TREATMENT_PROGRAM,
  GET_ALL_ASSIGN_TREATMENT_PROGRAMS,
  DELETE_ASSIGNED_HEALTH_TREATMENT_PROGRAM,
  EDIT_ASSIGN_TREATMENT_PROGRAMS,
  GET_PATIENTS_UPCOMING_TELEHEALTH_SESSIONS,
  DELETE_APPOINTMENT,
  GET_CURRENT_CONCIERGE_PLAN,
  CHANGE_CONCIERGE_PLAN,
  CANCEL_CONCIERGE_PLAN,
  GET_MEDICAL_COMMITTEE_MEMBERS,
  DELETE_MEDICAL_COMMITTEE_MEMBER,
  ADD_MEDICAL_COMMITTEE_MEMBER,
  UPATE_MEDICAL_COMMITTEE_MEMBER,
  GET_USER_ACCESS_CONTROL,
  ADD_PRE_MEDICAL_CONDITIONS,
  ADD_ALLERGY,
  GET_LIFESTYLE_DATA,
  UPDATE_LIFESTYLE_DATA,
  VIEW_BIOMARKERS,
} = patientManagementEndpoints;

// GET ALL PATIENTS
export function getAllPatients(token) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_PATIENTS_API, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      const patient = response?.data?.Patients.map(async (patient) => {
        try {
          const Image = await getBucketURL(patient?.ProfileImageUrl);
          patient.Image = Image;
        } catch (error) {
          console.error(
            `Error fetching image for patient ${patient?.ID}:`,
            error
          );
          // Leave the image as it is if there's an error
        }
        return patient;
      });
      const updatedPatientList = await Promise.all(patient);
      dispatch(setAllPatients(updatedPatientList));
    } catch (error) {
      console.log("GET_PATIENT_API ERROR............", error);
      // toast.error("Could Not Get Patient Data");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// GET ALL ARCHIVED PATIENTS
export function getArchivedPatients(token) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_ARCHIVED_PATIENTS_API,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      // console.log("GET_PATIENT_API RESPONSE............", response);

      const patient = response?.data?.DeletedPatientList.map(
        async (patient) => {
          const Image = await getBucketURL(patient?.ProfileImageUrl);
          patient.Image = Image;
          return patient;
        }
      );

      const updatedPatientList = await Promise.all(patient);
      dispatch(setArchivedPatients(updatedPatientList));
    } catch (error) {
      console.log("GET_ARCHIVED_PATIENT_API ERROR............", error);
      // toast.error("Could Not Get Patient Data");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// ADD NEW PATIENT
export function addNewPatient(token, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_NEW_PATIENT_API, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      // console.log("ADD_PATIENT_API RESPONSE............", response);
      toast.success("Patient Added Successfully");
      dispatch(getAllPatients(token));
      navigate("/patientDashboard");
    } catch (error) {
      console.log("ADD_PATIENT_API ERROR............", error);
      toast.error(error?.response?.data?.message || "Could Not Add Patient");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// GET PATIENT PROFILE
export function getPatientProfile(token, body) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", GET_PATIENT_PROFILE, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("GET_PATIENT_PROFILE_API RESPONSE............", response);
      dispatch(setPatientProfile(response?.data?.Patient));
    } catch (error) {
      console.log("GET_PATIENT_PROFILE_API ERROR............", error);
      toast.error("Could Not Get Patient Profile");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// PUT UPDATE PATIENT PROFILE
export function updatePatientProfile(
  token,
  ID,
  body,
  navigate,
  patient,
  navigateTo
) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_PATIENT_PROFILE + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("UPDATE_PATIENT_PROFILE_API RESPONSE............", response);
      dispatch(getAllPatients(token));
      dispatch(getArchivedPatients(token));
      if (navigateTo) navigate(navigateTo, { state: patient });
      else navigate("/patientDashboard/patientProfile", { state: patient });
    } catch (error) {
      console.log("UPDATE_PATIENT_PROFILE_API ERROR............", error);
      toast.error("Could Not Update Patient Profile");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// ------------------MENTAL HEALTH-------------------

// GET MENTAL HEALTH ASSESMENT SCORE
export function getMentalHealthAssessmentScore(token, body) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        GET_MENTAL_HEALTH_ASSESSMENT_SCORE,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      const combinedData = await Promise.all(
        response?.data?.PreviousAssessMentData.map(async (item) => {
          const questionAnswer = await getMentalHealthQuestionAnswer(token, {
            AHUID: body.AHUID,
            ScoreID: item.ID,
          });
          return { ...item, ...questionAnswer };
        })
      );

      dispatch(setMentalHealthDetails(combinedData));
    } catch (error) {
      console.log("GET MENTAL HEALTH ASSESMENT SCORE ERROR............", error);
      toast.error("Could Not Get Mental Health Score");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

// GET MENTAL HEALTH QUESTION ANSWER
export const getMentalHealthQuestionAnswer = async (token, body) => {
  // const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector(
      "POST",
      GET_MENTAL_HEALTH_QUESTION_ANSWERS,
      body,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    result = response?.data;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("GET MENTAL HEALTH QUESTION ANSWER API ERROR:", error);
    toast.error("Oops! Something went wrong. Please try again.");
  }
  // toast.dismiss(toastId);
  return result;
};

// ------------------FAMILY MEMBER-------------------

// GET FAMILY MEMBER
export function getFamilyMembers(token, body) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", GET_FAMILY_MEMBER, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      const members = response?.data?.FamilyMembers.filter(
        (member) => member.AHUID !== body.AHUID
      );
      const updatedMembers = await Promise.all(
        members.map(async (member) => {
          const Image = await getBucketURL(member?.ProfileImageUrl);
          return { ...member, Image };
        })
      );

      dispatch(setFamilyMembers(updatedMembers));
    } catch (error) {
      console.log("GET FAMILY MEMBER ERROR............", error);
      toast.error("Could Not Get Consultation History");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

// ADD FAMILY MEMBER
export function addFamilyMember(token, body, navigate, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_FAMILY_MEMBER, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Family Member Added Successfully");
      dispatch(getAllPatients(token));
      dispatch(getFamilyMembers(token, { AHUID: patient.AHUID }));
    } catch (error) {
      console.log("ADD FAMILY MEMBER ERROR............", error);
      toast.error("Could Not Add Family Member");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// GET ALL DIAGNOSIS TYPE
export function getDiagnosisType(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_DIAGNOSIS_TYPE, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setDiagnosisType(response?.data?.DiagnosisList));
    } catch (error) {
      console.log("GET_DIAGNOSIS_TYPE ERROR............", error);
      toast.error("Could Not Get Disease Type");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

// GET ALL MEDICINES
export function getAllMedicines(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_MEDICINES, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setAllMedicines(response?.data?.Medicines));
    } catch (error) {
      console.log("GET_ALL_MEDICINES ERROR............", error);
      toast.error("Could Not Get Medicines");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

// ------------------CONSULTATION-------------------

//GET CONSULTATION HISTORY
export function getConsultationHistory(token, body) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        GET_CONSULTATION_HISTORY,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("GET CONSULTATION HISTORY RESPONSE............", response);
      const history = response?.data?.consultationData.map(async (item) => {
        const Image = await getBucketURL(item?.DoctorProfile);
        item.DocImage = Image;
        return item;
      });

      const updatedHistory = await Promise.all(history);
      dispatch(setConsultationHistory(updatedHistory));
    } catch (error) {
      console.log("GET CONSULTATION HISTORY ERROR............", error);
      toast.error("Could Not Get Consultation History");
    } finally {
      // dispatch(setLoading(false));
    }
  };
}

// ADD CONSULTATION NOTES
export function addNotesToPatientProfile(token, ID, body, navigate, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("PUT", ADD_NOTES + "/" + ID, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(
        getConsultationHistory(token, { AHUID: patient.AHUID, Key: "All" })
      );
    } catch (error) {
      console.log("ADD_NOTES ERROR............", error);
      toast.error("Could Not Add Notes");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// GET PRE MEDICAL CONDITIONS PER PATIENT
export function getPreMedicalConditons(token, ID) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_PRE_MEDICAL_CONDITIONS + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setMedicalCondition(response?.data?.preMedicalConditionDetails));
    } catch (error) {
      console.log("GET PRE MEDICAL CONDITIONS ERROR............", error);
      toast.error("Could Not Get Pre Medical Conditions");
    } finally {
      // dispatch(setLoading(false));
    }
  };
}

//GET ALLERGIES PER PATIENT
export function getAllergiesPerPatient(token, ID) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_ALLERGIES_PER_PATIENT + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setAllergies(response?.data?.allergyDetails));
    } catch (error) {
      console.log("GET ALLERGIES PER PATIENT ERROR............", error);
      // toast.error("Could Not Get Allergies Per Patient");
    } finally {
      // dispatch(setLoading(false));
    }
  };
}

// ADD PRE MEDICAL CONDITION
export function addPreMedicalCondition(token, body, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        ADD_PRE_MEDICAL_CONDITIONS,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // toast.success("Added Successfully");
      dispatch(getPreMedicalConditons(token, patient?.AHUID));
    } catch (error) {
      console.log("ADD_PRE_MEDICAL_CONDITIONS API ERROR :", error);
      toast.error(error?.response?.data?.message || "Error While Adding ");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// ADD ALLERGY
export function addAllergy(token, body, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_ALLERGY, body, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // toast.success("Added Successfully");
      dispatch(getAllergiesPerPatient(token, patient?.AHUID));
    } catch (error) {
      console.log("ADD_ALLERGY API ERROR :", error);
      toast.error(error?.response?.data?.message || "Error While Adding ");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// ------------------PRESCRIPTION-------------------

// GET PRESCRIPTION
export function getDosageAdherence(token, body) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", GET_PRESCRIPTION, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setDosageAdherence(response?.data?.PreviousRecords));
    } catch (error) {
      console.log("GET_PRESCRIPTION ERROR............", error);
      toast.error("Could Not Get Dosage Adherence History");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

// ADD PRESCRIPTION
export function addPrescription(token, body, navigate, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_PRESCRIPTION, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Data Added Successfully");
      // navigate("/patientDashboard/patientProfile", { state: patient });
      dispatch(getDosageAdherence(token, { AHUID: patient.AHUID }));
    } catch (error) {
      console.log("ADD_PRESCRIPTION ERROR............", error);
      toast.error("Could Not Add Prescription");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// UPDATE PRESCRIPTION
export function updatePrescription(token, ID, body, navigate, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPATE_PRESCRIPTION + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Data Updated Successfully");
      // navigate("/patientDashboard/patientProfile", { state: patient });
      dispatch(getDosageAdherence(token, { AHUID: patient.AHUID }));
    } catch (error) {
      console.log("UPATE_PRESCRIPTION ERROR............", error);
      toast.error("Could Not Update Prescription");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// DELETE PRESCRIPTION
export function deletePrescription(token, ID, navigate, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_PRESCRIPTION + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Deleted Successfully");
      // navigate("/patientDashboard/patientProfile", { state: patient });
      dispatch(getDosageAdherence(token, { AHUID: patient.AHUID }));
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data?.msg ||
          "Error While Deleting Prescription"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// ------------------TREATMENT PROGRAM-------------------

// ASSIGN TREATMENT PROGRAM
export function assignTreatmentProgram(token, body, navigate, patient) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        ASSIGN_TREATMENT_PROGRAM,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("ASSIGN_TREATMENT_PROGRAM RESPONSE............", response);
      toast.success("Treatment Program Added Successfully");
      navigate("/patientDashboard/patientProfile", { state: patient });
    } catch (error) {
      console.log("ASSIGN_TREATMENT_PROGRAM ERROR............", error);
      toast.error("Need Consultation to be Done");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// GET ASSIGNED TREATMENT PROGRAM
export function getAllAssignedTreatmentProgram(token, id) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    let result;
    try {
      const url = `${GET_ALL_ASSIGN_TREATMENT_PROGRAMS}/${id}`;
      const response = await apiConnector("GET", url, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(
        setHealthProgram(response?.data?.AssignedTreatmentPrograms || [])
      );
      console.log(
        "GET_ALL_ASSIGN_TREATMENT_PROGRAM RESPONSE............",
        response
      );
    } catch (error) {
      console.log("GET_ALL_ASSIGN_TREATMENT_PROGRAM ERROR............", error);
      // toast.error("Could Not Fetch Assigned Treatment Program");
      dispatch(setHealthProgram([]));
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// GET ASSIGNED TREATMENT PROGRAM
export function getAssignedTreatmentProgram(token, sessionId, tpid) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    try {
      const params = new URLSearchParams({
        SessionID: sessionId,
        TPID: tpid,
      });

      const url = `${GET_ASSIGN_TREATMENT_PROGRAM}?${params.toString()}`;

      const response = await apiConnector("GET", url, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setHealthProgram(response?.data || []));
      console.log(
        "GET_ASSIGN_TREATMENT_PROGRAM RESPONSE............",
        response
      );
    } catch (error) {
      console.log("GET_ASSIGN_TREATMENT_PROGRAM ERROR............", error);
      // toast.error("Could Not Fetch Assigned Treatment Program");
      dispatch(setHealthProgram([]));
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// EDIT ASSIGNED HEALTH TREATMENT PROGRAM
export function editAssignedTreatmentProgram(
  token,
  ID,
  body,
  navigate,
  patient
) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        EDIT_ASSIGN_TREATMENT_PROGRAMS + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log(
        "EDIT ASSIGNED HEALTH TREATMENT PROGRAM RESPONSE............",
        response
      );
      toast.success("Assigned Health Program Updated");
      navigate("/patientDashboard/patientProfile", { state: patient });
    } catch (error) {
      console.log(
        "EDIT ASSIGNED HEALTH TREATMENT PROGRAM ERROR............",
        error
      );
      toast.error("Could Not Update the Assigned Health Program");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// DELETE ASSIGNED HEALTH TREATMENT PROGRAM
export function deleteAssignedTreatmentProgram(token, ID, navigate, patient) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_ASSIGNED_HEALTH_TREATMENT_PROGRAM + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log(
        "DELETE ASSIGNED HEALTH TREATMENT PROGRAM API RESPONSE",
        response
      );
      toast.success(
        response?.data?.message || "Assigned Treatment Program Deleted"
      );
      navigate("/patientDashboard/patientProfile", { state: patient });
    } catch (error) {
      console.log(
        "DELETE ASSIGNED HEALTH TREATMENT PROGRAM API ERROR :",
        error
      );
      toast.error(
        error?.response?.data?.message ||
          "ERROR WHILE DELETING ASSIGNED HEALTH TREATMENT PROGRAM"
      );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// ------------------AUDIO DICTATAION-------------------

//ADD AUDIO EXTRACTED INFO
export function addAudioExtractedInfo(token, ID, body, navigate, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        ADD_AUDIO_EXTRACTED_DATA + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Saved Successfully");
      navigate("/patientDashboard/patientProfile", { state: patient });
    } catch (error) {
      console.log("ADD_AUDIO_EXTRACTED_DATA ERROR............", error);
      toast.error("Could Not Add Extracted Information");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// ------------------REPORT MANAGEMENT-------------------

// GET HEALTH REPORTS
export function getHealthReports(token, ID, filterDays = "all") {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const url = `${GET_HEALTH_REPORTS}/${ID}?filterDays=${filterDays}`;
      const response = await apiConnector("GET", url, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setHealthReports(response?.data?.healthEpisodes));
    } catch (error) {
      console.log("GET_HEALTH_REPORTS ERROR............", error);
      toast.error("Could Not Get Health Reports");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

//UPLOAD HEALTH REPORT
// export function uploadHealthReport(token, body, type, navigate, patient) {
//   return async (dispatch) => {
//     const toastId = toast.loading("Loading...");
//     // dispatch(setLoading(true));
//     try {
//       const response = await apiConnector("POST", UPLOAD_HEALTH_REPORT, body, {
//         Authorization: `Bearer ${token}`,
//       });

//       if (!response?.status === 200) {
//         throw new Error(response?.data?.message);
//       }
//       dispatch(
//         saveKeyBioMarkers(token, response?.data, type, navigate, patient)
//       );
//     } catch (error) {
//       console.log("UPLOAD_HEALTH_REPORT ERROR............", error);
//       toast.error("Could Not Upload Report");
//     }
//     // dispatch(setLoading(false));
//     toast.dismiss(toastId);
//   };
// }

export async function uploadHealthReport(token, body) {
  const toastId = toast.loading("Uploading Report...");
  let result = [];
  try {
    const response = await apiConnector("POST", UPLOAD_HEALTH_REPORT, body, {
      Authorization: `Bearer ${token}`,
    });
    result = response?.data;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("UPLOAD_HEALTH_REPORT API ERROR:", error);
    toast.error("Oops! Something went wrong. Please try again.");
  }
  toast.dismiss(toastId);
  return result;
}

export async function saveKeyBioMarkers(token, body) {
  const toastId = toast.loading("Extracting Biomarkers...");
  let result = [];
  try {
    const response = await apiConnector("POST", SAVE_BIOMARKERS, body, {
      Authorization: `Bearer ${token}`,
    });
    result = response?.data;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("SAVE_BIOMARKERS API ERROR:", error);
    toast.error("Oops! Something went wrong. Please try again.");
  }
  toast.dismiss(toastId);
  return result;
}

// SAVE KEY BIO MARKERS
// export function saveKeyBioMarkers(token, body, type, navigate, patient) {
//   return async (dispatch) => {
//     const toastId = toast.loading("Loading...");
//     // dispatch(setLoading(true));
//     try {
//       const data = {
//         AHUID: patient.AHUID,
//         diagnosisReportsID: [body?.diagnosisReports[0]?.ID],
//         healthEpisodeID: body?.healthEpisode?.ID,
//         ConsentGiven: true,
//         PathReportType: type,
//       };
//       const response = await apiConnector("POST", SAVE_BIOMARKERS, data, {
//         Authorization: `Bearer ${token}`,
//       });

//       if (!response?.status === 200) {
//         throw new Error(response?.data?.message);
//       }
//       console.log("UPLOAD_HEALTH_REPORT RESPONSE............", response);
//       toast.success("Report Uploaded Successfully");
//       // navigate("/patientDashboard/patientProfile", { state: patient });
//       dispatch(getHealthReports(token, patient.AHUID));
//     } catch (error) {
//       console.log("UPLOAD_HEALTH_REPORT ERROR............", error);
//       toast.error("Could Not Upload Report");
//     }
//     // dispatch(setLoading(false));
//     toast.dismiss(toastId);
//   };
// }

// GET DECRYPTED PDF
export const getDecryptedReport = async (token, data) => {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector("POST", DECRYPT_REPORT, data, {
      Authorization: `Bearer ${token}`,
    });
    result = response?.data;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("GET DECRYPTED API ERROR:", error);
    toast.error("Oops! Something went wrong. Please try again.");
  }
  toast.dismiss(toastId);
  return result;
};

// GET HEALTH JOURNEY
export const getHealthJourney = async (token, ID, year) => {
  // const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const url = `${GET_HEALTH_JOURNEY}/${ID}?year=${year}`;
    const response = await apiConnector("GET", url, null, {
      Authorization: `Bearer ${token}`,
    });
    result = response?.data?.healthEpisodes;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log("GET_HEALTH_JOURNEY ERROR............", error);
    toast.error("Could Not Get Health Journey");
  }
  // toast.dismiss(toastId);
  return result;
};

// GET BIOMARKERS
export const getBioMarkers = async (token, path) => {
  // const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      VIEW_BIOMARKERS,
      null,
      {
        Authorization: `Bearer ${token}`,
      },
      { PhysicalReport: path }
    );
    result = response?.data;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.log("GET_BIOMARKERS ERROR............", error);
    toast.error("Could Not Get Biomarkers");
  }
  // toast.dismiss(toastId);
  return result;
};

// GET PATIENTS UPCOMING TELEHEALTH SESSIONS
export function getPatientsUpcomingTelehealthSession(token, id) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    let result = [];
    try {
      const url = `${GET_PATIENTS_UPCOMING_TELEHEALTH_SESSIONS}/${id}`;

      const response = await apiConnector("GET", url, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      if (response?.data?.Sessions) result = response?.data?.Sessions;
    } catch (error) {
      console.log(
        "GET_PATIENTS_UPCOMING_TELEHEALTH_SESSIONS ERROR............",
        error
      );
      // toast.error("Could Not Fetch Assigned Treatment Program");
      // dispatch(setHealthProgram([]));
    }
    dispatch(setLoading(false));
    return result;
    // toast.dismiss(toastId);
  };
}

// DELETE APPOINTMENT
export function deletePatientAppointment(token, body) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", DELETE_APPOINTMENT, body, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
    } catch (error) {
      console.log("DELETE_APPOINTMENT API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "ERROR WHILE DELETING APPOINTMENT"
      );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// ------------------MEDICAL CONCIERGE-------------------

// GET CURRENT CONCIERGE PLAN
export function getCurrentConciergePlan(token, ID) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_CURRENT_CONCIERGE_PLAN + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(
        setCurrentConciergePlan(response?.data?.MedicalConciergePlan) || null
      );
    } catch (error) {
      dispatch(setCurrentConciergePlan(null));
      console.log("GET PRE MEDICAL CONDITIONS ERROR............", error);
      // toast.error("Could Not Get Pre Medical Conditions");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

// CHANGE CURRENT CONCIERGE PLAN
export function changeConciergePlan(token, body, navigate, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", CHANGE_CONCIERGE_PLAN, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(getAllPatients(token));
      // navigate("/patientDashboard/patientProfile", { state: patient });
      dispatch(getCurrentConciergePlan(token, patient.AHUID));
    } catch (error) {
      console.log("CHANGE_CONCIERGE_PLAN ERROR............", error);
      toast.error("Could Not Change Current Plan");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// CANCEL CURRENT PLAN
export function cancelCurrentPlan(token, ID, navigate, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        CANCEL_CONCIERGE_PLAN + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(getCurrentConciergePlan(token, patient.AHUID));
      dispatch(getAllPatients(token));
      dispatch(getHealthManagers(token));
      dispatch(getAllConciergeUser(token));

      navigate("/patientDashboard/patientProfile", {
        state: { ...patient, changeTab: true, IsMedicalConciergeMember: 0 },
      });
    } catch (error) {
      console.log("CANCEL_CONCIERGE_PLAN ERROR............", error);
      toast.error("Could Not Cancel Current Plan");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// ------------------MEDICAL COMMITTEE-------------------

// GET ALL MEDICAL COMMITTEE MEMBER
export function getAllMedicalCommitteeMember(token, ID) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_MEDICAL_COMMITTEE_MEMBERS + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      const membersWithImages = await Promise.all(
        response?.data?.MedicalCommitteeMembers.map(async (mem) => {
          let Image = null;
          if (mem?.ProfileImageUrl) {
            Image = await getBucketURL(mem?.ProfileImageUrl);
          }
          return { ...mem, Image }; // Correctly spread the doctor object and add the Image property
        })
      );
      dispatch(setMedicalCommittee(membersWithImages));
    } catch (error) {
      console.log("GET_MEDICAL_COMMITTEE_MEMBERS API ERROR............", error);
      // toast.error(error?.response?.data?.message || "Unable to fetch doctors");
    }
    dispatch(setLoading(false));
  };
}

// DELETE MEDICAL COMMITTEE MEMBER
export function deleteMedicalCommitteeMember(token, ID, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_MEDICAL_COMMITTEE_MEMBER + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Member Removed Successfully");
      dispatch(getAllMedicalCommitteeMember(token, patient?.AHUID));
    } catch (error) {
      console.log("DELETE_MEDICAL_COMMITTEE_MEMBER ERROR :", error);
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data?.msg ||
          "Error While Removing Member"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// ADD MEMBER TO MEDICAL COMMITTEE
export function addMedicalCommitteeMember(token, body, patient) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        ADD_MEDICAL_COMMITTEE_MEMBER,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Member Added Successfully");
      dispatch(getAllMedicalCommitteeMember(token, patient?.AHUID));
    } catch (error) {
      console.log("ADD_MEDICAL_COMMITTEE_MEMBER API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Adding Member"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// UPDATE MEMBER TO MEDICAL COMMITTEE
export function updateMedicalCommitteeMember(token, ID, body, patient) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPATE_MEDICAL_COMMITTEE_MEMBER + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // toast.success("Updated Successfully");
      dispatch(getAllMedicalCommitteeMember(token, patient?.AHUID));
    } catch (error) {
      console.log("UPATE_MEDICAL_COMMITTEE_MEMBER API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Adding Member"
      );
    }
    // dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// CHECK USER ACCESS CONTROL
export function getUserAccessControl(token, ID) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result;
    try {
      const response = await apiConnector(
        "GET",
        GET_USER_ACCESS_CONTROL + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("====================================");
      console.log("GET_USER_ACCESS_CONTROL RESPONSE............", response);
      console.log("====================================");
      result = response?.data;
    } catch (error) {
      console.log(error);
      // toast.error(
      //   error?.response?.data?.message ||
      //     "Error while fetching user access control"
      // );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
    return result;
  };
}

// ------------------ LIFESTYLE -------------------

// GET LIFESTYLE DATA
export function getLifestyleData(token, ID) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_LIFESTYLE_DATA + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(updateLifestyle(response?.data?.data));
    } catch (error) {
      console.log("GET_LIFESTYLE_DATA ERROR............", error);
      toast.error("Could Not Get Lifestyle Data");
    } finally {
      // dispatch(setLoading(false));
    }
  };
}

// UPDATE LIFESTYLE DATA
export function updateLifestyleData(token, body) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", UPDATE_LIFESTYLE_DATA, body, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(getLifestyleData(token, body?.AHUID));
    } catch (error) {
      console.log("UPDATE_LIFESTYLE_DATA API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Updating Lifestyle"
      );
    }
    // dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}
