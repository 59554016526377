import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboard: [],
  loading: false,
  showMeetingView: false,
};

const patientSlice = createSlice({
  name: "dashboard",
  initialState: initialState,
  reducers: {
    setDashboard(state, action) {
      state.dashboard = action.payload;
      // localStorage.setItem("dashboard", JSON.stringify(action.payload));
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setShowMeetingView(state, action) {
      state.showMeetingView = action.payload;
    },
  },
});

export const { setDashboard, setLoading, setShowMeetingView  } = patientSlice.actions;

export default patientSlice.reducer;
