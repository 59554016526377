import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "../slices/authSlice";
import profileReducer from "../slices/profileSlice";
import productImagesReducer from "../slices/ProductImageSlice";
import patientReducer from "../slices/patientSlice";
import dashboardReducer from "../slices/dashboardSlice";
import masterDataReducer from "../slices/masterDataSlice";
import breadcrumbReducer from "../slices/breadcrumbSlice";
import sidebarReducer from "../slices/sidebarSlice";
import medicalConciergeReducer from "../slices/medicalConciergeSlice";
import ourServicesReducer from "../slices/ourServicesSlice";
import rewardReducer from "../slices/rewardSlice";
import formReducer from "../slices/formSlice";
import platformManagementReducer from "../slices/platformManagementSlice";
import membershipReducer from "../slices/membershipSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  patient: patientReducer,
  dashboard: dashboardReducer,
  productImages: productImagesReducer,
  masterData: masterDataReducer,
  breadcrumbs: breadcrumbReducer,
  sidebar: sidebarReducer,
  medicalConcierge: medicalConciergeReducer,
  ourServices: ourServicesReducer,
  reward: rewardReducer,
  form: formReducer,
  platformManagement: platformManagementReducer,
  membership: membershipReducer,
});

export default rootReducer;