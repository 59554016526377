import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaMinus, FaChevronLeft } from "react-icons/fa6";
import Vertical_switch_light from "../../assets/Images/TreatmentProgram/Vertical_switch_light.png";
import previewIcon from "../../assets/Images/Invoicing/previewIcon.png";
import generateInvoiceIcon from "../../assets/Images/Invoicing/generateInvoiceIcon.png";
import reminderIcon from "../../assets/Images/Invoicing/reminderIcon.png";
import showInvoice from "../../assets/Images/Invoicing/showInvoice.png";
import createInvoice from "../../assets/Images/Invoicing/createInvoice.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoices,
  getSpecificInvoice,
  invoice,
} from "../../services/operations/masterDataAPI";
import moment from "moment";
import ModalComponent from "../../components/Modal";
import AHlogo from "../../assets/Images/logo.png";
import Breadcrumb from "../../components/Breadcrumb";
import qrCodeImg from "../../assets/Images/Invoicing/qrCodeImg.png";
import { IoIosSearch } from "react-icons/io";
import Tooltip from "../../components/Tooltip";
import Pagination from "../../components/Pagination";
import { getBucketURL } from "../../utils/regex";

function Invoicing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useSelector((state) => state.profile);
  const { settings } = useSelector((state) => state.masterData);
  const { logo } = useSelector((state) => state.profile);
  const [invoices, setInvoices] = useState([]);
  const [draft, setDraft] = useState(null);
  const [orgData, setOrgData] = useState([]);
  const [lastIncrement, setLastIncrement] = useState(7);
  const [showModal, setShowModal] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [specificInvoice, setSpecificInvoice] = useState([]);
  const [draftInvoice, setDraftInvoice] = useState([]);
  const [address, setAddress] = useState(null);
  const [subtotal, setSubtotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('ALL');
  const [filteredInvoices, setFilteredInvoices] = useState(invoices);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = () => {
    let results = invoices;

    // Filter by search query (checking in patient name or service code)
    if (searchQuery) {
      results = results.filter((invoice) =>
        invoice?.Name?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        invoice?.ID.toString().includes(searchQuery)
      );
    }

    // Filter by selected filter (e.g., service status)
    if (selectedFilter && selectedFilter !== 'ALL') {
      results = results.filter((invoice) =>
        invoice?.Subscription_Status === selectedFilter
      );
    }

    setFilteredInvoices(results);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleFilterChange = (e) => {
    const filterValue = e.target.innerText.toUpperCase();
    setSelectedFilter(filterValue);
  };

  // const increaseItemsPerPage = () => {
  //   const remainingItems = invoices?.length - itemsPerPage;
  //   const increment = Math.min(remainingItems, 7);
  //   setItemsPerPage((prev) => prev + increment);
  //   setLastIncrement(increment);
  // };

  // const decreaseItemsPerPage = () => {
  //   setItemsPerPage((prev) => (prev > 7 ? prev - lastIncrement : 7));
  //   setLastIncrement(7); // Reset to default decrement value
  // };

  // Function to aggregate services
  const aggregateServices = (services) => {
    const aggregated = {};

    services.forEach((service) => {
      const key = `${service.ServiceCode}-${service.ServiceName}`;

      if (!aggregated[key]) {
        aggregated[key] = {
          ...service,
          Quantity: parseInt(service.Quantity, 10),
          ServiceTime: parseInt(service.ServiceTime, 10),
          Rate: parseFloat(service.Rate).toFixed(2),
          Tax: parseFloat(service.Tax).toFixed(2),
          Cost: parseFloat(service.Cost).toFixed(2),
        };
      } else {
        if (service.Quantity >= 1) {
          aggregated[key].Quantity += parseInt(service.Quantity, 10);
        } else {
          aggregated[key].ServiceTime += parseInt(service.ServiceTime, 10);
        }
        aggregated[key].Rate = (parseFloat(aggregated[key].Rate) + parseFloat(service.Rate)).toFixed(2);
        aggregated[key].Tax = (parseFloat(aggregated[key].Tax) + parseFloat(service.Tax)).toFixed(2);
        aggregated[key].Cost = (parseFloat(aggregated[key].Cost) + parseFloat(service.Cost)).toFixed(2);
      }
    });

    return Object.values(aggregated);
  };

  useEffect(() => {
    handleSearch();
    setCurrentPage(1);
  }, [searchQuery, selectedFilter, invoices]);

  useEffect(() => {
    const fetchInvoices = async () => {
      if (token && user) {
        const body = {
          DID: user?.DID,
        };
        try {
          const res = await dispatch(getInvoices(token, body));
          // Fetch images and attach them to each invoice item
          const invoicesWithImages = await Promise.all(
            res?.data?.map(async (invoice) => {
              const profileImageUrl = invoice?.ProfileImageUrl
                ? await getBucketURL(invoice?.ProfileImageUrl)
                : invoice?.ProfileImageUrl;

              return {
                ...invoice,
                Image: profileImageUrl,
              };
            })
          );

          setInvoices(invoicesWithImages);
          setOrgData(res?.OrganizationInfo);
        } catch (error) {
          setInvoices([]);
          console.error("Failed to fetch invoices", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchInvoices();
  }, [token, user, dispatch]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFilteredInvoices = filteredInvoices?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  useEffect(() => {
    const preview = async () => {
      if (token && invoiceData) {
        const id = invoiceData?.ID;
        try {
          const res = await dispatch(getSpecificInvoice(token, id));
          const invoiceDetails = res;

          // Aggregate services by ServiceCode and ServiceName
          const aggregatedServices = aggregateServices(invoiceDetails?.ServicePerInvoices);

          // Update the specific invoice with aggregated services
          setSpecificInvoice({
            ...invoiceDetails,
            ServicePerInvoices: aggregatedServices
          });
          setShowModal(true);
        } catch (error) {
          console.error("Failed to fetch specific invoice", error);
          setShowModal(false);
        }
      }
    };

    preview();
  }, [token, invoiceData, dispatch]);

  useEffect(() => {
    const preview = async () => {
      if (token && draft) {
        const id = draft?.ID;
        try {
          const res = await dispatch(getSpecificInvoice(token, id));
          const invoiceDetails = res;
          setDraftInvoice(invoiceDetails);
          navigate("AddInvoice", { state: { draft: invoiceDetails, orgData: invoiceDetails?.OrganizationInfo } });
        } catch (error) {
          console.error("Failed to fetch specific invoice", error);
        }
      }
    };

    preview();
  }, [token, draft, dispatch]);

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  if (invoices?.length === 0) {
    return (
      <div className="bg-white min-h-screen py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
        <div className="flex flex-col justify-center items-start gap-2">
          <h1 className="text-[#1C1C1C] text-2xl font-medium font-poppins leading-9">
            Billing & Invoice
          </h1>
          <Breadcrumb />
          <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins mt-4">
            No invoices found for this specific DID
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
      {/* HEADER */}
      <div className="flex flex-row justify-between items-center">
        {/* TITLE */}
        <div className="flex flex-row items-center">
          <div className="flex flex-col justify-center items-start gap-2">
            <h1 className="text-[#1C1C1C] text-2xl font-medium font-poppins leading-9">
              Billing & Invoice
            </h1>
            <Breadcrumb />
          </div>
        </div>
      </div>

      {/* Show Preview Modal */}
      <ModalComponent
        show={showModal}
        handleClose={() => {
          setShowModal(false);
          setSpecificInvoice([]);
          setInvoiceData(null);
          setAddress(null);
        }}
        outSideModalPress={false}
      >
        <div className="bg-[#FFFFFF] h-[70vh] p-4 w-[800px] overflow-x-hidden overflow-y-scroll scroll-smooth scrollbar scrollbar-thin scrollbar-thumb-[#3CA5DC] scrollbar-track-[#E5F2FF] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
          {/* Preview HEADER */}
          <div className="flex flex-col justify-center items-center w-full gap-2">
            <div className="flex flex-row justify-between items-center w-full gap-2">
              <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                <h1 className="text-black text-xs font-regular font-poppins">
                  Appointment ID :
                </h1>
              </div>
              <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                <h1 className="text-black text-xs font-regular font-poppins">
                  Patient Name :
                </h1>
              </div>
              <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                <h1 className="text-black text-xs font-regular font-poppins">
                  Service :
                </h1>
              </div>
              <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                <h1 className="text-black text-xs font-regular font-poppins">
                  Date :
                </h1>
              </div>
              <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                <h1 className="text-black text-xs font-regular font-poppins">
                  Invoice Amount :
                </h1>
              </div>
            </div>

            <div className="flex flex-row justify-between items-center w-full gap-2">
              <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                <h1 className="text-black text-sm font-semibold font-poppins">
                  #{specificInvoice?.SessionID}
                </h1>
              </div>
              <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                <h1 className="text-black text-sm font-semibold font-poppins">
                  {specificInvoice?.AHMember?.FirstName}{" "}
                  {specificInvoice?.AHMember?.LastName}
                </h1>
              </div>
              <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                <h1 className="text-black text-sm font-semibold font-poppins">
                  {specificInvoice?.AppointmentType}
                </h1>
              </div>
              <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                <h1 className="text-black text-sm font-semibold font-poppins">
                  {moment(specificInvoice?.CreatedAt).format("DD/MM/YYYY")}
                </h1>
              </div>
              <div className="flex flex-row justify-start items-center gap-1 w-1/5">
                <h1 className="text-black text-sm font-semibold font-poppins">
                  CHF {specificInvoice?.TotalAmount}
                </h1>
              </div>
            </div>
          </div>

          <div className="w-[100%] bg-[#F3F5F6] h-[1.5px] my-5"></div>

          {/* Preview Content */}
          <div className="flex justify-center px-16 pt-10 overflow-hidden items-end w-full bg-[#F1F1F1] rounded-lg mt-4">
            <div className="flex flex-col justify-center items-center bg-white rounded-xl shadow-lg mb-6 w-full">
              {/* INVOICE HEADER */}
              <div className="flex flex-row justify-between w-full items-start px-6 py-2">
                <div className="flex justify-start items-start w-40">
                  <img
                    src={
                      logo
                        ? logo
                        : settings?.OrgImage
                          ? settings?.OrgImage
                          : AHlogo
                    }
                    className="w-32 h-auto object-contain"
                    alt="Company Logo"
                  />
                </div>
                <h1 className="text-[#545454] text-[10px] font-normal font-poppins py-1">
                  Page 1 of 2
                </h1>
              </div>

              <div className="w-[100%] bg-[#F3F5F6] h-[2px]"></div>

              {/* INVOICE CONTENT */}
              <div className="flex flex-col justify-center items-start w-full mt-3">
                <div className="flex flex-row justify-center items-start w-full gap-7 px-6">
                  {/* Bill From */}
                  <div className="w-1/2 flex flex-col justify-center items-start gap-2">
                    <h1 className="text-[#1C1C1C] text-sm font-semibold font-poppins">
                      Bill From
                    </h1>
                    <div className="flex flex-col justify-center items-start w-full gap-1">
                      <h1 className="text-[#000000] text-xs font-semibold font-poppins">
                        Company Details
                      </h1>
                      <p className="text-[#000000] text-[10px] font-medium font-poppins">
                        Company Name & Address: <span className="text-[#000000] text-[10px] font-normal font-poppins">{specificInvoice?.OrganizationInfo?.Name} {specificInvoice?.OrganizationInfo?.LocationInfo}</span>
                      </p>
                      <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                        VAT no: <span className="text-[#000000] text-[10px] font-medium font-poppins">{specificInvoice?.OrganizationInfo?.VAT_No}</span>
                      </p>
                      <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                        Phone no: <span className="text-[#000000] text-[10px] font-normal font-poppins">{specificInvoice?.OrganizationInfo?.Phone}</span>
                      </p>
                      <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                        Email: <span className="text-[#000000] text-[10px] font-normal font-poppins">{specificInvoice?.OrganizationInfo?.Email}</span>
                      </p>
                    </div>
                  </div>

                  {/* Bill To */}
                  <div className="w-1/2 flex flex-col justify-center items-start gap-2">
                    <h1 className="text-[#1C1C1C] text-sm font-semibold font-poppins">
                      Bill To
                    </h1>
                    <div className="flex flex-col justify-center items-start w-full gap-1">
                      <h1 className="text-[#000000] text-xs font-semibold font-poppins">
                        Patient Details
                      </h1>
                      <p className="text-[#000000] text-[10px] font-medium font-poppins">
                        <span className="text-[#000000] text-[10px] font-normal font-poppins">{specificInvoice?.AHMember?.FirstName}{" "}
                          {specificInvoice?.AHMember?.LastName} <br />
                          {specificInvoice?.AHMember?.Address}
                          {","}
                          <br />
                          {specificInvoice?.AHMember?.City}
                          {","}
                          {specificInvoice?.AHMember?.State} <br />
                          {specificInvoice?.AHMember?.Country}
                          {"-"}
                          {specificInvoice?.AHMember?.PinCode}</span>
                      </p>
                      <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                        Patient ID: <span className="text-[#000000] text-[10px] font-medium font-poppins">{specificInvoice?.AHMember?.AHUID}</span>
                      </p>
                      <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                        Phone no: <span className="text-[#000000] text-[10px] font-normal font-poppins">{specificInvoice?.AHMember?.Phone}</span>
                      </p>
                      <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                        Email: <span className="text-[#000000] text-[10px] font-normal font-poppins">{specificInvoice?.AHMember?.Email}</span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row justify-center items-start w-full gap-7 mt-4 px-6">
                  {/* Invoice Details */}
                  <div className="w-1/2 flex flex-col justify-center items-start gap-2">
                    <h1 className="text-[#1C1C1C] text-sm font-semibold font-poppins">
                      Invoice Details
                    </h1>
                    <div className="flex flex-col justify-center items-start w-full gap-1">
                      <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                        Invoice No: <span className="text-[#000000] text-[10px] font-normal font-poppins">#{specificInvoice?.InvoiceNumber}</span>
                      </p>
                      <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                        Invoice Date: <span className="text-[#000000] text-[10px] font-medium font-poppins">{moment(specificInvoice?.CreatedAt).format("DD/MM/YYYY")}</span>
                      </p>
                      <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                        Payment Due Date: <span className="text-[#000000] text-[10px] font-normal font-poppins">{moment(specificInvoice?.CreatedAt).add(30, 'days').format("DD/MM/YYYY")}</span>
                      </p>
                    </div>
                  </div>

                  {/* Invoice Details cont */}
                  <div className="w-1/2 flex flex-col justify-center items-start gap-2">
                    <div className="flex flex-col justify-center items-start w-full gap-1">
                      <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                        Session ID:  <span className="text-[#000000] text-[10px] font-medium font-poppins">#{specificInvoice?.SessionID}</span>
                      </p>
                      <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                        Consultation Date: <span className="text-[#000000] text-[10px] font-normal font-poppins">{moment(specificInvoice?.CreatedAt).format("DD.MM.YY")}</span>
                      </p>
                      <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                        Doctor Name: <span className="text-[#000000] text-[10px] font-normal font-poppins">Dr. {specificInvoice?.RelatedDoctor?.Name}</span>
                      </p>
                    </div>
                  </div>
                </div>

                {/* Table header */}
                <div className="flex flex-row gap-2 justify-center items-center w-full mt-6 bg-[#E5F2FF] py-1 px-2">
                  <h1 className="w-1/6 text-[#1C1C1C] text-xs font-semibold font-poppins">
                    Service Code
                  </h1>
                  <h1 className="w-1/6 text-[#1C1C1C] text-xs font-semibold font-poppins">
                    Description
                  </h1>
                  <h1 className="w-1/6 text-[#1C1C1C] text-xs font-semibold font-poppins">
                    Qty/Time
                  </h1>
                  <h1 className="w-1/6 text-[#1C1C1C] text-xs font-semibold font-poppins">
                    Rate
                  </h1>
                  <h1 className="w-1/6 text-[#1C1C1C] text-xs font-semibold font-poppins">
                    Tax ( VAT)
                  </h1>
                  <h1 className="w-1/6 text-[#1C1C1C] text-xs font-semibold font-poppins">
                    Amount
                  </h1>
                </div>

                {/* Table Content */}
                <div className="flex flex-col justify-center items-center gap-2 w-full mt-2 px-2">
                  {/* Service Data */}
                  {specificInvoice?.ServicePerInvoices?.map((service) => (
                    <div
                      key={service?.ID}
                      className="flex flex-row gap-2 justify-between items-start w-full mb-2"
                    >
                      <div className="w-1/6 flex flex-row justify-start items-center">
                        <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                          {service?.ServiceCode}
                        </h1>
                      </div>
                      <div className="w-1/6 flex flex-row justify-start items-center">
                        <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                          {service?.ServiceName}
                        </h1>
                      </div>
                      <div className="w-1/6 flex flex-row justify-start items-center">
                        <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                          {service?.Quantity >= 1 ? service?.Quantity : `${service?.ServiceTime} Mins`}
                        </h1>
                      </div>
                      <div className="w-1/6 flex flex-row justify-start items-center">
                        <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                          CHF {service?.Rate}
                        </h1>
                      </div>
                      <div className="w-1/6 flex flex-row justify-start items-center">
                        <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                          CHF {service?.Tax}
                        </h1>
                      </div>
                      <div className="w-1/6 flex flex-row justify-start items-center">
                        <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                          CHF {service?.Cost}
                        </h1>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="w-[100%] bg-[#F3F5F6] h-[2px]"></div>

                <div className="flex flex-col justify-center items-center w-full mt-3 mb-2 gap-1 pr-6">
                  <div className="flex flex-row self-end justify-center items-center w-1/4">
                    <h1 className="text-black text-xs font-semibold font-poppins w-[60%]">
                      Total
                    </h1>
                    <h1 className="text-black text-xs font-semibold font-poppins w-[40%] text-right">
                      CHF {specificInvoice?.TotalAmount}
                    </h1>
                  </div>
                  <h1 className="self-end text-[#545454] text-xs font-normal font-poppins w-1/4 text-left mt-1">
                    (Inclusive of VAT)
                  </h1>
                </div>

                <div className="w-[95%] mx-4 bg-[#F3F5F6] h-[2px]"></div>

                {/* Notes */}
                <div className="w-full flex flex-col justify-center items-start gap-2 px-6 my-3">
                  <h1 className="text-black text-sm font-semibold font-poppins">
                    Notes
                  </h1>
                  <ul className="flex flex-col justify-center items-start w-full gap-1">
                    <li className="text-[#545454] text-[10px] font-normal font-poppins list-disc list-inside">
                      Payment Due in <span className="text-[#1C1C1C] font-semibold">30 days</span> from the invoice sent date.
                    </li>
                    <li className="text-[#545454] text-[10px] font-normal font-poppins list-disc list-inside">
                      Please contact us at{" "}
                      <a href={`mailto:${specificInvoice?.OrganizationInfo?.Email}`} className="text-[#1C1C1C] font-semibold">{specificInvoice?.OrganizationInfo?.Email}</a>
                      {" "}or{" "}
                      <a href={`tel:${specificInvoice?.OrganizationInfo?.Phone}`} className="text-[#1C1C1C] font-semibold">{specificInvoice?.OrganizationInfo?.Phone}</a>
                      {" "}for any queries.
                    </li>
                  </ul>
                </div>

                <div className="w-[95%] mx-4 border-t-2 border-dashed border-[#F3F5F6]"></div>

                <div className="flex flex-row justify-center items-start w-full px-6 mb-8 mt-3">
                  {/* Receipt */}
                  <div className="w-1/2 border-r-2 border-dashed border-[#F3F5F6]">
                    <div className="w-[90%] flex flex-col justify-center items-start gap-2">
                      {/* Account/ Payable To: */}
                      <h1 className="text-[#1C1C1C] text-sm font-semibold font-poppins">
                        Receipt
                      </h1>
                      <div className="flex flex-col justify-center items-start w-full gap-1">
                        <h1 className="text-[#000000] text-xs font-semibold font-poppins">
                          Account/ Payable To:
                        </h1>
                        <p className="text-[#000000] text-[10px] font-medium font-poppins mt-1">
                          Company Name & Address: <span className="text-[#000000] text-[10px] font-normal font-poppins">{specificInvoice?.OrganizationInfo?.Name} {specificInvoice?.OrganizationInfo?.LocationInfo}</span>
                        </p>
                        <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                          IBAN No: <span className="text-[#000000] text-[10px] font-medium font-poppins">{specificInvoice?.OrganizationInfo?.IBAN_No}</span>
                        </p>
                        <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                          Bank: <span className="text-[#000000] text-[10px] font-normal font-poppins">{specificInvoice?.OrganizationInfo?.Bank}</span>
                        </p>
                        <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                          Beneficiary: <span className="text-[#000000] text-[10px] font-normal font-poppins">{specificInvoice?.OrganizationInfo?.Beneficiary}</span>
                        </p>
                        <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                          Referance No. <span className="text-[#000000] text-[10px] font-normal font-poppins">{specificInvoice?.OrganizationInfo?.Referance_No}</span>
                        </p>
                      </div>
                      {/* Account/ Payable By: */}
                      <div className="flex flex-col justify-center items-start w-full gap-1">
                        <h1 className="text-[#000000] text-xs font-semibold font-poppins">
                          Account/ Payable By:
                        </h1>
                        <p className="text-[#000000] text-[10px] font-medium font-poppins mt-1">
                          Patient Name & Address: <span className="text-[#000000] text-[10px] font-normal font-poppins">{specificInvoice?.AHMember?.FirstName}{" "}
                            {specificInvoice?.AHMember?.LastName} <br />
                            {specificInvoice?.AHMember?.Address}
                            {","}
                            <br />
                            {specificInvoice?.AHMember?.City}
                            {","}
                            {specificInvoice?.AHMember?.State} <br />
                            {specificInvoice?.AHMember?.Country}
                            {"-"}
                            {specificInvoice?.AHMember?.PinCode}</span>
                        </p>
                        <div className="flex flex-row justify-start items-start w-full gap-2 mt-2">
                          <div className="flex flex-col justify-center items-start w-12 gap-1">
                            <h1 className="text-[#000000] text-[10px] font-semibold font-poppins">
                              Currency:
                            </h1>
                            <p className="text-[#545454] text-[10px] font-normal font-poppins mt-1">CHF
                            </p>
                          </div>
                          <div className="flex flex-col justify-center items-start w-12 gap-1">
                            <h1 className="text-[#000000] text-[10px] font-semibold font-poppins">
                              Amount:
                            </h1>
                            <p className="text-[#545454] text-[10px] font-normal font-poppins mt-1">{specificInvoice?.TotalAmount}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Scan QR */}
                  <div className="w-1/2 flex flex-col justify-center items-start gap-2 ml-5">
                    <h1 className="text-[#1C1C1C] text-sm font-semibold font-poppins">
                      Scan QR
                    </h1>
                    <div className="flex flex-col justify-start items-start w-full gap-2">
                      <div className="flex justify-start items-start w-full">
                        <img
                          src={qrCodeImg}
                          className="w-32 h-32 h-auto object-contain"
                          alt="QR Code"
                        />
                      </div>
                      <div className="flex flex-row justify-start items-start w-full gap-2">
                        <div className="flex flex-col justify-center items-start w-12 gap-1">
                          <h1 className="text-[#000000] text-[10px] font-semibold font-poppins">
                            Currency:
                          </h1>
                          <p className="text-[#545454] text-xs font-normal font-poppins mt-1">CHF
                          </p>
                        </div>
                        <div className="flex flex-col justify-center items-start w-12 gap-1">
                          <h1 className="text-[#000000] text-[10px] font-semibold font-poppins">
                            Amount:
                          </h1>
                          <p className="text-[#545454] text-xs font-normal font-poppins mt-1">{specificInvoice?.TotalAmount}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>

      {/* Content */}
      <div className="flex flex-col justify-center items-center w-full mt-6">
        {/* Search N Filter */}
        <div className="flex flex-row justify-between items-center w-full">
          {/* Search */}
          <label
            className={`flex flex-row items-center justify-start bg-gray-100 rounded-full py-2 px-4 gap-2 w-[32rem]`}
          >
            <IoIosSearch className="text-[#acacac] w-5 h-5" />
            <input
              type="text"
              placeholder="Search by patient name or session id"
              value={searchQuery}
              onChange={handleSearchInputChange}
              className={`bg-gray-100 text-sm text-[#acacac] font-normal font-poppins focus:outline-none focus:border-transparent w-full`}
            />
          </label>

          {/* Filter */}
          <div className="flex flex-row justify-center items-center">
            {['All', 'New', 'Draft', 'Paid', 'Unpaid', 'Overdue'].map((filter, index, array) => (
              <button
                key={filter}
                className={`
        py-2 px-4
        ${index === 0 ? 'rounded-l-lg' : ''} 
        ${index === array.length - 1 ? 'rounded-r-lg' : ''} 
        ${selectedFilter === filter.toUpperCase()
                    ? 'bg-[#E5F4FE] border-[#AFD8F2]'
                    : 'bg-white border-[#D4D4D4]'
                  }
        ${selectedFilter === filter.toUpperCase() ? 'border' : 'border'}
      `}
                onClick={handleFilterChange}
              >
                <h1 className={`${selectedFilter === filter.toUpperCase() ? "text-[#007AAE]" : "text-[#545454]"} text-sm font-medium font-poppins`}>
                  {filter}
                </h1>
              </button>
            ))}
          </div>
        </div>

        {/* Table Header */}
        <div
          className="mt-4 flex flex-row justify-between items-center w-full bg-[#F9FAFB] py-3 px-6 border-b-2 border-[#EAECF0] shadow"
        >
          <div className="flex flex-row justify-start items-center gap-1 w-[116px]">
            <h1 className="text-[#545454] text-xs font-medium font-poppins leading-[18px]">
              Session ID
            </h1>
          </div>
          <div className="flex flex-row justify-start items-center gap-1 w-52">
            <h1 className="text-[#545454] text-xs font-medium font-poppins leading-[18px]">
              Patient Name
            </h1>
          </div>
          <div className="flex flex-row justify-start items-center gap-1 w-[201px]">
            <h1 className="text-[#545454] text-xs font-medium font-poppins leading-[18px]">
              Service Type
            </h1>
          </div>
          <div className="flex flex-row justify-start items-center gap-1 w-36">
            <h1 className="text-[#545454] text-xs font-medium font-poppins leading-[18px]">
              Date Created
            </h1>
          </div>
          <div className="flex flex-row justify-start items-center gap-1 w-[132px]">
            <h1 className="text-[#545454] text-xs font-medium font-poppins leading-[18px]">
              Invoice Amount
            </h1>
          </div>
          <div className="flex flex-row justify-start items-center gap-1 w-32">
            <h1 className="text-[#545454] text-xs font-medium font-poppins leading-[18px]">
              Status
            </h1>
          </div>
          <div className="flex flex-row justify-start items-center gap-1 w-[86px]">
            <h1 className="text-[#545454] text-xs font-medium font-poppins leading-[18px]">
              Actions
            </h1>
          </div>
          <div className="flex flex-row justify-start items-center gap-1 w-[120px]">
            <h1 className="text-[#545454] text-xs font-medium font-poppins leading-[18px]">
              Invoice Id
            </h1>
          </div>
        </div>

        {/* Table Content */}
        {currentFilteredInvoices?.length === 0 && (
          <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins mt-4 w-full text-center">
            No invoices found
          </h1>
        )}
        {currentFilteredInvoices?.slice(0, itemsPerPage).map((invoice) => (
          <React.Fragment key={invoice?.ID}>
            <div className="flex flex-row justify-between items-center w-full bg-[#FFFFFF] py-[22px] px-6 border-b-2 border-[#EAECF0] shadow">
              <div className="flex flex-row justify-start items-center gap-1 w-[116px]">
                <h1 className="text-[#1C1C1C] text-sm font-normal font-poppins leading-5">
                  #{invoice?.ID}
                </h1>
              </div>
              <button onClick={() => {
                navigate("/patientDashboard/patientProfile", {
                  state: {
                    ...invoice,
                    Image: invoice?.Image,
                  },
                })
              }} className="flex flex-row justify-start items-center gap-3 w-52">
                <img
                  src={invoice?.Image}
                  className="w-7 h-7 object-cover rounded-full"
                  alt={`${invoice?.Name} "Image"`}
                />
                <h1 className="text-[#1C1C1C] text-sm font-normal font-poppins leading-[20px]">
                  {invoice?.Name}
                </h1>
              </button>
              <div className="flex flex-row justify-start items-center gap-1 w-[201px]">
                <h1 className="text-[#1C1C1C] text-sm font-normal font-poppins leading-[20px]">
                  {invoice?.AppointmentType}
                </h1>
              </div>
              <div className="flex flex-row justify-start items-center gap-1 w-36">
                <h1 className="text-[#1C1C1C] text-sm font-normal font-poppins leading-[20px]">
                  {moment(invoice?.CreatedAt).format("DD/MM/YYYY")}
                </h1>
              </div>
              <div className="flex flex-row justify-start items-center gap-1 w-[132px]">
                <h1 className="text-[#1C1C1C] text-sm font-normal font-poppins leading-[20px]">
                  {invoice?.Invoice?.TotalAmount
                    ? `CHF ${invoice?.Invoice?.TotalAmount}`
                    : null}
                </h1>
              </div>
              {invoice?.Subscription_Status === "PAID" && (
                <div className="flex flex-row justify-start items-center w-32">
                  <div className="flex flex-row justify-center items-center bg-[#D4FEE2] rounded-[48px] px-6 py-2 w-20">
                    <h1 className="text-[#018516] text-xs font-semibold font-poppins leading-[14px]">
                      Paid
                    </h1>
                  </div>
                </div>
              )}
              {invoice?.Subscription_Status === "NEW" && (
                <div className="flex flex-row justify-start items-center w-32">
                  <div className="flex flex-row justify-center items-center bg-[#E5F2FF] rounded-[48px] px-6 py-2 w-20">
                    <h1 className="text-[#007AAE] text-xs font-semibold font-poppins leading-[14px]">
                      New
                    </h1>
                  </div>
                </div>
              )}
              {invoice?.Subscription_Status === "OVERDUE" && (
                <div className="flex flex-row justify-start items-center w-32">
                  <div className="flex flex-row justify-center items-center bg-[#FEE4E2] rounded-[48px] px-6 py-2 w-20">
                    <h1 className="text-[#B42318] text-xs font-semibold font-poppins leading-[14px]">
                      Overdue
                    </h1>
                  </div>
                </div>
              )}
              {invoice?.Subscription_Status === "UNPAID" && (
                <div className="flex flex-row justify-start items-center w-32">
                  <div className="flex flex-row justify-center items-center bg-[#FDF4EC] rounded-[48px] px-6 py-2 w-20">
                    <h1 className="text-[#954D05] text-xs font-semibold font-poppins leading-[14px]">
                      Unpaid
                    </h1>
                  </div>
                </div>
              )}
              {invoice?.Subscription_Status === "DRAFT" && (
                <div className="flex flex-row justify-start items-center w-32">
                  <div className="flex flex-row justify-center items-center bg-[#F3F5F6] rounded-[48px] px-6 py-2 w-20">
                    <h1 className="text-[#545454] text-xs font-semibold font-poppins leading-[14px]">
                      Draft
                    </h1>
                  </div>
                </div>
              )}
              {invoice?.Invoice ? (
                <>
                  {invoice?.Subscription_Status === "DRAFT" ? (
                    <div className="w-[86px]">
                      <Tooltip
                        message={"Generate Invoice"}
                        // activation="click"
                        showCopyButton={false}
                      >
                        <div
                          onClick={() => {
                            setDraft(invoice);
                          }}
                          className="flex flex-row justify-center items-center w-10 h-10 cursor-pointer hover:scale-105 duration-500 hover:bg-[#E4F2FE] rounded-full"
                        >
                          <img
                            src={generateInvoiceIcon}
                            className="w-6 h-6 object-contain"
                            alt="Preview Invoice Icon"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <>
                      {invoice?.Subscription_Status === "OVERDUE" ? (
                        <div className="w-[86px]">
                          <Tooltip
                            message={"Generate Reminder"}
                            // activation="click"
                            showCopyButton={false}
                          >
                            <div
                              onClick={() => { }}
                              className="flex flex-row justify-center items-center w-10 h-10 cursor-pointer hover:scale-105 duration-500 hover:bg-[#E4F2FE] rounded-full"
                            >
                              <img
                                src={reminderIcon}
                                className="w-6 h-6 object-contain"
                                alt="Send Reminder Icon"
                              />
                            </div>
                          </Tooltip>
                        </div>
                      ) : (
                        <div className="w-[86px]">
                          <Tooltip
                            message={"Show Preview"}
                            // activation="click"
                            showCopyButton={false}
                          >
                            <div
                              onClick={() => {
                                setInvoiceData(invoice);
                                setAddress(
                                  [
                                    invoice?.Address,
                                    `${invoice?.City} ${invoice?.State}`,
                                    `${invoice?.Country} - ${invoice?.PinCode}`,
                                  ]
                                    .filter(Boolean)
                                    .join(", ")
                                );
                              }}
                              className="flex flex-row justify-center items-center w-10 h-10 cursor-pointer hover:scale-105 duration-500 hover:bg-[#E4F2FE] rounded-full"
                            >
                              <img
                                src={previewIcon}
                                className="w-6 h-6 object-contain"
                                alt="Preview Invoice Icon"
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div className="w-[86px]">
                  <Tooltip
                    message={"Generate Invoice"}
                    // activation="click"
                    showCopyButton={false}
                  >
                    <div
                      onClick={() => {
                        navigate("AddInvoice", { state: { invoice: invoice, orgData: orgData } });
                      }}
                      className="flex flex-row justify-center items-center w-10 h-10 cursor-pointer hover:scale-105 duration-500 hover:bg-[#E4F2FE] rounded-full"
                    >
                      <img
                        src={generateInvoiceIcon}
                        className="w-6 h-6 object-contain"
                        alt="Create Invoice Icon"
                      />
                    </div>
                  </Tooltip>
                </div>
              )}

              <div className="flex flex-row justify-start items-center gap-1 w-[120px]">
                <h1 className="text-[#1C1C1C] text-sm font-normal font-poppins leading-[20px]">
                  {invoice?.Invoice?.InvoiceNumber || "-"}
                </h1>
              </div>
            </div>
          </React.Fragment>
        ))}

        {filteredInvoices?.length !== 0 && (
          <Pagination
            totalItems={filteredInvoices?.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
}

export default Invoicing;
