import React from "react";
import crown from "../assets/Images/OperationsManagement/PlatformManagement/crown.svg";
import { FaArrowRight } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function SubscriptionBanner() {
  const navigate = useNavigate();
  const { isSubscribed, currentPlan } = useSelector(
    (state) => state.membership
  );

  if (isSubscribed) {
    return null;
  }

  return (
    <div className="w-full bg-[url('/src/assets/Images/OperationsManagement/PlatformManagement/bannerBackground.png')] mt-7 mb-20 bg-cover bg-center text-white p-4 rounded-lg flex items-center justify-between">
      <div className="flex items-center space-x-3">
        <img src={crown} alt="crown" className="w-8 h-8" />
        <div className="bg-black/40 p-1 rounded-md">
          <p className="font-bold text-lg">
            Unlock the power of best Clinician Dashboard with Premium
            Subscription
          </p>
          <p className="text-sm text-gray-300">
            Get all the essentials plus amazing premium-only features.
          </p>
        </div>
      </div>
      <button
        onClick={() => navigate("/operationsManagement/platformManagement/subscriptionPlans")}
        className="bg-white px-4 py-2 rounded-full flex items-center justify-center space-x-2 hover:bg-gray-100 transition-colors duration-200"
      >
        <img src={crown} alt="crown" className="w-4 h-4" />
        <div className="text-gray-800 text-xs font-semibold font-poppins">
          Upgrade to Premium
        </div>
        <FaArrowRight className="text-gray-800 text-xs font-semibold" />
      </button>
    </div>
  );
}

export default SubscriptionBanner;
