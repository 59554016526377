const CheckboxIcon = ({ checked }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline-block mr-2"
  >
    {checked ? (
      <path
        d="M14.1623 1H3.83773C2.26792 1 1 2.26792 1 3.83773V14.1623C1 15.7321 2.26792 17 3.83773 17H14.1623C15.7321 17 17 15.7321 17 14.1623V3.83773C17 2.29811 15.7019 1 14.1623 1ZM7.30943 13.4075L7.27924 13.3774L7.18868 13.4679L3.53585 9.78491L5.25661 8.09434L7.21887 10.0566L12.7434 4.53208L14.4642 6.25283L7.30943 13.4075Z"
        fill="#3CA5DC"
        stroke="#3CA5DC"
        strokeWidth="0.90566"
        strokeMiterlimit="10"
      />
    ) : (
      <path
        d="M13.0426 16.8926H2.96598C1.33194 16.8926 0.015625 15.5763 0.015625 13.9422V3.84293C0.015625 2.20889 1.33194 0.892578 2.96598 0.892578H13.0653C14.6993 0.892578 16.0156 2.20889 16.0156 3.84293V13.9422C15.9929 15.5763 14.6766 16.8926 13.0426 16.8926ZM2.96598 1.34648C1.58158 1.34648 0.469525 2.45854 0.469525 3.84293V13.9422C0.469525 15.3266 1.58158 16.4387 2.96598 16.4387H13.0653C14.4497 16.4387 15.5617 15.3266 15.5617 13.9422V3.84293C15.5617 2.45854 14.4497 1.34648 13.0653 1.34648H2.96598Z"
        fill="#545454"
      />
    )}
  </svg>
);

export default CheckboxIcon;