import React from 'react';
import './style.css';

const RadioButton1 = ({ name, id, value, onChange, checked, text, imageSrc }) => {
    return (
        <label
            htmlFor={id}
            className="radio-label flex flex-row justify-center items-center mb-1 w-full"
        >
            <input
                className="radio-input"
                type="radio"
                name={name}
                id={id}
                value={value}
                onChange={onChange}
                checked={checked}
            />
            <span className="custom-radio" />
            <div className={`w-20 flex justify-between items-center px-1 py-[2px] rounded-md shadow-sm bg-[#FAFAFA]}`}>
                <p className="text-[#3CA5DC] text-[0.6rem] font-regular font-poppins mr-1">{text === 'INCLINIC' ? 'In Clinic' : text === 'LABTEST' ? 'Lab Test' : text=== 'ATHOME' ? 'At Home' : text}</p>
                <img src={imageSrc} alt={text} className="w-7 h-7 object-contain" />
            </div>
        </label>
    );
};

export default RadioButton1;
