import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AHlogo from "../assets/Images/logo.png";
import resetLogo from "../assets/Images/resetPasswordLogo.png";
import PasswordChangedSuccess from "../assets/Images/PasswordChangedSuccess.png";
import { Link, useNavigate } from "react-router-dom";
import { setSignupData } from "../slices/authSlice";
import { SlLogin } from "react-icons/sl";
import reqDemoIcon from "../assets/Images/reqDemoIcon.png";
import { IoIosCheckmarkCircle } from "react-icons/io";

function ResetPasswordSuccessForm({ type }) {
  const { signupData, loading } = useSelector((state) => state.auth);
  const { logo } = useSelector((state) => state.profile);
  const { settings } = useSelector((state) => state.masterData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (signupData) {
      dispatch(setSignupData(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div>
      {loading ? (
        <div>
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="bg-white w-full md:w-[37vw] h-[90vh] rounded-[22px] py-10 px-20 flex flex-col justify-start">
          <div className="flex items-center justify-center gap-3 mt-6 w-full">
            <Link to={type === 'clinic' ? "/partnerLogin" : "/login"} className="block w-44 bg-[#3ca5dc] rounded-2xl py-2 px-6 flex flex-row justify-between items-center gap-2">
              <h1 className="text-[#FFFFFF] text-[13px] font-semibold font-open font-poppins">
                {type === 'clinic' ? "Partner Login" : "Clinic Login"}
              </h1>
              <SlLogin className="text-[#FFFFFF] font-semibold text-lg" />
            </Link>
            <button onClick={() => window.open("https://alleshealth.com/contactus", "_blank")} className="block w-44 bg-white border border-[#3ca5dc] rounded-2xl py-2 px-4 flex flex-row justify-between items-center gap-2">
              <h1 className="text-black text-[13px] font-semibold font-open font-poppins">
                Request a Demo
              </h1>
              <div className="">
                <img
                  src={reqDemoIcon}
                  alt="Demo Icon"
                  className="w-5 h-5 object-contain"
                />
              </div>
            </button>
          </div>

          <h1 className="text-black text-[32px] font-semibold font-open font-poppins leading-relaxed mt-28">
            Password Changed
          </h1>
          <div className="flex flex-row justify-start items-center gap-2 mt-3">
            <h1 className="text-[#85d410] text-sm font-medium font-open font-poppins leading-relaxed">
              Password Changed Successfully
            </h1>
            <IoIosCheckmarkCircle className="text-[#85d410]" />
          </div>

          <div className="flex justify-center items-center w-full mt-9">
            <img
              src={PasswordChangedSuccess}
              className="w-52 h-56 object-contain"
              alt="Password Changed Success Image"
            />
          </div>

          <div className="mt-10">
            <button
              onClick={() => {
                if (type === 'clinic') {
                  navigate("/login");
                } else {
                  navigate("/partnerLogin");
                }
              }}
              className="w-full bg-sky-400 hover:bg-sky-400 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 rounded-2xl py-3 text-sky-100 text-sm font-medium font-poppins"
            >
              Back to Login Page
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ResetPasswordSuccessForm;
