import { useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

function PdfComp({ pdf, width = "650px", height = "400px" }) {
  console.log("====================================");
  console.log(pdf);
  console.log("====================================");
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const isJpg = pdf?.endsWith(".jpg");

  const pages = Array.from(new Array(numPages), (el, index) => (
    <Page key={`page_${index + 1}`} pageNumber={index + 1} width={500} />
  ));

  return (
    <div
      style={{
        width: width,
        height: height,
        overflow: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start", // Align items to the top
        paddingTop: "10px", // Add padding to the top
      }}
      className="max-h-[calc(100vh-300px)] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full"
    >
      {isJpg ? (
        <img
          src={pdf}
          alt="Document"
          style={{ width: "100%", height: "auto" }}
        />
      ) : (
        <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
          {pages}
        </Document>
      )}
    </div>
  );
}

export default PdfComp;
