import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import bannerBackground from "../assets/Images/OperationsManagement/PlatformManagement/bannerBackground.png";
import subs_right_small_icon from "../assets/Images/OperationsManagement/PlatformManagement/subs_right_small_icon.svg";
import subs_right_big_icon from "../assets/Images/OperationsManagement/PlatformManagement/subs_right_big_icon.svg";
import policies_big_icon from "../assets/Images/OperationsManagement/PlatformManagement/crown.svg";
import crown from "../assets/Images/OperationsManagement/PlatformManagement/crown.svg";
import { FaArrowRight } from "react-icons/fa6";
import dollar_icon from "../assets/Images/PlatformManagement/dollar_icon.svg";
import arrow_icon from "../assets/Images/PlatformManagement/arrow_icon.svg";
import shield_icon from "../assets/Images/PlatformManagement/shield_icon.svg";
import todo_icon from "../assets/Images/PlatformManagement/todo_icon.svg";
import close_exit_icon from "../assets/Images/PlatformManagement/close_exit_icon.svg";
import SubscriptionBanner from "../components/SubscriptionBanner";
import { useDispatch, useSelector } from "react-redux";
import { getAllPolicies } from "../services/operations/platformManagementAPI";
import moment from "moment";

function PlatformPolicy() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { policies, loading: platformLoading } = useSelector(
    (state) => state.platformManagement
  );

  useEffect(() => {
    dispatch(getAllPolicies(token));
  }, []);

  console.log("====================================");
  console.log("policies", policies);
  console.log("====================================");

  if (platformLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  const PolicyCard = ({ data, index }) => {
    return (
      <div className="w-full max-w-md p-8 bg-gradient-to-bl from-sky-100 to-sky-300 rounded-2xl">
        <div className="flex justify-between items-start">
          <div>
            {data.updatedAt ? (
              <p className="h-4 text-gray-800 text-xs font-normal font-poppins">
                Last Update: {moment(data.updatedAt).format("DD/MM/YY")}
              </p>
            ) : (
              <div className="h-4"></div>
            )}
            <div className=" text-gray-800 text-lg font-bold font-poppins leading-normal mt-5">
              {data?.DocumentTitle}
            </div>
            <div className=" text-gray-800 text-xs font-normal font-poppins mt-1">
              {data.Description}
            </div>
          </div>
          <div className="bg-white p-3 rounded-full">
            <img src={DATA[index].icon} alt="icon" className="w-6 h-6" />
          </div>
        </div>

        <button
          onClick={() => navigate("uploadPolicy", { state: data })}
          className="flex flex-row items-center justify-between py-2 px-4 bg-white rounded-xl shadow-md hover:shadow-lg mt-6"
        >
          <img src={arrow_icon} alt="" className="mr-2" />
          <div className="text-gray-800 text-xs font-semibold font-poppins">
            Update {data?.DocumentTitle}
          </div>
        </button>
      </div>
    );
  };
  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        Platform Management
      </div>
      <Breadcrumb />

      {/* Banner */}
      <SubscriptionBanner />

      <div className=" text-neutral-600 text-base font-medium font-poppins mt-4">
        Here are all the Company/Organization Policies you need to maintain
        regularly
      </div>

      <div className="grid grid-cols-3 gap-10 mt-5">
        {policies?.map((data, i) => (
          <div key={i}>
            <PolicyCard data={data} index={i} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PlatformPolicy;

const DATA = [
  {
    id: 1,
    title: "Refund Policy",
    lastUpdate: "12/06/24",
    description: "Keeping your refund policy up-to-date is crucial.",
    buttonText: "Update Refund Policy",
    icon: dollar_icon,
  },
  {
    id: 2,
    title: "Cancellation Policy",
    lastUpdate: null,
    description: "Keeping your refund policy up-to-date is crucial.",
    buttonText: "Update Cancellation Policy",
    icon: close_exit_icon,
  },
  {
    id: 3,
    title: "Terms & Conditions",
    lastUpdate: "12/06/24",
    description: "Keeping your refund policy up-to-date is crucial.",
    buttonText: "Update Terms & Conditions",
    icon: todo_icon,
  },
  {
    id: 4,
    title: "Privacy Policy",
    lastUpdate: "12/06/24",
    description: "Keeping your refund policy up-to-date is crucial.",
    buttonText: "Update Privacy Policy",
    icon: shield_icon,
  },
];
