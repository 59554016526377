import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function Tooltip({
  message,
  children,
  position = "bottom",
  activation = "hover",
  showCopyButton = false,
  isNavigationEnabled = false,
  isTooltipContentObject = false,
}) {
  const [visible, setVisible] = useState(false);
  const tooltipRef = useRef(null);
  const navigate = useNavigate();
  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    if (activation === "click") {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      if (activation === "click") {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };
  }, [activation]);

  const toggleVisibility = () => {
    if (activation === "click") {
      setVisible(!visible);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(message);
    toast.success("Copied to clipboard");
  };

  const positionClasses = {
    top: "bottom-full left-1/2 -translate-x-1/2 mb-1",
    bottom: "top-full left-1/2 -translate-x-1/2 mt-1",
    left: "right-full top-1/2 -translate-y-1/2 mr-1",
    right: "left-full top-1/2 -translate-y-1/2 ml-1",
    bottomLeft: "top-full right-0 mt-1",
    bottomRight: "top-full left-0 mt-1",
    topLeft: "bottom-full right-0 mb-1",
    topRight: "bottom-full left-0 mb-1",
    leftCenter: "top-0 right-full mr-1",
    rightCenter: "top-0 left-full ml-1",
  };

  const arrowClasses = {
    top: "top-full border-t-white",
    bottom: "bottom-full border-b-white",
    left: "left-full border-l-white",
    right: "right-full border-r-white",
    bottomLeft: "bottom-full right-2 border-b-white",
    bottomRight: "bottom-full left-2 border-b-white",
    topLeft: "top-full right-2 border-t-white",
    topRight: "top-full left-2 border-t-white",
    leftCenter: "left-full top-2 border-l-white",
    rightCenter: "right-full top-2 border-r-white",
  };

  return (
    <div
      className="group relative inline-block"
      onClick={toggleVisibility}
      ref={tooltipRef}
      onMouseEnter={() => activation === "hover" && setVisible(true)}
      onMouseLeave={() => activation === "hover" && setVisible(false)}
    >
      {children}
      <div
        className={`absolute z-10 transition-all duration-300 shadow-lg rounded-lg p-3 bg-white text-neutral-600 text-sm font-semibold font-poppins leading-tight ${
          visible ? "scale-100" : "scale-0"
        } whitespace-nowrap ${positionClasses[position]} flex ${
          isTooltipContentObject
            ? "flex-col items-start space-y-3"
            : "flex-row items-center"
        } justify-center`}
      >
        {isTooltipContentObject ? (
          message?.map((patient, index) => (
            <button
              onClick={() => {
                navigate("/patientDashboard/patientProfile", { state: patient });
              }}
              key={index}
              className="text-sky-400 text-xs font-medium font-poppins underline leading-none"
            >
              {patient.Name}
            </button>
          ))
        ) : (
          <span>{message}</span>
        )}
        {showCopyButton && (
          <button onClick={handleCopy} className="ml-2 active:scale-90">
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 text-sky-400"
            >
              <path
                d="M8 12.5C8 10.143 8 8.9645 8.73223 8.23223C9.4645 7.5 10.643 7.5 13 7.5H13.8333C16.1903 7.5 17.3688 7.5 18.1011 8.23223C18.8333 8.9645 18.8333 10.143 18.8333 12.5V13.3333C18.8333 15.6903 18.8333 16.8688 18.1011 17.6011C17.3688 18.3333 16.1903 18.3333 13.8333 18.3333H13C10.643 18.3333 9.4645 18.3333 8.73223 17.6011C8 16.8688 8 15.6903 8 13.3333V12.5Z"
                stroke="currentColor"
                strokeWidth="1.25"
                stroke-linecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.664 7.5013C14.662 5.03706 14.6247 3.76064 13.9074 2.88666C13.7689 2.71788 13.6141 2.56312 13.4454 2.4246C12.5234 1.66797 11.1536 1.66797 8.41406 1.66797C5.6745 1.66797 4.30471 1.66797 3.38275 2.4246C3.21397 2.56311 3.05921 2.71788 2.9207 2.88666C2.16406 3.80862 2.16406 5.1784 2.16406 7.91797C2.16406 10.6576 2.16406 12.0273 2.9207 12.9493C3.0592 13.1181 3.21397 13.2728 3.38275 13.4113C4.25674 14.1286 5.53315 14.1659 7.9974 14.1679"
                stroke="currentColor"
                strokeWidth="1.25"
                stroke-linecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        )}
        <div
          className={`absolute border-[8px] border-transparent ${arrowClasses[position]}`}
        />
      </div>
    </div>
  );
}
