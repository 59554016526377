import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import useOnClickOutside from "../hooks/useOnClickOutside";
import MyProfileModal from "./MyProfileModal";
import ChangePasswordModal from "./ChangePasswordModal";

export default function ProfileDropDown() {
  const { user } = useSelector((state) => state.profile);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  useOnClickOutside(ref, () => setOpen(false));

  // Helper function to format the name
  const formatUserName = (name) => {
    if (!name) return "";
    const [firstName, lastName] = name.split(" ");
    if (!lastName) return firstName;
    return `${firstName} ${lastName.charAt(0)}.`;
  };

  if (!user) return null;

  return (
    <>
      <button className="relative" onClick={() => setOpen(true)}>
        <div className="flex flex-row justify-center items-center space-x-2">
          <img
            src={user?.image}
            className={`w-10 h-10 rounded-full object-cover`}
          />
          <div className="text-left">
            <h1 className="text-neutral-800 text-sm font-semibold font-poppins">
              {formatUserName(user?.Name)}
            </h1>
            <h1 className="text-gray-400 text-xs font-normal font-poppins">
              {user?.UserType}
            </h1>
          </div>
        </div>
        {open && (
          <div
            onClick={(e) => e.stopPropagation()}
            ref={ref}
            className="absolute top-10 right-20 w-56 bg-white rounded shadow-2xl p-4 z-50 overflow-hidden"
          >
            <div className="flex flex-col items-center">
              <img
                src={user?.image}
                className={`w-16 h-16 rounded-full object-cover`}
              />
              <h1 className="text-neutral-800 text-sm font-semibold font-poppins mt-1">
                {formatUserName(user?.Name)}
              </h1>
              <div>
                <span className="text-neutral-600 text-sm font-semibold font-poppins leading-tight">
                  Role:
                </span>
                <span className="text-neutral-600 text-sm font-normal font-poppins leading-tight">
                  {" "}
                  {user?.UserType}
                </span>
              </div>
              <div className="text-right text-neutral-600 text-xs font-normal font-poppins leading-none">
                {user?.Email}
              </div>
            </div>
            <div className="w-full h-px border border-gray-200 my-4" />
            <button
              onClick={() => {
                setOpen(false);
                setShowProfileModal(true);
              }}
              className="flex flex-row items-center justify-center gap-2"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 text-sky-400"
              >
                <path
                  d="M21.3448 11.045C21.6488 11.4713 21.8008 11.6845 21.8008 12C21.8008 12.3155 21.6488 12.5287 21.3448 12.955C19.9787 14.8706 16.49 19 11.8008 19C7.11156 19 3.62288 14.8706 2.25682 12.955C1.95279 12.5287 1.80078 12.3155 1.80078 12C1.80078 11.6845 1.95279 11.4713 2.25682 11.045C3.62288 9.12944 7.11156 5 11.8008 5C16.49 5 19.9787 9.12944 21.3448 11.045Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
                <path
                  d="M14.8008 12C14.8008 10.3431 13.4577 9 11.8008 9C10.1439 9 8.80078 10.3431 8.80078 12C8.80078 13.6569 10.1439 15 11.8008 15C13.4577 15 14.8008 13.6569 14.8008 12Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                />
              </svg>
              <div className="text-sky-700 text-base font-semibold font-poppins underline leading-tight">
                View Profile
              </div>
            </button>
            <div className="w-full h-px border border-gray-200 my-4" />
            <button
              onClick={() => {
                setOpen(false);
                setShowChangePassword(true);
              }}
              className="flex flex-row items-center justify-center gap-2"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.44 2.5C9.39437 2.5 6.92 4.97437 6.92 8.02V10.66H6.68C5.76173 10.66 5 11.4217 5 12.34V20.98C5 21.8983 5.76173 22.66 6.68 22.66H18.2C19.1183 22.66 19.88 21.8983 19.88 20.98V12.34C19.88 11.4217 19.1183 10.66 18.2 10.66H17.96V8.02C17.96 4.97437 15.4856 2.5 12.44 2.5ZM12.44 3.94C14.7128 3.94 16.52 5.74723 16.52 8.02V10.66H8.36V8.02C8.36 5.74723 10.1672 3.94 12.44 3.94ZM6.68 12.1H18.2C18.3454 12.1 18.44 12.1946 18.44 12.34V20.98C18.44 21.1254 18.3454 21.22 18.2 21.22H6.68C6.53459 21.22 6.44 21.1254 6.44 20.98V12.34C6.44 12.1946 6.53459 12.1 6.68 12.1Z"
                  fill="#3CA5DC"
                />
              </svg>
              <div className="text-sky-700 text-base font-semibold font-poppins underline leading-tight">
                Change Password
              </div>
            </button>
            <div className="w-full h-px border border-gray-200 mt-4" />
          </div>
        )}
      </button>

      {showProfileModal && (
        <MyProfileModal
          user={user}
          showProfileModal={showProfileModal}
          setShowProfileModal={setShowProfileModal}
          setShowChangePassword={setShowChangePassword}
        />
      )}

      {showChangePassword && (
        <ChangePasswordModal
          showChangePassword={showChangePassword}
          setShowChangePassword={setShowChangePassword}
        />
      )}
    </>
  );
}
