import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import RadioIcon from "../components/RadioIcon";
import toast from "react-hot-toast";
import iphoneFrame from "../assets/Images/MedicalConcierge/iphoneFrame.svg";
import planPreviewBackground from "../assets/Images/MedicalConcierge/planPreviewBackground.png";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import initialPayment from "../assets/Images/MedicalConcierge/initialPayment.svg";
import member from "../assets/Images/MedicalConcierge/member.svg";
import interaction from "../assets/Images/MedicalConcierge/interaction.svg";
import service from "../assets/Images/MedicalConcierge/features.svg";
import Dropdown from "../components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ModalComponent from "../components/Modal";
import deleteIcon from "../assets/Images/MedicalConcierge/deleteRedIcon.svg";
import imageCompression from "browser-image-compression";
import { uploadOrganizationLogo } from "../services/operations/masterDataAPI";
import upload_image_icon from "../assets/Icons/upload_image_icon.svg";
import CheckboxIcon from "../components/CheckBoxIcon";
import { rewardTypeList } from "../data/rewardType";
import {
  addRewardOffer,
  updateRewardOffer,
  uploadRewardImage,
} from "../services/operations/rewardManagementAPI";
import rewardFrontBg from "../assets/Images/RewardManagement/rewardFrontBg.png";
import iphoneIsland from "../assets/Images/OurServices/iphoneIsland.png";
import { PiHeartbeatBold } from "react-icons/pi";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Tile1 from "../assets/Images/RewardManagement/Tile1.png";
import labTestPreviewIcon from "../assets/Images/RewardManagement/labTestPreviewIcon.png";
import merchandisePreviewIcon from "../assets/Images/RewardManagement/merchandisePreviewIcon.png";
import treatmentPreviewIcon from "../assets/Images/RewardManagement/treatmentPreviewIcon.png";
import starIcon from "../assets/Images/RewardManagement/starIcon.png";
import coin from "../assets/Images/RewardManagement/Coin.png";
import { IoIosArrowDown } from "react-icons/io";
import locationIcon from "../assets/Images/RewardManagement/locationIcon.png";
import basketShopIcon from "../assets/Images/RewardManagement/basketShopIcon.png";
import getDirectionIcon from "../assets/Images/RewardManagement/getDirectionIcon.png";
const Form = ({
  showBackView,
  setShowBackView,
  formData,
  setFormData,
  errors,
  setErrors,
  discountType,
  setDiscountType,
  offerType,
  setOfferType,
  isViewing,
  image,
  setImage,
  allBranches,
  reward,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  function handleOnChange(event) {
    const { name, value } = event.target;
    let maxLength;

    // Set the maxLength based on the field name and discountType
    switch (name) {
      case "StandardPrice":
        maxLength = 6;
        break;
      case "DiscountInput":
        if (discountType === "Percentage") {
          maxLength = 4; // Max length for 99.9
        } else if (discountType === "Price Reduction") {
          const standardPrice = String(formData.StandardPrice);
          const standardPriceLength = standardPrice.length;
          maxLength = standardPriceLength; // One less than the length of StandardPrice
        } else {
          maxLength = 6;
        }
        break;
      case "ShippingPrice":
        maxLength = 6;
        break;
      default:
        maxLength = Infinity;
    }

    // For other types, handle validation without removing non-numeric characters
    if (value.length <= maxLength) {
      // Additional validation for DiscountInput when discountType is Price Reduction
      if (name === "DiscountInput" && discountType === "Price Reduction") {
        // const standardPrice = parseInt(formData.StandardPrice);
        // const discountValue = parseInt(value);
        // if (discountValue >= standardPrice) {
        //   setErrors((prev) => ({
        //     ...prev,
        //     [name]: "Discount amount must be less than the standard price",
        //   }));
        //   return;
        // }
      }

      // Additional validation for DiscountInput when discountType is Percentage
      if (name === "DiscountInput" && discountType === "Percentage") {
        // const discountPercentage = parseInt(value);
        // if (discountPercentage >= 100) {
        //   setErrors((prev) => ({
        //     ...prev,
        //     [name]: "Discount percentage must be less than 100%",
        //   }));
        //   return;
        // }
      }

      // Handle case when discountType is FREE
      if (name === "DiscountInput" && discountType === "Free") {
        setFormData((prev) => ({
          ...prev,
          [name]: formData.StandardPrice,
        }));
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
        return;
      }

      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }

    if (name === "Description") {
      setShowBackView(true);
    } else {
      setShowBackView(false);
    }
  }

  function handleSelect(name, option) {
    setFormData((prev) => ({
      ...prev,
      [name]: option,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const toastId = toast.loading("Compressing Image...");
    const compressedFile = await imageCompression(file, options);
    toast.dismiss(toastId);
    uploadImage(compressedFile);
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("RewardImage", file);
    try {
      const res = await uploadRewardImage(token, formData);
      setFormData((prev) => ({
        ...prev,
        RewardImage: res?.RewardImageURL,
      }));
      setErrors((prev) => ({
        ...prev,
        RewardImage: "",
      }));
      setImage(URL.createObjectURL(file));
    } catch (error) {
      console.log(error);
    }
  };

  const calculateOfferPrice = () => {
    let offerPrice = formData.StandardPrice;
    if (discountType === "Free") {
      offerPrice = 0;
    } else if (discountType === "Price Reduction") {
      if (formData.DiscountInput) {
        offerPrice -= parseInt(formData.DiscountInput);
      }
    } else if (discountType === "Percentage") {
      if (formData.DiscountInput) {
        offerPrice -=
          (formData.StandardPrice * parseInt(formData.DiscountInput)) / 100;
      }
    }
    return offerPrice;
  };

  const calculateTotalPrice = () => {
    let totalPrice = calculateOfferPrice();
    if (formData.ShippingPrice) {
      totalPrice += parseInt(formData.ShippingPrice);
    }
    return totalPrice;
  };

  const handleBranchChange = (branchId) => {
    setFormData((prev) => {
      const updatedBranches = prev.Branches.includes(branchId)
        ? prev.Branches.filter((id) => id !== branchId)
        : [...prev.Branches, branchId];
      return { ...prev, Branches: updatedBranches };
    });
  };

  const submitHandler = async (x) => {
    if (x.status !== "DRAFT") {
      const mandatoryFields = [
        "RewardImage",
        "RewardType",
        "Title",
        "Description",
        "StandardPrice",
        "DiscountInput",
        // "ShippingPrice",
      ];
      const newErrors = {};

      mandatoryFields.forEach((key) => {
        const value = formData[key];

        if (!value) {
          newErrors[key] = "This field is required";
        } else if (typeof value === "string" && value.trim() === "") {
          newErrors[key] = "This field cannot be empty";
        }
      });

      // Additional validation for OFFLINE offerType
      if (
        offerType === "OFFLINE" &&
        (!formData.Branches || formData.Branches.length === 0)
      ) {
        newErrors.Branches =
          "Please select at least one branch for offline offers";
      }

      if (Object.keys(newErrors).length > 0) {
        toast.error("Please fill all the required fields");
        setErrors(newErrors);
        console.log("ERRORS", newErrors);
        return;
      }

      if (discountType === "Price Reduction") {
        if (
          parseInt(formData.DiscountInput) >= parseInt(formData.StandardPrice)
        ) {
          setErrors((prev) => ({
            ...prev,
            DiscountInput:
              "Discount amount must be less than the standard price",
          }));
          toast.error("Discount amount must be less than the standard price");
          return;
        }
      }
    }

    const body = {
      RewardImageURL: formData.RewardImage,
      TypeOfReward: formData.RewardType,
      Title: formData.Title,
      Description: formData.Description,
      Currency: "CHF",
      StandardPrice: parseInt(formData.StandardPrice),
      OfferPrice: parseInt(calculateOfferPrice()),
      TypeOfDiscount: discountTypeMapping[discountType],
      DiscountInput: parseInt(formData.DiscountInput),
      TypeOfOffer: offerType,
      TotalPrice: parseInt(calculateTotalPrice()),
      Status: x.status,
      ...(offerType === "OFFLINE" && {
        Branches: formData.Branches,
        ShippingPrice: parseInt(formData.ShippingPrice),
      }),
    };

    if (reward?.RewardID) {
      dispatch(updateRewardOffer(token, reward?.RewardID, body, navigate));
    } else {
      dispatch(addRewardOffer(token, body, navigate));
    }
  };

  return (
    <div className="w-1/2">
      <div className="bg-white rounded-lg shadow-md p-6 border border-sky-500">
        {/* Reward Image */}
        <div className="mb-4">
          <label
            className={` border-dashed ${
              errors.RewardImage ? "border-red-500" : "border-zinc-500"
            }  border-2 rounded-md p-4 flex flex-col items-center justify-center cursor-pointer`}
          >
            {formData?.RewardImage ? (
              <>
                <div className="w-20 h-20 shadow-lg rounded-full shadow-neutral-400">
                  <img
                    src={image}
                    alt="upload icon"
                    className="w-full h-full object-cover rounded-full"
                  />
                </div>
                {!isViewing && (
                  <div className="px-3 py-1.5 bg-sky-100 rounded-2xl justify-center items-center inline-flex cursor-pointer mt-3">
                    <p className="text-sky-400 text-xs font-semibold font-open">
                      Change Image
                    </p>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="w-14 h-14 bg-slate-100 rounded-full flex justify-center items-center">
                  <img
                    src={upload_image_icon}
                    alt=""
                    className="w-6 h-9 object-contain cursor-pointer"
                  />
                </div>
                <div className="text-center text-stone-950 text-sm font-medium font-poppins leading-none mt-2">
                  Add Logo/ Product Image
                </div>
                <div className="text-center text-neutral-600 text-xs font-normal font-poppins leading-3 mt-1">
                  File : JPG, PNG, SVG Max 5 MB
                </div>
              </>
            )}
            <input
              type="file"
              accept="image/*"
              name="Image"
              //   disabled={isViewing}
              value={undefined}
              onChange={(e) => handleImageChange(e)}
              style={{ display: "none" }}
            />
          </label>
        </div>
        {/* Reward Type */}
        <div className="mb-4">
          <label>
            <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
              Reward Type
              <sup className="text-red-600">*</sup>
            </p>
            <Dropdown
              error={errors.RewardType}
              name="RewardType"
              placeholder={"Select Reward Type"}
              options={rewardTypeList}
              onSelect={handleSelect}
              value={formData.RewardType}
              labelKey={"label"}
              valueKey={"value"}
            />
          </label>
        </div>
        {/* Reward Title */}
        <div className="mb-4">
          <label>
            <div className="flex items-center justify-between">
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                Reward Title
                <sup className="text-red-600">*</sup>
              </p>
              <p className="text-right text-neutral-600 text-xs font-normal font-poppins">
                {formData?.Title.length}/20
              </p>
            </div>
            <input
              type="text"
              name="Title"
              value={formData.Title}
              onChange={handleOnChange}
              maxLength={20}
              placeholder="Enter Reward Title"
              className={`w-full ${
                errors.Title ? "border-red-500" : ""
              }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
            />
          </label>
        </div>
        {/* Reward Description */}
        <div className="mb-4">
          <label>
            <div className="flex items-center justify-between">
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                Reward Description
                <sup className="text-red-600">*</sup>
              </p>
              <p className="text-right text-neutral-600 text-xs font-normal font-poppins">
                {formData?.Description.length}/100
              </p>
            </div>
            <textarea
              type="text"
              name="Description"
              value={formData.Description}
              onChange={handleOnChange}
              maxLength={100}
              placeholder="Provide some description about the Reward"
              className={`w-full ${
                errors.Description ? "border-red-500" : ""
              }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
            />
          </label>
        </div>
        {/* Standard Price*/}
        <div className="mb-4">
          <label>
            <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
              Set Price
              <sup className="text-red-600">*</sup>
            </p>
            <input
              type="number"
              name="StandardPrice"
              onKeyDown={(e) => {
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
              onWheel={(e) => {
                e.preventDefault();
                e.target.blur();
              }}
              value={formData.StandardPrice}
              onChange={handleOnChange}
              placeholder="Ex:  CHF 1500"
              className={`w-full ${
                errors.StandardPrice ? "border-red-500" : ""
              }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
            />
          </label>
        </div>
        {/* Type of Discount */}
        <div className="mb-2.5">
          <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
            Type of Discount
            <sup className="text-red-600">*</sup>
          </p>
          <div className="flex items-center space-x-4">
            {["Percentage", "Price Reduction", "Free"].map((option) => (
              <label key={option} className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  className="hidden"
                  checked={discountType === option}
                  onChange={() => {
                    if (option === "Free") {
                      setDiscountType(option);
                      setFormData((prev) => ({
                        ...prev,
                        DiscountInput: formData.StandardPrice || 0,
                      }));
                      setErrors((prev) => ({
                        ...prev,
                        DiscountInput: "",
                      }));
                    } else {
                      setDiscountType(option);
                      setFormData((prev) => ({
                        ...prev,
                        DiscountInput: "",
                      }));
                      setErrors((prev) => ({
                        ...prev,
                        DiscountInput: "",
                      }));
                    }
                  }}
                />
                <RadioIcon selected={discountType === option} />
                <span className="text-zinc-900 text-sm font-medium font-poppins leading-none">
                  {option}
                </span>
              </label>
            ))}
          </div>
        </div>
        {/* Discount Input*/}
        {discountType !== "Free" && (
          <div className="mb-4">
            <label>
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                {discountType === "Price Reduction"
                  ? "Discount Amount"
                  : "Discount Percentage"}
                <sup className="text-red-600">*</sup>
              </p>
              <input
                type="number"
                name="DiscountInput"
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault();
                  }
                }}
                onWheel={(e) => {
                  e.preventDefault();
                  e.target.blur();
                }}
                value={formData.DiscountInput}
                onChange={handleOnChange}
                placeholder={
                  discountType === "Price Reduction" ? "Ex: CHF 100" : "Ex: 10%"
                }
                className={`w-full ${
                  errors.DiscountInput ? "border-red-500" : ""
                }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>
        )}
        {/* Offer Price */}
        <div className="mb-4 flex flex-row items-center space-x-2">
          <div className="text-zinc-950 text-xs font-semibold font-poppins">
            Offer Price (Auto Calculated):
          </div>
          <div className="text-neutral-600 text-sm font-normal font-poppins">
            CHF {calculateOfferPrice()}
          </div>
        </div>
        {/* Type of Offer */}
        <div className="mb-4">
          <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
            Type of Offer
            <sup className="text-red-600">*</sup>
          </p>
          <div className="flex items-center space-x-4">
            {["ONLINE", "OFFLINE"].map((option) => (
              <label key={option} className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  className="hidden"
                  checked={offerType === option}
                  onChange={() => {
                    setOfferType(option);
                    setShowBackView(true);
                  }}
                />
                <RadioIcon selected={offerType === option} />
                <span className="text-zinc-900 text-sm font-medium font-poppins leading-none capitalize">
                  {option.toLowerCase()}
                </span>
              </label>
            ))}
          </div>
        </div>
        {/* Branch Address */}
        {offerType === "OFFLINE" && (
          <>
            <div className="flex flex-row items-center justify-between mb-2">
              <div className="text-zinc-900 text-sm font-medium font-poppins">
                Address
              </div>
              <button
                onClick={() => navigate("/serviceManagement/branchManagement")}
                className="text-sky-700 text-sm font-semibold font-poppins leading-none"
              >
                Maintain Branches
              </button>
            </div>
            <div className="mb-4">
              <div className="grid grid-cols-3 gap-4">
                {allBranches.map((branch) => (
                  <div
                    key={branch.BranchID}
                    className="p-4 bg-gray-100 rounded-lg border"
                  >
                    <label className="flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="hidden"
                        checked={formData.Branches.includes(branch.BranchID)}
                        onChange={() => {
                          setShowBackView(true);
                          handleBranchChange(branch.BranchID);
                        }}
                      />
                      <CheckboxIcon
                        checked={formData.Branches.includes(branch.BranchID)}
                      />
                      <span className="ml-2 text-zinc-900 text-sm font-normal font-poppins">
                        {branch.Name}
                      </span>
                    </label>
                    <p className="ml-7 mt-2 text-neutral-600 text-sm font-normal font-poppins line-clamp-3">
                      {branch.Address}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            {/* Shipping Price*/}
            <div className="mb-4">
              <label>
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Set Shipping Price
                  {/* <sup className="text-red-600">*</sup> */}
                </p>
                <input
                  type="number"
                  name="ShippingPrice"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  value={formData.ShippingPrice}
                  onChange={handleOnChange}
                  placeholder="Ex:  CHF 100"
                  className={`w-full ${
                    errors.ShippingPrice ? "border-red-500" : ""
                  }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
          </>
        )}
      </div>
      <div className="flex flex-row items-center justify-center mt-6 space-x-4">
        <button
          onClick={() => {
            if (reward?.RewardID) {
              navigate(-1);
            } else {
              submitHandler({ status: "DRAFT" });
            }
          }}
          className="flex-1 px-5 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
        >
          <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
            {reward?.RewardID ? "Cancel" : "Save as Draft"}
          </div>
        </button>
        <button
          onClick={() => submitHandler({ status: "ACTIVE" })}
          className="flex-1 px-5 py-3 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-500 active:scale-95"
        >
          <div className="text-white text-base font-semibold font-poppins leading-normal">
            {reward?.RewardID ? "Save Changes" : "Save & Publish"}
          </div>
        </button>
      </div>
    </div>
  );
};

const PhoneMockup = ({ children, showBackView, setShowBackView }) => {
  return (
    <div
      onClick={() => setShowBackView && setShowBackView(!showBackView)}
      className="relative mx-auto w-[300px] h-[600px]"
    >
      <img
        src={iphoneFrame}
        alt="iPhone Frame"
        className="w-full h-full object-contain"
      />
      {/* <div className="absolute inset-[9%] overflow-hidden">{children}</div> */}
      <div className="absolute inset-y-[1.6%] left-[5%] right-[5.8%] overflow-hidden rounded-[40px]">
        {children}
      </div>
    </div>
  );
};

const PreviewSection = ({
  showBackView,
  setShowBackView,
  formData,
  discountType,
  offerType,
  image,
  allBranches,
  reward,
}) => {
  return (
    <div className="w-1/2">
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="text-center text-zinc-900 text-xl font-semibold font-poppins mb-7">
          Preview for Patient
        </div>
        <div className="relative flex items-center justify-center h-[650px]">
          <div>
            <div
              className={`absolute top-1 left-1/3 transition-all duration-300`}
            >
              <PhoneMockup
                showBackView={showBackView}
                setShowBackView={setShowBackView}
              >
                {showBackView ? (
                  <FrontView
                    showBackView={showBackView}
                    setShowBackView={setShowBackView}
                    formData={formData}
                    discountType={discountType}
                    offerType={offerType}
                    image={image}
                    allBranches={allBranches}
                    reward={reward}
                  />
                ) : (
                  <BackView
                    showBackView={showBackView}
                    setShowBackView={setShowBackView}
                    formData={formData}
                    discountType={discountType}
                    offerType={offerType}
                    image={image}
                    allBranches={allBranches}
                    reward={reward}
                  />
                )}
              </PhoneMockup>
            </div>
            <div
              className={`absolute top-12 left-1/4 transition-all duration-300`}
            >
              <PhoneMockup>
                {showBackView ? (
                  <BackView
                    showBackView={showBackView}
                    setShowBackView={setShowBackView}
                    formData={formData}
                    discountType={discountType}
                    offerType={offerType}
                    image={image}
                    allBranches={allBranches}
                    reward={reward}
                  />
                ) : (
                  <FrontView
                    showBackView={showBackView}
                    setShowBackView={setShowBackView}
                    formData={formData}
                    discountType={discountType}
                    offerType={offerType}
                    image={image}
                    allBranches={allBranches}
                    reward={reward}
                  />
                )}
              </PhoneMockup>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center space-x-10 mt-5">
          <div
            onClick={() => setShowBackView(false)}
            className={`w-10 h-10 ${
              !showBackView
                ? "bg-white border border-neutral-300"
                : "bg-sky-100 border border-sky-400"
            } rounded-full flex items-center justify-center`}
          >
            <IoChevronBack
              className={`${
                !showBackView
                  ? "text-black cursor-not-allowed"
                  : "text-sky-400 cursor-pointer"
              } `}
            />
          </div>
          <div
            onClick={() => setShowBackView(true)}
            className={`w-10 h-10 ${
              showBackView
                ? "bg-white border border-neutral-300"
                : "bg-sky-100 border border-sky-400"
            } rounded-full flex items-center justify-center`}
          >
            <IoChevronForward
              className={`${
                showBackView
                  ? "text-black cursor-not-allowed"
                  : "text-sky-400 cursor-pointer"
              } `}
            />
          </div>
        </div>
        <div className="w-full bg-gray-100 rounded-lg mt-7 p-2.5 flex items-center justify-center">
          <div className="w-96 text-center text-black text-xl font-normal font-poppins">
            This is how your offer will look to Patients on the App
          </div>
        </div>
      </div>
    </div>
  );
};

const FrontView = ({
  showBackView,
  setShowBackView,
  formData,
  discountType,
  offerType,
  image,
  allBranches,
  reward,
}) => {
  const calculateOfferPrice = () => {
    let offerPrice = formData.StandardPrice;
    if (discountType === "Free") {
      offerPrice = 0;
    } else if (discountType === "Price Reduction") {
      if (formData.DiscountInput) {
        offerPrice -= parseInt(formData.DiscountInput);
      }
    } else if (discountType === "Percentage") {
      if (formData.DiscountInput) {
        offerPrice -=
          (formData.StandardPrice * parseInt(formData.DiscountInput)) / 100;
      }
    }
    return offerPrice;
  };
  return (
    <div
      // onClick={() => setShowBackView(false)}
      className="h-full flex flex-col justify-between bg-cover relative"
      style={{ backgroundImage: `url(${rewardFrontBg})` }}
    >
      <img
        src={iphoneIsland}
        alt="iphone Island"
        className="w-16 h-6 object-contain absolute top-4 left-[6.5rem]"
      />
      <div className="flex flex-col justify-center items-start bg-white rounded-[10px] absolute bottom-[70px] right-[6px] w-[124px] p-1">
        <div className="flex flex-row justify-between items-center w-full">
          <img
            src={image ? image : Tile1}
            alt="Reward Image"
            className="w-10 h-10 object-cover rounded-full"
          />
          <div className="flex flex-row justify-center items-center bg-[#E5F2FF] rounded-full w-6 h-6">
            <IoIosInformationCircleOutline className="text-[#3CA5DC] text-lg" />
          </div>
        </div>

        {/* Product Name */}
        <h1 className="text-[#000000] text-[10px] font-semibold font-poppins mt-2">
          {formData?.Title || "Product Name"}
        </h1>

        {/* Using AH coin */}
        <div className="flex flex-row justify-start items-center w-full">
          <img
            src={coin}
            alt="Coin Icon"
            className="w-3 h-3 object-contain rounded-full"
          />
          <h1 className="text-[#ACACAC] text-[10px] font-normal font-poppins mx-1">
            Using
          </h1>
          <h1 className="text-[#3CA5DC] text-[10px] font-normal font-poppins">
            10 AH
          </h1>
        </div>

        {/* Reward Type */}
        <div
          className={`flex flex-row justify-center items-center mt-2 px-1 py-[2px] ${
            formData?.RewardType === "STORES"
              ? "bg-[#FDECD3]"
              : formData?.RewardType === "LABORATORY"
              ? "bg-[#E5F2FF]"
              : "bg-[#ECFFD0]"
          } rounded-[10px] gap-1`}
        >
          <img
            src={
              formData?.RewardType === "STORES"
                ? merchandisePreviewIcon
                : formData?.RewardType === "LABORATORY"
                ? labTestPreviewIcon
                : treatmentPreviewIcon
            }
            alt={
              formData?.RewardType === "STORES"
                ? merchandisePreviewIcon
                : formData?.RewardType === "LABORATORY"
                ? labTestPreviewIcon
                : treatmentPreviewIcon
            }
            className="w-3 h-3 object-contain"
          />
          <h1
            className={`${
              formData?.RewardType === "STORES"
                ? "text-[#F7A025]"
                : formData?.RewardType === "LABORATORY"
                ? "text-[#3CA5DC]"
                : "text-[#86D511]"
            } text-[8px] font-normal font-poppins`}
          >
            {formData?.RewardType === "STORES"
              ? "Merchandise"
              : formData?.RewardType === "LABORATORY"
              ? "Lab Test"
              : "Treatment"}
          </h1>
        </div>

        {/* Offer Type */}
        <h1 className="text-[#ACACAC] text-[8px] font-normal font-poppins mt-2">
          {offerType === "ONLINE" ? "Online" : "2 KMs away"}
        </h1>

        {/* Price */}
        <div className="flex flex-row justify-between items-center w-full mt-2">
          <div className="flex flex-row justify-start items-center w-full gap-1">
            <p className="text-[#1C1C1C] text-[9px] font-semibold font-poppins">
              CHF {calculateOfferPrice()}
            </p>
            <p className="text-[#ACACAC] text-[7px] font-semibold font-poppins line-through">
              CHF {formData?.StandardPrice || 0}
            </p>
          </div>
          <div className="flex flex-row justify-center items-center gap-1">
            <img
              src={starIcon}
              alt="Star Icon"
              className="w-3 h-3 object-contain mb-1"
            />
            <h1 className="text-[#F7A025] text-[8px] font-normal font-poppins">
              5.0
            </h1>
          </div>
        </div>

        {/* Discount */}
        <div
          className={`w-full flex flex-row justify-center items-center mt-1 p-1 ${
            discountType === "Price Reduction" || discountType === "Percentage"
              ? "bg-[#FDECD3]"
              : "bg-[#E5F2FF]"
          } rounded-[10px] gap-1`}
        >
          {discountType === "Price Reduction" ||
          discountType === "Percentage" ? (
            <h1 className={`text-[#F7A025] text-sm font-bold font-poppins`}>
              {discountType === "Price Reduction"
                ? "CHF " + formData?.DiscountInput + " Off"
                : formData?.DiscountInput + " % Off"}
            </h1>
          ) : (
            <h1 className={`text-[#3CA5DC] text-sm font-bold font-poppins`}>
              Free
            </h1>
          )}
        </div>
      </div>
    </div>
  );
};

const BackView = ({
  showBackView,
  setShowBackView,
  formData,
  discountType,
  offerType,
  image,
  allBranches,
  reward,
}) => {
  const calculateOfferPrice = () => {
    let offerPrice = formData.StandardPrice;
    if (discountType === "Free") {
      offerPrice = 0;
    } else if (discountType === "Price Reduction") {
      if (formData.DiscountInput) {
        offerPrice -= parseInt(formData.DiscountInput);
      }
    } else if (discountType === "Percentage") {
      if (formData.DiscountInput) {
        offerPrice -=
          (formData.StandardPrice * parseInt(formData.DiscountInput)) / 100;
      }
    }
    return offerPrice;
  };
  return (
    <div className="h-full w-full flex flex-col justify-between relative bg-cover">
      {/* Card Content */}
      <div className="absolute top-10 flex flex-col justify-center items-start w-full rounded-xl bg-white py-3 px-5">
        {/*  */}
        {image ? (
          <img
            src={image ? image : Tile1}
            alt="Reward Image"
            className="w-full h-36 object-cover rounded-lg"
          />
        ) : (
          <div className="w-full h-36 rounded-lg bg-[#888888] animate-pulse"></div>
        )}

        <div className="flex flex-row justify-between items-center w-full gap-2 mt-4">
          <h1 className="text-[#1C1C1C] text-sm font-semibold font-poppins">
            {formData?.Title || "Product Name"}
          </h1>
          <h1 className="text-[#000000] text-[10px] font-normal font-poppins">
            Partner Rating
          </h1>
        </div>
        <div className="flex flex-row justify-between items-center w-full gap-2 mt-1 relative">
          {formData?.StandardPrice ? (
            <div className="flex flex-row items-center space-x-1">
              <p className="text-[#1C1C1C] text-[9px] font-semibold font-poppins">
                CHF {calculateOfferPrice()}
              </p>
              <p className="text-[#ACACAC] text-[7px] font-semibold font-poppins line-through">
                CHF {formData?.StandardPrice || 0}
              </p>
            </div>
          ) : (
            <h1 className="text-[#1C1C1C] text-xs font-semibold font-poppins">
              -
            </h1>
          )}
          <div className="flex flex-row justify-center items-center gap-1">
            <IoIosArrowDown className="text-xs text-[#3CA5DC]" />
            <div className="flex flex-row space-x-[2px]">
              {Array(5)
                .fill()
                .map((_, index) => (
                  <img
                    key={index}
                    src={starIcon}
                    alt="Star Icon"
                    className="w-3 h-3 object-contain mb-1"
                  />
                ))}
            </div>
          </div>
          <h1 className="absolute -bottom-2 right-0 text-[#F7A025] text-[8px] font-normal font-poppins">
            5.0
          </h1>
        </div>

        {/* Discount */}
        <div
          className={`w-32 self-start flex flex-row justify-center items-center mt-3 p-1 ${
            discountType === "Price Reduction" || discountType === "Percentage"
              ? "bg-[#FDECD3]"
              : "bg-[#E5F2FF]"
          } rounded-[10px] gap-1`}
        >
          {discountType === "Price Reduction" ||
          discountType === "Percentage" ? (
            <h1 className={`text-[#F7A025] text-sm font-bold font-poppins`}>
              {discountType === "Price Reduction"
                ? "CHF " + formData?.DiscountInput + " Off"
                : formData?.DiscountInput + " % Off"}
            </h1>
          ) : (
            <h1 className={`text-[#3CA5DC] text-sm font-bold font-poppins`}>
              Free
            </h1>
          )}
        </div>

        <h1 className="text-[#000000] text-[10px] font-normal font-poppins mt-4">
          Partner Name
        </h1>

        <h1 className="text-[#000000] text-[10px] font-semibold font-poppins mt-1">
          Accurate Health Labs
        </h1>

        <h1 className="text-[#1C1C1C] text-[8px] font-normal font-poppins mt-2">
          Location:
        </h1>

        {offerType === "ONLINE" ? (
          <div className="flex flex-row justify-center items-center bg-[#E5F2FF] rounded-md p-1 mt-1">
            <h1 className="text-[#3CA5DC] text-[9px] font-normal font-poppins">
              Online
            </h1>
          </div>
        ) : (
          <>
            {allBranches
              .filter((branch) => formData?.Branches.includes(branch.BranchID))
              .map((branch) => (
                <div
                  key={branch.BranchID}
                  className="flex flex-row justify-start items-center w-full mt-1 gap-2"
                >
                  <img
                    src={locationIcon}
                    alt="Location Icon"
                    className="w-8 h-8 object-contain"
                  />
                  <div className="flex flex-col justify-center items-start">
                    <h1 className="text-[#000000] text-[10px] font-semibold font-poppins">
                      {branch?.Address || "-"}
                    </h1>
                    <h1 className="text-[#888888] text-[7px] font-normal font-poppins mt-1">
                      - KMs Away
                    </h1>
                  </div>
                  <div className="flex flex-col justify-center items-center ml-auto">
                    <img
                      src={getDirectionIcon}
                      alt="Get Direction Icon"
                      className="w-5 h-5 object-contain"
                    />
                    <h1 className="text-[#979797] text-[7px] font-normal font-poppins">
                      Get directions
                    </h1>
                  </div>
                </div>
              ))}
          </>
        )}

        <div className="h-[6px] w-full bg-[#F3F5F7] my-2"></div>

        <h1 className="text-[#000000] text-xs font-semibold font-poppins">
          Description
        </h1>
        <h1 className="text-[#545454] text-[9px] font-normal font-poppins mt-2 break-words w-full">
          {formData?.Description ||
            "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facilis quidem porro blanditiis quasi accusamus itaque. Ab necessitatibus"}
        </h1>

        <div className="absolute -bottom-16 flex flex-row justify-start items-center w-full gap-1">
          <div className="w-[42%] flex flex-col justify-center items-center bg-[#D1D1D1] rounded-lg p-2">
            <h1 className="text-[#F8F9FA] text-[9px] font-semibold font-poppins">
              Back
            </h1>
          </div>
          <div className="w-[42%] flex flex-row justify-center items-center bg-[#3CA5DC] rounded-lg p-2 px-1">
            <img
              src={basketShopIcon}
              alt="Basket Shop Icon"
              className="w-3 h-3 object-contain"
            />
            <h1 className="text-[#F8F9FA] text-[9px] font-semibold font-poppins">
              (1) Add to Basket
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

function CreateRewardOffer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();
  const reward = location.state || {};
  const { allBranches } = useSelector((state) => state.masterData);
  const [showBackView, setShowBackView] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isViewing, setIsViewing] = useState(false);
  const [image, setImage] = useState("");
  const [discountType, setDiscountType] = useState("Price Reduction");
  const [offerType, setOfferType] = useState("ONLINE");
  const [formData, setFormData] = useState({
    RewardImage: "",
    RewardType: "",
    Title: "",
    Description: "",
    StandardPrice: "",
    DiscountInput: "0",
    OfferPrice: "",
    Branches: [],
    ShippingPrice: "",
  });
  const [errors, setErrors] = useState({
    RewardImage: "",
    RewardType: "",
    Title: "",
    Description: "",
    StandardPrice: "",
    DiscountInput: "",
    OfferPrice: "",
    Branches: "",
    ShippingPrice: "",
  });

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteReward = () => {
    dispatch(
      updateRewardOffer(
        token,
        reward?.RewardID,
        {
          Status: "DELETED",
        },
        navigate
      )
    );
    closeDeleteModal();
  };

  useEffect(() => {
    if (reward?.RewardID) {
      const {
        RewardImageURL,
        TypeOfReward,
        Title,
        Description,
        StandardPrice,
        OfferPrice,
        TypeOfDiscount,
        DiscountInput,
        TypeOfOffer,
        ShippingPrice,
        Branches,
        Image,
      } = reward;
      setFormData({
        RewardImage: RewardImageURL,
        RewardType: TypeOfReward,
        Title,
        Description,
        StandardPrice: StandardPrice,
        // OfferPrice: OfferPrice.toString(),
        DiscountInput: DiscountInput ? DiscountInput : 0,
        ShippingPrice: ShippingPrice,
        Branches: Branches || [],
      });
      setDiscountType(
        TypeOfDiscount === "PERCENTAGE"
          ? "Percentage"
          : TypeOfDiscount === "FREE"
          ? "Free"
          : "Price Reduction"
      );
      setOfferType(TypeOfOffer);
      setImage(Image);
    }
  }, [reward]);

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      <ModalComponent
        show={showDeleteModal}
        handleClose={closeDeleteModal}
        outSideModalPress={false}
        showCloseButton={false}
      >
        <div className="flex flex-col items-center">
          <div className="w-14 h-14 p-3 bg-red-50 rounded-full mb-6">
            <img src={deleteIcon} alt="" />
          </div>
          <div className="text-center text-slate-800 text-base font-semibold font-poppins leading-tight">
            Delete Reward '{formData?.Title}'?
          </div>
          <div className="text-center max-w-sm mx-auto text-zinc-500 text-sm font-normal font-poppins leading-tight mt-4">
            Are you sure you want to delete this reward? This action cannot be
            undone.
          </div>
          <div className="flex flex-row items-center justify-end mt-6 space-x-4">
            <button
              onClick={closeDeleteModal}
              className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
                Cancel
              </div>
            </button>
            <button
              onClick={handleDeleteReward}
              className="w-48 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-white text-base font-semibold font-poppins leading-normal">
                Delete
              </div>
            </button>
          </div>
        </div>
      </ModalComponent>
      {/* Header */}
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        {reward?.RewardID ? "Edit Reward" : "Create Reward"}
      </div>
      <Breadcrumb />
      <div className="flex gap-8 p-8">
        <Form
          showBackView={showBackView}
          setShowBackView={setShowBackView}
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          discountType={discountType}
          setDiscountType={setDiscountType}
          offerType={offerType}
          setOfferType={setOfferType}
          isViewing={isViewing}
          image={image}
          setImage={setImage}
          allBranches={allBranches}
          reward={reward}
        />
        <PreviewSection
          showBackView={showBackView}
          setShowBackView={setShowBackView}
          formData={formData}
          discountType={discountType}
          offerType={offerType}
          image={image}
          allBranches={allBranches}
          reward={reward}
        />
      </div>
      {reward?.RewardID && (
        <div className="flex justify-end pr-8">
          <button
            onClick={() => setShowDeleteModal(true)}
            className="text-rose-700 text-base font-normal font-poppins leading-tight active:scale-95"
          >
            Delete Reward
          </button>
        </div>
      )}
    </div>
  );
}

export default CreateRewardOffer;

const discountTypeMapping = {
  "Price Reduction": "REDUCTION",
  Percentage: "PERCENTAGE",
  Free: "FREE",
};
