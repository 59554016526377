export const adherence = [
    {
      medicineName: "Insulin",
      duration: "12 weeks",
      intakeFrequency: "2 times a day",
      intakeRecords: [
        {
          date: "2024-09-01",
          doses: [
            {
              time: "morning",
              status: "taken",
              timestamp: "2024-09-01T08:00:00",
            },
            {
              time: "evening",
              status: "missed",
              timestamp: null,
            },
          ],
        },
        {
          date: "2024-09-02",
          doses: [
            {
              time: "morning",
              status: "delayed",
              timestamp: "2024-09-02T09:00:00",
            },
            {
              time: "evening",
              status: "taken",
              timestamp: "2024-09-02T20:00:00",
            },
          ],
        },
        {
          date: "2024-09-03",
          doses: [
            {
              time: "morning",
              status: "taken",
              timestamp: "2024-09-03T08:00:00",
            },
            {
              time: "evening",
              status: "missed",
              timestamp: null,
            },
          ],
        },
        {
          date: "2024-09-04",
          doses: [
            {
              time: "morning",
              status: "taken",
              timestamp: "2024-09-04T08:00:00",
            },
            {
              time: "evening",
              status: "delayed",
              timestamp: "2024-09-04T21:00:00",
            },
          ],
        },
        // Continue till today's date (2024-09-19)
        {
          date: "2024-09-19",
          doses: [
            {
              time: "morning",
              status: "taken",
              timestamp: "2024-09-19T08:30:00",
            },
            {
              time: "evening",
              status: "pending",
              timestamp: null,
            },
          ],
        },
      ],
    },
    {
      medicineName: "Sertranin",
      duration: "8 weeks",
      intakeFrequency: "1 time a day",
      intakeRecords: [
        {
          date: "2024-09-01",
          doses: [
            {
              time: "morning",
              status: "taken",
              timestamp: "2024-09-01T07:00:00",
            },
          ],
        },
        {
          date: "2024-09-02",
          doses: [
            {
              time: "morning",
              status: "missed",
              timestamp: null,
            },
          ],
        },
        {
          date: "2024-09-03",
          doses: [
            {
              time: "morning",
              status: "delayed",
              timestamp: "2024-09-03T09:00:00",
            },
          ],
        },
        {
          date: "2024-09-04",
          doses: [
            {
              time: "morning",
              status: "taken",
              timestamp: "2024-09-04T07:30:00",
            },
          ],
        },
        // Continue till today's date (2024-09-19)
        {
          date: "2024-09-19",
          doses: [
            {
              time: "morning",
              status: "taken",
              timestamp: "2024-09-19T07:15:00",
            },
          ],
        },
      ],
    },
    {
      medicineName: "Rifampicin",
      duration: "10 weeks",
      intakeFrequency: "1 time a day",
      intakeRecords: [
        {
          date: "2024-09-01",
          doses: [
            {
              time: "evening",
              status: "taken",
              timestamp: "2024-09-01T19:00:00",
            },
          ],
        },
        {
          date: "2024-09-02",
          doses: [
            {
              time: "evening",
              status: "missed",
              timestamp: null,
            },
          ],
        },
        {
          date: "2024-09-03",
          doses: [
            {
              time: "evening",
              status: "taken",
              timestamp: "2024-09-03T19:30:00",
            },
          ],
        },
        {
          date: "2024-09-04",
          doses: [
            {
              time: "evening",
              status: "delayed",
              timestamp: "2024-09-04T21:00:00",
            },
          ],
        },
        // Continue till today's date (2024-09-19)
        {
          date: "2024-09-19",
          doses: [
            {
              time: "evening",
              status: "pending",
              timestamp: null,
            },
          ],
        },
      ],
    },
  ];