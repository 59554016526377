import React, { useEffect, useState, useRef, useCallback } from "react";
import Breadcrumb from "../components/Breadcrumb";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import upload_icon from "../assets/Images/PlatformManagement/upload_icon.svg";
import pdf_icon from "../assets/Icons/pdf_icon.svg";
import pdf_placeholder from "../assets/Images/PlatformManagement/pdf_placeholder.png";
import PdfComp from "../components/PdfComp";
import { updatePolicies } from "../services/operations/platformManagementAPI";

const Form = ({
  formData,
  setFormData,
  errors,
  setErrors,
  uploadedFile,
  setUploadedFile,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const validateFile = (file) => {
    const validTypes = ["application/pdf"];
    const maxSize = 10 * 1024 * 1024; // 10MB

    if (!validTypes.includes(file.type)) {
      alert("Only PDF files are allowed");
      return false;
    }

    if (file.size > maxSize) {
      alert("File size must be less than 10MB");
      return false;
    }

    return true;
  };

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (validateFile(file)) {
      setUploadedFile(file);
    }
  }, []);

  const handleFileInput = useCallback((e) => {
    const file = e.target.files[0];
    if (validateFile(file)) {
      setUploadedFile(file);
    }
  }, []);

  function handleOnChange(event) {
    const { name, value } = event.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  return (
    <div className="w-1/2">
      <div className="bg-white p-6">
        {/* Pdf Import */}
        <label
          className={`cursor-pointer bg-sky-50 rounded-3xl border border-dashed border-sky-400 flex flex-col items-center justify-center p-20 ${
            isDragging ? "shadow-xl shadow-sky-200" : "shadow"
          }`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {/* {uploadedFile ? (
            <>
              <img src={pdf_icon} alt="PDF Icon" />
              <div className="text-gray-800 text-xs font-semibold font-poppins mt-2">Change File</div>
            </>
          ) : ( */}
          <>
            <img src={upload_icon} alt="" />
            <div className="text-gray-800 text-xs font-semibold font-poppins mt-2">
              Upload Your Policy File here
            </div>
            <div className="text-zinc-500 text-xs font-medium font-poppins mt-1">
              Drag or click to upload
            </div>
          </>
          {/* )} */}
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            accept=".pdf"
            onChange={handleFileInput}
          />
        </label>

        {/* Title */}
        <div className="my-6">
          <label>
            <div className="flex items-center justify-between">
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                Document Title
                <sup className="text-red-600">*</sup>
              </p>
              <p className="text-right text-neutral-600 text-xs font-normal font-poppins">
                {formData?.title.length}/25
              </p>
            </div>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleOnChange}
              maxLength={25}
              placeholder="Ex: Policy Document"
              className={`w-full ${
                errors.title ? "border-red-500" : ""
              }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
            />
          </label>
        </div>
        {/* Name */}
        <div className="mb-16">
          <label>
            <div className="flex items-center justify-between">
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                Name of Policy Updater
                <sup className="text-red-600">*</sup>
              </p>
              <p className="text-right text-neutral-600 text-xs font-normal font-poppins">
                {formData?.name.length}/25
              </p>
            </div>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleOnChange}
              maxLength={25}
              placeholder="Ex: John Doe"
              className={`w-full ${
                errors.name ? "border-red-500" : ""
              }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
            />
          </label>
        </div>

        <div className="text-zinc-500 text-xs font-normal font-poppins leading-relaxed">
          Note: Once updated, the older file/ policy would be swapped with the
          latest updated one. Please be careful while updating the policies.
        </div>
      </div>
    </div>
  );
};

const PreviewSection = ({
  data,
  formData,
  uploadedFile,
  errors,
  setErrors,
}) => {
  const { user } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // submit handler
  const handleSubmit = () => {
    if (!formData.title) {
      setErrors((prev) => ({
        ...prev,
        title: "Title is required",
      }));
    }

    if (!formData.name) {
      setErrors((prev) => ({
        ...prev,
        name: "Name is required",
      }));
    }

    if (!uploadedFile) {
      toast.error("Please upload a file");
    }

    const fd = new FormData();
    fd.append("PlatFormManagerFiles", uploadedFile);
    fd.append("DocumentTitle", formData.title);
    fd.append("Description", data?.Description);
    fd.append("UploadedBy", formData.name);

    if (formData.title && formData.name && uploadedFile) {
      dispatch(updatePolicies(token, data?.PMPID, fd, navigate));
    }
  };
  return (
    <div className="w-1/2">
      <div className="text-gray-800 text-center text-base font-medium font-poppins leading-normal">
        Once Uploaded you can see the preview of your File
      </div>
      <div className="bg-gray-100 rounded-xl shadow-md p-6 pb-0 mt-4">
        <div className="text-center text-zinc-900 text-base font-semibold font-poppins mb-5">
          {formData.title ? formData.title : "Name of the Policy"}
        </div>
        <div className="w-full bg-white rounded-lg px-2.5 flex items-center justify-center">
          {uploadedFile ? (
            <PdfComp
              pdf={
                typeof uploadedFile === "string"
                  ? uploadedFile
                  : URL.createObjectURL(uploadedFile)
              }
              width="600px"
              height="500px"
            />
          ) : (
            <img
              src={pdf_placeholder}
              alt="PDF Placeholder"
              className="w-[600px] h-[500px] object-contain"
            />
          )}
        </div>
      </div>

      {uploadedFile && (
        <div className="flex flex-row items-center justify-center mt-6 space-x-4">
          <button
            onClick={() => navigate(-1)}
            className="flex-1 px-5 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
          >
            <div className="text-sky-500 text-base font-semibold font-poppins leading-normal">
              Cancel
            </div>
          </button>
          <button
            onClick={() => handleSubmit()}
            className="flex-1 px-5 py-3 flex items-center justify-center bg-sky-500 rounded-lg border border-sky-500 active:scale-95"
          >
            <div className="text-white text-base font-semibold font-poppins leading-normal">
              Save Policy
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

function UploadPolicy() {
  const location = useLocation();
  const data = location.state || {};
  const [uploadedFile, setUploadedFile] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    name: "",
  });
  const [errors, setErrors] = useState({});

  console.log("====================================");
  console.log(data);
  console.log("====================================");

  useEffect(() => {
    if (data) {
      setFormData({
        title: data.DocumentTitle || "",
        name: data.UploadedBy || "",
      });
      setUploadedFile(data.UploadedFile ? data.UploadedFile[0] : null);
    }
  }, [data]);

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      {/* Header */}
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        Platform Management
      </div>
      <Breadcrumb />
      <div className="flex gap-64 p-8">
        <Form
          data={data}
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
        />
        <PreviewSection
          data={data}
          formData={formData}
          uploadedFile={uploadedFile}
          errors={errors}
          setErrors={setErrors}
        />
      </div>
    </div>
  );
}

export default UploadPolicy;
