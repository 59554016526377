import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaMinus, FaChevronLeft } from "react-icons/fa6";
import Vertical_switch_light from "../assets/Images/TreatmentProgram/Vertical_switch_light.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getClinicCapacity,
  getInvoices,
  getSpecificInvoice,
  invoice,
} from "../services/operations/masterDataAPI";
import moment from "moment";
import ModalComponent from "../components/Modal";
import AHlogo from "../assets/Images/logo.png";
import Breadcrumb from "../components/Breadcrumb";

function ClinicCapacity() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { clinicCapacity } = useSelector((state) => state.masterData);
  const [isLoading, setIsLoading] = useState(true);
  const [numberOfDoc, setNumberOfDoc] = useState(null);
  const [totalHours, setTotalHours] = useState(null);
  const [calculatedCapacity, setCalculatedCapacity] = useState(null);

  useEffect(() => {
    const fetchClinicCapacity = async () => {
      if (token) {
        try {
          const res = await dispatch(getClinicCapacity(token));
        } catch (error) {
          console.error("Failed to fetch clinic capacity", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchClinicCapacity();
  }, [token, dispatch]);

  useEffect(() => {
    if (clinicCapacity) {
      setNumberOfDoc(clinicCapacity?.TotalDoctors);
      setTotalHours(clinicCapacity?.TotalHoursPerWeek);
      setCalculatedCapacity(clinicCapacity?.calculatedCapacity);
    }
  }, [clinicCapacity]);

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  const numberOfDocArray = String(numberOfDoc).split("");
  const totalHoursArray = String(totalHours).split("");
  const calculatedCapacityArray = String(calculatedCapacity).split("");

  return (
    <div className="bg-white min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
      <div className="bg-white rounded-lg shadow-lg p-6">
        {/* HEADER */}
        <div className="flex flex-col justify-center items-start gap-3 w-full">
          {/* TITLE */}
          <div className="flex flex-row items-center space-x-4">
            <div>
              <h1 className="text-black text-xl font-bold font-poppins">
                Clinic Capacity
              </h1>
              <p className="text-[#8A8A8A] text-xs font-regular font-poppins">
                Calculated to ensure Maximum Utilization of the Clinic and to
                ensure increased Profitability
              </p>
            </div>
          </div>
          <Breadcrumb />
        </div>

        {/* Content */}
        <div className="flex flex-row justify-center items-center w-full">
          <div className="flex flex-col justify-center items-start w-1/2">
            <h1 className="text-black text-xl font-bold font-poppins mt-3">
              Doctors Available
            </h1>
            <div className="flex flex-row justify-center items-center gap-5 my-3">
              <h1 className="text-[#8A8A8A] text-xs font-regular font-poppins">
                Number of Doctor Available
              </h1>
              <button
                onClick={() => {
                  navigate("/doctorManagement");
                }}
                className="text-[#3CA5DC] text-xs font-regular font-poppins underline"
              >
                Maintain Here
              </button>
            </div>
            {/* Doctor */}
            <div className="flex flex-row justify-center items-center gap-4">
              {numberOfDocArray.map((digit, index) => (
                <div
                  key={index}
                  className="flex justify-center items-center px-6 py-4 bg-[#E5F2FF] rounded-md shadow"
                >
                  <h1 className="text-[#3CA5DC] text-2xl font-bold font-poppins">
                    {digit}
                  </h1>
                </div>
              ))}
            </div>

            <div className="flex flex-row justify-center items-center gap-5 mt-9">
              <h1 className="text-[#070808] text-xs font-semibold font-poppins">
                Total Number of Hours / week
              </h1>
              <button
                onClick={() => {
                  navigate("/doctorManagement");
                }}
                className="text-[#3CA5DC] text-xs font-regular font-poppins underline"
              >
                Maintain Here
              </button>
            </div>
            {/* Total Hours */}
            <div className="flex flex-row justify-center items-center gap-4 mt-4">
              {totalHoursArray.map((digit, index) => (
                <div
                  key={index}
                  className="flex justify-center items-center px-6 py-4 bg-[#E5F2FF] rounded-md shadow"
                >
                  <h1 className="text-[#3CA5DC] text-2xl font-bold font-poppins">
                    {digit}
                  </h1>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col justify-center w-1/2">
            <div className="flex flex-col justify-center items-center w-full bg-[#F3F5F6] rounded-lg shadow py-20 px-20">
              <div className="flex flex-row justify-center items-center gap-5 mt-9">
                <h1 className="text-[#070808] text-xl font-bold font-poppins">
                  Calculated Capacity
                </h1>
              </div>
              {/* capacity */}
              <div className="flex flex-row justify-center items-center gap-4 mt-10">
                {calculatedCapacityArray.map((digit, index) => (
                  <div
                    key={index}
                    className="flex justify-center items-center px-6 py-4 bg-[#E5F2FF] rounded-md shadow"
                  >
                    <h1 className="text-[#3CA5DC] text-2xl font-bold font-poppins">
                      {digit}
                    </h1>
                  </div>
                ))}
              </div>
              <h1 className="text-[#3CA5DC] text-sm font-regular font-poppins mt-6 text-center">
                Auto Calculated based on Best Practices.
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClinicCapacity;
