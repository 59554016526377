import React, { useState, useEffect, useRef } from "react";
import TitleWithAddButton from "./TitleWithAddButton";
import smoking from "../assets/Images/PatientProfile/smoking.png";
import alcohol from "../assets/Images/PatientProfile/alcohol.png";
import exercise from "../assets/Images/PatientProfile/exercise.png";
import { useDispatch, useSelector } from "react-redux";
import { updateLifestyleData } from "../services/operations/patientManagementAPI";

function Lifestyle({ hasEditAccess, lifestyle, patient }) {
  const [showStatusDropdown, setShowStatusDropdown] = useState(null);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setData([
      {
        id: 1,
        title: "Smoking",
        image: smoking,
        Status: lifestyle?.Smoking || "NONE",
      },
      {
        id: 2,
        title: "Alcohol",
        image: alcohol,
        Status: lifestyle?.Alcohol || "NONE",
      },
      {
        id: 3,
        title: "Exercise",
        image: exercise,
        Status: lifestyle?.Exercise || "NONE",
      },
    ]);
  }, [lifestyle]);

  const toggleStatusDropdown = (id) => {
    setShowStatusDropdown((prev) => (prev === id ? null : id));
  };

  const handleStatusSelect = (id, status) => {
    setData((prevData) => {
      const updatedData = prevData.map((item) =>
        item.id === id ? { ...item, Status: status } : item
      );

      const body = {
        AHUID: patient?.AHUID,
        Smoking: updatedData.find((item) => item.title === "Smoking").Status,
        Alcohol: updatedData.find((item) => item.title === "Alcohol").Status,
        Exercise: updatedData.find((item) => item.title === "Exercise").Status,
      };

      console.log(body);
      dispatch(updateLifestyleData(token, body));

      return updatedData;
    });

    setShowStatusDropdown(null);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowStatusDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getStatusColor = (title, status) => {
    if (title === "Exercise" && status === "ACTIVE") {
      return "bg-lime-500";
    } else if (title === "Exercise" && status === "NEVER") {
      return "bg-red-600";
    }
    switch (status) {
      case "ACTIVE":
        return "bg-red-600";
      case "SOMETIMES":
        return "bg-amber-500";
      case "NEVER":
        return "bg-lime-500";
      default:
        return null ;
    }
  };

  const renderCard = (item) => {
    return (
      <div className="p-2 bg-white rounded-xl shadow border border-gray-200">
        <div className="flex flex-row items-center justify-between mb-2">
          <h2 className="text-zinc-900 text-sm font-normal font-poppins">
            {item.title}
          </h2>
          <div
            className={`w-3 h-3 rounded-full ${getStatusColor(
              item.title,
              item.Status
            )}`}
          />
        </div>
        <div className="flex items-center justify-center mb-4">
          <div className="w-14 h-14 bg-sky-50 rounded-full flex items-center justify-center">
            <img
              src={item.image}
              alt={item.title}
              className="w-8 h-8 object-contain"
            />
          </div>
        </div>
        <div className="relative">
          <div
            onClick={() => hasEditAccess && toggleStatusDropdown(item.id)}
            className={`w-full px-3 py-2 bg-white border border-sky-400 rounded-full text-sm text-slate-700 font-normal flex items-center justify-between ${
              hasEditAccess ? "cursor-pointer" : "cursor-not-allowed"
            } `}
          >
            <div className="flex items-center space-x-1">
              <div className="text-center text-slate-700 text-sm font-normal font-poppins leading-tight">
                {STATUS.find((status) => status.value === item.Status)?.label ||
                  "None"}
              </div>
            </div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 7.50004C15 7.50004 11.3176 12.5 10 12.5C8.68233 12.5 5 7.5 5 7.5"
                stroke="black"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {showStatusDropdown === item.id && (
            <div className="absolute w-full mt-1 bg-white border rounded-lg p-2 space-y-1 shadow-lg z-10">
              {STATUS.map((x) => (
                <button
                  key={x.value}
                  onClick={() => handleStatusSelect(item.id, x.value)}
                  className={`w-full flex flex-row items-center justify-between px-4 py-2 text-sm rounded-lg ${
                    item.Status === x.value
                      ? "bg-slate-50 text-sky-400 hover:bg-sky-200 hover:text-sky-600"
                      : "text-black hover:bg-gray-100 hover:text-gray-800"
                  }`}
                >
                  <div>{x.label}</div>
                  {item.Status === x.value && (
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.3353 5.5L8.16862 14.6667L4.00195 10.5"
                        stroke="#3CA5DC"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <TitleWithAddButton title="Lifestyle" />
      <div className="grid grid-cols-3 gap-4 mt-4">
        {data.map((item) => (
          <div key={item.id}>{renderCard(item)}</div>
        ))}
      </div>
    </div>
  );
}

export default Lifestyle;

const STATUS = [
  {
    label: "Active",
    value: "ACTIVE",
  },
  {
    label: "Sometimes",
    value: "SOMETIMES",
  },
  {
    label: "Never",
    value: "NEVER",
  },
];