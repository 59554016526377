import styles from "./styles.module.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
// import widgetIcon from "../assets/Images/TelehealthDashboard/widgetIcon.png";
import profilePic from "../assets/Images/TelehealthDashboard/profilePic.png";
import e2 from "../assets/Images/TelehealthDashboard/e2.png";
import checkWidget from "../assets/Images/TelehealthDashboard/checkWidget.png";
import closeWidget from "../assets/Images/TelehealthDashboard/closeWidget.png";
import widgetIcon from "../assets/Images/icon/widgetIcon.png";
import Widget1 from "../assets/Images/TelehealthDashboard/Widget1.png";
import Widget2 from "../assets/Images/TelehealthDashboard/Widget2.png";
import Widget3 from "../assets/Images/TelehealthDashboard/Widget3.png";
import Widget4 from "../assets/Images/TelehealthDashboard/Widget4.png";
import TodayPatientList from "../components/TodayPatientList";
import NextSchedule from "../components/NextSchedule";
import Payouts from "../components/Payouts";
import VideoCall from "../components/VideoCall";
import ModalComponent from "../components/Modal";
import { IoIosSearch } from "react-icons/io";
import moment from "moment";
import toast from "react-hot-toast";
import Breadcrumb from "../components/Breadcrumb";

function TelehealthDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.profile);
  const [panel3Size, setPanel3Size] = useState(55);
  const [showModal, setShowModal] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const { allPatients } = useSelector((state) => state.patient);
  const [filteredPatients, setFilteredPatients] = useState(allPatients);
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const getGreeting = () => {
    const currentHour = moment().hour();

    if (currentHour >= 3 && currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      return "Good Afternoon";
    } else {
      return "Good Evening";
    }
  };

  const handleExpand = (expanded) => {
    setIsExpanded(expanded);
  };
  const handleHideTile = (name1) => {
    setVisibility((prevVisibility) => ({
      ...prevVisibility,
      [name1]: false,
    }));
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchInput(value);
    filterPatients(value);
  };

  const filterPatients = (searchTerm) => {
    const lowercasedTerm = searchTerm.toLowerCase();
    const filtered = allPatients.filter((allPatients) => {
      const nameMatch = allPatients.Name.toLowerCase().includes(lowercasedTerm);
      const dobMatch1 = allPatients.DOB.includes(lowercasedTerm);

      // Parse the allPatients's DOB into a moment object
      const patientDOB = moment(allPatients.DOB, "YYYY-MM-DD");

      // Parse the search term into a moment object
      const searchDate = moment(
        lowercasedTerm,
        [
          "DD/MM/YYYY",
          "DD-MM-YYYY",
          "DD MM YYYY",
          "DD MMM YYYY",
          "DD/MM",
          "DD MM",
          "DD MMM",
          "DD-MM",
          "DD-MMM",
          "DD/MMM",
        ],
        true
      );

      // Check if the search term is a valid date
      const isDate = searchDate.isValid();

      // Extract day and month from the search term
      const searchDay = searchDate.date();
      const searchMonth = searchDate.month();

      // Check if the search term matches the allPatients's day and month of birth
      const dobMatch =
        isDate &&
        (searchDate.isSame(patientDOB, "day") ||
          (searchDay === patientDOB.date() &&
            searchMonth === patientDOB.month()));

      return nameMatch || dobMatch || dobMatch1;
    });
    setFilteredPatients(filtered);
  };

  const [visibility, setVisibility] = useState(() => {
    const savedVisibility = localStorage.getItem(
      "clinicDashboardWidgetsVisibility"
    );

    if (savedVisibility) {
      return JSON.parse(savedVisibility);
    } else {
      return {
        showTodayPatientList: true,
        showNextSchedule: true,
        showPayouts: true,
        showTelehealth: true,
      };
    }
  });

  useEffect(() => {
    if (!isExpanded)
      localStorage.setItem(
        "clinicDashboardWidgetsVisibility",
        JSON.stringify(visibility)
      );
  }, [visibility]);

  useEffect(() => {
    setFilteredPatients(allPatients);
  }, [allPatients]);

  useEffect(() => {
    if (isExpanded) {
      setVisibility((prevVisibility) => ({
        ...prevVisibility,
        showTodayPatientList: false,
        showNextSchedule: false,
        showPayouts: false,
        showTelehealth: true,
      }));
    } else {
      const savedVisibility = localStorage.getItem(
        "clinicDashboardWidgetsVisibility"
      );

      if (savedVisibility) {
        setVisibility(JSON.parse(savedVisibility));
      }
      setVisibility((prevVisibility) => ({
        ...prevVisibility,
        showTodayPatientList: true,
        showNextSchedule: true,
        showPayouts: true,
        showTelehealth: true,
      }));
    }
  }, [isExpanded]);

  return (
    <div className="bg-[#F3F3F3] min-h-screen h-auto flex-1 py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
      {/* HEADER */}
      {!isExpanded && (
        <div className="flex flex-col justify-start items-start w-full gap-2 ml-3">
          <div className="flex flex-row justify-between items-center w-full">
            {/* TITLE */}
            <h1 className="text-[#1C1C1C] text-[15px] font-normal font-poppins">
              {getGreeting()} <span className="text-[#1C1C1C] text-[15px] font-bold font-poppins">{user?.Name}!</span>
            </h1>
            <div
              onClick={() => setShowModal(true)}
              className="flex flex-col justify-center items-center w-[30px] h-[30px] rounded-full ml-auto cursor-pointer hover:scale-110 duration-500"
            >
              <img
                src={widgetIcon}
                className="w-full h-full object-contain rounded-full"
                alt="widget Icon"
              />
            </div>

            {/* Widget Modal */}
            <ModalComponent
              show={showModal}
              handleClose={() => setShowModal(false)}
              outSideModalPress={false}
            >
              <div className="bg-[#FFFFFF] p-4 h-[55vh]">
                {/* Widget HEADER */}
                <div className="flex flex-row justify-between items-center">
                  {/* TITLE */}
                  <h1 className="text-black text-2xl font-bold font-poppins rounded-lg">
                    Widgets
                  </h1>
                </div>

                <div className="w-full bg-[#F3F5F6] h-[1px] my-5"></div>

                {/* Widget Content */}
                <div className="grid grid-cols-2 gap-5 justify-center items-center mt-5">
                  {WIDGET.map((widget) => (
                    <button
                      key={widget.id}
                      onClick={() =>
                        setVisibility((prevState) => ({
                          ...prevState,
                          [widget.name]: !prevState[widget.name],
                        }))
                      }
                      className="w-[20vw] h-[10vw] flex flex-col justify-start items-start bg-[#3CA5DC] rounded-lg p-4 relative overflow-hidden"
                    >
                      <h1 className="text-[#F3F5F6] text-sm font-bold font-poppins">
                        {widget.text}
                      </h1>

                      {visibility[widget.name] && (
                        <div className="flex flex-col justify-center items-center w-[30px] h-[30px] rounded-full mt-2">
                          <img
                            src={checkWidget}
                            className="w-full h-full object-contain rounded-full"
                            alt="Check Btn"
                          />
                        </div>
                      )}

                      {/* Circle  */}
                      <div className="absolute right-[-2em] bottom-[-1.5em] flex flex-col justify-center items-center w-64 h-full z-9">
                        <img
                          src={e2}
                          className="w-full h-full object-contain rounded-full"
                          alt="Circle"
                        />
                      </div>

                      {/* Widget Bg  */}
                      <div className="absolute right-0 bottom-0 flex flex-col justify-center items-center w-48 h-auto z-99">
                        <img
                          src={widget.img}
                          className="w-full h-full object-contain"
                          alt="Widget Image"
                        />
                      </div>
                    </button>
                  ))}
                </div>
              </div>
            </ModalComponent>

            <button
              onClick={() => setShowAppointmentModal(true)}
              className="ml-5 flex flex-row justify-center items-center rounded-[5.52px] bg-[#3CA5DC] px-4 py-2 cursor-pointer hover:scale-110 duration-500"
            >
              <FaPlus className="text-white mr-2 text-sm" />
              <h1 className="text-white text-[11.05px] font-semibold font-poppins leading-[13.81px]">
                Add Appointment
              </h1>
            </button>

            {/* New Appointment Modal */}
            <ModalComponent
              show={showAppointmentModal}
              handleClose={() => setShowAppointmentModal(false)}
              outSideModalPress={false}
            >
              <div className="bg-[#FFFFFF] p-4 h-[55vh] w-[50vw] overflow-y-scroll scroll-smooth scrollbar scrollbar-thin scrollbar-thumb-[#3CA5DC] scrollbar-track-[#E5F2FF] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                {/* New Appointment HEADER */}
                <div className="flex flex-row justify-between items-center">
                  {/* TITLE */}
                  <h1 className="text-black text-md font-bold font-poppins rounded-lg">
                    Add New Appointment
                  </h1>
                  <div className="flex flex-row justify-center items-center bg-[#F3F5F6] rounded-3xl px-5 gap-2 py-2 mx-5 ml-auto w-[20vw]">
                    <IoIosSearch className="text-neutral-400 w-4 h-4" />
                    <input
                      type="text"
                      className={`bg-gray-100 text-xs text-neutral-400 w-full focus:outline-none focus:border-transparent`}
                      placeholder="Search with name or Date of Birth"
                      onChange={handleSearchChange}
                      value={searchInput}
                    />
                  </div>
                </div>

                <div className="w-full bg-[#F3F5F6] h-[1px] my-5"></div>

                {/* New Appointment Content */}
                <div className="flex flex-col space-y-3">
                  {filteredPatients?.map((patient, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        navigate("/patientDashboard/bookAppointment", { state: patient });
                      }}
                      className="bg-[#F3F5F6] rounded-full py-5 px-8  w-full cursor-pointer active:scale-95 duration-200"
                    >
                      <div className="flex flex-row items-center justify-between">
                        <div className="flex items-center space-x-3 w-52">
                          <img
                            src={patient.Image}
                            alt="PatientPic"
                            className="w-12 h-12 rounded-full"
                          />
                          <div className="flex flex-col">
                            <div className="text-black text-base font-semibold font-poppins">
                              {patient.Name}
                            </div>
                            <div className="text-black text-sm font-normal font-poppins">
                              {patient.Gender} -{" "}
                              {new Date().getFullYear() -
                                new Date(patient.DOB).getFullYear()}{" "}
                              Yrs
                            </div>
                          </div>
                        </div>

                        <div>
                          <div className="text-black text-sm font-semibold font-poppins">
                            Date of Birth
                          </div>
                          <div className="text-black text-xs font-normal font-poppins leading-none mt-2">
                            {moment(patient.DOB).format("DD MMM YYYY")}
                          </div>
                        </div>
                        <div>
                          <div className="text-black text-sm font-semibold font-poppins">
                            Phone Number
                          </div>
                          <div className="text-black text-xs font-normal font-poppins leading-none mt-2">
                            {patient?.Phone?.startsWith("+")
                              ? patient?.Phone
                              : "+" + patient?.Phone}
                          </div>
                        </div>
                        <div>
                          <div className="text-black text-sm font-semibold font-poppins ">
                            Email
                          </div>
                          <div className="w-40 text-black text-xs font-normal font-poppins leading-none mt-2 overflow-hidden overflow-ellipsis">
                            {patient.Email}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </ModalComponent>
          </div>
          <Breadcrumb />
        </div>
      )}

      <div className="w-full h-[100vh] mt-3">
        <div className="flex flex-col justify-center items-center gap-2 w-full">
          <div className="flex flex-row justify-stretch items-stretch gap-2 w-full">
            {visibility.showTodayPatientList && (
              <TodayPatientList onHideTile1={handleHideTile} />
            )}

            {visibility.showNextSchedule && (
              <NextSchedule onHideTile2={handleHideTile} />
            )}
          </div>
          <div className="flex flex-row justify-stretch items-stretch gap-2 w-full">
            {visibility.showPayouts && <Payouts onHideTile3={handleHideTile} />}

            {visibility.showTelehealth && (
              <VideoCall
                onExpand={handleExpand}
                isExpanded={isExpanded}
                onHideTile4={handleHideTile}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TelehealthDashboard;

const WIDGET = [
  {
    id: 1,
    name: "showTodayPatientList",
    text: "Patient List",
    img: Widget1,
  },
  {
    id: 2,
    name: "showNextSchedule",
    text: "Next Schedule",
    img: Widget2,
  },
  {
    id: 3,
    name: "showPayouts",
    text: "Payouts",
    img: Widget3,
  },
  {
    id: 4,
    name: "showTelehealth",
    text: "Video Call",
    img: Widget4,
  },
];
