import React, { useEffect, useState } from "react";
import backgroundImage from "../assets/Images/bgImageLogin.png";
import backgroundImage1 from "../assets/Images/bgImageLogin1.png";
import LoginForm from "./LoginForm";
import {
  getOrganizationLogo,
  getUserDetails,
} from "../services/operations/profileAPI";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AntdCarousel from "./AntdCarousel";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ForgotPasswordOtpForm from "./ForgotPasswordOtpForm";
import AHlogo from "../assets/Images/logo1.png";
import toast from "react-hot-toast";
import ResetPasswordForm from "./ResetPasswordForm";
import ResetPasswordSuccessForm from "./ResetPasswordSuccessForm";
import PartnerLoginForm from "./PartnerLoginForm";

function Template({ formType }) {
  const { token } = useSelector((state) => state.auth);
  const { settings } = useSelector((state) => state.masterData);
  const { user, logo } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [demoAddress, setDemoAddress] = useState("");

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (!demoAddress.includes("@")) {
      toast.error("Please enter a valid email");
      return;
    }
  };

  useEffect(() => {
    dispatch(getOrganizationLogo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* component */}
      <div className="flex flex-col-reverse md:flex-row justify-center items-center bg-white h-full w-full px-6 py-9 gap-2">
        {/* Left Content */}
        <div
          className="bg-no-repeat bg-cover bg-center w-full md:w-[60vw] h-[50vh] md:h-[90vh] rounded-[22px]"
          style={{
            backgroundImage: `url(${formType.includes('partner') ? backgroundImage1 : backgroundImage})`,
          }}
        >
          <div className="flex justify-center md:justify-start items-center w-40 h-[2.8rem] mt-10 md:ml-20 mb-10 md:mb-44">
            <img
              src={logo || settings?.OrgImage || AHlogo}
              alt="logo"
              className="w-full h-auto object-contain"
            />
          </div>
          <AntdCarousel />
        </div>

        {/* Right Content */}
        <div className="flex-1 h-full w-full md:w-[40vw]">
          {formType === "resetPassword" ? (
            <ResetPasswordForm type="clinic" />
          ) : formType === "partnerResetPassword" ? (
            <ResetPasswordForm type="partner" />
          ) : formType === "resetPasswordSuccess" ? (
            <ResetPasswordSuccessForm type="clinic" />
          ) : formType === "partnerResetPasswordSuccess" ? (
            <ResetPasswordSuccessForm type="partner" />
          ) : formType === "login" ? (
            <LoginForm />
          ) : formType === "partnerLogin" ? (
            <PartnerLoginForm />
          ) : formType === "forgotPassword" ? (
            <ForgotPasswordForm type="clinic" />
          ) : formType === "partnerForgotPassword" ? (
            <ForgotPasswordForm type="partner" />
          ) : formType === "forgotPasswordOtp" ? (
            <ForgotPasswordOtpForm type="clinic" />
          ) : formType === "partnerForgotPasswordOtp" ? (
            <ForgotPasswordOtpForm type="partner" />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Template;
