import moment from "moment";
import React, { useState } from "react";
import doctorIcon from "../assets/Images/doctorIcon.svg";
import telehealthIcon from "../assets/Images/telehealthIcon.svg";
import inclinicIcon from "../assets/Images/inclinicIcon.svg";
import calendarIcon from "../assets/Images/calendarIcon.svg";
import notesIcon from "../assets/Images/notesIcon.svg";
import FooterWithExpandButton from "./FooterWithExpandButton";
import ConsultationDetails from "./ConsultationDetails";
import Tooltip from "./Tooltip";

function Consultation({
  hasEditAccess,
  consultationHistory,
  patient,
  setSelectedTab,
}) {
  const [filter, setFilter] = useState("all");
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isViewing, setIsViewing] = useState(false);
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  if (consultationHistory?.length === 0) {
    return (
      <div>
        <div className="text-black text-lg font-bold font-poppins">
          Consultation
        </div>
        <div className="text-neutral-400 text-2xl text-center my-10 font-semibold font-poppins">
          No Data
        </div>
      </div>
    );
  }

  const handleModalOpen = (item) => {
    setSelectedItem(item);
    setIsViewing(false);
    setShowModal(true);
  };

  const handleViewDetails = (item) => {
    setSelectedItem(item);
    setIsViewing(true);
    setShowModal(true);
  };

  return (
    <>
      <ConsultationDetails
        show={showModal}
        patient={patient}
        ITEM={selectedItem}
        isViewing={isViewing}
        hide={() => {
          setShowModal(false);
          setSelectedItem(null);
        }}
      />
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="flex flex-row items-center space-x-5 justify-between">
            <div className="text-zinc-900 text-lg font-medium font-poppins leading-relaxed">
              Consultation
            </div>
            <div className="flex flex-row items-center">
              <div className="flex flex-row items-center">
                <button
                  onClick={() => handleFilterChange("all")}
                  className={`px-4 py-2.5 ${
                    filter === "all"
                      ? "bg-sky-100 text-sky-700 border-blue-200"
                      : "bg-white text-neutral-600 border-neutral-300"
                  }  rounded-tl-lg rounded-bl-lg border-l border-t border-b text-sm font-medium font-poppins leading-tight`}
                >
                  All
                </button>
                <button
                  onClick={() => handleFilterChange("current")}
                  className={`px-4 py-2.5 ${
                    filter === "current"
                      ? "bg-sky-100 text-sky-700 border-blue-200"
                      : "bg-white text-neutral-600 border-neutral-300"
                  }  border text-sm font-medium font-poppins leading-tight`}
                >
                  Current
                </button>
                <button
                  onClick={() => handleFilterChange("previous")}
                  className={`px-4 py-2.5 ${
                    filter === "previous"
                      ? "bg-sky-100 text-sky-700 border-blue-200"
                      : "bg-white text-neutral-600 border-neutral-300"
                  }  rounded-tr-lg rounded-br-lg border-r border-t border-b text-sm font-medium font-poppins leading-tight`}
                >
                  Previous
                </button>
              </div>
            </div>
          </div>
          <div className="mt-5 flex flex-col space-y-4">
            {consultationHistory
              ?.filter((item) => {
                if (filter === "all") return true;
                if (filter === "current") return item.PersonalNote === null;
                if (filter === "previous") return item.PersonalNote !== null;
              })
              .slice(0, 4)
              .map((item) => (
                <div
                  key={item.AppointmentID}
                  className="bg-neutral-50 rounded-lg shadow p-4 grid grid-cols-6 gap-4 items-center"
                >
                  <div className="flex flex-col">
                    <div className="text-zinc-900 text-sm font-normal font-poppins">
                      Session ID
                    </div>
                    <div className="text-zinc-900 text-sm font-normal font-poppins mt-1">
                      #{item?.AppointmentID}
                    </div>
                  </div>
                  <div className="flex flex-col col-span-2">
                    <div className="flex items-center space-x-2">
                      <img src={doctorIcon} alt="" />
                      <div className="text-zinc-900 text-sm font-normal font-poppins">
                        {item?.DoctorName}
                      </div>
                    </div>
                    <div className="flex items-center space-x-2 mt-1">
                      <img
                        src={
                          item?.AppointmentType === "TeleHealth"
                            ? telehealthIcon
                            : inclinicIcon
                        }
                        alt=""
                      />
                      <div className="text-zinc-900 text-sm font-normal font-poppins">
                        {item?.AppointmentType}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col col-span-2">
                    <div className="flex items-center space-x-2">
                      <img src={calendarIcon} alt="" />
                      <div className="text-zinc-900 text-sm font-normal font-poppins">
                        {moment(item?.ConsultationDate).format("DD MMMM YYYY")}
                      </div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <img src={notesIcon} alt="" />
                      {item?.PersonalNote === null && hasEditAccess ? (
                        <button
                          onClick={() => {
                            handleModalOpen(item);
                          }}
                          className="text-sky-400 text-sm font-medium font-poppins underline leading-none"
                        >
                          Add Notes
                        </button>
                      ) : (
                        <div className="text-zinc-900 text-sm font-normal font-poppins truncate">
                          {item?.PersonalNote || "-"}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-end">
                    {item?.PersonalNote !== null && (
                      <Tooltip message="View">
                        <button
                          onClick={() => handleViewDetails(item)}
                          className="p-1 rounded-full group group-hover:bg-sky-100"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                          >
                            <path
                              d="M21.3448 11.045C21.6488 11.4713 21.8008 11.6845 21.8008 12C21.8008 12.3155 21.6488 12.5287 21.3448 12.955C19.9787 14.8706 16.49 19 11.8008 19C7.11156 19 3.62288 14.8706 2.25682 12.955C1.95279 12.5287 1.80078 12.3155 1.80078 12C1.80078 11.6845 1.95279 11.4713 2.25682 11.045C3.62288 9.12944 7.11156 5 11.8008 5C16.49 5 19.9787 9.12944 21.3448 11.045Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                            />
                            <path
                              d="M14.8008 12C14.8008 10.3431 13.4577 9 11.8008 9C10.1439 9 8.80078 10.3431 8.80078 12C8.80078 13.6569 10.1439 15 11.8008 15C13.4577 15 14.8008 13.6569 14.8008 12Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                            />
                          </svg>
                        </button>
                      </Tooltip>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <FooterWithExpandButton
          onPress={() => {
            setSelectedTab(1);
          }}
        />
      </div>
    </>
  );
}

export default Consultation;
