import React, { useEffect, useState } from "react";
import ModalComponent from "./Modal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "./Dropdown";
import RadioIcon from "./RadioIcon";
import { IoAddSharp } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import toast from "react-hot-toast";
import { addPrescription } from "../services/operations/patientManagementAPI";
import { useNavigate } from "react-router-dom";
import { TimePicker } from "antd";
import telehealthIcon from "../assets/Images/telehealthIcon.svg";
import inclinicIcon from "../assets/Images/inclinicIcon.svg";
function Prescription({ show, hide, patient, data }) {
  const {
    loading: patientLoading,
    patientProfile,
    mentalHealth,
    familyMembers,
    medicalCondition,
    dosageAdherence,
    consultationHistory,
    diagnosisType,
    allMedicines,
    allPreMedicalConditions,
    allergies,
    allergiesType,
  } = useSelector((state) => state.patient);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { doctorDetails } = useSelector((state) => state.profile);
  // const [expandedMedicationId, setExpandedMedicationId] = useState(null);
  const [expandedMedicationId, setExpandedMedicationId] = useState(1);
  const [session, setSession] = useState(consultationHistory[0]);
  const [showConsultationPicker, setShowConsultationPicker] = useState(false);
  const [showConsultationList, setShowConsultationList] = useState(false);
  const [medications, setMedications] = useState([
    {
      id: 1,
      MedicationID: "",
      DosageAmount: "",
      Route: "",
      MealTime: "Pre Meal",
      Frequency: "Once",
      Duration: "",
      DurationType: "Day",
      SpecialInstructions: "",
      CustomTimes: [""],
    },
  ]);
  const [errors, setErrors] = useState({});
  const addMedication = () => {
    const newId = medications.length + 1;
    setMedications([
      ...medications,
      {
        id: newId,
        MedicationID: "",
        DosageAmount: "",
        Route: "",
        MealTime: "Pre Meal",
        Frequency: "Once",
        Duration: "",
        DurationType: "Day",
        SpecialInstructions: "",
        CustomTimes: [""],
      },
    ]);
    setExpandedMedicationId(newId);
  };

  useEffect(() => {
    if (medications.length === 1) {
      setExpandedMedicationId(medications[0].id);
    }
  }, [medications]);

  const removeMedication = (id) => {
    setMedications(medications.filter((med) => med.id !== id));
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      Object.keys(newErrors).forEach((key) => {
        if (key.endsWith(id.toString())) {
          delete newErrors[key];
        }
      });
      return newErrors;
    });
  };

  const handleInputChange = (id, field, value) => {
    setMedications(
      medications.map((med) =>
        med.id === id
          ? {
              ...med,
              [field]: value,
              ...(field === "Frequency" && value !== "Custom"
                ? { CustomTimes: [""] }
                : {}),
            }
          : med
      )
    );
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`${field}${id}`];
      return newErrors;
    });
  };

  const handleSelect = (selectedOption, field, id) => {
    setMedications((prev) => {
      return prev.map((med) => {
        if (med.id === id) {
          return {
            ...med,
            [field]: selectedOption,
          };
        }
        return med;
      });
    });
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`${field}${id}`];
      return newErrors;
    });
  };

  const handleTimeChange = (id, index, time) => {
    setMedications(
      medications.map((med) =>
        med.id === id
          ? {
              ...med,
              CustomTimes: med.CustomTimes.map((t, i) =>
                i === index ? time : t
              ),
            }
          : med
      )
    );
  };

  const addCustomTime = (id) => {
    setMedications(
      medications.map((med) =>
        med.id === id && med.CustomTimes.length < 4
          ? { ...med, CustomTimes: [...med.CustomTimes, ""] }
          : med
      )
    );
  };

  const removeCustomTime = (id, index) => {
    setMedications(
      medications.map((med) =>
        med.id === id
          ? {
              ...med,
              CustomTimes: med.CustomTimes.filter((_, i) => i !== index),
            }
          : med
      )
    );
  };

  const validateForm = () => {
    let newErrors = {};
    medications.forEach((med) => {
      if (!med.MedicationID)
        newErrors[`MedicationID${med.id}`] =
          "Medicine MedicationID is required";
      if (!med.Route) newErrors[`Route${med.id}`] = "Route is required";
      if (!med.DosageAmount)
        newErrors[`DosageAmount${med.id}`] = "Dosage is required";
      if (!med.Duration)
        newErrors[`Duration${med.id}`] = "Duration is required";
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let transformedMedicines = medications.map((medicine, index) => {
        return {
          SessionID: session?.AppointmentID,
          MedicationID: medicine?.MedicationID,
          MedicineName: allMedicines.find(
            (med) => med.MedicationID === medicine?.MedicationID
          )?.Name,
          DosageAmount: medicine?.DosageAmount,
          DID: doctorDetails?.DID,
          AHUID: patient?.AHUID,
          Frequency: medicine?.Frequency,
          StartDate: moment().format("YYYY-MM-DD"),
          EndDate: moment()
            .add(
              moment.duration(
                medicine.DurationType === "Day"
                  ? medicine.Duration - 1
                  : medicine.Duration,
                medicine.DurationType
              )
            )
            .format("YYYY-MM-DD"),
          Duration: medicine?.Duration,
          Route: medicine?.Route,
          DurationType: medicine?.DurationType,
          MealTime: medicine?.MealTime,
          SpecialInstructions: medicine.SpecialInstructions,
          ...(medicine.Frequency === "Custom" && {
            customMealTimes: {
              ...(medicine.MealTime === "Pre Meal" && {
                "Pre Meal": medicine.CustomTimes.map((time) =>
                  moment(time, "h:mm a").format("HH:mm")
                ),
              }),
              ...(medicine.MealTime === "Post Meal" && {
                "Post Meal": medicine.CustomTimes.map((time) =>
                  moment(time, "h:mm a").format("HH:mm")
                ),
              }),
            },
          }),
        };
      });

      let body = {
        DosageData: transformedMedicines,
      };
      dispatch(addPrescription(token, body, navigate, patient));
      handleCloseModal();
    } else {
      toast.error("Please fill all the required fields");
    }
  };

  const handleCloseModal = () => {
    hide();
    setMedications([
      {
        id: 1,
        MedicationID: "",
        DosageAmount: "",
        Route: "",
        MealTime: "Pre Meal",
        Frequency: "Once",
        Duration: "",
        DurationType: "Day",
        SpecialInstructions: "",
        CustomTimes: [""],
      },
    ]);
    setErrors({});
  };

  useEffect(() => {
    if (consultationHistory.length > 0) {
      setSession(consultationHistory[0]);
    }
  }, [consultationHistory]);

  const toggleExpand = (id) => {
    setExpandedMedicationId(expandedMedicationId === id ? null : id);
  };

  return (
    <ModalComponent
      show={show}
      handleClose={handleCloseModal}
      outSideModalPress={false}
    >
      <div className="text-slate-800 text-2xl font-semibold font-poppins leading-loose">
        Add Prescription for{" "}
        {patient?.Name || patient?.FirstName + " " + patient?.LastName}
      </div>
      <div className="h-px bg-gray-200 my-4" />
      <div className="flex flex-row items-center space-x-5 w-full">
        <div className="flex items-center space-x-2">
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.5 2.5V4.5M4.5 2.5V4.5"
              stroke="#545454"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1 12.7432C1 8.38594 1 6.20728 2.25212 4.85364C3.50424 3.5 5.51949 3.5 9.55 3.5H11.45C15.4805 3.5 17.4958 3.5 18.7479 4.85364C20 6.20728 20 8.38594 20 12.7432V13.2568C20 17.6141 20 19.7927 18.7479 21.1464C17.4958 22.5 15.4805 22.5 11.45 22.5H9.55C5.51949 22.5 3.50424 22.5 2.25212 21.1464C1 19.7927 1 17.6141 1 13.2568V12.7432Z"
              stroke="#545454"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.5 8.5H19.5"
              stroke="#545454"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="text-zinc-900 text-sm font-normal font-poppins leading-tight">
            Consultation Date:{" "}
            <span className="font-medium">
              {moment(session?.ConsultationDate).format("Do MMM YYYY")}
            </span>
          </div>
        </div>
        {showConsultationPicker ? (
          <div className="relative">
            <h1 className="text-zinc-900 text-sm font-normal font-poppins">
              Select session ID
            </h1>
            <button
              className={`w-[21vw] text-black text-sm font-normal leading-tight font-poppins bg-gray-100 rounded-md p-3 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent flex items-center justify-between`}
              onClick={() => setShowConsultationList(!showConsultationList)}
            >
              {session?.AppointmentID +
                " • " +
                moment(session?.ConsultationDate).format("Do MMM YYYY")}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={`w-6 h-6 ${
                  showConsultationList
                    ? "transform rotate-180 transition-transform duration-200"
                    : "transform rotate-0 transition-transform duration-200"
                }`}
              >
                <path
                  d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            {showConsultationList && (
              <div className="absolute left-0 mt-1 w-full bg-white border rounded-lg py-2 space-y-1 shadow-lg z-10 max-h-[35vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                {consultationHistory?.map((history) => (
                  <button
                    key={history.AppointmentID}
                    onClick={() => {
                      setSession(history);
                      setShowConsultationList(false);
                      setShowConsultationPicker(false);
                    }}
                    className={`w-full px-4 py-2 ${
                      session?.AppointmentID === history.AppointmentID
                        ? "bg-sky-100"
                        : ""
                    }  flex items-center justify-between hover:bg-gray-100`}
                  >
                    <div className="flex items-center space-x-2 text-zinc-900 text-xs font-normal font-poppins leading-tight">
                      <p>
                        #
                        {history?.AppointmentID +
                          " • " +
                          moment(history?.ConsultationDate).format(
                            "Do MMM YYYY"
                          ) +
                          " • "}
                      </p>
                      <div className="flex flex-row items-center space-x-2">
                        <img
                          src={
                            history?.AppointmentType === "TeleHealth"
                              ? telehealthIcon
                              : inclinicIcon
                          }
                          alt=""
                        />
                        <p>{history?.AppointmentType}</p>
                      </div>
                    </div>

                    {session?.AppointmentID === history.AppointmentID && (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 14.5C5 14.5 6.5 14.5 8.5 18C8.5 18 14.0588 8.83333 19 7"
                          stroke="#3CA5DC"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </button>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div className="flex items-center space-x-2">
            <div className="text-zinc-900 text-sm font-normal font-poppins leading-tight">
              Session ID:{" "}
              <span className="font-medium">{session?.AppointmentID}</span>
            </div>
            <button
              onClick={() => setShowConsultationPicker(true)}
              className="active:scale-95"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.2141 6.48239L16.6158 5.08063C17.39 4.30646 18.6452 4.30646 19.4194 5.08063C20.1935 5.8548 20.1935 7.10998 19.4194 7.88415L18.0176 9.28591M15.2141 6.48239L6.98023 14.7163C5.93493 15.7616 5.41226 16.2842 5.05637 16.9211C4.70047 17.558 4.3424 19.0619 4 20.5C5.43809 20.1576 6.94199 19.7995 7.57889 19.4436C8.21579 19.0877 8.73844 18.5651 9.78375 17.5198L18.0176 9.28591M15.2141 6.48239L18.0176 9.28591"
                  stroke="black"
                  strokeWidth="1.5"
                  stroke-linecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11 20.5H17"
                  stroke="black"
                  strokeWidth="1.5"
                  stroke-linecap="round"
                />
              </svg>
            </button>
          </div>
        )}
      </div>
      <div className="p-1 h-[calc(100vh-25rem)] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
        {medications?.map((medication, index) => (
          <div
            key={medication.id}
            className="p-4 rounded-lg shadow my-4 relative"
          >
            <button
              type="button"
              onClick={() => toggleExpand(medication.id)}
              className="w-full text-left"
            >
              <div className="flex justify-between items-center">
                <div className="text-zinc-900 text-sm font-normal font-poppins">
                  {allMedicines.find(
                    (med) => med.MedicationID === medication.MedicationID
                  )?.Name || "Medicine " + (index + 1)}
                </div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`w-6 h-6 ${
                    expandedMedicationId === medication.id
                      ? "transform rotate-180 transition-transform duration-200"
                      : "transform rotate-0 transition-transform duration-200"
                  }`}
                >
                  <path
                    d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </button>
            {expandedMedicationId === medication.id && (
              <div className="mt-4">
                {index > 0 && (
                  <button
                    type="button"
                    onClick={() => removeMedication(medication.id)}
                    className="absolute bottom-5 right-5 flex items-center space-x-1 active:scale-95"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.3008 5.5L18.6811 15.5251C18.5227 18.0864 18.4436 19.3671 17.8016 20.2879C17.4841 20.7431 17.0755 21.1273 16.6015 21.416C15.6429 22 14.3598 22 11.7935 22C9.2239 22 7.93908 22 6.97983 21.4149C6.50558 21.1257 6.09678 20.7408 5.77946 20.2848C5.13766 19.3626 5.06023 18.0801 4.90539 15.5152L4.30078 5.5"
                        stroke="#D92D20"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M2.80078 5.5H20.8008M15.8565 5.5L15.1739 4.09173C14.7204 3.15626 14.4936 2.68852 14.1025 2.39681C14.0158 2.3321 13.9239 2.27454 13.8278 2.2247C13.3947 2 12.8749 2 11.8353 2C10.7696 2 10.2368 2 9.79646 2.23412C9.69888 2.28601 9.60576 2.3459 9.51807 2.41317C9.12242 2.7167 8.90141 3.20155 8.45939 4.17126L7.8537 5.5"
                        stroke="#D92D20"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M9.30078 16.5V10.5"
                        stroke="#D92D20"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M14.3008 16.5V10.5"
                        stroke="#D92D20"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                    <div className="text-red-600 text-sm font-normal font-poppins underline leading-none">
                      Remove
                    </div>
                  </button>
                )}
                <div className="grid grid-cols-2 items-center gap-4 mb-4">
                  <div>
                    <label>
                      <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                        Medicine Name
                      </p>
                      <Dropdown
                        enableSearch={true}
                        placeholder={"Select Medicine"}
                        error={errors[`MedicationID${medication.id}`]}
                        MedicationID="MedicationID"
                        options={allMedicines}
                        onSelect={(MedicationID, option) =>
                          handleSelect(option, "MedicationID", medication.id)
                        }
                        value={medication.MedicationID}
                        labelKey={"Name"}
                        valueKey={"MedicationID"}
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                        Dosage
                      </p>
                      <input
                        type="text"
                        id={`Dosage${medication.id}`}
                        MedicationID="Dosage"
                        value={medication.DosageAmount}
                        maxLength={10}
                        onChange={(e) =>
                          handleInputChange(
                            medication.id,
                            "DosageAmount",
                            e.target.value
                          )
                        }
                        placeholder="Ex: 5 mg"
                        className={`w-full text-zinc-900 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                          errors[`DosageAmount${medication.id}`]
                            ? "border-red-500"
                            : ""
                        } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                        Route
                      </p>
                      <Dropdown
                        enableSearch={true}
                        placeholder={"Select Route"}
                        error={errors[`Route${medication.id}`]}
                        MedicationID="Route"
                        options={[
                          "Oral",
                          "Sublingual",
                          "Intravenous",
                          "Topical",
                          "Inhalation",
                          "Intramuscular",
                          "Subcutaneous",
                          "Rectal",
                          "Transdermal",
                        ]}
                        onSelect={(MedicationID, option) =>
                          handleSelect(option, "Route", medication.id)
                        }
                        value={medication.Route}
                      />
                    </label>
                  </div>
                  <div className="flex items-center space-x-6">
                    <p className="text-zinc-900 text-sm font-medium font-poppins leading-none">
                      Meal Intake:
                    </p>
                    {["Pre Meal", "Post Meal"].map((option) => (
                      <label
                        key={option}
                        className="flex items-center cursor-pointer"
                      >
                        <input
                          type="radio"
                          className="hidden"
                          MedicationID={`MealTime${medication.id}`}
                          value={option}
                          checked={medication.MealTime === option}
                          onChange={(e) =>
                            handleInputChange(
                              medication.id,
                              "MealTime",
                              e.target.value
                            )
                          }
                        />
                        <RadioIcon selected={medication.MealTime === option} />
                        <span className="text-zinc-900 text-sm font-normal font-poppins leading-none">
                          {option.charAt(0).toUpperCase() + option.slice(1)}
                        </span>
                      </label>
                    ))}
                  </div>
                  <div className="flex items-center space-x-6 col-span-2">
                    <p className="text-zinc-900 text-sm font-medium font-poppins leading-none">
                      Frequency:
                    </p>
                    {["Once", "Twice", "Thrice", "Custom"].map((option) => (
                      <label
                        key={option}
                        className="flex items-center cursor-pointer"
                      >
                        <input
                          type="radio"
                          className="hidden"
                          MedicationID={`Frequency${medication.id}`}
                          value={option}
                          checked={medication.Frequency === option}
                          onChange={(e) =>
                            handleInputChange(
                              medication.id,
                              "Frequency",
                              e.target.value
                            )
                          }
                        />
                        <RadioIcon selected={medication.Frequency === option} />
                        <span className="text-zinc-900 text-sm font-normal font-poppins leading-none">
                          {option.charAt(0).toUpperCase() + option.slice(1)}{" "}
                          {option !== "Custom" ? "a day" : ""}
                        </span>
                      </label>
                    ))}
                  </div>
                  {medication.Frequency === "Custom" && (
                    <div className="flex items-center space-x-4 col-span-2">
                      {medication?.CustomTimes.map((time, index) => (
                        <div key={index} className="flex items-center mb-2">
                          <TimePicker
                            use12Hours
                            format="h:mm a"
                            value={time ? moment(time, "HH:mm") : null}
                            onChange={(time, timeString) =>
                              handleTimeChange(medication.id, index, timeString)
                            }
                            className="mr-2"
                          />
                          {medication.CustomTimes.length > 1 && (
                            <button
                              onClick={() =>
                                removeCustomTime(medication.id, index)
                              }
                              className="p-1.5 rounded-md justify-center items-center gap-2 inline-flex border border-gray-300 mr-2"
                            >
                              <RxCross1 className="" />
                            </button>
                          )}
                          {index === medication.CustomTimes.length - 1 &&
                            index < 3 && (
                              <button
                                onClick={() => addCustomTime(medication.id)}
                                className="p-1.5 rounded-md justify-center items-center gap-2 inline-flex border border-gray-300"
                              >
                                <IoAddSharp className="text-sky-400 text-md" />
                              </button>
                            )}
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="flex items-center space-x-6 col-span-2">
                    <p className="text-zinc-900 text-sm font-medium font-poppins leading-none">
                      Duration:
                    </p>
                    <input
                      type="text"
                      id={`Duration${medication.id}`}
                      MedicationID="Duration"
                      value={medication.Duration}
                      maxLength={2}
                      onChange={(e) =>
                        handleInputChange(
                          medication.id,
                          "Duration",
                          e.target.value
                        )
                      }
                      placeholder="Enter no. of"
                      className={`text-zinc-900 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                        errors[`Duration${medication.id}`]
                          ? "border-red-500"
                          : ""
                      } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                    />
                    {["Day", "Week", "Month"].map((option) => (
                      <label
                        key={option}
                        className="flex items-center cursor-pointer"
                      >
                        <input
                          type="radio"
                          className="hidden"
                          MedicationID={`DurationType${medication.id}`}
                          value={option}
                          checked={medication.DurationType === option}
                          onChange={(e) =>
                            handleInputChange(
                              medication.id,
                              "DurationType",
                              e.target.value
                            )
                          }
                        />
                        <RadioIcon
                          selected={medication.DurationType === option}
                        />
                        <span className="text-zinc-900 text-sm font-normal font-poppins leading-none">
                          {option.charAt(0).toUpperCase() + option.slice(1)}(S)
                        </span>
                      </label>
                    ))}
                  </div>
                  <div className="col-span-2 mb-4">
                    <label>
                      <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                        Special Instructions (Optional)
                      </p>
                      <textarea
                        type="text"
                        id={`SpecialInstructions${medication.id}`}
                        MedicationID="SpecialInstructions"
                        value={medication.SpecialInstructions}
                        // maxLength={10}
                        onChange={(e) =>
                          handleInputChange(
                            medication.id,
                            "SpecialInstructions",
                            e.target.value
                          )
                        }
                        placeholder="Enter Special Instructions"
                        className={`w-full text-zinc-900 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                      />
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <button
        onClick={addMedication}
        className="mt-6 flex items-center justify-center text-sky-400 text-base font-semibold font-poppins leading-tight"
      >
        <IoAddSharp className="mr-2 text-2xl" />
        Add Another Medication
      </button>

      <div className="flex flex-row items-center justify-center mt-4 space-x-4">
        <button
          onClick={handleCloseModal}
          className="w-96 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
        >
          <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
            Cancel
          </div>
        </button>
        <button
          onClick={handleSubmit}
          className="w-96 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-500 active:scale-95"
        >
          <div className="text-white text-base font-semibold font-poppins leading-normal">
            Save Prescription
          </div>
        </button>
      </div>
    </ModalComponent>
  );
}

export default Prescription;
