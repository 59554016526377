import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus, FaMinus, FaChevronLeft, FaHeartPulse } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import iphoneIsland from "../../assets/Images/OurServices/iphoneIsland.png";
import treatmentPreviewBg from "../../assets/Images/OurServices/treatmentPreviewBg.png";
import rightArrowIcon from "../../assets/Images/OurServices/rightArrowIcon.png";
import durationIcon from "../../assets/Images/OurServices/durationIcon.png";
import uploadIcon from "../../assets/Images/OurServices/uploadIcon.png";
import Breadcrumb from "../../components/Breadcrumb";
import toast from "react-hot-toast";
import iphoneFrame from "../../assets/Images/MedicalConcierge/iphoneFrame.svg";
import Dropdown from "../../components/Dropdown";
import { FaExternalLinkAlt } from "react-icons/fa";
import {
  addNewTreatmentCategory,
  updateTreatmentCategory,
  uploadOurServicesImage,
} from "../../services/operations/ourServicesAPI";
import imageCompression from "browser-image-compression";
import { getAllDoctorSpeciality } from "../../services/operations/masterDataAPI";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import MultiSelectDropdownForOurServices from "../../components/MultiSelectDropdownForOurServices";
import { Switch } from "antd";

function AddTreatmentCategory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const data = location.state || {};
  const { allDoctors } = useSelector((state) => state.masterData);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState("");
  const [doctorsList, setDoctorsList] = useState([]);
  const [isSpecialOffer, setIsSpecialOffer] = useState(false);

  console.log("====================================");
  console.log("data", data);
  console.log("====================================");

  const [formData, setFormData] = useState({
    LogoLocation: "",
    TreatmentName: "",
    LabelText: "Special Offer",
    Doctors: "",
  });
  const [errors, setErrors] = useState({
    LogoLocation: "",
    TreatmentName: "",
    LabelText: "",
    Doctors: "",
  });

  useEffect(() => {
    if (data?.OSTCID) {
      setFormData({
        LogoLocation: data?.Image || "",
        TreatmentName: data?.Name || "",
        LabelText: data?.SpecialOffer || "Special Offer",
        Doctors: data?.DoctorsData.map((doctor) => doctor.DID) || [],
      });

      setImage(data?.categoryImage || "");

      if (data?.SpecialOffer) {
        setIsSpecialOffer(true);
      }
    }
  }, [data?.OSTCID]);

  useEffect(() => {
    const doctorOptions = allDoctors.map((doctor) => ({
      id: doctor.DID,
      name: doctor.Name,
      speciality: doctor.RelatedSpecialities
        ? doctor.RelatedSpecialities.map(
            (speciality) => speciality.Speciality
          ).join(", ")
        : "N/A",
      image: doctor.Image,
    }));
    setDoctorsList(doctorOptions);
  }, [allDoctors]);

  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="bg-white min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
      {/* HEADER */}
      <div className="flex flex-row justify-between items-center">
        {/* TITLE */}
        <div className="flex flex-row items-center">
          <div className="flex flex-col justify-center items-start gap-1">
            <div className="text-zinc-900 text-2xl font-medium font-poppins">
              {data?.OSTCID ? "Edit Treatment" : "Add New Treatment"}
            </div>
            <Breadcrumb />
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="flex gap-8 p-8">
        <TreatmentForm
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          image={image}
          setImage={setImage}
          doctorsList={doctorsList}
          isSpecialOffer={isSpecialOffer}
          setIsSpecialOffer={setIsSpecialOffer}
          data={data}
        />
        <PreviewSection
          formData={formData}
          isSpecialOffer={isSpecialOffer}
          image={image}
        />
      </div>
    </div>
  );
}

const TreatmentForm = ({
  data,
  formData,
  setFormData,
  errors,
  setErrors,
  image,
  setImage,
  doctorsList,
  isSpecialOffer,
  setIsSpecialOffer,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);

  function handleOnChange(event) {
    const { name, value } = event.target;
    let maxLength;

    // Set the maxLength based on the field name
    switch (name) {
      case "MinDuration":
        maxLength = 2;
        break;
      case "MaxDuration":
        maxLength = 2;
        break;
      case "StartPrice":
        maxLength = 6;
        break;
      case "EndPrice":
        maxLength = 6;
        break;
      default:
        maxLength = Infinity; // No maxLength for other fields
    }

    // For other types, handle validation without removing non-numeric characters
    if (value.length <= maxLength) {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  }

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const toastId = toast.loading("Compressing Image...");
    const compressedFile = await imageCompression(file, options);
    toast.dismiss(toastId);
    setImage(compressedFile);
    uploadImage(compressedFile);
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("ServiceImage", file);
    try {
      const res = await uploadOurServicesImage(formData, token);
      setFormData((prev) => ({
        ...prev,
        LogoLocation: res?.ServiceImageUrl,
      }));
      setErrors((prev) => ({
        ...prev,
        LogoLocation: "",
      }));
      setImage(URL.createObjectURL(file));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelect = (name, selectedOption) => {
    setFormData({
      ...formData,
      [name]: selectedOption,
    });
  };

  const submitHandler = async (event) => {
    // event.preventDefault();
    const mandatoryFields = ["LogoLocation", "TreatmentName", "Doctors"];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    // Check for LabelText if isSpecialOffer is true
    if (isSpecialOffer) {
      const labelTextValue = formData.LabelText;
      if (
        !labelTextValue ||
        (typeof labelTextValue === "string" && labelTextValue.trim() === "")
      ) {
        newErrors.LabelText = "This field is required for special offers";
      }
    }

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      console.log("ERRORS", errors);
      return;
    }

    const body = {
      Image: formData.LogoLocation,
      Name: formData.TreatmentName,
      ...(isSpecialOffer && {
        SpecialOffer: formData.LabelText,
      }),
      AssignDoctor: formData.Doctors,
    };

    if (data?.OSTCID) {
      dispatch(updateTreatmentCategory(token, data?.OSTCID, body, navigate));
    } else {
      dispatch(addNewTreatmentCategory(token, body, navigate));
    }
  };

  return (
    <div className="w-1/2">
      <div className="bg-white rounded-lg shadow-md p-6 border border-sky-500">
        {/* Treatment Image */}
        <div className="mb-6">
          <label
            className={`w-full border-dashed ${
              errors.LogoLocation ? "border-red-500" : "border-[#8A8A8A]"
            }  border-2 rounded-2xl p-4 flex flex-col items-center justify-center cursor-pointer bg-white
                    `}
          >
            {formData?.LogoLocation ? (
              <>
                <img
                  src={image}
                  alt="upload icon"
                  className="w-14 h-14 object-contain"
                />

                <span className="text-sm font-medium font-poppins mt-2">
                  Change Photo
                </span>
              </>
            ) : (
              <>
                <img
                  src={uploadIcon}
                  alt="upload icon"
                  className="w-14 h-14 object-contain"
                />
                <span className="text-sm font-medium font-poppins mt-2">
                  Add Photo
                </span>
                <span className="text-[#555252] text-[10px] font-normal font-poppins mt-1">
                  (Click to Add)
                </span>
              </>
            )}

            <input
              type="file"
              accept="image/*"
              name="LogoLocation"
              value={undefined}
              onChange={(e) => handleImageChange(e)}
              style={{ display: "none" }}
            />
          </label>
        </div>

        {/* Treatment Name */}
        <div className="mb-6">
          <label>
            <div className="flex items-center justify-between">
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                Add name of Treatment
                <sup className="text-red-600">*</sup>
              </p>
            </div>
            <input
              type="text"
              name="TreatmentName"
              value={formData.TreatmentName}
              onChange={handleOnChange}
              maxLength={30}
              style={{ wordBreak: "break-word", overflowWrap: "break-word" }}
              placeholder="Enter name of treatment"
              className={`w-full ${
                errors.TreatmentName ? "border-red-500" : ""
              }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent break-words`}
            />
          </label>
        </div>

        <div className="flex items-center mb-6">
          <Switch
            checked={isSpecialOffer}
            onChange={(checked) => {
              setIsSpecialOffer(checked);
              if (!checked) {
                setFormData((prev) => ({
                  ...prev,
                  LabelText: "Special Offer",
                }));
              }
            }}
            className="mr-2"
          />
          <div className="text-black text-sm font-normal font-poppins">
            Enable 'Discount' Label
          </div>
        </div>

        {isSpecialOffer && (
          <div className="mb-6">
            <label>
              <div className="flex items-center justify-between">
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Add Custom discount text
                  <sup className="text-red-600">*</sup>
                </p>
                <p className="text-right text-neutral-600 text-xs font-normal font-poppins">
                  {formData?.LabelText.length}/13
                </p>
              </div>
              <input
                type="text"
                name="LabelText"
                value={formData.LabelText}
                onChange={handleOnChange}
                maxLength={13}
                placeholder="Enter name of treatment"
                className={`w-full ${
                  errors.LabelText ? "border-red-500" : ""
                }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>
        )}

        {/* Assign Doctors to this Category */}
        <div className="mb-6">
          <label>
            <div className="flex items-center justify-between">
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                Assign Doctors to this Category
                <sup className="text-red-600">*</sup>
              </p>
            </div>
            <MultiSelectDropdownForOurServices
              placeholder="Select Doctors"
              error={errors.Doctors}
              name="Doctors"
              options={doctorsList}
              onSelect={handleSelect}
              value={formData.Doctors}
              labelKey="name"
              valueKey="id"
            />
          </label>
        </div>
      </div>
      <div className="flex flex-row items-center justify-center mt-6 space-x-4">
        <button
          onClick={submitHandler}
          className="flex-1 px-5 py-3 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-500 active:scale-95"
        >
          <div className="text-white text-base font-semibold font-poppins leading-normal">
            {data?.OSTCID ? "Update" : "  Save"}
          </div>
        </button>
      </div>
    </div>
  );
};

const PhoneMockup = ({ children }) => {
  return (
    <div className="relative mx-auto w-[300px] h-[600px]">
      <img
        src={iphoneFrame}
        alt="iPhone Frame"
        className="w-full h-full object-contain"
      />
      <div className="absolute inset-y-[1.6%] left-[5%] right-[5.8%] overflow-hidden rounded-[40px]">
        {children}
      </div>
    </div>
  );
};

const PreviewSection = ({ formData, isSpecialOffer, image }) => {
  return (
    <div className="w-1/2">
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="text-center text-zinc-900 text-xl font-semibold font-poppins mb-7">
          Preview for Patient
        </div>
        <div className="relative flex items-center justify-center h-[600px]">
          <div>
            <div className={`transition-all duration-300`}>
              <PhoneMockup>
                <FrontView
                  formData={formData}
                  isSpecialOffer={isSpecialOffer}
                  image={image}
                />
              </PhoneMockup>
            </div>
          </div>
        </div>
        <div className="w-full bg-gray-100 rounded-lg mt-7 p-2.5 flex items-center justify-center">
          <div className="w-96 text-center text-black text-xl font-normal font-poppins">
            This is how your service will look to Patients on the App
          </div>
        </div>
      </div>
    </div>
  );
};

const FrontView = ({ formData, isSpecialOffer, image }) => (
  <div
    className="h-full w-full flex flex-col justify-between relative bg-cover"
    style={{ backgroundImage: `url(${treatmentPreviewBg})` }}
  >
    <div className="absolute inset-0 bg-black opacity-50"></div>
    <img
      src={iphoneIsland}
      alt="iphone Island"
      className="w-16 h-6 object-contain absolute top-4 left-[6.5rem]"
    />

    {/* Card Content */}
    <div className="relative top-24 overflow-hidden rounded-3xl shadow-lg w-60 h-28 ml-3">
      <div
        className="absolute inset-0 bg-cover bg-center z-0"
        style={{ backgroundImage: `url(${image})` }}
      />
      <div className="relative z-10 py-3 px-4 bg-black bg-opacity-50 h-full">
        <h2 className="text-white text-[0.7rem] font-semibold">
          {formData?.TreatmentName || "Name"}
        </h2>
        <ul className="text-white gap-[0.2rem] mt-1.5">
          {[1, 2, 3].map((item, index) => (
            <li
              key={index}
              className="flex items-center text-white text-[0.4rem] font-semibold font-poppins"
            >
              <span className="mr-1">•</span>
              lorem
            </li>
          ))}
        </ul>
        <button className="mt-2 px-4 py-1 bg-white rounded-3xl shadow">
          <div className="text-black text-[0.5rem] font-medium font-poppins">
            See More
          </div>
        </button>
      </div>
      {isSpecialOffer && (
        <div className="absolute shadow z-10 top-0 right-0 rotate-45 -translate-y-1/2 translate-x-1/2 w-40 text-center bg-white text-black pb-1 pt-20 px-4 text-[0.5rem] font-bold">
          {formData?.LabelText}
        </div>
      )}
    </div>
  </div>
);

export default AddTreatmentCategory;
