import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CiSearch } from "react-icons/ci";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import addPrescription from "../assets/Images/PatientProfile/addPrescription.svg";
import deleteIcon from "../assets/Images/MedicalConcierge/deleteRedIcon.svg";
import cameraIcon from "../assets/Images/PatientProfile/camera.png";
import completed from "../assets/Images/PatientProfile/completed.svg";
import ongoing from "../assets/Images/PatientProfile/ongoing.svg";
import discontinue from "../assets/Images/PatientProfile/discontinue.svg";
import { BsArrowsAngleContract } from "react-icons/bs";
import Tooltip from "../components/Tooltip";
import moment from "moment";
import Pagination from "../components/Pagination";
import ModalComponent from "../components/Modal";
import RadioIcon from "../components/RadioIcon";
import Prescription from "../components/Prescription";
import { IoAddSharp } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { TimePicker } from "antd";
import toast from "react-hot-toast";
import {
  deletePrescription,
  updatePrescription,
} from "../services/operations/patientManagementAPI";

function MedicationFullScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();
  const patient = location.state || {};
  const { loading: patientLoading, dosageAdherence } = useSelector(
    (state) => state.patient
  );
  const [filter, setFilter] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const filteredMedicine = dosageAdherence.filter((medicine) =>
    medicine?.MedicineName?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDiscontinueModal, setShowDiscontinueModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [reason, setReason] = useState("");
  const [date, setDate] = useState(null);
  const [formData, setFormData] = useState({
    Dosage: "",
    MealTime: "Pre Meal",
    Frequency: "Once",
    Duration: "",
    DurationType: "Day",
    CustomTimes: [""],
  });

  const [error, setError] = useState({
    Dosage: "",
    Duration: "",
    CustomTimes: "",
  });

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentMedicine = filteredMedicine.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
      ...(field === "Frequency" && value !== "Custom"
        ? { CustomTimes: [""] }
        : {}),
    }));
    setError((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleSelect = (selectedOption, field) => {
    setFormData((prev) => ({
      ...prev,
      [field]: selectedOption,
    }));
    setError((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[field];
      return newErrors;
    });
  };

  const handleTimeChange = (index, time) => {
    setFormData((prev) => ({
      ...prev,
      CustomTimes: prev.CustomTimes.map((t, i) => (i === index ? time : t)),
    }));
  };

  const addCustomTime = () => {
    setFormData((prev) => ({
      ...prev,
      CustomTimes:
        prev.CustomTimes.length < 4
          ? [...prev.CustomTimes, ""]
          : prev.CustomTimes,
    }));
  };

  const removeCustomTime = (index) => {
    setFormData((prev) => ({
      ...prev,
      CustomTimes: prev.CustomTimes.filter((_, i) => i !== index),
    }));
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.Dosage) newErrors.Dosage = "Dosage is required";
    if (!formData.Duration) newErrors.Duration = "Duration is required";
    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const body = {
        DosageAmount: formData?.Dosage,
        MealTime: formData?.MealTime,
        Frequency: formData?.Frequency,
        Duration: formData?.Duration,
        DurationType: formData?.DurationType,
        ...(formData.Frequency === "Custom" && {
          customMealTimes: {
            ...(formData.MealTime === "Pre Meal" && {
              "Pre Meal": formData.CustomTimes.map((time) =>
                moment(time, "h:mm a").format("HH:mm")
              ),
            }),
            ...(formData.MealTime === "Post Meal" && {
              "Post Meal": formData.CustomTimes.map((time) =>
                moment(time, "h:mm a").format("HH:mm")
              ),
            }),
          },
        }),
        EndDate: moment()
          .add(
            moment.duration(
              formData.DurationType === "Day"
                ? formData.Duration - 1
                : formData.Duration,
              formData.DurationType
            )
          )
          .format("YYYY-MM-DD"),
      };
      dispatch(
        updatePrescription(
          token,
          selectedItem?.DosageID,
          body,
          navigate,
          patient
        )
      );
      handleCloseEditModal();
    } else {
      toast.error("Please fill all the required fields");
    }
  };

  const handledeleteMedication = () => {
    dispatch(
      deletePrescription(token, selectedItem?.DosageID, navigate, patient)
    );
    setSelectedItem(null);
    setShowDeleteModal(false);
  };

  const handleDiscontinueMedication = () => {
    let newErrors = {};
    if (!reason) newErrors.reason = "Reason is required";
    if (!date) newErrors.date = "Date is required";
    setError(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const body = {
        ReasonForDiscontinuation: reason,
        DisContinuedAt: moment(date).format("YYYY-MM-DD HH:mm:ss"),
        Status: "Discontinued",
      };

      dispatch(
        updatePrescription(
          token,
          selectedItem?.DosageID,
          body,
          navigate,
          patient
        )
      );
      setSelectedItem(null);
      setShowDiscontinueModal(false);
    } else {
      toast.error("Please fill all the required fields");
    }
  };

  const handleShowEditModal = (item) => {
    setSelectedItem(item);
    setShowEditModal(true);
    setFormData({
      Dosage: item.DosageAmount,
      MealTime: item.MealTime,
      Frequency: item.Frequency,
      Duration: item.Duration,
      DurationType: item.DurationType,
      CustomTimes: item.customMealTimes || [""],
    });
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedItem(null);
    setFormData({
      Dosage: "",
      MealTime: "Pre Meal",
      Frequency: "Once",
      Duration: "",
      DurationType: "Day",
      CustomTimes: [""],
    });
    setError({
      Dosage: "",
      Duration: "",
      CustomTimes: "",
    });
  };

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      <Prescription
        show={showAddModal}
        patient={patient}
        data={dosageAdherence}
        hide={() => setShowAddModal(false)}
      />
      {/* Edit Modal */}
      <ModalComponent show={showEditModal} handleClose={handleCloseEditModal}>
        <div className="text-slate-800 text-2xl font-semibold font-poppins leading-loose">
          Edit Medication for {patient?.Name}
        </div>
        <div className="h-px bg-gray-200 my-4" />
        <div className="grid grid-cols-4 gap-6 content-center items-center">
          <div>
            <p className="text-neutral-600 text-sm font-normal font-poppins">
              Consultation Date
            </p>
            <p className="text-zinc-900 text-sm font-normal font-poppins mt-1">
              {moment(selectedItem?.ConsultationDate).format("DD.MM.YYYY")}
            </p>
          </div>
          <div>
            <p className="text-neutral-600 text-sm font-normal font-poppins">
              Session ID
            </p>
            <p className="text-zinc-900 text-sm font-normal font-poppins mt-1">
              {selectedItem?.SessionID || "-"}
            </p>
          </div>
          <div>
            <p className="text-neutral-600 text-sm font-normal font-poppins">
              Medicine Name
            </p>
            <p className="text-zinc-900 text-sm font-normal font-poppins mt-1">
              {selectedItem?.MedicineName}
            </p>
          </div>
          <div>
            <p className="text-neutral-600 text-sm font-normal font-poppins">
              Route
            </p>
            <p className="text-zinc-900 text-sm font-normal font-poppins mt-1">
              {selectedItem?.Route || "-"}
            </p>
          </div>
        </div>
        <div className="h-px bg-gray-200 my-4" />
        <div className="flex flex-col space-y-4">
          <div>
            <label>
              <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                Dosage
              </p>
              <input
                type="text"
                id="Dosage"
                name="Dosage"
                value={formData.Dosage}
                onChange={(e) => handleInputChange("Dosage", e.target.value)}
                maxLength={10}
                placeholder="Ex: 5 mg"
                className={` text-zinc-900 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                  error.Dosage ? "border-red-500" : ""
                } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>
          <div className="flex items-center space-x-6">
            <p className="text-zinc-900 text-sm font-medium font-poppins leading-none">
              Meal Intake:
            </p>
            {["Pre Meal", "Post Meal"].map((option) => (
              <label key={option} className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  className="hidden"
                  value={option}
                  checked={formData.MealTime === option}
                  onChange={(e) =>
                    handleInputChange("MealTime", e.target.value)
                  }
                />
                <RadioIcon selected={formData.MealTime === option} />
                <span className="text-zinc-900 text-sm font-normal font-poppins leading-none">
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </span>
              </label>
            ))}
          </div>
          <div className="flex items-center space-x-6 col-span-2">
            <p className="text-zinc-900 text-sm font-medium font-poppins leading-none">
              Frequency:
            </p>
            {["Once", "Twice", "Thrice", "Custom"].map((option) => (
              <label key={option} className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  className="hidden"
                  value={option}
                  checked={formData.Frequency === option}
                  onChange={(e) =>
                    handleInputChange("Frequency", e.target.value)
                  }
                />
                <RadioIcon selected={formData.Frequency === option} />
                <span className="text-zinc-900 text-sm font-normal font-poppins leading-none">
                  {option.charAt(0).toUpperCase() + option.slice(1)}{" "}
                  {option !== "Custom" ? "a day" : ""}
                </span>
              </label>
            ))}
          </div>
          {formData.Frequency === "Custom" && (
            <div className="flex items-center space-x-4 col-span-2">
              {formData?.CustomTimes.map((time, index) => (
                <div key={index} className="flex items-center mb-2">
                  <TimePicker
                    use12Hours
                    format="h:mm a"
                    value={time ? moment(time, "HH:mm") : null}
                    onChange={(time, timeString) =>
                      handleTimeChange(index, timeString)
                    }
                    className="mr-2"
                  />
                  {formData.CustomTimes.length > 1 && (
                    <button
                      onClick={() => removeCustomTime(index)}
                      className="p-1.5 rounded-md justify-center items-center gap-2 inline-flex border border-gray-300 mr-2"
                    >
                      <RxCross1 className="" />
                    </button>
                  )}
                  {index === formData.CustomTimes.length - 1 && index < 3 && (
                    <button
                      onClick={addCustomTime}
                      className="p-1.5 rounded-md justify-center items-center gap-2 inline-flex border border-gray-300"
                    >
                      <IoAddSharp className="text-sky-400 text-md" />
                    </button>
                  )}
                </div>
              ))}
            </div>
          )}
          <div className="flex items-center space-x-6 col-span-2">
            <p className="text-zinc-900 text-sm font-medium font-poppins leading-none">
              Duration:
            </p>
            <input
              type="text"
              id="Duration"
              name="Duration"
              value={formData.Duration}
              onChange={(e) => handleInputChange("Duration", e.target.value)}
              maxLength={2}
              placeholder="Enter no. of"
              className={`text-zinc-900 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                error.Duration ? "border-red-500" : ""
              } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
            />
            {["Day", "Week", "Month"].map((option) => (
              <label key={option} className="flex items-center cursor-pointer">
                <input
                  type="radio"
                  className="hidden"
                  value={option}
                  checked={formData.DurationType === option}
                  onChange={(e) =>
                    handleInputChange("DurationType", e.target.value)
                  }
                />
                <RadioIcon selected={formData.DurationType === option} />
                <span className="text-zinc-900 text-sm font-normal font-poppins leading-none">
                  {option.charAt(0).toUpperCase() + option.slice(1)}
                </span>
              </label>
            ))}
          </div>
        </div>
        <div className="flex flex-row items-center justify-center mt-4 space-x-4">
          <button
            onClick={() => {
              setShowEditModal(false);
              setShowDiscontinueModal(true);
            }}
            className="w-96 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
          >
            <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
              Discontinue Medication
            </div>
          </button>
          <button
            onClick={handleSubmit}
            className="w-96 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-500 active:scale-95"
          >
            <div className="text-white text-base font-semibold font-poppins leading-normal">
              Save Prescription
            </div>
          </button>
        </div>
      </ModalComponent>

      {/* Discontinue Modal */}
      <ModalComponent
        show={showDiscontinueModal}
        handleClose={() => setShowDiscontinueModal(false)}
      >
        <div className="flex flex-col items-center">
          <div className="w-14 h-14 p-3 bg-gray-100 rounded-full mb-6">
            <img src={discontinue} alt="" className="w-8 h-8" />
          </div>

          <div className="text-center max-w-xs text-slate-800 text-base font-semibold font-poppins leading-normal">
            Discontinue Medication '{selectedItem?.MedicineName}' for{" "}
            {patient?.Name || patient?.FirstName + " " + patient?.LastName}?
          </div>
          <div className="text-center max-w-sm mx-auto text-zinc-500 text-sm font-normal font-poppins leading-tight mt-4">
            Discontinuing this medication will remove it from the active
            prescriptions list. You can still view its history in medication
            list with a discontinued status.
          </div>
        </div>
        <div className="mt-4">
          <label>
            <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
              Reason for Discontinuation
            </p>
            <input
              type="text"
              id="reason"
              name="reason"
              placeholder="Enter reason"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                setError((prevErrors) => {
                  const newErrors = { ...prevErrors };
                  delete newErrors.reason;
                  return newErrors;
                });
              }}
              className={`w-full ${
                error.reason ? "border-red-500" : ""
              } text-zinc-900 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
            />
          </label>
        </div>
        <div className="mt-4">
          <label>
            <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
              Date of Discontinuation
            </p>
            <input
              type="date"
              name="date"
              placeholder="Select Date"
              min={moment().format("YYYY-MM-DD")}
              value={moment(date).format("YYYY-MM-DD")}
              onChange={(e) => {
                setDate(e.target.value);
                setError((prevErrors) => {
                  const newErrors = { ...prevErrors };
                  delete newErrors.date;
                  return newErrors;
                });
              }}
              className={`w-full ${
                error.date ? "border-red-500" : ""
              }  placeholder:text-zinc-500 text-zinc-900 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
            />
          </label>
        </div>

        <div className="flex flex-row items-center justify-end mt-6 space-x-4">
          <button
            onClick={() => {
              setShowDiscontinueModal(false);
              setSelectedItem(null);
              setReason("");
              setDate(null);
              setError({
                Dosage: "",
                Duration: "",
                CustomTimes: "",
              });
            }}
            className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-400 active:scale-95"
          >
            <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
              Keep it
            </div>
          </button>
          <button
            onClick={handleDiscontinueMedication}
            className="w-48 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-400 active:scale-95"
          >
            <div className="text-white text-base font-semibold font-poppins leading-normal">
              Discontinue
            </div>
          </button>
        </div>
      </ModalComponent>

      {/* Delete Modal */}
      <ModalComponent
        show={showDeleteModal}
        handleClose={() => {
          setSelectedItem(null);
          setShowDeleteModal(false);
        }}
        outSideModalPress={false}
        showCloseButton={false}
      >
        <div className="flex flex-col items-center">
          <div className="w-14 h-14 p-3 bg-red-50 rounded-full mb-6">
            <img src={deleteIcon} alt="" />
          </div>
          <div className="text-center text-slate-800 text-base font-semibold font-poppins leading-tight">
            Delete Medication '{selectedItem?.MedicineName}' for{" "}
            {patient?.Name || patient?.FirstName + " " + patient?.LastName}?
          </div>
          <div className="text-center max-w-sm mx-auto text-zinc-500 text-sm font-normal font-poppins leading-tight mt-4">
            Once deleted, this medication record will be deleted from the list.
          </div>
          <div className="flex flex-row items-center justify-end mt-6 space-x-4">
            <button
              onClick={() => {
                setSelectedItem(null);
                setShowDeleteModal(false);
              }}
              className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
                Keep it
              </div>
            </button>
            <button
              onClick={handledeleteMedication}
              className="w-48 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-white text-base font-semibold font-poppins leading-normal">
                Delete Medication
              </div>
            </button>
          </div>
        </div>
      </ModalComponent>
      {/* Header */}
      <div className="flex flex-row items-center justify-between">
        <div className="text-zinc-900 text-2xl font-medium font-poppins">
          {patient?.Name}'s Medication
        </div>
        {/* <img src={settingSelected} alt="" /> */}
        <div className="flex flex-row items-center space-x-4">
          {patient?.hasEditAccess && (
            <button
              onClick={() => setShowAddModal(true)}
              className="px-4 py-2 bg-sky-400 rounded-lg border border-sky-400 flex justify-center items-center gap-2 active:scale-95"
            >
              <img
                src={addPrescription}
                alt="Add Prescription"
                className="w-5 h-5"
              />

              <div className="text-white text-sm font-semibold poppins leading-tight">
                Add Prescription
              </div>
            </button>
          )}

          <button
            onClick={() => navigate(-1)}
            className="px-4 py-2 flex items justify-center bg-white rounded-lg shadow border border-neutral-300 group"
          >
            <BsArrowsAngleContract className="w-5 h-5 text-sky-400 group-active:scale-90" />
          </button>
        </div>
      </div>
      <Breadcrumb />

      {/* Search and Button  */}
      <div className="flex flex-row items-center justify-between mt-5">
        <label className="w-2/3 flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2">
          <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
          <input
            type="text"
            placeholder="Search for medications"
            value={searchTerm}
            onChange={handleSearchChange}
            className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
          />
        </label>

        <div class="flex flex-row items-center">
          <button
            onClick={() => handleFilterChange("all")}
            className={`px-4 py-2.5 ${
              filter === "all"
                ? "bg-sky-100 text-sky-700 border-blue-200"
                : "bg-white text-neutral-600 border-neutral-300"
            }  rounded-tl-lg rounded-bl-lg border-l border-t border-b text-sm font-medium font-poppins leading-tight`}
          >
            All
          </button>
          <button
            onClick={() => handleFilterChange("ongoing")}
            className={`px-4 py-2.5 ${
              filter === "ongoing"
                ? "bg-sky-100 text-sky-700 border-blue-200"
                : "bg-white text-neutral-600 border-neutral-300"
            }  border text-sm font-medium font-poppins leading-tight`}
          >
            Ongoing
          </button>
          <button
            onClick={() => handleFilterChange("completed")}
            className={`px-4 py-2.5 ${
              filter === "completed"
                ? "bg-sky-100 text-sky-700 border-blue-200"
                : "bg-white text-neutral-600 border-neutral-300"
            }  border text-sm font-medium font-poppins leading-tight`}
          >
            Completed
          </button>
          <button
            onClick={() => handleFilterChange("discontinued")}
            className={`px-4 py-2.5 ${
              filter === "discontinued"
                ? "bg-sky-100 text-sky-700 border-blue-200"
                : "bg-white text-neutral-600 border-neutral-300"
            }  rounded-tr-lg rounded-br-lg border-r border-t border-b text-sm font-medium font-poppins leading-tight`}
          >
            Discontinued
          </button>
        </div>
      </div>

      <div className="w-full mx-auto mt-4 shadow rounded-md">
        <table className="min-w-full">
          <thead>
            <tr className="text-left bg-gray-50 border-b border-gray-200">
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Medicine Name
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Dosage
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Frequency
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Route
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Prescribing Physician
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Duration
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Status
              </th>
              {patient?.hasEditAccess && (
                <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                  Action
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {currentMedicine
              ?.filter((item) => {
                if (filter === "all") return true;
                if (filter === "ongoing") return item.Status === "Ongoing";
                if (filter === "completed") return item.Status === "Completed";
                if (filter === "discontinued")
                  return item.Status === "Discontinued";
              })
              .map((item) => {
                return (
                  <tr
                    key={item?.AHUID}
                    className="border-b border-gray-200 hover:bg-sky-50"
                  >
                    <td className="p-4">
                      <div className="flex items-center">
                        <div>
                          <p className="text-zinc-900 text-sm font-normal font-poppins leading-tight">
                            {item?.MedicineName}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                      {item?.DosageAmount}
                    </td>
                    <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                      {item?.Frequency}
                    </td>
                    <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                      {item?.Route || "-"}
                    </td>
                    <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                      {item?.DoctorName || "-"}
                    </td>
                    <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                      {moment(item.StartDate).format("Do MMM")} -{" "}
                      {moment(item.EndDate).format("Do MMM YYYY")}
                    </td>
                    <td className="p-4">
                      <div className="flex items-center space-x-4">
                        <div
                          className={`px-4 py-2 rounded-3xl justify-center items-center gap-1.5 inline-flex ${
                            item?.Status === "Ongoing"
                              ? "bg-orange-100 "
                              : item?.Status === "Completed"
                              ? "bg-emerald-100"
                              : "bg-gray-100"
                          }`}
                        >
                          <img
                            src={
                              item?.Status === "Ongoing"
                                ? ongoing
                                : item?.Status === "Completed"
                                ? completed
                                : discontinue
                            }
                            alt=""
                            className="w-4 h-4"
                          />

                          <div
                            className={`text-center text-xs font-semibold font-poppins leading-3 ${
                              item?.Status === "Ongoing"
                                ? "text-amber-800"
                                : item?.Status === "Completed"
                                ? "text-neutral-600"
                                : "text-neutral-600"
                            }`}
                          >
                            {item?.Status}
                          </div>
                        </div>
                      </div>
                    </td>
                    {patient?.hasEditAccess && (
                      <td className="p-4">
                        <div className="flex flex-row items-center space-x-2">
                          <Tooltip message="Edit">
                            <button
                              onClick={() => {
                                // setSelectedItem(item);
                                // setShowEditModal(true);
                                handleShowEditModal(item);
                              }}
                              className="p-1 rounded-full group group-hover:bg-sky-100"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                              >
                                <path
                                  d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  stroke-linecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                          <Tooltip message="Delete">
                            <button
                              onClick={() => {
                                setSelectedItem(item);
                                setShowDeleteModal(true);
                              }}
                              className="p-1 rounded-full group group-hover:bg-sky-100"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                              >
                                <path
                                  d="M19.3008 5.5L18.6811 15.5251C18.5227 18.0864 18.4436 19.3671 17.8016 20.2879C17.4841 20.7431 17.0755 21.1273 16.6015 21.416C15.6429 22 14.3598 22 11.7935 22C9.2239 22 7.93908 22 6.97983 21.4149C6.50558 21.1257 6.09678 20.7408 5.77946 20.2848C5.13766 19.3626 5.06023 18.0801 4.90539 15.5152L4.30078 5.5"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  stroke-linecap="round"
                                />
                                <path
                                  d="M2.80078 5.5H20.8008M15.8565 5.5L15.1739 4.09173C14.7204 3.15626 14.4936 2.68852 14.1025 2.39681C14.0158 2.3321 13.9239 2.27454 13.8278 2.2247C13.3947 2 12.8749 2 11.8353 2C10.7696 2 10.2368 2 9.79646 2.23412C9.69888 2.28601 9.60576 2.3459 9.51807 2.41317C9.12242 2.7167 8.90141 3.20155 8.45939 4.17126L7.8537 5.5"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  stroke-linecap="round"
                                />
                                <path
                                  d="M9.30078 16.5V10.5"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  stroke-linecap="round"
                                />
                                <path
                                  d="M14.3008 16.5V10.5"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <Pagination
        totalItems={filteredMedicine.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default MedicationFullScreen;
