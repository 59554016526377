import styles from "./styles.module.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import { FaList } from "react-icons/fa6";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import PatientListView from "../components/PatientListView";
import PatientCardView from "../components/PatientCardView";
import noPatientFound from "../assets/Images/PatientDashboard/noPatientFound.png";
import Breadcrumb from "../components/Breadcrumb";
import Pagination from "../components/Pagination";

function PatientDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isListView, setIsListView] = useState(
    sessionStorage.getItem("isListView") !== "false"
  );
  const [searchInput, setSearchInput] = useState("");
  const { allPatients, loading: patientLoading } = useSelector(
    (state) => state.patient
  );
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;
  const filteredPatients = allPatients.filter((p) => {
    const dob = new Date(p.DOB);
    const month = dob.toLocaleString("default", { month: "long" });
    return `${p.Name} ${p.DOB} ${month}`
      .toLowerCase()
      .includes(searchInput.toLowerCase());
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPatients = filteredPatients.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  useEffect(() => {
    sessionStorage.setItem("isListView", isListView);
  }, [isListView]);

  if (patientLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  if (allPatients.length === 0) {
    return (
      <div className="bg-[#F3F3F3] min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth">
        <div className="bg-white rounded-lg shadow-lg flex flex-col items-center justify-center h-full p-20">
          <img
            src={noPatientFound}
            alt=""
            className="w-[533px] h-72 object-contain"
          />
          <div className="text-black text-xl font-bold font-Inter">
            Add New Patients to view them on this screen
          </div>
          <button
            className="w-1/2 mx-auto bg-sky-400 text-center text-stone-50 text-sm font-semibold font-poppins py-3 rounded-lg mt-4 hover:opacity-80"
            onClick={() => {
              navigate("/patientDashboard/patientRegistration");
            }}
          >
            Add New Patient
          </button>
        </div>
      </div>
    );
  }

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      {/* Header */}
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
       Patient Management
      </div>
      {/* <Breadcrumb /> */}

      {/* Search and Button  */}
      <div className="flex flex-row items-center justify-between mt-5">
        <label className="w-2/3 flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2">
          <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
          <input
            type="text"
            placeholder="Search patient here"
            value={searchInput}
            onChange={handleSearchChange}
            className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
          />
        </label>

        <div className="flex flex-row items-center space-x-5">
          <div className="flex flex-row items-center space-x-2">
            <button
              onClick={() => setIsListView(true)}
              className={`w-10 h-10 rounded-full ${
                isListView ? "bg-sky-400" : "bg-sky-100"
              } flex items-center justify-center active:scale-95`}
            >
              <FaList
                className={`${isListView ? "text-white" : "text-sky-400"}`}
              />
            </button>
            <button
              onClick={() => setIsListView(false)}
              className={`w-10 h-10 rounded-full ${
                !isListView ? "bg-sky-400" : "bg-sky-100"
              } flex items-center justify-center active:scale-95`}
            >
              <RxDashboard
                className={`${!isListView ? "text-white" : "text-sky-400"}`}
              />
            </button>
          </div>
          <button
            onClick={() => {
              navigate("/patientDashboard/patientRegistration");
            }}
            className="px-3 py-2 bg-sky-400 rounded shadow justify-center items-center gap-2.5 inline-flex cursor-pointer active:scale-95"
          >
            <FaPlus className="text-white text-sm" />
            <div className="text-white text-sm font-normal font-poppins">
              Add New Patient
            </div>
          </button>
        </div>
      </div>

      {isListView ? (
        <PatientListView patient={currentPatients} />
      ) : (
        <PatientCardView patient={currentPatients} />
      )}

      <button
        onClick={() => {
          navigate("archivePatients");
        }}
        className="text-[#263238] text-xs font-normal underline font-poppins leading-[18px] w-full text-end my-5"
      >
        View All Archives
      </button>

      <Pagination
        totalItems={allPatients.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default PatientDashboard;
