import React from "react";
import Lottie from "lottie-react";
import animationData from "../assets/animation/Dashboard.json";
import { IoIosArrowRoundBack } from "react-icons/io";
import { FaChevronLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
function LabPartnerDashboard() {
  return (
    <div className="bg-white min-h-full flex flex-col items-center justify-center py-2 px-2 sm:py-5 sm:px-10 scroll-smooth">
      <Lottie
        animationData={animationData}
        loop={true}
        className=" w-96 h-96"
      />
      {/* <button
        onClick={() => {
          navigate(-1);
        }}
        className="flex flex-row items-center justify-center space-x-2 active:scale-95"
      >
        <FaChevronLeft className="h-5" />
        <h1 className="text-zinc-900 text-lg font-semibold">Go Back</h1>
      </button> */}
    </div>
  );
}

export default LabPartnerDashboard;
