import React, { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import TabBar from "../components/TabBar";
import ModalComponent from "../components/Modal";
import create_form_icon from "../assets/Images/ConsultationForms/create_form_icon.svg";
import upload_form_icon from "../assets/Images/ConsultationForms/upload_form_icon.svg";
import pre_consultation from "../assets/Images/ConsultationForms/pre_consultation.svg";
import post_consultation from "../assets/Images/ConsultationForms/post_consultation.svg";
import pre_consultation_selected from "../assets/Images/ConsultationForms/pre_consultation_selected.svg";
import post_consultation_selected from "../assets/Images/ConsultationForms/post_consultation_selected.svg";
import form_icon from "../assets/Images/ConsultationForms/form_icon.svg";
import delete_icon from "../assets/Icons/delete_icon.svg";
import pdf_icon from "../assets/Icons/pdf_icon.svg";
import pencil_icon from "../assets/Icons/pencil_icon.svg";
import settings_icon from "../assets/Icons/settings_icon.svg";
import Tooltip from "../components/Tooltip";
import moment from "moment";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import deleteIcon from "../assets/Images/MedicalConcierge/deleteRedIcon.svg";
import {
  deleteConsultationForm,
  updateConsultationForm,
  uploadConsultationForm,
} from "../services/operations/consultationFormAPI";
import PdfComp from "../components/PdfComp";
import toast from "react-hot-toast";
import CheckboxIcon from "../components/CheckBoxIcon";

function ConsultationForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const { token } = useSelector((state) => state.auth);
  const {
    preConsultationForm,
    postConsultationForm,
    loading: formLoading,
  } = useSelector((state) => state.form);
  const [forms, setForms] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [openDocument, setOpenDocument] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [error, setError] = useState(false);
  const [newName, setNewName] = useState("");
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (selectedTab === 0) {
      setForms(preConsultationForm);
    } else {
      setForms(postConsultationForm);
    }
  }, [selectedTab, preConsultationForm, postConsultationForm]);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const validateFile = (file) => {
    const validTypes = ["application/pdf"];
    const maxSize = 10 * 1024 * 1024; // 10MB

    if (!validTypes.includes(file.type)) {
      alert("Only PDF files are allowed");
      return false;
    }

    if (file.size > maxSize) {
      alert("File size must be less than 10MB");
      return false;
    }

    return true;
  };

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    const validFiles = files.filter(validateFile);

    if (validFiles.length > 0) {
      setUploadedFiles((prev) => [
        ...prev,
        ...validFiles.map((file) => ({
          file,
        })),
      ]);
      uploadForm(validFiles); // Call uploadForm with validFiles
    }
  }, []);

  const handleFileInput = useCallback((e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(validateFile);

    if (validFiles.length > 0) {
      setUploadedFiles((prev) => [
        ...prev,
        ...validFiles.map((file) => ({
          file,
        })),
      ]);
      uploadForm(validFiles); // Call uploadForm with validFiles
    }
  }, []);

  const uploadForm = (files) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("ConsultationForm", file);
    });

    console.log("====================================");
    console.log(selectedTab === 0 ? "PRE_CONSULTATION" : "POST_CONSULTATION");
    console.log("====================================");
    formData.append(
      "Type",
      selectedTab === 0 ? "PRE_CONSULTATION" : "POST_CONSULTATION"
    );

    dispatch(uploadConsultationForm(token, formData));
    setUploadedFiles([]);
    setShowUploadModal(false);
  };

  const closeDeleteModal = () => {
    setSelectedForm(null);
    setShowDeleteModal(false);
  };

  const handleDeleteForm = () => {
    dispatch(deleteConsultationForm(token, selectedForm.CFID));
    closeDeleteModal();
  };

  const handleOpenDocument = (item) => {
    fetchFile(item);
    setSelectedForm(item);
    setOpenDocument(true);
  };

  const fetchFile = async (item) => {
    try {
      // const res = await getBucketURL(item?.ConsultationFormURL);
      setPdfUrl(item?.ConsultationFormURL);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditClick = (form) => {
    setIsEditing(form.CFID);
    setNewName(form.Name);
  };

  const handleNameChange = (e) => {
    setNewName(e.target.value);
    setError(false);
  };

  const handleBlur = (form) => {
    if (newName === "") {
      toast.error("Form name cannot be empty");
      setError(true);
      return;
    }

    if (newName !== form.Name) {
      const body = {
        Name: newName,
      };
      dispatch(updateConsultationForm(token, form.CFID, body));
    }
    setIsEditing(null);
  };

  const handleToggle = (form) => {
    const body = {
      IsDefault: !form.IsDefault,
    };
    dispatch(updateConsultationForm(token, form.CFID, body));
  };

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      <ModalComponent
        show={showUploadModal}
        handleClose={() => {
          setShowUploadModal(false);
          setUploadedFiles([]);
        }}
        outSideModalPress={false}
      >
        <div className="text-zinc-900 text-sm font-normal font-poppins">
          Upload File
        </div>

        <div
          className={`w-96 p-4 bg-white rounded-2xl border border-dashed ${
            isDragging ? "border-sky-400 bg-sky-50" : "border-zinc-500"
          } flex-col justify-start items-center gap-4 inline-flex mt-1`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className="w-14 h-14 bg-sky-100 rounded-full justify-center items-center gap-2.5 inline-flex">
            <img src={upload_form_icon} alt="upload_form_icon" />
          </div>
          <div className="text-center text-stone-950 text-lg font-medium font-poppins leading-snug">
            {isDragging ? "Drop your file here" : "Upload"}
          </div>

          <div className="text-center text-neutral-600 text-sm font-normal font-poppins leading-tight">
            (Drag & Drop or Choose file to upload)
          </div>
          <div className="text-center text-neutral-600 text-xs font-normal font-poppins leading-none">
            (Max File size limit: 10 MB & File Format: only pdf)
          </div>
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            accept=".pdf"
            multiple
            onChange={handleFileInput}
          />
          <button
            className="w-2/3 px-5 py-3 bg-sky-400 rounded-lg border border-sky-400 active:scale-95 hover:bg-sky-500 transition-colors"
            onClick={() => fileInputRef.current?.click()}
          >
            <div className="text-white text-center text-sm font-semibold font-poppins leading-normal">
              Browse
            </div>
          </button>
        </div>
      </ModalComponent>

      <ModalComponent
        show={showDeleteModal}
        handleClose={closeDeleteModal}
        outSideModalPress={false}
        showCloseButton={false}
      >
        <div className="flex flex-col items-center">
          <div className="w-14 h-14 p-3 bg-red-50 rounded-full mb-6">
            <img src={deleteIcon} alt="" />
          </div>
          <div className="text-center text-slate-800 text-base font-semibold font-poppins leading-tight">
            Delete Form '{selectedForm?.Name}' ?
          </div>
          <div className="text-center max-w-sm mx-auto text-zinc-500 text-sm font-normal font-poppins leading-tight mt-4">
            Are you sure you want to delete this form? This action cannot be
            undone.
          </div>
          <div className="flex flex-row items-center justify-end mt-6 space-x-4">
            <button
              onClick={closeDeleteModal}
              className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
                Cancel
              </div>
            </button>
            <button
              onClick={handleDeleteForm}
              className="w-48 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-white text-base font-semibold font-poppins leading-normal">
                Delete
              </div>
            </button>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        show={openDocument}
        handleClose={() => {
          setOpenDocument(false);
          setSelectedForm(null);
          setPdfUrl(null);
        }}
        outSideModalPress={false}
      >
        <div className="flex flex-row items-center justify-between mb-4">
          <div className="flex flex-row items-center space-x-4">
            <div className="text-black text-sm font-semibold font-poppins">
              {selectedForm?.Name}
            </div>
          </div>
        </div>
        <div className="border border-b-1 border-gray-200 mb-4"></div>
        <div className="m-5">
          <div
            className={`flex flex-col items-center justify-center overflow-y-auto`}
          >
            {pdfUrl && <PdfComp pdf={pdfUrl} />}
            {/* <PdfComp pdf={pdfUrl} /> */}
          </div>
        </div>
      </ModalComponent>

      {/* Header */}
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        Consultation Forms
      </div>
      <Breadcrumb />

      <div className="flex flex-row items-center justify-between mt-6">
        <div className="flex flex-row items-center space-x-1">
          {TABS.map((tab) => (
            <TabBar
              key={tab.id}
              id={tab.id}
              name={tab.name}
              icon={tab.icon}
              icon2={tab.icon2}
              selectedTab={selectedTab}
              onClick={setSelectedTab}
            />
          ))}
        </div>
        <button
          onClick={() => navigate("formSettings")}
          className="flex flex-row items-center space-x-2"
        >
          <div className="flex items-center space-x-1">
            <img src={settings_icon} alt="" />
            <p className="text-sky-700 text-sm font-semibold font-poppins underline leading-none">
              Form Settings
            </p>
          </div>
        </button>
      </div>

      {forms.length > 0 ? (
        <>
          <div className="w-full mx-auto mt-4 shadow rounded-md">
            <table className="min-w-full">
              <thead>
                <tr className="text-left bg-gray-50 border-b border-gray-200">
                  <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                    Form Name
                  </th>
                  <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                    Last Updated
                  </th>
                  <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {forms?.map((form, i) => (
                  <tr
                    key={i}
                    className="border-b border-gray-200 hover:bg-sky-50"
                  >
                    <td className="p-4">
                      <div className="flex items-center space-x-2">
                        <img
                          src={form.ConsultationFormURL ? pdf_icon : form_icon}
                          alt="pdf_icon"
                        />
                        {isEditing === form.CFID ? (
                          <input
                            type="text"
                            value={newName}
                            onChange={handleNameChange}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleBlur(form);
                              }
                            }}
                            onBlur={() => handleBlur(form)}
                            className={`${
                              error ? "border-red-500" : ""
                            } text-neutral-600 text-sm font-normal font-poppins leading-tight p-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                            autoFocus
                          />
                        ) : (
                          <p className="text-neutral-600 text-sm font-normal font-poppins leading-tight">
                            {form.Name}
                          </p>
                        )}
                        <button onClick={() => handleEditClick(form)}>
                          <img src={pencil_icon} alt="" />
                        </button>
                      </div>
                    </td>
                    <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                      {moment(form?.UpdatedAt).format("DD MMM YYYY")}
                    </td>
                    <td className="p-4">
                      <div className="flex flex-row items-center space-x-2">
                        <label className="flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            className="hidden"
                            checked={form.IsDefault}
                            onChange={() => handleToggle(form)}
                          />
                          <CheckboxIcon checked={form.IsDefault} />
                          <span className="ml-2 text-zinc-900 text-sm font-normal font-poppins">
                            Set as Default
                          </span>
                        </label>
                        <Tooltip message="View">
                          <button
                            onClick={() => handleOpenDocument(form)}
                            className="p-1 rounded-full group group-hover:bg-sky-100"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                            >
                              <path
                                d="M21.3448 11.045C21.6488 11.4713 21.8008 11.6845 21.8008 12C21.8008 12.3155 21.6488 12.5287 21.3448 12.955C19.9787 14.8706 16.49 19 11.8008 19C7.11156 19 3.62288 14.8706 2.25682 12.955C1.95279 12.5287 1.80078 12.3155 1.80078 12C1.80078 11.6845 1.95279 11.4713 2.25682 11.045C3.62288 9.12944 7.11156 5 11.8008 5C16.49 5 19.9787 9.12944 21.3448 11.045Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                              />
                              <path
                                d="M14.8008 12C14.8008 10.3431 13.4577 9 11.8008 9C10.1439 9 8.80078 10.3431 8.80078 12C8.80078 13.6569 10.1439 15 11.8008 15C13.4577 15 14.8008 13.6569 14.8008 12Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                              />
                            </svg>
                          </button>
                        </Tooltip>
                        {!form.ConsultationFormURL && (
                          <Tooltip message="Edit">
                            <button
                              onClick={() => {
                                setSelectedForm(form);
                              }}
                              className="p-1 rounded-full group group-hover:bg-sky-100"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                              >
                                <path
                                  d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  stroke-linecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                                  stroke="currentColor"
                                  strokeWidth="1.5"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                        )}
                        <Tooltip message="Delete">
                          <button
                            onClick={() => {
                              setSelectedForm(form);
                              setShowDeleteModal(true);
                            }}
                            className="p-1 rounded-full group group-hover:bg-sky-100"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                            >
                              <path
                                d="M19.3008 5.5L18.6811 15.5251C18.5227 18.0864 18.4436 19.3671 17.8016 20.2879C17.4841 20.7431 17.0755 21.1273 16.6015 21.416C15.6429 22 14.3598 22 11.7935 22C9.2239 22 7.93908 22 6.97983 21.4149C6.50558 21.1257 6.09678 20.7408 5.77946 20.2848C5.13766 19.3626 5.06023 18.0801 4.90539 15.5152L4.30078 5.5"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                stroke-linecap="round"
                              />
                              <path
                                d="M2.80078 5.5H20.8008M15.8565 5.5L15.1739 4.09173C14.7204 3.15626 14.4936 2.68852 14.1025 2.39681C14.0158 2.3321 13.9239 2.27454 13.8278 2.2247C13.3947 2 12.8749 2 11.8353 2C10.7696 2 10.2368 2 9.79646 2.23412C9.69888 2.28601 9.60576 2.3459 9.51807 2.41317C9.12242 2.7167 8.90141 3.20155 8.45939 4.17126L7.8537 5.5"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                stroke-linecap="round"
                              />
                              <path
                                d="M9.30078 16.5V10.5"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                stroke-linecap="round"
                              />
                              <path
                                d="M14.3008 16.5V10.5"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                stroke-linecap="round"
                              />
                            </svg>
                          </button>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex items-center justify-end mt-4">
            <button
              onClick={() => navigate("addConsultationForm")}
              className="h-10 px-4 py-2 bg-sky-400 rounded-lg border border-sky-400 justify-center items-center gap-2 inline-flex active:scale-95"
            >
              <FiPlus className="text-white text-lg font-semibold" />
              <div className="text-white text-sm font-semibold font-poppins leading-tight">
                Create New
              </div>
            </button>
            <div className="w-11 h-7 text-center text-black text-sm font-normal font-poppins">
              Or
            </div>
            <button
              onClick={() => setShowUploadModal(true)}
              className="h-10 px-4 py-2 bg-white rounded-lg border border-sky-700 justify-center items-center gap-2 inline-flex active:scale-95"
            >
              <img src={upload_form_icon} alt="upload_form_icon" />
              <div className="text-sky-700 text-sm font-medium font-poppins leading-tight">
                Upload
              </div>
            </button>
          </div>
        </>
      ) : (
        <div className="w-full max-w-2xl mt-6 p-20 rounded-lg shadow-md">
          <div className="flex flex-col items-center">
            <div className="flex items-center justify-center">
              {/* Upload Option */}
              <button
                onClick={() => setShowUploadModal(true)}
                className="h-20 p-2 bg-white rounded-lg shadow border border-gray-200 flex flex-row items-center space-x-2 hover:border-sky-400 hover:shadow-lg transition-colors duration-200"
              >
                <div className="w-14 h-14 bg-sky-100 rounded-full justify-center items-center gap-2.5 inline-flex">
                  <img src={upload_form_icon} alt="upload_form_icon" />
                </div>
                <div>
                  <div className="text-center text-stone-950 text-sm font-medium font-poppins leading-none">
                    Upload
                  </div>
                  <div className="text-center text-neutral-600 text-xs font-normal font-poppins leading-3 mt-1">
                    (click to upload)
                  </div>
                </div>
              </button>

              {/* Or Divider */}
              <div className="text-center text-black text-lg font-normal font-poppins mx-7">
                Or
              </div>

              {/* Create from Scratch Option */}
              <button
                onClick={() => navigate("addConsultationForm")}
                className="h-20 p-2 bg-white rounded-lg shadow border border-gray-200 flex flex-row items-center space-x-2 hover:border-sky-400 hover:shadow-lg transition-colors duration-200"
              >
                <img src={create_form_icon} alt="addConsultationForm" />

                <div>
                  <div className="text-center text-stone-950 text-sm font-medium font-poppins leading-none">
                    Create from Scratch
                  </div>
                  <div className="text-center text-neutral-600 text-xs font-normal font-poppins leading-3 mt-1">
                    (click to Add)
                  </div>
                </div>
              </button>
            </div>
            <div className="text-center text-zinc-900 text-lg font-semibold font-poppins leading-snug mt-4">
              Add your first {selectedTab === 0 ? "Pre" : "Post"}-consultation
              form
            </div>
            <div className="w-72 text-center text-neutral-600 text-sm font-normal font-poppins leading-tight mt-1">
              Gather important information & patient concerns before the
              consultation
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConsultationForm;

const TABS = [
  {
    id: 0,
    name: "Pre-Consultation",
    icon: pre_consultation,
    icon2: pre_consultation_selected,
  },
  {
    id: 1,
    name: "Post-Consultation",
    icon: post_consultation,
    icon2: post_consultation_selected,
  },
];

// {uploadedFiles.length > 0 && (
//   <>
//     <div className="text-zinc-900 text-sm font-medium font-poppins leading-none mt-6">
//       Uploaded Files
//     </div>

//     <div className="space-y-2 mt-4 pr-2 max-h-[30vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
//       {uploadedFiles.map((fileData, index) => (
//         <div
//           key={index}
//           className="w-full p-2 bg-white rounded-lg border border-gray-200"
//         >
//           <div className="flex flex-row justify-between items-start">
//             <div className="flex flex-row">
//               <div className="h-14 p-4 bg-red-50 rounded-lg justify-start items-start gap-2.5 inline-flex">
//                 <img src={pdf_icon} alt="pdf_icon" />
//               </div>

//               <div className="ml-2">
//                 <div className="h-5 text-zinc-900 text-sm font-normal font-poppins leading-tight">
//                   {fileData.file.name}
//                 </div>
//                 <div className="text-neutral-600 text-xs font-normal font-poppins leading-none mt-1">
//                   {(fileData.file.size / (1024 * 1024)).toFixed(1)} MB
//                   {/* •{" "}
//                   {fileData.progress}% done • {fileData.timeLeft} left */}
//                 </div>
//               </div>
//             </div>
//             <button onClick={() => handleDelete(index)}>
//               <img src={delete_icon} alt="delete_icon" />
//             </button>
//           </div>
//         </div>
//       ))}
//     </div>
//   </>
// )}
