import React, { useEffect, useState } from "react";
import ModalComponent from "./Modal";
import { useNavigate } from "react-router-dom";
import { languageList } from "../data/language";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import TabBar from "./TabBar";
import PhoneInput from "react-phone-input-2";
import MultiSelectDropdown from "./MultiSelectDropdown";
import Dropdown from "./Dropdown";
import { countries } from "../data/country";
import { uploadOrganizationLogo } from "../services/operations/masterDataAPI";
import toast from "react-hot-toast";
import imageCompression from "browser-image-compression";
import { updateUserProfile } from "../services/operations/profileAPI";
import camera_icon from "../assets/Icons/camera_icon.svg";

function MyProfileModal({
  showProfileModal,
  setShowProfileModal,
  user,
  setShowChangePassword,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const { token } = useSelector((state) => state.auth);
  const { allOrganizationUsers } = useSelector((state) => state.masterData);
  const currentClinician = allOrganizationUsers?.find(
    (orgUser) => orgUser?.DID === user?.DID
  );
  const [image, setImage] = useState("");
  const [formData, setFormData] = useState({
    ImageLocation: "",
    PhoneNumber: "",
    Languages: [""],
    StreetName: "",
    HouseNumber: "",
    City: "",
    State: "",
    PinCode: "",
    Country: "",
  });

  const [errors, setErrors] = useState({});

  function handleOnChange(event) {
    const { name, value } = event.target;
    let maxLength;

    // Set the maxLength based on the field name
    switch (name) {
      case "PinCode":
        maxLength = 6;
        break;
      default:
        maxLength = Infinity; // No maxLength for other fields
    }

    // For other types, handle validation without removing non-numeric characters
    if (value.length <= maxLength) {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  }

  function handleSelect(name, option) {
    setFormData((prev) => ({
      ...prev,
      [name]: option,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  useEffect(() => {
    setFormData({
      ImageLocation: currentClinician?.ProfileImageUrl,
      PhoneNumber: currentClinician?.Phone,
      Languages:
        currentClinician?.RelatedLanguages?.map(
          (language) => language.LanguageID
        ) || [],
      StreetName: currentClinician.Street,
      HouseNumber: currentClinician.HouseNo,
      PinCode: currentClinician.PinCode,
      City: currentClinician.City,
      State: currentClinician.State,
      Country: currentClinician.Country,
    });
    setImage(currentClinician?.Image || "");
  }, [user]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const toastId = toast.loading("Compressing Image...");
    const compressedFile = await imageCompression(file, options);
    toast.dismiss(toastId);
    uploadImage(compressedFile);
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("ProfileImage", file);
    try {
      const res = await uploadOrganizationLogo(token, formData);
      setFormData((prev) => ({
        ...prev,
        ImageLocation: res?.ProfileImageUrl,
      }));
      setErrors((prev) => ({
        ...prev,
        ImageLocation: "",
      }));
      setImage(URL.createObjectURL(file));
    } catch (error) {
      console.log(error);
    }
  };

  const renderInputFields = () => {
    switch (selectedTab) {
      case 0:
        return (
          <>
            {/* Phone Number */}
            <div className="mb-3">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-1">
                  Phone Number
                  <sup className="text-red-600">*</sup>
                </p>
                <PhoneInput
                  className="number"
                  country={"ch"}
                  value={formData.PhoneNumber}
                  onChange={(value) => {
                    setFormData({ ...formData, PhoneNumber: value });
                    setErrors({ ...errors, PhoneNumber: "" });
                  }}
                  inputClass={`w-full text-black text-xs font-normal font-poppins rounded-lg p-3 border ${
                    errors.PhoneNumber ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  inputStyle={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#F3F5F7",
                    border: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    outline: "none",
                    borderRadius: 6,
                  }}
                  buttonStyle={{
                    borderLeft:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderTop: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderBottom:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderRight: errors.PhoneNumber && "1px solid transparent",
                    borderTopLeftRadius: 6,
                    borderBottomLeftRadius: 6,
                  }}
                />
              </label>
            </div>
            {/* Languages */}
            <div className="mb-3">
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-1">
                Languages Known
                <sup className="text-red-600">*</sup>
              </p>
              <MultiSelectDropdown
                placeholder="Select Languages"
                error={errors.Languages}
                name="Languages"
                options={languageList}
                onSelect={handleSelect}
                value={formData.Languages}
                labelKey="name"
                valueKey="id"
              />
            </div>
            <div className="">
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-1">
                Hours /week ( Auto-calculated)
              </p>

              <div className="flex flex-row items-center justify-between bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent cursor-pointer">
                <div className="text-zinc-900 text-sm font-normal font-popins">
                  {currentClinician?.WorkingHours} hrs/week
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="w-6 h-6 text-neutral-600"
                >
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M12 8V12L14 14"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <div className="grid grid-cols-2 gap-4">
              {/* Street Name */}
              <div>
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-1">
                    Street Name
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="text"
                    name="StreetName"
                    value={formData.StreetName}
                    onChange={handleOnChange}
                    className={`w-full ${
                      errors.StreetName ? "border-red-500" : ""
                    } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>
              {/* Street Name */}
              <div>
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-1">
                    House Number
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="text"
                    name="HouseNumber"
                    value={formData.HouseNumber}
                    onChange={handleOnChange}
                    className={`w-full ${
                      errors.HouseNumber ? "border-red-500" : ""
                    } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>
              {/* Zip Code */}
              <div>
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-1">
                    Pincode
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="text"
                    maxLength={6}
                    name="PinCode"
                    value={formData.PinCode}
                    onChange={handleOnChange}
                    className={`w-full ${
                      errors.PinCode ? "border-red-500" : ""
                    } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>
              {/* City */}
              <div>
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-1">
                    City
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="text"
                    name="City"
                    value={formData.City}
                    onChange={handleOnChange}
                    className={`w-full ${
                      errors.City ? "border-red-500" : ""
                    } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>
              {/* State */}
              <div>
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-1">
                    State
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    type="text"
                    name="State"
                    value={formData.State}
                    onChange={handleOnChange}
                    className={`w-full ${
                      errors.Address ? "border-red-500" : ""
                    } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>
              {/* Country */}
              <div>
                <label>
                  <p className="text-zinc-950 text-xs font-semibold font-poppins mb-1">
                    Country
                    <sup className="text-red-600">*</sup>
                  </p>
                  <Dropdown
                    placeholder={"Select Country"}
                    error={errors.Country}
                    name="Country"
                    options={countries}
                    onSelect={handleSelect}
                    value={formData.Country}
                    labelKey="label"
                    valueKey="value"
                    enableSearch={true}
                    position="top"
                  />
                </label>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const submitHandler = async () => {
    const mandatoryFields = [
      "ImageLocation",
      "PhoneNumber",
      "Languages",
      "StreetName",
      "HouseNumber",
      "City",
      "State",
      "PinCode",
      "Country",
    ];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      return;
    }

    const body = {
      ProfileImageUrl: formData.ImageLocation,
      PhoneNumber: formData.PhoneNumber,
      PreferredLanguage: formData.Languages.map((item) => item),
      Street: formData.StreetName,
      HouseNo: formData.HouseNumber,
      PinCode: formData.PinCode,
      City: formData.City,
      State: formData.State,
      Country: formData.Country,
    };
    dispatch(updateUserProfile(token, user.DID, body, navigate));
    setShowProfileModal(false);
  };

  return (
    <>
      <ModalComponent
        show={showProfileModal}
        handleClose={() => setShowProfileModal(false)}
        outSideModalPress={false}
      >
        <div className="container w-[30vw] mx-auto">
          <div className="text-slate-800 text-2xl font-semibold font-poppins leading-loose">
            My Profile
          </div>

          <div className="h-px border border-gray-200 my-3" />

          {/* Change Password */}
          <div className="flex items-center justify-end">
            <button
              onClick={() => {
                setShowChangePassword(true);
                setShowProfileModal(false);
              }}
              className="flex items-center space-x-1"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.44 2.5C9.39437 2.5 6.92 4.97437 6.92 8.02V10.66H6.68C5.76173 10.66 5 11.4217 5 12.34V20.98C5 21.8983 5.76173 22.66 6.68 22.66H18.2C19.1183 22.66 19.88 21.8983 19.88 20.98V12.34C19.88 11.4217 19.1183 10.66 18.2 10.66H17.96V8.02C17.96 4.97437 15.4856 2.5 12.44 2.5ZM12.44 3.94C14.7128 3.94 16.52 5.74723 16.52 8.02V10.66H8.36V8.02C8.36 5.74723 10.1672 3.94 12.44 3.94ZM6.68 12.1H18.2C18.3454 12.1 18.44 12.1946 18.44 12.34V20.98C18.44 21.1254 18.3454 21.22 18.2 21.22H6.68C6.53459 21.22 6.44 21.1254 6.44 20.98V12.34C6.44 12.1946 6.53459 12.1 6.68 12.1Z"
                  fill="#3CA5DC"
                />
              </svg>

              <div className="text-sky-700 text-base font-semibold font-poppins underline leading-tight">
                Change Password
              </div>
            </button>
          </div>

          <div className="mt-2">
            <div className="flex flex-row space-x-6">
              <div>
                <img
                  src={image}
                  className="w-16 h-16 rounded-full object-cover aspect-square"
                />
                <label className="cursor-pointer">
                  <img
                    src={camera_icon}
                    alt="camera icon"
                    className="mx-auto mt-2"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    name="Image"
                    value={undefined}
                    onChange={(e) => handleImageChange(e)}
                    style={{ display: "none" }}
                  />
                </label>
              </div>
              <div>
                <div>
                  <span className="text-neutral-600 text-sm font-semibold font-poppins">
                    {currentClinician?.Name}
                    <br />
                  </span>
                  <span className="text-zinc-500 text-sm font-normal font-poppins">
                    ID: {currentClinician?.DID} <br />
                    Member since{" "}
                    {moment(currentClinician?.JoiningDate).format(
                      "DD MMM YYYY"
                    )}{" "}
                    <br />
                    {currentClinician?.RelatedSpecialities[0]?.Speciality}... (
                    {currentClinician?.Experience} yrs exp.){" "}
                  </span>
                </div>

                <div className="flex items-center space-x-1 mt-2">
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.33594 4.5L5.94462 7.11131C7.64367 8.074 8.36154 8.074 10.0606 7.11131L14.6693 4.5"
                      stroke="#545454"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M1.34645 9.48634C1.39003 11.5301 1.41182 12.5519 2.16591 13.3089C2.91999 14.0658 3.96949 14.0921 6.06849 14.1449C7.36214 14.1774 8.64307 14.1774 9.93674 14.1449C12.0357 14.0921 13.0852 14.0658 13.8393 13.3089C14.5934 12.5519 14.6152 11.5301 14.6587 9.48634C14.6728 8.82921 14.6728 8.176 14.6587 7.51887C14.6152 5.47518 14.5934 4.45333 13.8393 3.69638C13.0852 2.93942 12.0357 2.91306 9.93674 2.86032C8.64307 2.82781 7.36214 2.82781 6.06848 2.86031C3.96949 2.91304 2.91999 2.93941 2.1659 3.69637C1.41182 4.45332 1.39003 5.47517 1.34644 7.51887C1.33243 8.176 1.33244 8.82921 1.34645 9.48634Z"
                      stroke="#545454"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className=" text-neutral-600 text-sm font-normal font-poppins leading-tight">
                    {currentClinician?.Email}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="h-px border border-gray-200 my-3" />

          <div className="flex flex-row items-center space-x-1 my-6">
            {TABS.map((tab) => (
              <TabBar
                key={tab.id}
                id={tab.id}
                name={tab.name}
                selectedTab={selectedTab}
                onClick={setSelectedTab}
              />
            ))}
          </div>

          <div className="w-full">{renderInputFields(selectedTab)}</div>

          <button
            onClick={submitHandler}
            className="px-5 py-3 mt-4 bg-sky-400 rounded-lg"
          >
            <div className="text-white text-sm font-semibold font-poppins leading-normal">
              Save Changes
            </div>
          </button>
        </div>
      </ModalComponent>
    </>
  );
}

export default MyProfileModal;

const TABS = [
  {
    id: 0,
    name: "Basic Details",
  },
  {
    id: 1,
    name: "Address Details",
  },
];
