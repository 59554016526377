const RadioIcon = ({ selected }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline-block mr-2"
  >
    {selected ? (
      <>
        <path
          d="M18 12C18 15.3137 15.3137 18 12 18C8.68628 18 6 15.3137 6 12C6 8.68628 8.68628 6 12 6C15.3137 6 18 8.68628 18 12Z"
          fill="#3CA5DC"
        />
        <path
          d="M12 2C6.48725 2 2 6.48725 2 12C2 17.5128 6.48725 22 12 22C17.5147 22 22 17.5128 22 12C22 6.48725 17.5147 2 12 2ZM12 20.795C7.15225 20.795 3.205 16.8498 3.205 12C3.205 7.15025 7.15225 3.205 12 3.205C16.8495 3.205 20.795 7.15025 20.795 12C20.795 16.8498 16.8498 20.795 12 20.795Z"
          fill="#3CA5DC"
        />
      </>
    ) : (
      <path
        d="M11.7734 21.7734C17.2875 21.7734 21.7734 17.2875 21.7734 11.7734C21.7734 6.25941 17.2875 1.77344 11.7734 1.77344C6.25941 1.77344 1.77344 6.25941 1.77344 11.7734C1.77344 17.2875 6.25941 21.7734 11.7734 21.7734ZM11.7734 3.16964C16.5184 3.16964 20.3781 7.02939 20.3781 11.7743C20.3781 16.5193 16.5184 20.379 11.7734 20.379C7.0285 20.3782 3.16874 16.5184 3.16874 11.7734C3.16874 7.0285 7.0285 3.16874 11.7734 3.16874V3.16964Z"
        fill="#D4D4D4"
      />
    )}
  </svg>
);

export default RadioIcon;
