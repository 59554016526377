import React, { useEffect, useState } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { FaPlus } from "react-icons/fa6";
import expandIcons from "../assets/Images/TelehealthDashboard/expandIcons.png";
import { BiCollapseAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getKeyPerformanceIndicator,
  getCustomKeyPerformanceIndicator,
} from "../services/operations/masterDataAPI";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import moment from "moment";
import { FaChevronLeft } from "react-icons/fa6";
import Breadcrumb from "../components/Breadcrumb";
import collapseIcon from "../assets/Images/TelehealthDashboard/collapseIcon.png";

const PayoutsFullScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [payoutType, setPayoutType] = useState("Week");
  const [todayData, setTodayData] = useState([]);
  const [weekData, setWeekData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [customData, setCustomData] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: "#3CA5DC",
    },
  ]);

  useEffect(() => {
    const fetchKeyPerformanceIndicator = async () => {
      if (token) {
        try {
          let body;
          let res;
          if (payoutType === "dateRange") {
            const startDate = moment(dateRange[0].startDate).format(
              "YYYY-MM-DD"
            );
            const endDate = moment(dateRange[0].endDate).format("YYYY-MM-DD");
            body = {
              startDate: startDate,
              endDate: endDate,
            };
            res = await dispatch(getCustomKeyPerformanceIndicator(token, body));
            setCustomData(res?.KPI?.CustomDateRange);
          } else {
            res = await dispatch(getKeyPerformanceIndicator(token));
            setTodayData(res?.KPI?.Today);
            setWeekData(res?.KPI?.Week);
            setMonthData(res?.KPI?.Month);
          }
        } catch (error) {
          console.error("Failed to fetch Key Performance Indicator", error);
        }
      }
    };

    fetchKeyPerformanceIndicator();
  }, [token, dispatch, payoutType, dateRange]);

  return (
    <div
      className={`overflow-y-scroll bg-[#F3F3F3] h-full scroll-smooth scrollbar scrollbar-thin scrollbar-thumb-[#3CA5DC] scrollbar-track-[#E5F2FF] scrollbar-thumb-rounded-full scrollbar-track-rounded-full relative`}
    >
      <div className="flex flex-col justify-center item-center my-3 bg-[#FFFFFF] mx-3 rounded-lg shadow-lg p-6">
        {/* Header */}
        <div className="flex flex-row justify-between items-center px-5 relative">
          <div className="flex flex-col justify-center items-start gap-2 mb-4">
            <div className="flex flex-row items-center space-x-4">
              {/* <button
                className="active:scale-90"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaChevronLeft className="h-5" />
              </button> */}
              <h1 className={`flex text-black text-lg font-bold font-poppins`}>
                Clinic Performance
              </h1>
            </div>

            <Breadcrumb />
          </div>

          {/* Date Range btn */}
          <button onClick={() => { setShowCalendar(!showCalendar) }} className="ml-auto mb-4 flex flex-row justify-center items-center">
            <h1 className="text-[#3CA5DC] text-xs font-semibold font-poppins">
              Select date range

            </h1>
          </button>
          {showCalendar && (
            <div className='flex flex-col justify-center items-center gap-1 absolute top-[1.5rem] right-1 w-[21rem] shadow-lg rounded-md'>
              <DateRange
                date={new Date()}
                shownDate={new Date()}
                onChange={item => { setDateRange([item.selection]); setPayoutType('dateRange'); setShowCalendar(false) }}
                ranges={dateRange}
                months={1}
                showDateDisplay={false}
                rangeColors={['#3CA5DC']}
                direction="horizontal"
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                className=''
              />
            </div>
          )}

          {/* Collapse screen Icon */}
          <div className="flex flex-row justify-end items-center w-full absolute top-[-10px] right-0">
            <button
              className="text-sky-400 text-2xl active:scale-90 transition-transform border rounded-lg border-[#D1D1D1] w-7 h-7 p-1"
              onClick={() => {
                navigate("/clinicianDashboard");
              }}
            >
              <img
                src={collapseIcon}
                className="w-full h-full object-contain"
                alt="Collapse Icon"
              />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="flex flex-col justify-center items-center px-5 mt-5">
          {/* 3 Btns */}
          <div className={`flex flex-row justify-start items-center w-full gap-3 mb-6`}>
            <div onClick={() => setPayoutType('Today')} className={`cursor-pointer hover:scale-110 duration-500 flex justify-center items-center py-1 px-8 ${payoutType === 'Today' ? "bg-[#E5F2FF]" : "bg-[#F3F5F6]"} rounded-3xl`}>
              <h1 className={`${payoutType === 'Today' ? "text-[#3A3838]" : "text-[#545454]"} text-sm font-semibold font-poppins`}>
                Today
              </h1>
            </div>
            <div onClick={() => setPayoutType('Week')} className={`cursor-pointer hover:scale-110 duration-500 flex justify-center items-center py-1 px-8 ${payoutType === 'Week' ? "bg-[#E5F2FF]" : "bg-[#F3F5F6]"} rounded-3xl`}>
              <h1 className={`${payoutType === 'Week' ? "text-[#3A3838]" : "text-[#545454]"} text-sm font-semibold font-poppins`}>
                Week
              </h1>
            </div>
            <div onClick={() => setPayoutType('Month')} className={`cursor-pointer hover:scale-110 duration-500 flex justify-center items-center py-1 px-8 ${payoutType === 'Month' ? "bg-[#E5F2FF]" : "bg-[#F3F5F6]"} rounded-3xl`}>
              <h1 className={`${payoutType === 'Month' ? "text-[#3A3838]" : "text-[#545454]"} text-sm font-semibold font-poppins`}>

                Month
              </h1>
            </div>
          </div>

          {/* KPI */}
          <div className="flex flex-col gap-4 mb-2">
            <div className={`flex flex-row gap-4 w-full`}>
              <div className="flex-1 flex justify-start items-center py-10 px-4 w-[15rem] rounded-2xl shadow" style={{ backgroundColor: 'rgba(255, 255, 255, 0.55)' }}>
                <div className="flex flex-col justify-center items-start gap-4">
                  <h1 className="text-[#000000] text-sm font-medium font-poppins text-left capitalize tracking-tight">
                    Patients seen
                  </h1>
                  <h1 className="text-[#3CA5DC] text-base font-medium font-poppins">
                    {payoutType === 'Today' ? todayData?.patientsSeen : payoutType === 'Week' ? weekData?.patientsSeen : payoutType === 'Month' ? monthData?.patientsSeen : payoutType === 'dateRange' ? customData?.patientsSeen : ''}
                  </h1>
                </div>
              </div>
              <div className="flex-1 flex justify-start items-center py-10 px-4 w-[15rem] rounded-2xl shadow" style={{ backgroundColor: 'rgba(255, 255, 255, 0.55)' }}>
                <div className="flex flex-col justify-center items-start gap-4">
                  <h1 className="text-[#000000] text-sm font-medium font-poppins text-left capitalize tracking-tight">
                    Payout Amount
                  </h1>
                  <h1 className="text-[#3CA5DC] text-base font-medium font-poppins">
                    {payoutType === 'Today' ? todayData?.RevenueAmount : payoutType === 'Week' ? weekData?.RevenueAmount : payoutType === 'Month' ? monthData?.RevenueAmount : payoutType === 'dateRange' ? customData?.RevenueAmount : ''} CHF

                  </h1>
                </div>
              </div>
            </div>
            <div className={`flex flex-row gap-4 w-full`}>
              <div className="flex-1 flex justify-start items-center py-10 px-4 w-[15rem] rounded-2xl shadow" style={{ backgroundColor: 'rgba(255, 255, 255, 0.55)' }}>
                <div className="flex flex-col justify-center items-start gap-4">
                  <h1 className="text-[#000000] text-sm font-medium font-poppins text-left capitalize tracking-tight">
                    Clinic Occupancy Rate
                  </h1>
                  <h1 className="text-[#3CA5DC] text-base font-medium font-poppins">
                    {payoutType === 'Today' ? todayData?.occupancyRate : payoutType === 'Week' ? weekData?.occupancyRate : payoutType === 'Month' ? monthData?.occupancyRate : payoutType === 'dateRange' ? customData?.occupancyRate : ''}%

                  </h1>
                </div>
              </div>
              <div className="flex-1 flex justify-start items-center py-10 px-4 w-[15rem] rounded-2xl shadow" style={{ backgroundColor: 'rgba(255, 255, 255, 0.55)' }}>
                <div className="flex flex-col justify-center items-start gap-4">
                  <h1 className="text-[#000000] text-sm font-medium font-poppins text-left capitalize tracking-tight">
                    Revenue per-patient
                  </h1>
                  <h1 className="text-[#3CA5DC] text-base font-medium font-poppins">
                    {payoutType === 'Today' ? todayData?.RevenuePerPatient : payoutType === 'Week' ? weekData?.RevenuePerPatient : payoutType === 'Month' ? monthData?.RevenuePerPatient : payoutType === 'dateRange' ? customData?.RevenuePerPatient : ''} CHF/patient

                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayoutsFullScreen;
