import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { platformManagementEndpoints } from "../apis";
import { getBucketURL } from "../../utils/regex";
import { setPolicies, setLoading } from "../../slices/platformManagementSlice";

const { GET_ALL_POLICIES, UPDATE_POLICIES } = platformManagementEndpoints;

// GET ALL POLICIES
export function getAllPolicies(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_POLICIES, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setPolicies(response?.data?.policies));
    } catch (error) {
      console.log("GET_ALL_REWARD_OFFERS API ERROR............", error);
    }
    dispatch(setLoading(false));
  };
}

// UPDATE POLICIES
export function updatePolicies(token, ID, data, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_POLICIES + "/" + ID,
        data,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Policy updated successfully");
      dispatch(getAllPolicies(token));
      navigate(-1);
    } catch (error) {
      console.log("UPDATE_POLICIES API ERROR............", error);
      toast.error(
        error?.response?.data?.message || "Error While Updating Policy"
      );
    }
    toast.dismiss(toastId);
  };
}
