import React, { useEffect, useState } from "react";
import { HiDotsHorizontal } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import profilePic from "../assets/Images/TelehealthDashboard/profilePic.png";
import collapseIcon from "../assets/Images/TelehealthDashboard/collapseIcon.png";
import note_Icon from "../assets/Images/TelehealthDashboard/note_Icon.png";
import closeWidget from "../assets/Images/TelehealthDashboard/closeWidget.png";
import noPatientsDataForToday from "../assets/Images/TelehealthDashboard/noPatientsDataForToday.png";
import VisitPic from "../assets/Images/TelehealthDashboard/VisitPic.png";
import telehealthPic from "../assets/Images/TelehealthDashboard/telehealthPic.png";
import inClinicPic from "../assets/Images/TelehealthDashboard/inClinicPic.png";
import { getBucketURL } from "../utils/regex";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useNavigate } from "react-router-dom";
import { BiCollapseAlt } from "react-icons/bi";
import ModalComponent from "../components/Modal";
import { FaChevronLeft } from "react-icons/fa6";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range';
import moment from "moment";
import Breadcrumb from "../components/Breadcrumb";

const TodayPatientListFullScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [patientsData, setPatientsData] = useState([]);
  const { dashboard } = useSelector((state) => state.dashboard);
  const [patientListType, setPatientListType] = useState('Today')
  const [showCalendar, setShowCalendar] = useState(false)
  const [dateRange, setDateRange] = useState([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
    color: '#3CA5DC',
  },]);
  const fetchBookedPatients = async () => {
    if (dashboard) {
      let patientsForTargetDate = [];
      let year, month, day;
      if (patientListType === 'Today' || patientListType === 'Tomorrow') {
        let targetDate = moment();
        if (patientListType === 'Tomorrow') {
          targetDate = targetDate.add(1, 'days');
        }
        year = targetDate.year();
        month = targetDate.format("MMM").toUpperCase();
        day = targetDate.date();
        patientsForTargetDate = dashboard[year]?.[month]?.[day] || [];
      } else if (patientListType === 'ThisWeek') {
        const startOfWeek = moment().startOf('week');
        for (let i = 0; i < 7; i++) {
          const weekDay = startOfWeek.clone().add(i, 'days');
          year = weekDay.year();
          month = weekDay.format("MMM").toUpperCase();
          day = weekDay.date();
          const weekDayData = dashboard[year]?.[month]?.[day] || [];
          patientsForTargetDate = patientsForTargetDate.concat(weekDayData);
        }
      } else if (patientListType === 'dateRange') {
        const start = moment(dateRange[0].startDate);
        const end = moment(dateRange[0].endDate);
        for (let m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'days')) {
          year = m.year();
          month = m.format("MMM").toUpperCase();
          day = m.date();
          const dayData = dashboard[year]?.[month]?.[day] || [];
          patientsForTargetDate = patientsForTargetDate.concat(dayData);
        }
      }

      const formattedPatientsData = await Promise.all(
        patientsForTargetDate.map(async (patient) => {
          const profileImageUrl = await getBucketURL(patient.Patient_Info.ProfileImageUrl).catch(() => profilePic);
          const appointmentDate = moment(`${year}-${month}-${day}`, "YYYY-MMM-DD");
          const appointmentTime = moment(`${appointmentDate.format("YYYY-MM-DD")}T${patient.Session_Info.Start_time}`);

          return {
            id: patient.ID,
            AHUID: patient.Patient_Info.AHUID,
            Image: profileImageUrl || profilePic,
            appType: patient.Session_Info.AppointmentType,
            Name: patient.Patient_Info.Name,
            Age: patient.Patient_Info.Age,
            meetingID: patient.Session_Info.MeetingID,
            desc: `${patient.Patient_Info.Gender} - ${patient.Patient_Info.Age} Yrs`,
            time: appointmentTime.format("h:mm A"),
            cond: "N/A",
            diagnosis: patient.Session_Info.DiagnosisName,
            note: patient.Session_Info.PersonalNote || "",
          };
        })
      );

      // Sort the formatted data based on the time
      formattedPatientsData.sort((a, b) => moment(a.time, "h:mm A") - moment(b.time, "h:mm A"));

      setPatientsData(formattedPatientsData);
    } else {
      setPatientsData([]);
    }
  };

  useEffect(() => {
    fetchBookedPatients();
  }, [dashboard, patientListType, dateRange]);

  return (
    <div
      className={`overflow-y-scroll bg-[#F3F3F3] h-full scroll-smooth scrollbar scrollbar-thin scrollbar-thumb-[#3CA5DC] scrollbar-track-[#E5F2FF] scrollbar-thumb-rounded-full scrollbar-track-rounded-full`}
    >
      <div
        className={`flex flex-col justify-center item-center my-3 bg-[#FFFFFF] mx-3 rounded-lg shadow-lg p-6`}
      >
        {/* Header */}
        <div className="flex flex-row justify-between items-center relative px-5">
          <div className="flex flex-col justify-center items-start gap-2 mb-4">
            <div className="flex flex-row items-center space-x-4">
              {/* <button
                className="active:scale-90"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <FaChevronLeft className="h-5" />
              </button> */}
              <h1 className={`flex text-black text-lg font-bold font-poppins`}>
                Patient List
              </h1>

            </div>
            <Breadcrumb />
          </div>

          {/* Date Range btn */}
          <button onClick={() => { setShowCalendar(!showCalendar) }} className="flex flex-row justify-center items-center">
            <h1 className="text-[#3CA5DC] text-xs font-semibold font-poppins">
              Select date range
            </h1>
          </button>
          {showCalendar && (
            <div className='flex flex-col justify-center items-center gap-1 absolute top-[1.5rem] right-1 w-[21rem] shadow-lg rounded-md'>
              <DateRange
                date={new Date()}
                shownDate={new Date()}
                onChange={item => { setDateRange([item.selection]); setPatientListType('dateRange'); setShowCalendar(false) }}
                ranges={dateRange}
                months={1}
                showDateDisplay={false}
                rangeColors={['#3CA5DC']}
                direction="horizontal"
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                className=''
              />
            </div>
          )}

          {/* Full screen Icon */}
          {/* <button
            className="text-sky-400 text-2xl active:scale-90 transition-transform absolute top-[-10px] right-0"
            onClick={() => {
              navigate("/clinicianDashboard");
            }}
          >
            <BiCollapseAlt />
          </button> */}
          {/* Collapse screen Icon */}
          <div className="flex flex-row justify-end items-center w-full absolute top-[-10px] right-0">
            <button
              className="text-sky-400 text-2xl active:scale-90 transition-transform border rounded-lg border-[#D1D1D1] w-7 h-7 p-1"
              onClick={() => {
                navigate("/clinicianDashboard");
              }}
            >
              <img
                src={collapseIcon}
                className="w-full h-full object-contain"
                alt="Collapse Icon"
              />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="flex flex-col w-full justify-center items-center mt-2 px-5">
          {/* 3 Btns */}
          <div className={`flex flex-row justify-start items-center w-full gap-3 mb-6`}>
            <div onClick={() => setPatientListType('Today')} className={`cursor-pointer hover:scale-110 duration-500 flex justify-center items-center py-1 px-8 ${patientListType === 'Today' ? "bg-[#E5F2FF]" : "bg-[#F3F5F6]"} rounded-3xl`}>
              <h1 className={`${patientListType === 'Today' ? "text-[#3A3838]" : "text-[#545454]"} text-sm font-semibold font-poppins`}>
                Today
              </h1>
            </div>
            <div onClick={() => setPatientListType('Tomorrow')} className={`cursor-pointer hover:scale-110 duration-500 flex justify-center items-center py-1 px-8 ${patientListType === 'Tomorrow' ? "bg-[#E5F2FF]" : "bg-[#F3F5F6]"} rounded-3xl`}>
              <h1 className={`${patientListType === 'Tomorrow' ? "text-[#3A3838]" : "text-[#545454]"} text-sm font-semibold font-poppins`}>
                Tomorrow
              </h1>
            </div>
            <div onClick={() => setPatientListType('ThisWeek')} className={`cursor-pointer hover:scale-110 duration-500 flex justify-center items-center py-1 px-8 ${patientListType === 'ThisWeek' ? "bg-[#E5F2FF]" : "bg-[#F3F5F6]"} rounded-3xl`}>
              <h1 className={`${patientListType === 'ThisWeek' ? "text-[#3A3838]" : "text-[#545454]"} text-sm font-semibold font-poppins`}>
                This Week
              </h1>
            </div>
          </div>

          {patientsData.length !== 0 ? (
            patientsData?.map((patient) => (
              <div
                key={patient.id}
                className={`grid grid-cols-8 justify-between items-center w-full bg-white rounded-xl px-6 py-4 gap-4 mb-4`}
              >
                <div className="col-span-3 flex flex-row justify-start items-center">
                  <div className="flex flex-row items-center justify-between">
                    <button onClick={() => { navigate("/patientDashboard/patientProfile", { state: patient }) }} className="flex items-center space-x-4 w-full">
                      <img
                        src={patient.Image}
                        alt="PatientPic"
                        className="w-12 h-12 rounded-full"
                      />
                      <div className="flex flex-col">
                        <h1 className="text-[#000000] text-base text-xs font-semibold font-poppins text-left">
                          {patient.Name}
                        </h1>
                        <h1 className="text-[#000000] text-base text-xs font-regular font-poppins text-left">

                          {patient.desc}
                        </h1>
                      </div>
                    </button>
                  </div>
                </div>

                <div className={`col-span-2 flex flex-col justify-center items-start w-full`}>
                  <h1 className="text-[#8A8A8A] text-base text-xs font-regular font-poppins">
                    Last Diagnosis
                  </h1>
                  <h1 className="text-[#000000] text-base text-xs font-semibold font-poppins">
                    {patient.diagnosis || "-"}
                  </h1>
                </div>

                <div className={`col-span-2 flex flex-col justify-center items-center w-full`}>
                  {(patient.appType === "TeleHealth" || patient.appType === "") && (
                    <div style={{ backgroundColor: 'rgba(247, 160, 37, 0.15)' }} className="flex justify-center items-center px-2 py-1 rounded-lg">
                      <h1 className="text-[#f7a025] text-xs font-normal font-poppins">
                        {patient.time}
                      </h1>
                    </div>
                  )}
                  {(patient.appType === "In-Clinic" || patient.appType === "HomeVisit" || patient.appType === "Personal Consultation") && (
                    <div style={{ backgroundColor: 'rgba(60, 165, 220, 0.15)' }} className="flex justify-center items-center px-2 py-1 rounded-lg">
                      <h1 className="text-[#3ca5dc] text-xs font-normal font-poppins">
                        {patient.time}
                      </h1>
                    </div>
                  )}
                </div>

                <div className="flex flex-row justify-start items-center w-full">
                  {(patient.appType === "TeleHealth" || patient.appType === "") && (
                    <>
                      <div data-tooltip-id="TeleHealth">
                        <img
                          src={telehealthPic}
                          alt="telehealthPic"
                          className="w-10 h-10 object-contain"
                        />
                      </div>
                      <Tooltip
                        id="TeleHealth"
                        place="top"
                        content="TeleHealth"
                      />
                    </>
                  )}
                  {(patient.appType === "In-Clinic" || patient.appType === "HomeVisit" || patient.appType === "Personal Consultation") && (
                    <>
                      <div data-tooltip-id="In-Clinic">
                        <img
                          src={inClinicPic}
                          alt="inClinicPic"
                          className="w-10 object-contain h-10"
                        />
                      </div>
                      <Tooltip
                        id="In-Clinic"
                        place="top"
                        content="In-Clinic"
                      />
                    </>
                  )}
                </div>

              </div>
            ))
          ) : (
            <div className={`flex flex-col justify-center items-center w-full px-4`}>
              <h1 className="text-[#A5A6A6] text-base text-sm font-semibold font-poppins text-center mt-2">
                No Patients available !
              </h1>
              <img
                src={noPatientsDataForToday}
                className="w-[70%] h-full object-contain"
                alt="No Patients Data"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TodayPatientListFullScreen;
