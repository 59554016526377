import React from 'react'
import './style.css'

const RadioButton = ({ name, id, value, onChange, checked, text, textFont, editText, onEdit }) => {
  return (
    <label htmlFor={id} className="radio-label flex flex-row justify-center items-center mb-1 w-full">
      <input
        className="radio-input"
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className="custom-radio" />
      <h1 className={`text-[#070808] text-xs ${textFont ? textFont : "font-semibold"} font-poppins text-left`}>
        {text}
      </h1>
      {editText && (
        <button onClick={onEdit} className={`text-[#3CA5DC] text-xs ${textFont ? textFont : "font-semibold"} font-poppins ml-auto underline`}>
          Edit
        </button>
      )}
    </label>
  )
}

export default RadioButton