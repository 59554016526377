import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { FaCaretDown, FaCaretUp, FaChevronLeft } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { VscEye } from "react-icons/vsc";
import { RxEnterFullScreen } from "react-icons/rx";
import { LuDownload } from "react-icons/lu";
import { FiPrinter } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { format, parseISO, set } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import claim_report from "../assets/Images/Report/claim_report.png";
import doctor_prescription from "../assets/Images/Report/doctor_prescription.png";
import imaging from "../assets/Images/Report/imaging.png";
import lab_reports from "../assets/Images/Report/lab_reports.png";
import other_reports from "../assets/Images/Report/other_reports.png";
import calendar from "../assets/Images/Report/calendar.png";
import ModalComponent from "../components/Modal";
import PdfComp from "../components/PdfComp";
import biomarker_icon_blue from "../assets/Icons/biomarker_icon_blue.svg";
import { pdfjs } from "react-pdf";
import {
  getDecryptedReport,
  getHealthJourney,
} from "../services/operations/patientManagementAPI";
import Breadcrumb from "../components/Breadcrumb";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

function transformData(data, selectedReportType) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const transformed = data.reduce((acc, item) => {
    const date = new Date(item.Date);
    const month = date.getMonth();
    const day = date.getDate();
    const year = date.getFullYear();

    const monthId = month < 9 ? `0${month + 1}` : `${month + 1}`;

    if (!acc[monthId]) {
      acc[monthId] = {
        id: monthId,
        name: monthNames[month],
        events: [],
      };
    }

    const filteredPathReports =
      selectedReportType === "All" || !selectedReportType
        ? item.pathReports
        : item.pathReports.filter(
            (report) => report.PathReportType === selectedReportType
          );

    if (filteredPathReports.length > 0) {
      acc[monthId].events.push({
        date: day,
        title: item.DiagnosisName,
        progress: `${item.Percentage}%`,
        type: "unknown",
        pathReports: filteredPathReports,
      });
    }

    return acc;
  }, {});

  const filteredTransformed = Object.values(transformed)
    .filter((month) => month.events.length > 0)
    .sort((a, b) => b.id.localeCompare(a.id));

  return filteredTransformed;
}

const HealthJourney = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const patient = location.state || {};
  const { token } = useSelector((state) => state.auth);
  const [isFetching, setIsFetching] = useState(true);
  const [healthJourney, setHealthJourney] = useState([]);
  const [expandedEvents, setExpandedEvents] = useState({});
  const [months, setMonths] = useState([]);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [showYearOptions, setShowYearOptions] = useState(false);
  const [viewUploadedDocumentType, setViewUploadedDocumentType] = useState("");
  const [openDocument, setOpenDocument] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [selectedReportType, setSelectedReportType] = useState("All");
  const transformedData = transformData(healthJourney, selectedReportType);

  const handleFullScreen = () => {
    window.open(pdfUrl, "_blank").focus();
  };

  const handleDownload = async () => {
    try {
      const response = await fetch(pdfUrl);
      if (!response.ok) throw new Error("Network response was not ok.");
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = selectedDocument?.FileName || "Report";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const handlePrint = () => {
    const printWindow = window.open(pdfUrl);
    printWindow.addEventListener(
      "load",
      () => {
        printWindow.print();
      },
      false
    );
  };

  const YEAR = [
    {
      year: 2024,
    },
    {
      year: 2023,
    },
    {
      year: 2022,
    },
    {
      year: 2021,
    },
    {
      year: 2020,
    },
  ];

  const FILTER = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Lab Reports",
      value: "Lab Reports",
    },
    {
      label: "Doctor's Prescription",
      value: "Doctors Prescription",
    },
    {
      label: "Imaging",
      value: "Imaging",
    },
    {
      label: "Other Reports",
      value: "Others Reports",
    },
  ];

  const toggleEvent = (monthId, eventIndex) => {
    setExpandedEvents((prevState) => ({
      ...prevState,
      [monthId]: {
        ...prevState[monthId],
        [eventIndex]: !prevState[monthId]?.[eventIndex],
      },
    }));
  };

  const expandAll = () => {
    const newExpandedEvents = {};
    months.forEach((month) => {
      newExpandedEvents[month.id] = {};
      month.events.forEach((_, eventIndex) => {
        newExpandedEvents[month.id][eventIndex] = true;
      });
    });
    setExpandedEvents(newExpandedEvents);
  };

  const collapseAll = () => {
    setExpandedEvents({});
  };

  const handleYearSelection = (year) => {
    setSelectedYear(year);
    setShowYearOptions(false);
  };

  const handleFilterSelection = (filter) => {
    setSelectedReportType(filter);
    setShowFilterOptions(false);
  };

  const fetchDecryptedReport = async (item) => {
    try {
      const data = {
        PhysicalReport: item?.PhysicalReport,
        ReportID: item?.ReportID,
        AHUID: patient?.AHUID,
      };
      const res = await getDecryptedReport(token, data);
      setPdfUrl(res?.filePath);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenDocument = (item) => {
    fetchDecryptedReport(item);
    setSelectedDocument(item);
    setOpenDocument(true);
  };

  useEffect(() => {
    if (selectedDocument) {
      const docType = REPORTTYPE.find(
        (type) => type.label === selectedDocument.PathReportType
      );
      setViewUploadedDocumentType(docType);
    }
  }, [selectedDocument]);

  const fetchHealthJourney = async () => {
    setIsFetching(true);
    try {
      const res = await getHealthJourney(token, patient.AHUID, selectedYear);
      console.log("====================================");
      console.log(res);
      console.log("====================================");
      setHealthJourney(res);
      const groupedByMonth = res.reduce((acc, item) => {
        const month = format(parseISO(item.Date), "MM");
        if (!acc[month]) {
          acc[month] = {
            id: month,
            name: format(parseISO(item.Date), "MMMM"),
            events: [],
          };
        }
        acc[month].events.push({
          date: parseISO(item.Date).getDate(),
          title: item.DiagnosisName,
          progress: `${item.Percentage}%`,
          type: item.Type || "unknown",
          pathReports: item.pathReports,
        });
        return acc;
      }, {});

      setMonths(Object.values(groupedByMonth).sort((a, b) => b.id - a.id));
    } catch (error) {
      console.log(error);
    }
    setIsFetching(false);
  };

  useLayoutEffect(() => {
    fetchHealthJourney();
  }, [patient, selectedYear]);

  if (healthJourney.length === 0 && !isFetching) {
    return (
      <div className="bg-white min-h-full p-6 scroll-smooth">
        {/* Header Section */}
        <div className="flex flex-row justify-between mb-6">
          <div>
            <div className="text-zinc-900 text-2xl font-medium font-poppins">
              Patient Health Journey
            </div>
            <Breadcrumb data={patient} />
          </div>
          <div className="flex flex-row items-center justify-center">
            <img
              src={patient.Image}
              alt="PatientPic"
              className="w-16 h-16 rounded-full object-contain"
            />
            <div className="ml-4">
              <div className="text-black text-base font-semibold">
                {patient.Name || patient.FirstName + " " + patient.LastName}
              </div>
              <div className="text-zinc-500 text-xs font-normal">
                No : {patient.AHUID}
              </div>
              {patient.Age !== null && patient.Age !== undefined ? (
                <>
                  <div className="text-black text-xs font-normal">
                    {patient.Age} Years Old
                  </div>
                </>
              ) : (
                <>
                  <div className="text-black text-xs font-normal">
                    {new Date().getFullYear() -
                      new Date(patient.DOB).getFullYear()}{" "}
                    Years Old
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between my-10">
          <div className="flex flex-row items-center space-x-5">
            <div className="relative">
              <button
                onClick={() => setShowYearOptions((prev) => !prev)}
                className="w-24 h-9 px-2.5 py-2 bg-sky-100 rounded-lg justify-center items-center gap-2 inline-flex"
              >
                <img src={calendar} className="w-4 h-4 object-contain" alt="" />
                <div className="w-16 h-4 text-sky-400 text-xs font-normal font-poppins">
                  {selectedYear}
                </div>
                <FaCaretDown className="text-sky-400" />
              </button>
              {showYearOptions && (
                <div className="absolute left-0 mt-2 w-32 bg-white border rounded-lg p-2 space-y-2 shadow-lg z-10">
                  {YEAR?.map((x) => (
                    <button
                      key={x.year}
                      onClick={() => handleYearSelection(x.year)}
                      className={`block w-full text-left px-4 py-2 text-sm rounded-lg ${
                        selectedYear === x.year
                          ? "bg-sky-100 text-sky-400 hover:bg-sky-200 hover:text-sky-600"
                          : "text-black hover:bg-gray-100 hover:text-gray-800"
                      }`}
                    >
                      {x.year}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div className=" text-black text-xs font-semibold font-poppins">
              Timelines of Reports:
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center">
          <div className="text-black text-base font-semibold font-poppins">
            No Reports Found
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      <ModalComponent
        show={openDocument}
        handleClose={() => {
          setOpenDocument(false);
          setSelectedDocument(null);
          setPdfUrl(null);
        }}
        outSideModalPress={false}
      >
        <div className="flex flex-row items-center justify-between mb-4">
          <div className="flex flex-row items-center space-x-4">
            <div
              className={`${viewUploadedDocumentType?.background} w-12 h-12 rounded-full justify-center items-center inline-flex`}
            >
              <img
                src={viewUploadedDocumentType?.image}
                className="w-9 h-9 object-contain"
                alt=""
              />
            </div>
            <div>
              <div className="text-black text-sm font-semibold font-poppins">
                {viewUploadedDocumentType?.title}
              </div>
              <div className="text-zinc-500 text-xs font-normal font-poppins">
                {selectedDocument?.title}
              </div>
            </div>
          </div>
          <div>
            <div className="text-black text-xs font-normal font-poppins leading-none">
              Date Upload :
            </div>
            <div className="text-right text-black text-xs font-semibold font-poppins leading-none mt-1">
              {moment(selectedDocument?.UploadDate).format("DD/MM/YYYY")}
            </div>
          </div>
        </div>
        <div className="border border-b-1 border-gray-200 mb-4"></div>
        <div className="m-5">
          <div
            className={`flex flex-col items-center justify-center overflow-y-auto`}
          >
            {/* {pdfUrl && <PdfComp pdf={pdfUrl} />} */}
            <PdfComp pdf={pdfUrl} />
          </div>
        </div>
        <div className="flex justify-end space-x-2 p-2">
          <button
            className="w-32 h-11 py-3 bg-sky-400 rounded-lg flex items-center justify-center gap-2 shadow-md active:scale-95 hover:scale-105 transform transition-all duration-200"
            onClick={handleFullScreen}
          >
            <RxEnterFullScreen className="text-white text-md" />
            <div className="text-white text-md font-normal font-poppins">
              Full Screen
            </div>
          </button>
          <button
            className="w-32 h-11 py-3 bg-sky-400 rounded-lg flex items-center justify-center gap-2 shadow-md active:scale-95 hover:scale-105 transform transition-all duration-200"
            onClick={handleDownload}
          >
            <LuDownload className="text-white text-md" />
            <div className="text-white text-md font-normal font-poppins">
              Download
            </div>
          </button>
          <button
            className="w-32 h-11 py-3 bg-sky-400 rounded-lg flex items-center justify-center gap-2 shadow-md active:scale-95 hover:scale-105 transform transition-all duration-200"
            onClick={handlePrint}
          >
            <FiPrinter className="text-white text-md" />
            <div className="text-white text-md font-normal font-poppins">
              Print
            </div>
          </button>
        </div>
      </ModalComponent>
      {/* Header Section */}
      <div className="flex flex-row justify-between mb-6">
        <div>
          <div className="text-zinc-900 text-2xl font-medium font-poppins">
            Patient Health Journey
          </div>
          <Breadcrumb data={patient} />
        </div>
        <div className="flex flex-row items-center justify-center">
          <img
            src={patient.Image}
            alt="PatientPic"
            className="w-16 h-16 rounded-full object-contain"
          />
          <div className="ml-4">
            <div className="text-black text-base font-semibold">
              {patient.Name || patient.FirstName + " " + patient.LastName}
            </div>
            <div className="text-zinc-500 text-xs font-normal">
              No : {patient.AHUID}
            </div>
            {patient.Age !== null && patient.Age !== undefined ? (
              <>
                <div className="text-black text-xs font-normal">
                  {patient.Age} Years Old
                </div>
              </>
            ) : (
              <>
                <div className="text-black text-xs font-normal">
                  {new Date().getFullYear() -
                    new Date(patient.DOB).getFullYear()}{" "}
                  Years Old
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-row items-center justify-between my-10">
        <div className="flex flex-row items-center space-x-5">
          <div className="relative">
            <button
              onClick={() => setShowYearOptions((prev) => !prev)}
              className="w-24 h-9 px-2.5 py-2 bg-sky-100 rounded-lg justify-center items-center gap-2 inline-flex"
            >
              <img src={calendar} className="w-4 h-4 object-contain" alt="" />
              <div className="w-16 h-4 text-sky-400 text-xs font-normal font-poppins">
                {selectedYear}
              </div>
              <FaCaretDown className="text-sky-400" />
            </button>
            {showYearOptions && (
              <div className="absolute left-0 mt-2 w-32 bg-white border rounded-lg p-2 space-y-2 shadow-lg z-10">
                {YEAR?.map((x) => (
                  <button
                    key={x.year}
                    onClick={() => handleYearSelection(x.year)}
                    className={`block w-full text-left px-4 py-2 text-sm rounded-lg ${
                      selectedYear === x.year
                        ? "bg-sky-100 text-sky-400 hover:bg-sky-200 hover:text-sky-600"
                        : "text-black hover:bg-gray-100 hover:text-gray-800"
                    }`}
                  >
                    {x.year}
                  </button>
                ))}
              </div>
            )}
          </div>
          <div className=" text-black text-xs font-semibold font-poppins">
            Timelines of Reports:
          </div>
        </div>

        <div className="flex space-x-2">
          <button
            onClick={expandAll}
            className={`w-28 h-10 px-4 py-2 duration-500 transition-all ${
              Object.keys(expandedEvents).length > 0
                ? "bg-sky-100"
                : "bg-sky-400"
            } rounded-lg justify-center items-center gap-2 inline-flex active:scale-95`}
          >
            <div
              className={`duration-500 transition-all ${
                Object.keys(expandedEvents).length > 0
                  ? "text-sky-400"
                  : "text-sky-100"
              } text-xs font-normal font-poppins`}
            >
              Expand All
            </div>
          </button>
          <button
            onClick={collapseAll}
            className={`w-28 h-10 px-4 py-2 duration-500 transition-all ${
              Object.keys(expandedEvents).length > 0
                ? "bg-sky-400"
                : "bg-sky-100"
            } rounded-lg justify-center items-center gap-2 inline-flex active:scale-95`}
          >
            <div
              className={`duration-500 transition-all ${
                Object.keys(expandedEvents).length > 0
                  ? "text-sky-100"
                  : "text-sky-400"
              } text-xs font-normal font-poppins`}
            >
              Collapse All
            </div>
          </button>
          <div className="relative">
            <button
              onClick={() => setShowFilterOptions((prev) => !prev)}
              className="w-28 h-10 px-4 py-2 bg-zinc-100 text-zinc-500 text-base font-semibold font-poppins rounded-lg flex flex-row justify-center items-center gap-2 active:scale-95"
            >
              <IoFilter className="text-lg" />
              <div>Filter</div>
            </button>
            {showFilterOptions && (
              <div className="absolute left-0 mt-2 w-32 bg-white border rounded-lg p-2 space-y-2 shadow-lg z-10">
                {FILTER?.map((filter) => (
                  <button
                    key={filter.value}
                    onClick={() => handleFilterSelection(filter.value)}
                    className={`block w-full text-left px-4 py-2 text-sm rounded-lg ${
                      selectedReportType === filter.value
                        ? "bg-sky-100 text-sky-400 hover:bg-sky-200 hover:text-sky-600"
                        : "text-black hover:bg-gray-100 hover:text-gray-800"
                    }`}
                  >
                    {filter.label}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* HealthJourney */}
      {isFetching ? (
        <div className="flex items-center justify-center">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="relative">
          <div
            className="absolute left-4 top-0 bottom-8"
            style={{
              backgroundImage:
                "linear-gradient(to bottom, #3CA5DC 50%, transparent 50%)",
              backgroundSize: "2px 20px",
              backgroundRepeat: "repeat-y",
              width: "2px",
            }}
          ></div>
          {transformedData?.length > 0 ? (
            <>
              {transformedData?.map((month) => (
                <div key={month.id} className="relative mb-6">
                  <div className="flex items-center mb-4">
                    <div className="flex items-center justify-center w-8 h-8 border-4 border-sky-500 rounded-full font-bold mr-4 bg-white">
                      {/* Hollow dot for the month */}
                    </div>
                    <h2 className="text-xl font-semibold">{month.name}</h2>
                  </div>

                  {month?.events?.map((event, eventIndex) => (
                    <div key={eventIndex} className="relative mb-6 flex">
                      {/* Date Circle */}
                      <div className="flex flex-col items-center mr-4">
                        <div
                          className={`w-8 h-8 rounded-full flex items-center justify-center border border-sky-400 text-black ${
                            expandedEvents[month.id]?.[eventIndex]
                              ? "bg-sky-100"
                              : "bg-white"
                          }`}
                        >
                          {event.date}
                        </div>
                      </div>

                      {/* Event Card */}
                      <div
                        className={`flex-1 flex flex-col p-4 border-l-4 border-amber-500 rounded-xl shadow-md ${
                          expandedEvents[month.id]?.[eventIndex]
                            ? "bg-white"
                            : "bg-zinc-100"
                        }`}
                      >
                        <div className="flex flex-row items-center justify-between">
                          <div className="flex flex-col items-start">
                            <h3
                              className="text-md font-bold flex items-center"
                              onClick={() => toggleEvent(month.id, eventIndex)}
                            >
                              {event.title}
                              <span className="ml-2 cursor-pointer">
                                {expandedEvents[month.id]?.[eventIndex] ? (
                                  <FaCaretUp />
                                ) : (
                                  <FaCaretDown />
                                )}
                              </span>
                            </h3>
                            <div className="text-zinc-500 text-xs font-normal font-poppins">
                              Last Updated on:{" "}
                              {moment(event?.pathReports[0]?.UploadDate).format(
                                "DD/MM/YYYY"
                              )}
                            </div>
                          </div>
                          <div className="flex items-center justify-between w-1/6">
                            <div className="flex flex-row items-center justify-center w-1/2 relative">
                              {event.pathReports
                                .reduce((unique, report) => {
                                  const exists = unique.find(
                                    (u) =>
                                      u.PathReportType === report.PathReportType
                                  );
                                  if (!exists) {
                                    unique.push(report);
                                  }
                                  return unique;
                                }, [])
                                ?.map((report, index) => {
                                  const docType = REPORTTYPE.find(
                                    (type) =>
                                      type.label === report.PathReportType
                                  );
                                  return (
                                    <div
                                      className="absolute flex items-center"
                                      style={{
                                        left: `${index * 35}px`,
                                        zIndex: index + 1,
                                      }}
                                    >
                                      <div
                                        className={`${
                                          docType?.background || "bg-sky-400"
                                        } w-12 h-12 rounded-full shadow-lg justify-center items-center inline-flex`}
                                      >
                                        <img
                                          src={docType?.image}
                                          className="w-9 h-9 object-contain"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>

                            <button>
                              <HiOutlineDotsVertical />
                            </button>
                          </div>
                        </div>

                        {expandedEvents[month.id]?.[eventIndex] && (
                          <>
                            <div className="flex flex-col my-6 space-y-10 px-10">
                              {event?.pathReports?.map((report) => {
                                const docType = REPORTTYPE.find(
                                  (type) => type.label === report.PathReportType
                                );
                                return (
                                  <div className="flex flex-row items-center justify-between">
                                    <div className="flex flex-row items-center space-x-10">
                                      <div
                                        className={`${
                                          docType?.background || "bg-sky-400"
                                        } w-12 h-12 rounded-full justify-center items-center inline-flex`}
                                      >
                                        <img
                                          src={docType?.image}
                                          className="w-9 h-9 object-contain"
                                          alt=""
                                        />
                                      </div>
                                      <div className="w-40 text-black text-xs font-semibold font-poppins">
                                        {report.PathReportType}
                                      </div>
                                      <div className=" text-zinc-500 text-xs font-normal font-poppins">
                                        {moment(report.UploadDate).format(
                                          "DD MMMM, YYYY"
                                        )}
                                      </div>
                                    </div>
                                    <div className="flex flex-row items-center space-x-10">
                                      <div className="w-60 text-black text-xs font-normal font-poppins">
                                        Uploaded by:{" "}
                                        {report?.UploadedBy?.Name ||
                                          report.PathReportType}
                                      </div>
                                      <button
                                        onClick={() => {
                                          navigate("viewBiomarker", {
                                            state: { report, patient },
                                          });
                                        }}
                                        className="flex flex-col items-center"
                                      >
                                        <img
                                          src={biomarker_icon_blue}
                                          alt="Biomarker"
                                          className="w-6 h-6"
                                        />
                                        {/* <div className="text-sky-400 text-xs font-normal font-poppins underline">
                                          View Biomarker(s)
                                        </div> */}
                                      </button>
                                      <button
                                        onClick={() => {
                                          handleOpenDocument(report);
                                        }}
                                        className="flex flex-col items-center"
                                      >
                                        <VscEye className="text-2xl text-sky-400" />
                                        <div className="text-sky-400 text-xs font-normal font-poppins underline">
                                          See Report(s)
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              <div className="flex items-center my-4">
                <div className="flex items-center justify-center w-8 h-8 border-4 border-sky-200 rounded-full font-bold mr-4 bg-sky-400 animate-pulse">
                  {/* Filled dot with pulsing animation */}
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-row items-center justify-center my-40">
              <div className="text-black text-base font-semibold font-poppins">
                No records available for the chosen report type.
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HealthJourney;

const REPORTTYPE = [
  {
    id: 1,
    title: "Lab Reports",
    label: "Lab Reports",
    image: lab_reports,
    background: "bg-amber-500",
  },
  {
    id: 2,
    title: "Imaging (X-Ray, MRI)",
    label: "Imaging",
    image: imaging,
    background: "bg-amber-500",
  },
  {
    id: 3,
    title: "Claims Document",
    label: "Claims Document",
    image: claim_report,
    background: "bg-amber-500",
  },
  {
    id: 4,
    title: "Doctor's Prescription",
    label: "Doctors Prescription",
    image: doctor_prescription,
    background: "bg-sky-400",
  },
  {
    id: 5,
    title: "Other Reports",
    label: "Others Reports",
    image: other_reports,
    background: "bg-lime-500",
  },
];
