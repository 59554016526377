import React from "react";
import AssociatedPhysicians from "./AssociatedPhysicians";
import ConsentForms from "./ConsentForms";
import LivingWill from "./LivingWill";
import PowerOfAttorney from "./PowerOfAttorney";
import ConsultationFormComponent from "./ConsultationFormComponent";

function AdminData({ patient }) {
  return (
    <div className="px-6">
      <section className="flex mt-5 space-x-5">
        <div className="flex-1 bg-white rounded-lg shadow p-4">
          <AssociatedPhysicians />
        </div>
        <div className="flex-1 bg-white rounded-lg shadow p-4">
          <ConsentForms />
        </div>
        <div className="flex-1">
          <div className="flex flex-col space-y-2">
            <div className="flex-1 bg-white rounded-lg shadow p-4">
              <LivingWill />
            </div>
            <div className="flex-1 bg-white rounded-lg shadow p-4">
              <PowerOfAttorney />
            </div>
          </div>
        </div>
      </section>
      <section className="my-5">
        <div className="bg-white rounded-lg shadow p-4 max-w-4xl">
          <ConsultationFormComponent patient={patient} />
        </div>
      </section>
    </div>
  );
}

export default AdminData;
