import styles from "./styles.module.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import mic_on from "../assets/Images/TelehealthDashboard/mic_on.png";
import vid_on from "../assets/Images/TelehealthDashboard/vid_on.png";
import speaker_on from "../assets/Images/TelehealthDashboard/speaker_on.png";
import phone_on from "../assets/Images/TelehealthDashboard/phone_on.png";
import DrBg from "../assets/Images/TelehealthDashboard/DrBg.png";
import VideoBg from "../assets/Images/TelehealthDashboard/VideoBg.png";
import ModalComponent from '../components/Modal';
import closeWidget from "../assets/Images/TelehealthDashboard/closeWidget.png";

function FullscreenVidCall() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [notes, setNotes] = useState("");
    const [personalNotes, setPersonalNotes] = useState("");
    const [diagnosisInfo, setDiagnosisInfo] = useState("");
    const [prescription, setPrescription] = useState("");

    return (
        <div className="bg-[#F3F3F3] min-h-screen py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
            <div className="w-full flex flex-col justify-start items-center bg-[#FFFFFF] rounded-lg p-4">
                <div className="flex flex-row justify-between w-full h-auto items-center gap-4 relative rounded-xl bg-[#F3F3F3] py-2">
                    {/* Medical History and Notes */}
                    <div className="w-[20vw] absolute left-4 top-4 flex flex-col justify-center items-center bg-[#FFFFFF] rounded-lg p-4">
                        <div className="w-full flex flex-col justify-center items-center gap-2 border-b-2 border-b-[#F3F5F6] pb-4">
                            <div className="flex flex-col justify-center items-center w-[55px] h-[55px] rounded-full">
                                <img
                                    src={require("../assets/Images/PatientDashboard/PatientPic.png")}
                                    className="w-full h-full object-contain rounded-full"
                                    alt="Patient image"
                                />
                            </div>
                            <h1 className="text-black text-base text-sm font-bold font-poppins">
                                Erin Dias
                            </h1>
                            <h1 className="text-[#8A8A8A] text-base text-xs font-regular font-poppins">
                                No : 123456789
                            </h1>
                            <h1 className="text-[#000000] text-base text-xs font-semibold font-poppins mt-3">
                                30 Years Old
                            </h1>
                        </div>
                        <div className="w-full flex flex-col justify-center items-start border-b-2 border-b-[#F3F5F6] pb-4 mt-4">
                            <h1 className="text-black text-base text-lg font-bold font-poppins">
                                Medical History
                            </h1>
                            <h1 className="text-[#8A8A8A] text-base text-xs font-regular font-poppins mt-4">
                                Childhood Illnesses
                            </h1>
                            <h1 className="text-[#000000] text-base text-xs font-bold font-poppins mt-1">
                                Had chickenpox at age 8.
                            </h1>
                            <h1 className="text-[#8A8A8A] text-base text-xs font-regular font-poppins mt-4">
                                Allergies
                            </h1>
                            <h1 className="text-[#000000] text-base text-xs font-bold font-poppins mt-1">
                                Penicillin (causes hives)
                            </h1>
                            <h1 className="text-[#8A8A8A] text-base text-xs font-regular font-poppins mt-4">
                                Chronic Conditions
                            </h1>
                            <h1 className="text-[#000000] text-base text-xs font-bold font-poppins mt-1 text-left">
                                Type 2 diabetes mellitus diagnosed at age 35.
                            </h1>
                        </div>
                        <div className="w-full flex flex-col justify-center items-start pb-4 mt-4">
                            <h1 className="text-black text-base text-lg font-bold font-poppins">
                                Notes
                            </h1>
                            <div className="w-full flex flex-row justify-start items-start bg-[#F3F5F6] rounded-xl px-5 py-2 mt-2">
                                <textarea
                                    type="text"
                                    className="bg-[#F3F5F6] p-2 w-full h-[10vh] border-0 border-[#F3F5F6] text-[#ABABAB] text-xs"
                                    placeholder="Input your notes here"
                                    onChange={(e) => setNotes(e.target.value)}
                                    value={notes} />
                            </div>
                        </div>
                    </div>

                    {/* Patient Vid */}
                    <img
                        src={VideoBg}
                        className="w-full h-full object-contain"
                        alt="Patient Video"
                    />

                    {/* Doctor Vid */}
                    <div
                        className={`absolute right-2 top-2 flex justify-center items-center w-[35%] h-auto`}
                    >
                        <img
                            src={DrBg}
                            className="w-full h-full object-contain"
                            alt="expand Video"
                        />
                    </div>

                    {/* Btns */}
                    <div className="absolute bottom-6 w-full flex flex-row justify-center items-center gap-2">
                        <div className="flex justify-center items-center w-[50px] h-[50px] p-2 rounded-full bg-[#E5F2FF]">
                            <img
                                src={mic_on}
                                className="w-full h-full object-contain"
                                alt="Mic Icon"
                            />
                        </div>
                        <div className="flex justify-center items-center w-[50px] h-[50px] p-2 rounded-full bg-[#E5F2FF]">
                            <img
                                src={speaker_on}
                                className="w-full h-full object-contain"
                                alt="Speaker Icon"
                            />
                        </div>
                        <div className="flex justify-center items-center w-[50px] h-[50px] p-2 rounded-full bg-[#E5F2FF]">
                            <img
                                src={vid_on}
                                className="w-full h-full object-contain"
                                alt="Video Icon"
                            />
                        </div>
                        <div onClick={() => setShowModal(true)} className="flex justify-center items-center w-[50px] h-[50px] p-2 rounded-full bg-[#DF535F]">
                            <img
                                src={phone_on}
                                className="w-full h-full object-contain"
                                alt="Call Icon"
                            />
                        </div>
                    </div>

                    {/* Video Call Done Modal */}
                    <ModalComponent show={showModal} >
                        <div className="bg-[#FFFFFF] px-4 py-1 h-fit w-[30vw]">
                            {/* HEADER */}
                            <div className={`flex flex-row w-full justify-start items-center rounded-lg px-4 gap-5 mb-4`}>
                                <div className="w-full flex flex-col justify-center items-center gap-1">
                                    <div className="w-full flex flex-row justify-center items-center relative">
                                        <h1 className="text-black text-base text-lg font-bold font-poppins">
                                            Telehealth Done
                                        </h1>
                                        {/* Close Btn */}
                                        <div onClick={() => setShowModal(false)} className="flex flex-col justify-center items-center w-[40px] h-[40px] rounded-full ml-auto cursor-pointer absolute right-4">
                                            <img
                                                src={closeWidget}
                                                className="w-full h-full object-contain rounded-full"
                                                alt="Check Btn"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center items-center w-[55px] h-[55px] rounded-full">
                                        <img
                                            src={require("../assets/Images/PatientDashboard/PatientPic.png")}
                                            className="w-full h-full object-contain rounded-full"
                                            alt="Patient image"
                                        />
                                    </div>
                                    <h1 className="text-black text-base text-sm font-bold font-poppins">
                                        Erin Dias
                                    </h1>
                                    <h1 className="text-[#8A8A8A] text-base text-xs font-regular font-poppins">
                                        No : 123456789
                                    </h1>
                                    <h1 className="text-[#000000] text-base text-xs font-semibold font-poppins mt-2">
                                        30 Years Old
                                    </h1>
                                </div>
                            </div>

                            {/* Content */}
                            <div className="w-full flex flex-col justify-center items-start pb-2 mt-2">
                                <h1 className="text-black text-base text-sm font-bold font-poppins">
                                    Personal Notes (Not Shared with Patient)
                                </h1>
                                <div className="w-full flex flex-row justify-start items-start bg-[#F3F5F6] rounded-lg px-5 py-2 mt-2">
                                    <textarea
                                        type="text"
                                        className="bg-[#F3F5F6] p-2 w-full h-[10vh] border-0 border-[#F3F5F6] text-[#ABABAB] text-xs"
                                        placeholder="Input your personal notes here"
                                        onChange={(e) => setPersonalNotes(e.target.value)}
                                        value={personalNotes} />
                                </div>
                                <h1 className="text-black text-base text-sm font-bold font-poppins mt-2">
                                    Diagnosis Information
                                </h1>
                                <div className="w-full flex flex-row justify-start items-start bg-[#F3F5F6] rounded-lg px-5 py-2 mt-2">
                                    <textarea
                                        type="text"
                                        className="bg-[#F3F5F6] p-2 w-full h-[10vh] border-0 border-[#F3F5F6] text-[#ABABAB] text-xs"
                                        placeholder="Input diagnosis information here"
                                        onChange={(e) => setDiagnosisInfo(e.target.value)}
                                        value={diagnosisInfo} />
                                </div>
                                <h1 className="text-black text-base text-sm font-bold font-poppins mt-2">
                                    Prescription
                                </h1>
                                <div className="w-full flex flex-row justify-start items-start bg-[#F3F5F6] rounded-lg px-5 py-2 mt-2">
                                    <textarea
                                        type="text"
                                        className="bg-[#F3F5F6] p-2 w-full h-[10vh] border-0 border-[#F3F5F6] text-[#ABABAB] text-xs"
                                        placeholder="Input diagnosis information here"
                                        onChange={(e) => setPrescription(e.target.value)}
                                        value={prescription} />
                                </div>
                                <div className="w-full flex flex-row justify-center items-center gap-2 mt-3">
                                    <div onClick={() => { }} className={`cursor-pointer hover:scale-110 duration-500 flex justify-center items-center py-3 px-2 bg-[#E5F2FF] rounded-lg w-[50%]`}>
                                        <h1 className={`text-[#3CA5DC] text-sm font-semibold font-poppins`}>
                                            Save
                                        </h1>
                                    </div>
                                    <div onClick={() => { }} className={`cursor-pointer hover:scale-110 duration-500 flex justify-center items-center py-3 px-2 bg-[#E5F2FF] rounded-lg w-[50%]`}>
                                        <h1 className={`text-[#3CA5DC] text-sm font-semibold font-poppins`}>
                                            Save & Send to Patient
                                        </h1>
                                    </div>
                                </div>

                                {/* Book Follow-up Consultation */}
                                <div onClick={() => { }} className={`cursor-pointer hover:scale-110 duration-500 flex justify-center items-center py-3 px-2 bg-[#3CA5DC] rounded-lg w-full mt-4`}>
                                    <h1 className={`text-[#F9F9F9] text-sm font-semibold font-poppins`}>
                                        Book Follow-up Consultation
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </ModalComponent>
                </div>
            </div>
        </div>
    );
}

export default FullscreenVidCall;
