import React, { useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import Dropdown from "../components/Dropdown";
import { IoAddOutline } from "react-icons/io5";

function AddConsultationForm() {
  const [questions, setQuestions] = useState([]);
  const [questionText, setQuestionText] = useState("");
  const [questionType, setQuestionType] = useState("text");
  const [options, setOptions] = useState([""]); // For select-type questions

  const handleAddQuestion = () => {
    const newQuestion = {
      text: questionText,
      type: questionType,
      options: questionType !== "text" ? options : [], // Options only for select types
    };
    setQuestions([...questions, newQuestion]);
    setQuestionText("");
    setQuestionType("text");
    setOptions([""]);
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  const addOptionField = () => {
    setOptions([...options, ""]);
  };

  const handleSubmit = () => {
    console.log("Survey Questions:", questions);
    // Here, you could send `questions` to a backend or save it for React Native
  };

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      <div className="flex flex-row items-center justify-between">
        {/* Header */}
        <div>
          <div className="text-zinc-900 text-2xl font-medium font-poppins">
            Add New Form
          </div>
          <Breadcrumb />
        </div>

        <div className="flex flex-row items-center justify-center space-x-4">
          <button className="w-40 h-10 px-4 py-2 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95">
            <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
              Save as Draft
            </div>
          </button>
          <button className="w-40 h-10 px-4 py-2 flex items-center justify-center bg-sky-400 rounded-lg active:scale-95">
            <div className="text-white text-base font-semibold font-poppins leading-normal">
              Save
            </div>
          </button>
        </div>
      </div>

      <div className="flex gap-8 p-8">
        {/* Form */}
        <div className="w-1/2">
          <div className="mb-6 flex space-x-4">
            {/* PinCode */}
            <label className="flex-1">
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                Name of the Form
                <sup className="text-red-600">*</sup>
              </p>
              <input
                name="Name"
                type="text"
                // value={formData.PinCode}
                // onChange={handleOnChange}
                placeholder="Enter"
                className={`w-full ${
                  errors.PinCode ? "border-red-500" : ""
                } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
            {/* Form Type */}
            <label className="flex-1">
              <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                Type
                <sup className="text-red-600">*</sup>
              </p>
              <Dropdown
                name="FormType"
                options={["Pre-Consultation", "Post-Consultation"]}
                // onSelect={handleSelect}
                // value={formData.Country}
              />
            </label>
          </div>
          <div className="bg-white rounded-lg shadow-md p-6 border border-sky-500">
            {/* Questions */}
            <div>
              <div className="w-40">
                <Dropdown
                  name="FormType"
                  options={[
                    "Short Text",
                    "Long Text",
                    "Yes/No",
                    "Single Choise",
                    "Multiple Choice",
                    "Dropdown",
                  ]}
                  // onSelect={handleSelect}
                  // value={formData.Country}
                />
              </div>
              <div className="my-6">
                <label>
                  <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                    Question
                    <sup className="text-red-600">*</sup>
                  </p>
                  <input
                    name="Name"
                    type="text"
                    // value={formData.PinCode}
                    // onChange={handleOnChange}
                    placeholder="Enter"
                    className={`w-full ${
                      errors.PinCode ? "border-red-500" : ""
                    } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>

              <button className="flex flex-row items-center space-x-1">
                <IoAddOutline className="text-sky-400 text-xl font-semibold" />
                <div className="text-sky-400 text-base font-semibold font-poppins leading-tight">
                  Add Question
                </div>
              </button>
            </div>
          </div>
        </div>

        {/* Preview  */}
        <div className="w-1/2">
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="text-center text-zinc-900 text-xl font-semibold font-poppins mb-7">
              Preview of form that will be shown to patients
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddConsultationForm;
