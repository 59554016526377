import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import {
//   registerMerchant,
//   sendForgotPasswordOtp,
//   verifyForgotPasswordOTP,
//   verifyMerchantOTP,
// } from "../services/operations/authAPI";
import logo from "../assets/Images/logo.png";
import OtpInput from "react-otp-input";
import { toast } from "react-hot-toast";
import reqDemoIcon from "../assets/Images/reqDemoIcon.png";
import { SlLogin } from "react-icons/sl";
import { sendForgotPasswordOtp, verifyForgotPasswordOTP } from "../services/operations/authAPI";

function ForgotPasswordOtpForm({type}) {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  //   const { signupData, loading } = useSelector((state) => state.auth);
  const tempData = localStorage.getItem("tempData")
    ? JSON.parse(localStorage.getItem("tempData"))
    : "";

  useEffect(() => {
    if (!tempData) {
      navigate("/forgot-password");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResendOTP = () => {
    dispatch(
      sendForgotPasswordOtp(
        tempData.Email || tempData.Phone,
        tempData.LoginType,
        navigate,
        type
      )
    );

    setOtp("");
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (otp.length !== 4) {
      toast.error("Please enter a valid OTP");
      return;
    }
    dispatch(
      verifyForgotPasswordOTP(
        tempData.Email || tempData.Phone,
        otp,
        tempData.LoginType,
        navigate,
        type
      )
    );

    setOtp("");
  };

  return (
    <div>
      {loading ? (
        <div>
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="bg-white w-full md:w-[37vw] h-[90vh] rounded-[22px] py-10 px-20 flex flex-col justify-start">
          <div className="flex items-center justify-center gap-3 mt-6 w-full">
            <Link to={type === 'clinic' ? "/partnerLogin" : "/login"} className="block w-44 bg-[#3ca5dc] rounded-2xl py-2 px-6 flex flex-row justify-between items-center gap-2">
              <h1 className="text-[#FFFFFF] text-[13px] font-semibold font-open font-poppins">
              {type === 'clinic' ? "Partner Login" : "Clinic Login"}
              </h1>
              <SlLogin className="text-[#FFFFFF] font-semibold text-lg" />
            </Link>
            <button onClick={() => window.open("https://alleshealth.com/contactus", "_blank")} className="block w-44 bg-white border border-[#3ca5dc] rounded-2xl py-2 px-4 flex flex-row justify-between items-center gap-2">
              <h1 className="text-black text-[13px] font-semibold font-open font-poppins">
                Request a Demo
              </h1>
              <div className="">
                <img
                  src={reqDemoIcon}
                  alt="Demo Icon"
                  className="w-5 h-5 object-contain"
                />
              </div>
            </button>
          </div>

          <div className="flex flex-col justify-center items-start">
            <div className="text-black text-[32px] font-semibold font-open font-poppins leading-relaxed mt-28">
              Enter OTP
            </div>
            <h1 className="text-[#acacac] text-[10px] font-normal font-open font-poppins leading-relaxed mt-3">
            Input the OTP sent to you
            </h1>
          </div>

          <p className="text-black text-sm font-normal font-poppins leading-tight mt-8">
            4 digit code has been sent to{" "}
            <span>{tempData?.email || tempData?.PhoneNumber}</span>
          </p>
          <form onSubmit={handleOnSubmit} className="mt-8">
            <div className="mr-20">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderInput={(props) => (
                  <input
                    {...props}
                    placeholder="-"
                    style={{
                      boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                    }}
                    className="w-[48px] lg:w-[50px] border-0 bg-gray-100 rounded-md text-black text-2xl font-bold font-orbitron aspect-square text-center focus:border-0 focus:outline-1 focus:outline-sky-400"
                  />
                )}
                containerStyle={{
                  justifyContent: "space-between",
                  gap: "0 6px",
                }}
              />
            </div>

            <div className="mt-8">
              <span className="text-gray-500 text-xs font-normal font-poppins">
                If you didn't receive code!{" "}
              </span>
              <span
                onClick={handleResendOTP}
                className="text-sky-400 text-xs font-normal font-poppins underline cursor-pointer"
              >
                Resend Code
              </span>
            </div>
            <div className="mt-8">
              <button
                type="submit"
                className="w-full bg-sky-400 hover:bg-sky-400 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 rounded-2xl py-3 text-sky-100 text-sm font-medium font-poppins"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default ForgotPasswordOtpForm;
