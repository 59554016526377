import Constant from "../config/Constant";
const BASE_URL = Constant.DEFAULT_APP_URL;
// AUTH ENDPOINTS
export const authEndpoints = {
  LOGIN_API: BASE_URL + "/clinicianPortal/doctorUserLogin",
  PARTNER_LOGIN_API: BASE_URL + "/masterDataManagement/labPartnerLogin",
  CHANGE_PASSWORD_API:
    BASE_URL + "/clinicianPortal/changeOrganizationUserPassword",
  VERIFY_OTP_API: BASE_URL + "/newPartnerManagement/verifyMerchantOTP",
  UPLOAD_IMAGE_API: BASE_URL + "/newPartnerManagement/upload-company-logo",
  FORGOT_PASSWORD_API:
    BASE_URL + "/clinicianPortal/sendClinicianForgotPasswordOTP",
  VERIFY_FORGOT_PASSWORD_OTP_API:
    BASE_URL + "/clinicianPortal/verifyClinicianForgotPasswordOTP",
  RESET_PASSWORD_API: BASE_URL + "/clinicianPortal/changeClinicianPassword",
};

// USER ENDPOINTS
export const userEndpoints = {
  GET_USER_DETAILS_API:
    BASE_URL + "/patientManagement/getCurrentUserAccountInfo",
  GET_ORGANIZATION_LOGO: BASE_URL + "/masterDataManagement/getPageLogo",
  CHANGE_USER_PASSWORD_API:
    BASE_URL + "/clinicianPortal/changeOrganizationUserPassword",
  UPDATE_USER_DETAILS_API:
    BASE_URL + "/clinicianPortal/updateOrganizationUserDetails",
};

// PATIENT MANAGEMENT ENDPOINST
export const patientManagementEndpoints = {
  GET_ALL_PATIENTS_API: BASE_URL + "/patientManagement/getAllPatients",
  GET_ARCHIVED_PATIENTS_API:
    BASE_URL + "/patientManagement/getAllDeletedPatientList",
  ADD_NEW_PATIENT_API: BASE_URL + "/patientManagement/registerPatient",
  GET_PATIENT_PROFILE: BASE_URL + "/patientManagement/getUserDetails",
  UPDATE_PATIENT_PROFILE: BASE_URL + "/patientManagement/updateUserDetails",
  GET_MENTAL_HEALTH_ASSESSMENT_SCORE:
    BASE_URL +
    "/patientManagement/getPaginateMentalHealthAssessmentScoreByDoctor",
  GET_MENTAL_HEALTH_QUESTION_ANSWERS:
    BASE_URL + "/patientManagement/checkMentalHealthSubmitedAnswer",
  GET_CONSULTATION_HISTORY:
    BASE_URL + "/patientManagement/getAllConsultationForSpecifiedPatient",
  GET_PRE_MEDICAL_CONDITIONS:
    // BASE_URL + "/patientManagement/viewPreMedicalConditions",
    BASE_URL + "/patientManagement/getPatientPreMedicalConditionDetails",
  GET_HEALTH_REPORTS:
    BASE_URL + "/patientManagement/getReportDataByDiseaseByOrganization",
  UPLOAD_HEALTH_REPORT:
    BASE_URL + "/patientManagement/uploadReportFromDoctorPortal",
  SAVE_BIOMARKERS:
    BASE_URL + "/patientManagement/savebiomarkersdataFromDoctorPortal",
  DECRYPT_REPORT: BASE_URL + "/patientManagement/getDecryptReportByAHUID",
  GET_HEALTH_JOURNEY:
    BASE_URL + "/patientManagement/getHealthJourneyDataFromDoctorPortal",
  GET_FAMILY_MEMBER: BASE_URL + "/patientManagement/viewFamilyMember",
  ADD_FAMILY_MEMBER:
    BASE_URL + "/patientManagement/addFamilyMemberByOrganization",
  GET_PRESCRIPTION:
    BASE_URL + "/patientManagement/getPreviousDosageAdherenceRecords",
  ADD_PRESCRIPTION: BASE_URL + "/patientManagement/addDosageAdherence",
  UPATE_PRESCRIPTION:
    BASE_URL + "/patientManagement/updateDosageAdherenceRecord",
  DELETE_PRESCRIPTION:
    BASE_URL + "/patientManagement/deleteDosageAdherenceRecord",
  GET_ALL_MEDICINES: BASE_URL + "/patientManagement/getAllMedicines",

  GET_ALLERGIES_PER_PATIENT:
    BASE_URL + "/clinicianPortal/getPatientAllergyDetails",
  ADD_ALLERGY: BASE_URL + "/clinicianPortal/addPatientAllergies",
  GET_DIAGNOSIS_TYPE: BASE_URL + "/patientManagement/getAllDiagnosis",
  ADD_NOTES: BASE_URL + "/clinicianPortal/storeAdditionalInformation",
  ADD_AUDIO_EXTRACTED_DATA:
    BASE_URL + "/clinicianPortal/storeAdditionalInformation",
  AUDIO_TRANSCRIPTION:
    "https://beta-audio-ai-api.alleshealth.com/v1/crownextractor",
  ASSIGN_TREATMENT_PROGRAM:
    BASE_URL + "/patientManagement/assignTreatmentProgram",
  GET_ASSIGN_TREATMENT_PROGRAM:
    BASE_URL + "/patientManagement/getAssignedTreatmentProgram",
  GET_ALL_ASSIGN_TREATMENT_PROGRAMS:
    BASE_URL + "/patientManagement/getAllAssignedTreatmentPrograms",
  EDIT_ASSIGN_TREATMENT_PROGRAMS:
    BASE_URL + "/patientManagement/updateAssignedTreatmentProgram",
  DELETE_ASSIGNED_HEALTH_TREATMENT_PROGRAM:
    BASE_URL + "/patientManagement/deleteAssignedTreatmentProgram",
  GET_PATIENTS_UPCOMING_TELEHEALTH_SESSIONS:
    BASE_URL + "/clinicianPortal/getUpcomingTeleHealthSession",
  DELETE_APPOINTMENT: BASE_URL + "/clinicianPortal/cancelAppointment",
  GET_CURRENT_CONCIERGE_PLAN:
    BASE_URL + "/patientManagement/getUserMedicalConciergePlanDetails",
  CHANGE_CONCIERGE_PLAN:
    BASE_URL + "/medicalConcierge/changeMedicalConciergePlan",
  CANCEL_CONCIERGE_PLAN:
    BASE_URL + "/medicalConcierge/cancelMedicalConciergePlan",
  GET_MEDICAL_COMMITTEE_MEMBERS:
    BASE_URL + "/patientManagement/getALLMedicalCommitteeMember",
  DELETE_MEDICAL_COMMITTEE_MEMBER:
    BASE_URL + "/patientManagement/deleteMedicalCommitteeMember",
  ADD_MEDICAL_COMMITTEE_MEMBER:
    BASE_URL + "/patientManagement/addOrganizationMembertoMedicalCommittee",
  UPATE_MEDICAL_COMMITTEE_MEMBER:
    BASE_URL + "/patientManagement/updateMedicalCommitteeMember",
  GET_USER_ACCESS_CONTROL:
    BASE_URL + "/patientManagement/checkUserAccessControl",
  ADD_PRE_MEDICAL_CONDITIONS:
    BASE_URL + "/patientManagement/addPatientPreMedicalConditionDetails",
  GET_LIFESTYLE_DATA:
    BASE_URL + "/patientManagement/getPatientLifestyleDetails",
  UPDATE_LIFESTYLE_DATA:
    BASE_URL + "/patientManagement/addPatientLifestyleDetails",
  VIEW_BIOMARKERS: BASE_URL + "/patientManagement/getBioMarkerData",
};

//DASHBOARD END POINTS
export const clinicianDashboardEndpoints = {
  GET_ALL_BOOKED_SLOTS_API: BASE_URL + "/clinicianPortal/getBookedPatientList",
  GET_DATE_ID: BASE_URL + "/clinicianPortal/getDateID",
  GET_AVAILABLE_SLOTS_FOR_SPECIFIED_DATE:
    BASE_URL + "/clinicianPortal/getAvailableSlotsForSpecifiedDate",
  GET_AVAILABLE_SLOTS_FOR_DOCTOR_ON_SPECIFIED_DATE:
    BASE_URL +
    "/clinicianPortal/getAvailableWindowsForSpecifiedDoctorFromDoctorPortal",
  GET_ORGANIZATION_DOCTORS:
    BASE_URL + "/patientManagement/getOrganizationDoctors",
  BOOK_APPOINTMENT: BASE_URL + "/clinicianPortal/bookSessionByOrganization",
  GET_SLOT_AVAILABILITY_STATUS:
    BASE_URL + "/clinicianPortal/getSlotAvailabilityStatus",
};

// MASTER DATA END POINTS
export const masterDataEndpoints = {
  INVOICING: BASE_URL + "/masterDataManagement/getBillingScreen",
  UPDATE_INVOICE: BASE_URL + "/masterDataManagement/updateInvoice",
  GET_ALL_INVOICES: BASE_URL + "/masterDataManagement/getAllInvoices",
  CREATE_INVOICE: BASE_URL + "/masterDataManagement/createInvoice",
  SEND_INVOICE_TO_PATIENT:
    BASE_URL + "/masterDataManagement/sendInvoiceToPatient",
  GET_SPECIFIC_INVOICES: BASE_URL + "/masterDataManagement/getSpecificInvoice",
  GET_ALL_TREATMENT_PROGRAMS:
    BASE_URL + "/masterDataManagement/getAllTreatmentProgram",
  GET_ALL_TREATMENT_PROGRAM_WEEK_DATA:
    BASE_URL + "/masterDataManagement/getAllTreatmentWeekData",
  CREATE_NEW_HEALTH_TREATMENT_PROGRAM:
    BASE_URL + "/masterDataManagement/createNewTreatmentProgram",
  UPDATE_HEALTH_TREATMENT_PROGRAM:
    BASE_URL + "/masterDataManagement/updateTreatmentProgram",
  DELETE_HEALTH_TREATMENT_PROGRAM:
    BASE_URL + "/masterDataManagement/deleteTreatmentProgram",
  GET_ALL_DIAGNOSIS: BASE_URL + "/patientManagement/getAllDiagnosis",
  GET_ALL_MEDICINES: BASE_URL + "/patientManagement/getAllMedicines",
  GET_ALL_PRE_MEDICAL_CONDITIONS:
    BASE_URL + "/patientManagement/getAllPreMedicalConditions",
  ADD_NEW_PRE_MEDICAL_CONDITIONS:
    BASE_URL + "/patientManagement/addNewPreMedicalCondition",
  GET_ALL_ALLERGIES: BASE_URL + "/clinicianPortal/getAllAllergies",
  ADD_NEW_ALLERGY: BASE_URL + "/clinicianPortal/addNewAllergy",
  GET_ALL_SERVICE_RATE_ENTRY:
    BASE_URL + "/masterDataManagement/getAllServiceRateEntry",
  GET_ALL_SERVICES_CARD_RATE:
    BASE_URL + "/masterDataManagement/getAllServiceRateEntry",
  ADD_SERVICES_CARD_RATE:
    BASE_URL + "/masterDataManagement/createNewServiceRateEntry",
  UPDATE_SERVICES_CARD_RATE:
    BASE_URL + "/masterDataManagement/updateServiceRateCard",
  DELETE_SERVICES_CARD_RATE:
    BASE_URL + "/masterDataManagement/deleteServiceRateCard",
  GET_ALL_SETTINGS: BASE_URL + "/masterDataManagement/getAllSetting",
  UPLOAD_ORGANIZATION_LOGO: BASE_URL + "/patientManagement/uploadProfileImage",
  UPDATE_SETTINGS: BASE_URL + "/masterDataManagement/updateSetting",
  GET_CLINIC_CAPACITY: BASE_URL + "/masterDataManagement/getClinicCapacity",
  GET_ALL_NURSES: BASE_URL + "/clinicianPortal/getAllOrganizationNurseDetails",
  ADD_NURSE: BASE_URL + "/clinicianPortal/registerNurse",
  DELETE_NURSE: BASE_URL + "/clinicianPortal/deleteNurseDetails",
  UPDATE_NURSE: BASE_URL + "/clinicianPortal/updateNurseDetails",
  GET_ALL_DOCTOR: BASE_URL + "/clinicianPortal/getAllOrganizationDoctorDetails",
  ADD_DOCTOR: BASE_URL + "/clinicianPortal/registerDoctor",
  DELETE_DOCTOR: BASE_URL + "/clinicianPortal/deleteDoctorDetails",
  UPDATE_DOCTOR: BASE_URL + "/clinicianPortal/updateDoctorDetails",
  GET_KEY_PERFORMANCE_INDICATOR:
    BASE_URL + "/clinicianPortal/getKeyPerformanceIndicator",
  GET_CUSTOM_KEY_PERFORMANCE_INDICATOR:
    BASE_URL + "/clinicianPortal/getKeyPerformanceIndicatorForCustomDate",
  GET_CLINIC_BRANCHES: BASE_URL + "/masterDataManagement/getAllClinicBranches",
  CREATE_CLINIC_BRANCH: BASE_URL + "/masterDataManagement/createClinicBranch",
  DELETE_CLINIC_BRANCH: BASE_URL + "/masterDataManagement/deleteClinicBranch",
  UPDATE_CLINIC_BRANCH: BASE_URL + "/masterDataManagement/updateClinicBranch",
  GET_ALL_LAB_PARTNERS:
    BASE_URL + "/masterDataManagement/getAllLabPartnersWithLabTypes",
  DELETE_LAB_PARTNER: BASE_URL + "/masterDataManagement/deleteLabPartnerRecord",
  GET_LAB_TYPES: BASE_URL + "/masterDataManagement/getALLLabTypes",
  ADD_LAB_PARTNER: BASE_URL + "/masterDataManagement/createLabPartner",
  UPDATE_LAB_PARTNER: BASE_URL + "/masterDataManagement/updateLabPartnerRecord",
  GET_ALL_ORGANIZATION_USER_DETAILS:
    BASE_URL + "/masterDataManagement/getAllOrganizationUserDetails",
  UPDATE_ORGANIZATION_USER_DETAILS:
    BASE_URL + "/masterDataManagement/changeOrganizationUserType",
  GET_ALL_DOCTOR_SPECIALITY:
    BASE_URL + "/clinicianPortal/getAllDoctorSpeciality",
  GET_TELEHEALTH_SESSION_PRICE:
    BASE_URL + "/serviceManagement/getTelehealthSessionPricesForDoctorPortal",
  CREATE_TELEHEALTH_SESSION_PRICE:
    BASE_URL + "/serviceManagement/createNewTelehealthSessionPrice",
  UPDATE_TELEHEALTH_SESSION_PRICE:
    BASE_URL + "/serviceManagement/updateTelehealthSessionPrice",
  GET_ALL_ALLERGIES_CATEGORY:
    BASE_URL + "/clinicianPortal/getAllergyCategories",
  GET_ALL_SYMPTOMS: BASE_URL + "/clinicianPortal/getAllPatientSymptoms",
  GET_ALL_CUSTOM_NOTIFICATION_LIST:
    BASE_URL + "/serviceManagement/getAllNotificationMaster",
  CREATE_NEW_CUSTOM_NOTIFICATION:
    BASE_URL + "/serviceManagement/createNewNotification",
  UPDATE_CUSTOM_NOTIFICATION:
    BASE_URL + "/serviceManagement/updateNotificationMasterDetails",
  GET_PATIENTS_COUNT: BASE_URL + "/serviceManagement/getPatientCounts",
  GET_NOTIFICATION_CATEGORIES: BASE_URL + "/notifications/categoriesForCP",
  OVERRIDE_AVAILABILITY:
    BASE_URL + "/clinicianPortal/updateSpecificAvailabilityTime",
  GET_OVERRIDE_AVAILABILITY:
    BASE_URL + "/clinicianPortal/getOverriddenAvailability",
  REMOVE_OVERRIDE_AVAILABILITY:
    BASE_URL + "/clinicianPortal/revertOverriddenAvailability",
};

// MEDICAL CONCIERGE END POINTS
export const medicalConciergeEndpoints = {
  GET_MEDICAL_CONCIERGE_SPECIALITY:
    BASE_URL + "/medicalConcierge/getAllHealthManagerSpeciality",
  GET_MEDICAL_CONCIERGE_FEATURES:
    BASE_URL + "/medicalConcierge/getMedicalConciergeFeatures",
  GET_MEDICAL_CONCIERGE_PLANS:
    BASE_URL + "/medicalConcierge/getAllMedicalConciergePlanDetails",

  GET_HEALTH_MANAGERS:
    BASE_URL + "/medicalConcierge/getAllHealthManagerWithAssignedClients",
  ADD_HEALTH_MANAGER: BASE_URL + "/medicalConcierge/registerHealthManager",
  UPDATE_HEALTH_MANAGER:
    BASE_URL + "/medicalConcierge/updateHealthManagerDetails",
  DELETE_HEALTH_MANAGER: BASE_URL + "/medicalConcierge/deleteHealthManager",

  CREATE_MEDICAL_CONCIERGE_PLANS:
    BASE_URL + "/medicalConcierge/createNewMedicalConciergePlan ",
  UPDATE_MEDICAL_CONCIERGE_PLANS:
    BASE_URL + "/medicalConcierge/updateMedicalConciergePlan",
  DELETE_MEDICAL_CONCIERGE_PLANS:
    BASE_URL + "/medicalConcierge/deleteMedicalConciergePlan",

  ADD_NOTES: BASE_URL + "/medicalConcierge/addNotesToMedicalConcierge",
  ASSIGN_HEALTH_MANAGER: BASE_URL + "/medicalConcierge/assignHealthManager",
  ASSIGN_HEALTH_MANAGER_BULK:
    BASE_URL + "/medicalConcierge/assignHealthManagersInBulk",
  GET_ASSIGNED_PATIENTS:
    BASE_URL + "/medicalConcierge/getAllPatientsUnderParticularHealthManager",
  ADD_CONCIERGE_PATIENT:
    BASE_URL + "/medicalConcierge/addNewMedicalConciergeMemberFromPortal",
  GET_CONCIERGE_PATIENT:
    BASE_URL + "/medicalConcierge/getAllMedicalConciergeUserDetails",
};

// OUR SERVICES END POINTS
export const ourServicesEndpoints = {
  GET_TREATMENT_CATEGORY:
    BASE_URL + "/ourServices/getOurServiceTreatmentCategories",
  ADD_TREATMENT_CATEGORY:
    BASE_URL + "/ourServices/createOurServiceTreatmentCategory",
  UPDATE_TREATMENT_CATEGORY:
    BASE_URL + "/ourServices/updateOurServiceTreatmentCategory",
  DELETE_TREATMENT_CATEGORY:
    BASE_URL + "/ourServices/deleteOurServiceTreatmentCategory",
  ADD_TREATMENT_SERVICE:
    BASE_URL + "/ourServices/createOurServiceTreatmentService",
  UPDATE_TREATMENT_SERVICE:
    BASE_URL + "/ourServices/updateOurServiceTreatmentService",
  DELETE_TREATMENT_SERVICE:
    BASE_URL + "/ourServices/deleteOurServiceTreatmentService",
  UPLOAD_OUR_SERVICES_IMAGE: BASE_URL + "/ourServices/uploadServiceImage",
};

// REWARD MANAGEMENT END POINTS
export const rewardManagementEndpoints = {
  GET_ALL_REWARD_OFFERS:
    BASE_URL + "/rewardManagement/getAllOrganizationRewardOffers",
  ADD_REWARD: BASE_URL + "/rewardManagement/createRewardOffer",
  UPDATE_REWARD: BASE_URL + "/rewardManagement/updateRewardOffer",
  GET_REWARD_CUSTOMER_LIST:
    BASE_URL + "/rewardManagement/getCustomerListByRewardID",
  MARK_AS_COMPLETED: BASE_URL + "/rewardManagement/markSaleAsComplete",
  GET_REWARD_SALES_REPORT: BASE_URL + "/rewardManagement/getRewardSalesData",
  UPLOAD_REWARD_IMAGE: BASE_URL + "/rewardManagement/uploadRewardImage",
};

// CONSULTATION FORM END POINTS
export const consultationFormEndpoints = {
  GET_CONSULTATION_FORMS: BASE_URL + "/serviceManagement/getConsultationForms",
  ADD_CONSULTATION_FORM: BASE_URL + "/serviceManagement/createConsultationForm",
  UPDATE_CONSULTATION_FORM:
    BASE_URL + "/serviceManagement/updateConsultationForm",
  DELETE_CONSULTATION_FORM:
    BASE_URL + "/serviceManagement/deleteConsultationForm",
  UPLOAD_CONSULTATION_FORM:
    BASE_URL + "/serviceManagement/uploadConsultationForms",
  GET_ALL_SIGNED_CONSULTATION_FORM:
    BASE_URL + "/serviceManagement/getSignedConsultationForms",
  GET_ALL_SCHEDULED_CONSULTATION_FORM:
    BASE_URL +
    "/serviceManagement/getAllScheduledConsultationFormNotificationData",
  UPLOAD_SIGNED_CONSULTATION_FORM:
    BASE_URL + "/serviceManagement/uploadSignedConsultationForms",
  SCHEDULE_CONSULTATION_FORM:
    BASE_URL + "/serviceManagement/scheduleConsultationFormNotification",
  RESCHEDULE_CONSULTATION_FORM:
    BASE_URL + "/serviceManagement/rescheduleConsultationFormNotification",
  DELETE_SIGNED_FORM:
    BASE_URL + "/serviceManagement/deleteSignedConsultationForm",
};

// PLATFORM MANAGEMENT END POINTS
export const platformManagementEndpoints = {
  GET_ALL_POLICIES: BASE_URL + "/platformManager/viewPlatformManagerFiles",
  UPDATE_POLICIES: BASE_URL + "/platformManager/updatePlatformManagerFiles",
};

// MEMBERSHIP END POINTS
export const membershipEndpoints = {
  GET_ALL_MEMBERSHIP_PLANS:
    BASE_URL + "/platformManager/getClinicianSubscriptionPlansForCP",
  GET_ALL_PERKS: BASE_URL + "/platformManager/getAllClinicianPerksForCP",
  GET_CURRENT_MEMBERSHIP:
    BASE_URL + "/platformManager/getCurrentOrganizationSubscription",
  INITIATE_PAYMENT: BASE_URL + "/platformManager/createPaymentSession",
  CREATE_SUBSCRIPTION: BASE_URL + "/platformManager/handlePaymentSuccess",
};
