import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import biomarker_icon from "../assets/Icons/biomarker_icon.svg";
import warning_icon from "../assets/Icons/warning_icon.svg";
import info_icon from "../assets/Icons/info_icon.svg";
import report_insights from "../assets/Icons/report_insights.svg";
import { CiSearch } from "react-icons/ci";
import { getBioMarkers } from "../services/operations/patientManagementAPI";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

function ViewBiomarker() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, patient, report } = location.state || {};
  const { token } = useSelector((state) => state.auth);
  const [searchTerm, setSearchTerm] = useState("");
  const [biomarkers, setBiomarkers] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const renderCard = (item) => {
    return (
      <div className="p-4 bg-white rounded-lg shadow">
        <div className="flex flex-row items-center justify-between">
          <div className="text-slate-800 text-lg font-medium font-poppins leading-snug line-clamp-1">
            {item.BiomarkerName}
          </div>
          <img src={info_icon} alt="Info" className="w-5 h-5" />
        </div>

        <div className="h-px bg-gray-200 my-2" />

        <div className="flex flex-row items-center space-x-4">
          <div className="bg-sky-100 w-8 h-8 rounded-full flex items-center justify-center">
            <img
              src={report_insights}
              alt="Report Insights"
              className="w-5 h-5"
            />
          </div>
          <div>
            <div className="text-black text-sm font-normal font-poppins line-clamp-1">
              {item.BiomarkerName}
            </div>
            <div className="text-neutral-600 text-sm font-normal font-poppins mt-1">
              Value: {item.PickedUpValue} {item.PickedUpUnit}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const flattenedData =
    data && biomarkers.length > 0 ? biomarkers[0] : biomarkers;
  const filteredData = flattenedData.filter((item) =>
    item.BiomarkerName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      try {
        if (report?.PhysicalReport) {
          const res = await getBioMarkers(token, report.PhysicalReport);
          if (res.BioMarkers.length > 1) {
            setBiomarkers(res.BioMarkers);
          }
        } else if (data) {
          setBiomarkers(data);
        }
      } catch (error) {
        toast.error("Error fetching biomarkers");
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchData();
  }, [token, report, data]);

  if (isFetching) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  if (!biomarkers || biomarkers.length === 0) {
    return (
      <div className="bg-white min-h-full p-6 scroll-smooth">
        {/* Header */}
        <div className="flex flex-row items-center justify-between">
          <div className="text-zinc-900 text-2xl font-medium font-poppins">
            View BioMarker
          </div>
        </div>
        <Breadcrumb />
        <div className="flex items-center justify-center mt-24">
          <div className="text-zinc-900 text-lg font-medium font-poppins">
            No Biomarkers found
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      {/* Header */}
      <div className="flex flex-row items-center justify-between">
        <div className="text-zinc-900 text-2xl font-medium font-poppins">
          View BioMarker
        </div>
      </div>
      <Breadcrumb />

      {/* Card  */}
      <div className="flex items-start">
        <div className="p-4 bg-white rounded-lg border border-gray-200 mt-6">
          <div className="flex flex-row items-center space-x-3">
            <img src={biomarker_icon} alt="Biomarker" className="w-6 h-6" />
            <div className="text-center text-black text-lg font-normal font-poppins">
              Biomarkers
            </div>
          </div>

          <div className="flex flex-row items-center space-x-10 mt-4">
            {/* Patient Profile */}
            <div>
              <div className="flex flex-row items-center space-x-2">
                <img
                  src={patient?.Image}
                  alt="PatientPic"
                  className="w-10 h-10 rounded-full object-contain"
                />
                <div>
                  <div className="text-zinc-900 text-sm font-medium font-poppins leading-tight">
                    {patient?.Name ||
                      patient?.FirstName + " " + patient?.LastName}
                  </div>
                  <div className="text-neutral-600 text-xs font-normal font-poppins leading-tight">
                    {patient?.Gender}
                  </div>
                </div>
              </div>

              <div className="flex flex-row items-center space-x-2 mt-1">
                <div className="text-neutral-600 text-xs font-normal font-poppins leading-tight">
                  Patient ID:
                </div>
                <div className="text-zinc-900 text-xs font-medium font-poppins leading-tight">
                  {patient?.AHUID}
                </div>
              </div>
            </div>

            {/* <div>
              <div className="flex flex-row items-center space-x-2">
                <div className="text-black text-sm font-medium font-poppins">
                  Asthma
                </div>
                <img src={warning_icon} alt="Asthma" className="w-6 h-6" />
              </div>
              <div className="text-neutral-600 text-xs font-normal font-poppins">
                Last Updated on: <br /> 12/07/2024
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <label className="w-full mx-auto flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2 mt-6">
        <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
        <input
          type="text"
          placeholder="Search for biomarkers"
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent"
        />
      </label>

      <div className="container mx-auto p-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
          {filteredData.map((item, index) => (
            <div key={index}>{renderCard(item)}</div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ViewBiomarker;
