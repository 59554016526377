import React, { useEffect, useState, useRef } from 'react';
import './calendarStyles.css';
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getBucketURL } from "../utils/regex";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { BiCollapseAlt } from "react-icons/bi";
import ModalComponent from '../components/Modal';
import { FaChevronLeft } from "react-icons/fa6";
import Breadcrumb from '../components/Breadcrumb';
import collapseIcon from "../assets/Images/TelehealthDashboard/collapseIcon.png";

const localizer = momentLocalizer(moment);
moment.updateLocale("en", { week: { dow: 1 } });

const NextScheduleFullScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const calendarRef1 = useRef(null);

  const [selected, setSelected] = useState(new Date());
  const [weekData1, setWeekData1] = useState([]);
  const { dashboard } = useSelector((state) => state.dashboard);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState('');

  const filterPatientsForWeek1 = (patientsData, startOfWeek, endOfWeek) => {
    const weekData = [];

    for (let date = moment(startOfWeek); date.isSameOrBefore(endOfWeek); date.add(1, 'days')) {
      const year = date.year();
      const month = date.format('MMM').toUpperCase(); // Use Moment.js to get the month abbreviation
      const day = date.date();

      if (patientsData[year] && patientsData[year][month] && patientsData[year][month][day]) {
        const events = patientsData[year][month][day].map(event => {
          return { ...event, date: `${year}-${month}-${day}` };
        });
        weekData.push(...events);
      }
    }

    return weekData;
  };

  const getCurrentWeek = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    const startOfWeek = new Date(currentDate);
    const endOfWeek = new Date(currentDate);

    // Calculate the start of the week (Monday)
    const firstDay = currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    startOfWeek.setDate(firstDay);

    // Calculate the end of the week (Sunday)
    const lastDay = firstDay + 6;
    endOfWeek.setDate(lastDay);

    return { startOfWeek, endOfWeek };
  };

  const getWeekFrom = (currentDate, direction) => {
    const dayOfWeek = currentDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
    const startOfWeek = new Date(currentDate);
    const endOfWeek = new Date(currentDate);

    if (direction === 'NEXT') {
      // Calculate start of next week (next Monday)
      const daysUntilNextMonday = (dayOfWeek === 0 ? 1 : 7 - dayOfWeek + 1);
      startOfWeek.setDate(startOfWeek.getDate() + daysUntilNextMonday);

      // Calculate end of next week (next Sunday)
      endOfWeek.setDate(startOfWeek.getDate() + 6);
    } else if (direction === 'PREV') {
      // Calculate start of the previous week (previous Monday)
      const daysSinceLastMonday = (dayOfWeek === 0 ? 6 : dayOfWeek - 1);
      startOfWeek.setDate(startOfWeek.getDate() - (daysSinceLastMonday + 7));

      // Calculate end of the previous week (previous Sunday)
      endOfWeek.setDate(startOfWeek.getDate() + 6);
    } else {
      // Calculate the current week (Monday to Sunday)
      const firstDay = currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Monday
      startOfWeek.setDate(firstDay);

      // Calculate the end of the current week (Sunday)
      endOfWeek.setDate(startOfWeek.getDate() + 6);
    }

    return { startOfWeek, endOfWeek };
  };

  const formatWeekData1 = (weekPatients) => {
    const now = new Date(); // Get the current date and time
    return weekPatients.map(patient => {
      const startDateTime = moment(`${patient.date} ${patient.Session_Info.Start_time}`, 'YYYY-MMM-DD HH:mm:ss').toDate();
      const endDateTime = moment(`${patient.date} ${patient.Session_Info.End_time}`, 'YYYY-MMM-DD HH:mm:ss').toDate();

      return {
        title: patient.Patient_Info.Name,
        start: startDateTime,
        end: endDateTime,
        allDay: false,
        resource: patient,
        isUpcoming: endDateTime > now // Check if the event is upcoming
      };
    });
  };

  const EventComponent = ({ event }) => {
    const truncateText = (text, length) => {
      return text.length > length ? `${text.slice(0, length)}...` : text;
    };

    const titleText = event.title;
    const resourceText = `${event.resource.Patient_Info.Gender} - ${event.resource.Patient_Info.Age} Yrs`;
    const combinedText = `${titleText}, ${resourceText}`;

    const eventClass = event.isUpcoming ? 'rbc-upcoming-event' : 'rbc-past-event';

    return (
      <div className={`flex flex-col justify-center items-start ${eventClass}`}>
        <h1 className="text-[#545454] text-[12px] font-regular font-poppins text-left truncate flex items-center">
          <span className="text-[#545454] mr-1">•</span>
          {combinedText.length > 22 ? `${combinedText.slice(0, 22)}...` : combinedText}

        </h1>
      </div>
    );
  };

  const CustomTimeSlotWrapper = ({ children }) => (
    React.cloneElement(React.Children.only(children), {
      className: 'custom-time-slot',
    })
  );

  const handleEventSelect = async (event) => {
    setSelectedEvent(event);
    setShowModal(true);

    try {
      const profileUrl = await getBucketURL(event.resource.Patient_Info.ProfileImageUrl);
      setProfileImageUrl(profileUrl);
    } catch (error) {
      console.log("Error fetching Profile Pic", error);
    }
  };

  const handleNavigate = (date) => {
    setSelected(date);
  };

  const fetchAndFormatDataForWeek = async (startOfWeek, endOfWeek) => {
    const weekPatients = filterPatientsForWeek1(dashboard, startOfWeek, endOfWeek);
    const weekDataFormatted = formatWeekData1(weekPatients);
    setWeekData1(weekDataFormatted);
  };

  const CustomToolbar = (toolbar) => {
    const date = moment(toolbar.date);
    const month = date.month(); // 0-based month index
    const year = date.year();
    const monthName = date.format('MMMM');

    const addMonths = (date, months) => {
      const d = date.getDate();
      date.setMonth(date.getMonth() + months);
      if (date.getDate() != d) {
        date.setDate(0);
      }
      return date;
    }

    const goToBack = () => {
      const { startOfWeek, endOfWeek } = getWeekFrom(toolbar.date, 'PREV');
      fetchAndFormatDataForWeek(startOfWeek, endOfWeek);
      toolbar.onNavigate('PREV');
    };
    const goToBackMonth = () => {
      const { startOfWeek, endOfWeek } = getWeekFrom(addMonths(toolbar.date, -1), 'CURR');
      fetchAndFormatDataForWeek(startOfWeek, endOfWeek);
      // toolbar.onNavigate('PREV', addMonths(toolbar.date, -1));
    };

    const goToNext = () => {
      const { startOfWeek, endOfWeek } = getWeekFrom(toolbar.date, 'NEXT');
      fetchAndFormatDataForWeek(startOfWeek, endOfWeek);
      toolbar.onNavigate('NEXT');
    };
    const goToNextMonth = () => {
      const { startOfWeek, endOfWeek } = getWeekFrom(addMonths(toolbar.date, 1), 'CURR');
      fetchAndFormatDataForWeek(startOfWeek, endOfWeek);
      // toolbar.onNavigate('NEXT', addMonths(toolbar.date, 1));
    };

    const label = () => {
      const date = moment(toolbar.date);
      const startOfWeek = date.clone().startOf('week');
      const endOfWeek = date.clone().endOf('week');
      return (
        <span className="custom-toolbar-label">
          {startOfWeek.format('DD MMM')} - {endOfWeek.format('DD MMM')}
        </span>
      );
    };

    return (
      <div className="custom-toolbar bg-[#FFFFFF] rounded-lg py-2 flex flex-row justify-start items-center relative px-5">
        {/* Header */}
        <div className="flex flex-col justify-center items-start gap-2 mb-4">
          <div className="flex flex-row items-center space-x-4">
            {/* <button
              className="active:scale-90"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaChevronLeft className="h-5" />
            </button> */}
            <h1 className={`flex text-black text-lg font-bold font-poppins`}>
              Your Weekly Calendar
            </h1>
          </div>

          <Breadcrumb />
        </div>

        {/* Weekly Toolbar */}
        <div className="ml-auto flex justify-center items-center border border-[#D4D4D4] rounded-lg py-2">
          <div className="flex items-center">
            {/* Prev Week Btn */}
            <button className="bg-white border border-gray-300 text-gray-600 font-semibold py-1 px-2 rounded-l" onClick={goToBack}><IoIosArrowBack /></button>
            <span className="custom-toolbar-label">{label()}</span>
            {/* Next Week Btn */}
            <button className="bg-white border border-gray-300 text-gray-600 font-semibold py-1 px-2 rounded-r" onClick={goToNext}><IoIosArrowForward /></button>
          </div>
        </div>

        {/* Monthly Toolbar */}
        <div className="flex justify-center items-center border border-[#D4D4D4] rounded-lg py-2 ml-2">
          <div className="flex items-center">
            {/* Prev Month Btn */}
            <button className="bg-white border border-gray-300 text-gray-600 font-semibold py-1 px-2 rounded-l" onClick={goToBackMonth}><IoIosArrowBack /></button>
            <span className="custom-toolbar-label">{monthName} {year}</span>
            {/* Next Month Btn */}
            <button className="bg-white border border-gray-300 text-gray-600 font-semibold py-1 px-2 rounded-r" onClick={goToNextMonth}><IoIosArrowForward /></button>
          </div>
        </div>

        {/* Collapse screen Icon */}
        <div className="flex flex-row justify-end items-center ml-2">
          <div
            className="text-sky-400 text-2xl active:scale-90 transition-transform border rounded-lg border-[#D1D1D1] w-7 h-7 p-1 cursor-pointer"
            onClick={() => {
              navigate("/clinicianDashboard");
            }}
          >
            <img
              src={collapseIcon}
              className="w-full h-full object-contain"
              alt="Collapse Icon"
            />
          </div>
        </div>
      </div>
    );
  };

  const CustomDayHeader = ({ label, date }) => {
    const isToday = moment().isSame(date, 'day');
    const dayName = moment(date).format('ddd');
    const formattedDate = isToday ? 'Today' : moment(date).format('DD');

    return (
      <div>
        <div style={{ color: '#545454', fontSize: '12px', fontFamily: 'Arial', fontWeight: '300' }}>
          {dayName}
        </div>
        <div style={{ fontWeight: '600', color: '#1C1C1C', fontSize: '14px', fontFamily: 'Arial' }}>
          {formattedDate}
        </div>
      </div>
    );
  };

  const momentCurrent = moment();

  // Add useEffect to dynamically update styles
  useEffect(() => {
    const updateEventClasses = () => {
      const events = calendarRef1?.current?.querySelectorAll('.rbc-event');

      events?.forEach(event => {
        const eventContent = event?.querySelector('.rbc-event-content');
        if (eventContent) {
          // Check for rbc-upcoming-event or rbc-past-event in the eventContent
          const upcomingEvent = eventContent?.querySelector('.rbc-upcoming-event');
          const pastEvent = eventContent?.querySelector('.rbc-past-event');

          if (upcomingEvent) {
            // Add upcoming-event class to rbc-event and remove from child
            event?.classList?.add('rbc-upcoming-events');
            event?.classList?.remove('rbc-past-events');
            upcomingEvent?.classList?.remove('rbc-upcoming-event');
          } else if (pastEvent) {
            // Add past-event class to rbc-event and remove from child
            event?.classList?.add('rbc-past-events');
            event?.classList?.remove('rbc-upcoming-events');
            pastEvent?.classList?.remove('rbc-past-event');
          }
        }
      });
    };

    updateEventClasses(); // Initial update

    // Cleanup function to remove classes if the component is unmounted or updated
    return () => {
      const events = calendarRef1?.current?.querySelectorAll('.rbc-event');
      events?.forEach(event => {
        event?.classList?.remove('rbc-upcoming-events', 'rbc-past-events');
      });
    };
  }, [weekData1]);

  useEffect(() => {
    const updateTimeContentClasses = () => {
      if (calendarRef1.current) {
        const timeContentElements = calendarRef1.current.querySelectorAll('.rbc-time-content');
        timeContentElements.forEach(element => {
          element.classList.add('custom-time-content1');
        });
      }
    };

    updateTimeContentClasses();

    // Optional: Cleanup function if needed
    return () => {
      if (calendarRef1.current) {
        const timeContentElements = calendarRef1.current.querySelectorAll('.rbc-time-content');
        timeContentElements.forEach(element => {
          element.classList.remove('custom-time-content1');
        });
      }
    };
  }, []);

  useEffect(() => {
    const { startOfWeek, endOfWeek } = getCurrentWeek();
    fetchAndFormatDataForWeek(startOfWeek, endOfWeek);
  }, [dashboard]);

  return (
    <div className={`overflow-y-scroll bg-[#F3F3F3] h-full scroll-smooth scrollbar scrollbar-thin scrollbar-thumb-[#3CA5DC] scrollbar-track-[#E5F2FF] scrollbar-thumb-rounded-full scrollbar-track-rounded-full relative p-4`}>
      <div className={`flex flex-col justify-center item-center`}>
        <div className="mt-[0px]">
          <div className="overflow-x-hidden custom-calendar" ref={calendarRef1}>
            <Calendar
              localizer={localizer}
              scrollToTime={
                momentCurrent.set({
                  h: momentCurrent.hours(),
                  m: momentCurrent.minutes() - 30
                }
                ).toDate()
              }
              events={weekData1}
              startAccessor="start"
              endAccessor="end"
              views={['week']}
              defaultView="week"
              date={selected}
              components={{
                event: EventComponent,
                timeSlotWrapper: CustomTimeSlotWrapper,
                toolbar: CustomToolbar,
                week: {
                  header: CustomDayHeader,
                },
              }}
              onSelectEvent={handleEventSelect}
              onNavigate={handleNavigate}
              formats={{
                dayFormat: (date, culture, localizer) =>
                  `${localizer.format(date, 'ddd', culture)}\n${localizer.format(date, 'DD MMM', culture)}`,

              }}
              showMultiDayTimes
              step={60}
              timeslots={1}
              min={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 7, 0)} // Start time 7 AM
              max={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 30)} // End time 11:30 PM
            />
          </div>
        </div>
      </div>
      {showModal && selectedEvent && (
        <ModalComponent show={showModal} handleClose={() => setShowModal(false)} outSideModalPress={false}>
          <div className="w-[490px] p-2">
            {/* Event HEADER */}
            <div className="flex flex-col w-full justify-start items-start">
              <h1 className="text-[#1D2939] text-2xl font-semibold font-poppins">
                Patient Info
              </h1>
            </div>

            <div className="w-full bg-[#EAECF0] h-[1px] my-3"></div>

            {/* Event CONTENT */}
            <div className="flex flex-col w-full justify-start items-start">
              <button onClick={() => {
                navigate("/patientDashboard/patientProfile", {
                  state: {
                    ...selectedEvent.resource.Patient_Info,
                    Image: profileImageUrl,
                  },
                })
              }} className="flex flex-row justify-start items-center gap-2 w-full mb-2">
                <div className="relative flex justify-center items-center w-[43px] h-[43px] rounded-full group">
                  <img
                    src={profileImageUrl}
                    className="w-[40px] h-[40px] object-contain rounded-full"
                    alt="user image"
                  />
                  <div className="absolute inset-0 rounded-full border-4 border-transparent group-hover:border-[#E5F2FF] group-hover:shadow-xl group-hover:shadow-[#E5F2FF]/50 transition-all duration-300"></div>
                </div>
                <div className="flex flex-col justify-center items-start">
                  <h1 className="text-[#3CA5DC] text-sm font-semibold font-poppins underline">
                    {selectedEvent?.resource?.Patient_Info?.Name}
                  </h1>
                </div>
              </button>

              {/* Patient Info Details */}
              <div className="flex flex-col w-full shadow-md rounded-lg">
                <div className="flex justify-between items-center w-full rounded-t-lg border border-[#F2F4F7] px-6 py-4">
                  <span className="text-[#1D2939] text-sm font-normal font-poppins">Gender & Age</span>
                  <span className="text-[#1D2939] text-sm font-normal font-poppins">{`${selectedEvent?.resource?.Patient_Info?.Gender}, ${selectedEvent?.resource?.Patient_Info?.Age}`}</span>
                </div>
                <div className="flex justify-between items-center w-full border border-[#F2F4F7] px-6 py-2">
                  <span className="text-[#1D2939] text-sm font-normal font-poppins">Contact</span>
                  <span className="text-[#1D2939] text-sm font-normal font-poppins text-right">{selectedEvent?.resource?.Patient_Info?.Phone}<br />{selectedEvent?.resource?.Patient_Info?.Email}</span>
                </div>
                <div className="flex justify-between items-center w-full rounded-b-lg border border-[#F2F4F7] px-6 py-4">
                  <span className="text-[#1D2939] text-sm font-normal font-poppins">Assigned Doctor</span>
                  <span className="text-[#1D2939] text-sm font-normal font-poppins ">Dr. {selectedEvent?.resource?.Doctor_Info?.Name}</span>
                </div>
              </div>
            </div>

          </div>
        </ModalComponent>
      )}
    </div>
  );
};

export default NextScheduleFullScreen;
