export const countries = [
  { id: 1, label: "Afghanistan", value: "AFGHANISTAN" },
  { id: 2, label: "Albania", value: "ALBANIA" },
  { id: 3, label: "Algeria", value: "ALGERIA" },
  { id: 4, label: "Andorra", value: "ANDORRA" },
  { id: 5, label: "Angola", value: "ANGOLA" },
  { id: 6, label: "Antigua and Barbuda", value: "ANTIGUA AND BARBUDA" },
  { id: 7, label: "Argentina", value: "ARGENTINA" },
  { id: 8, label: "Armenia", value: "ARMENIA" },
  { id: 9, label: "Australia", value: "AUSTRALIA" },
  { id: 10, label: "Austria", value: "AUSTRIA" },
  { id: 11, label: "Azerbaijan", value: "AZERBAIJAN" },
  { id: 12, label: "Bahamas", value: "BAHAMAS" },
  { id: 13, label: "Bahrain", value: "BAHRAIN" },
  { id: 14, label: "Bangladesh", value: "BANGLADESH" },
  { id: 15, label: "Barbados", value: "BARBADOS" },
  { id: 16, label: "Belarus", value: "BELARUS" },
  { id: 17, label: "Belgium", value: "BELGIUM" },
  { id: 18, label: "Belize", value: "BELIZE" },
  { id: 19, label: "Benin", value: "BENIN" },
  { id: 20, label: "Bhutan", value: "BHUTAN" },
  { id: 21, label: "Bolivia", value: "BOLIVIA" },
  { id: 22, label: "Bosnia and Herzegovina", value: "BOSNIA AND HERZEGOVINA" },
  { id: 23, label: "Botswana", value: "BOTSWANA" },
  { id: 24, label: "Brazil", value: "BRAZIL" },
  { id: 25, label: "Brunei", value: "BRUNEI" },
  { id: 26, label: "Bulgaria", value: "BULGARIA" },
  { id: 27, label: "Burkina Faso", value: "BURKINA FASO" },
  { id: 28, label: "Burundi", value: "BURUNDI" },
  { id: 29, label: "Cabo Verde", value: "CABO VERDE" },
  { id: 30, label: "Cambodia", value: "CAMBODIA" },
  { id: 31, label: "Cameroon", value: "CAMEROON" },
  { id: 32, label: "Canada", value: "CANADA" },
  {
    id: 33,
    label: "Central African Republic",
    value: "CENTRAL AFRICAN REPUBLIC",
  },
  { id: 34, label: "Chad", value: "CHAD" },
  { id: 35, label: "Chile", value: "CHILE" },
  { id: 36, label: "China", value: "CHINA" },
  { id: 37, label: "Colombia", value: "COLOMBIA" },
  { id: 38, label: "Comoros", value: "COMOROS" },
  {
    id: 39,
    label: "Congo, Democratic Republic of the",
    value: "CONGO, DEMOCRATIC REPUBLIC OF THE",
  },
  { id: 40, label: "Congo, Republic of the", value: "CONGO, REPUBLIC OF THE" },
  { id: 41, label: "Costa Rica", value: "COSTA RICA" },
  { id: 42, label: "Croatia", value: "CROATIA" },
  { id: 43, label: "Cuba", value: "CUBA" },
  { id: 44, label: "Cyprus", value: "CYPRUS" },
  { id: 45, label: "Czech Republic", value: "CZECH REPUBLIC" },
  { id: 46, label: "Denmark", value: "DENMARK" },
  { id: 47, label: "Djibouti", value: "DJIBOUTI" },
  { id: 48, label: "Dominica", value: "DOMINICA" },
  { id: 49, label: "Dominican Republic", value: "DOMINICAN REPUBLIC" },
  { id: 50, label: "Ecuador", value: "ECUADOR" },
  { id: 51, label: "Egypt", value: "EGYPT" },
  { id: 52, label: "El Salvador", value: "EL SALVADOR" },
  { id: 53, label: "Equatorial Guinea", value: "EQUATORIAL GUINEA" },
  { id: 54, label: "Eritrea", value: "ERITREA" },
  { id: 55, label: "Estonia", value: "ESTONIA" },
  { id: 56, label: "Eswatini", value: "ESWATINI" },
  { id: 57, label: "Ethiopia", value: "ETHIOPIA" },
  { id: 58, label: "Fiji", value: "FIJI" },
  { id: 59, label: "Finland", value: "FINLAND" },
  { id: 60, label: "France", value: "FRANCE" },
  { id: 61, label: "Gabon", value: "GABON" },
  { id: 62, label: "Gambia", value: "GAMBIA" },
  { id: 63, label: "Georgia", value: "GEORGIA" },
  { id: 64, label: "Germany", value: "GERMANY" },
  { id: 65, label: "Ghana", value: "GHANA" },
  { id: 66, label: "Greece", value: "GREECE" },
  { id: 67, label: "Grenada", value: "GRENADA" },
  { id: 68, label: "Guatemala", value: "GUATEMALA" },
  { id: 69, label: "Guinea", value: "GUINEA" },
  { id: 70, label: "Guinea-Bissau", value: "GUINEA-BISSAU" },
  { id: 71, label: "Guyana", value: "GUYANA" },
  { id: 72, label: "Haiti", value: "HAITI" },
  { id: 73, label: "Honduras", value: "HONDURAS" },
  { id: 74, label: "Hungary", value: "HUNGARY" },
  { id: 75, label: "Iceland", value: "ICELAND" },
  { id: 76, label: "India", value: "INDIA" },
  { id: 77, label: "Indonesia", value: "INDONESIA" },
  { id: 78, label: "Iran", value: "IRAN" },
  { id: 79, label: "Iraq", value: "IRAQ" },
  { id: 80, label: "Ireland", value: "IRELAND" },
  { id: 81, label: "Israel", value: "ISRAEL" },
  { id: 82, label: "Italy", value: "ITALY" },
  { id: 83, label: "Jamaica", value: "JAMAICA" },
  { id: 84, label: "Japan", value: "JAPAN" },
  { id: 85, label: "Jordan", value: "JORDAN" },
  { id: 86, label: "Kazakhstan", value: "KAZAKHSTAN" },
  { id: 87, label: "Kenya", value: "KENYA" },
  { id: 88, label: "Kiribati", value: "KIRIBATI" },
  { id: 89, label: "Korea, North", value: "KOREA, NORTH" },
  { id: 90, label: "Korea, South", value: "KOREA, SOUTH" },
  { id: 91, label: "Kosovo", value: "KOSOVO" },
  { id: 92, label: "Kuwait", value: "KUWAIT" },
  { id: 93, label: "Kyrgyzstan", value: "KYRGYZSTAN" },
  { id: 94, label: "Laos", value: "LAOS" },
  { id: 95, label: "Latvia", value: "LATVIA" },
  { id: 96, label: "Lebanon", value: "LEBANON" },
  { id: 97, label: "Lesotho", value: "LESOTHO" },
  { id: 98, label: "Liberia", value: "LIBERIA" },
  { id: 99, label: "Libya", value: "LIBYA" },
  { id: 100, label: "Liechtenstein", value: "LIECHTENSTEIN" },
  { id: 101, label: "Lithuania", value: "LITHUANIA" },
  { id: 102, label: "Luxembourg", value: "LUXEMBOURG" },
  { id: 103, label: "Madagascar", value: "MADAGASCAR" },
  { id: 104, label: "Malawi", value: "MALAWI" },
  { id: 105, label: "Malaysia", value: "MALAYSIA" },
  { id: 106, label: "Maldives", value: "MALDIVES" },
  { id: 107, label: "Mali", value: "MALI" },
  { id: 108, label: "Malta", value: "MALTA" },
  { id: 109, label: "Marshall Islands", value: "MARSHALL ISLANDS" },
  { id: 110, label: "Mauritania", value: "MAURITANIA" },
  { id: 111, label: "Mauritius", value: "MAURITIUS" },
  { id: 112, label: "Mexico", value: "MEXICO" },
  { id: 113, label: "Micronesia", value: "MICRONESIA" },
  { id: 114, label: "Moldova", value: "MOLDOVA" },
  { id: 115, label: "Monaco", value: "MONACO" },
  { id: 116, label: "Mongolia", value: "MONGOLIA" },
  { id: 117, label: "Montenegro", value: "MONTENEGRO" },
  { id: 118, label: "Morocco", value: "MOROCCO" },
  { id: 119, label: "Mozambique", value: "MOZAMBIQUE" },
  { id: 120, label: "Myanmar", value: "MYANMAR" },
  { id: 121, label: "Namibia", value: "NAMIBIA" },
  { id: 122, label: "Nauru", value: "NAURU" },
  { id: 123, label: "Nepal", value: "NEPAL" },
  { id: 124, label: "Netherlands", value: "NETHERLANDS" },
  { id: 125, label: "New Zealand", value: "NEW ZEALAND" },
  { id: 126, label: "Nicaragua", value: "NICARAGUA" },
  { id: 127, label: "Niger", value: "NIGER" },
  { id: 128, label: "Nigeria", value: "NIGERIA" },
  { id: 129, label: "North Macedonia", value: "NORTH MACEDONIA" },
  { id: 130, label: "Norway", value: "NORWAY" },
  { id: 131, label: "Oman", value: "OMAN" },
  { id: 132, label: "Pakistan", value: "PAKISTAN" },
  { id: 133, label: "Palau", value: "PALAU" },
  { id: 134, label: "Palestine", value: "PALESTINE" },
  { id: 135, label: "Panama", value: "PANAMA" },
  { id: 136, label: "Papua New Guinea", value: "PAPUA NEW GUINEA" },
  { id: 137, label: "Paraguay", value: "PARAGUAY" },
  { id: 138, label: "Peru", value: "PERU" },
  { id: 139, label: "Philippines", value: "PHILIPPINES" },
  { id: 140, label: "Poland", value: "POLAND" },
  { id: 141, label: "Portugal", value: "PORTUGAL" },
  { id: 142, label: "Qatar", value: "QATAR" },
  { id: 143, label: "Romania", value: "ROMANIA" },
  { id: 144, label: "Russia", value: "RUSSIA" },
  { id: 145, label: "Rwanda", value: "RWANDA" },
  { id: 146, label: "Saint Kitts and Nevis", value: "SAINT KITTS AND NEVIS" },
  { id: 147, label: "Saint Lucia", value: "SAINT LUCIA" },
  {
    id: 148,
    label: "Saint Vincent and the Grenadines",
    value: "SAINT VINCENT AND THE GRENADINES",
  },
  { id: 149, label: "Samoa", value: "SAMOA" },
  { id: 150, label: "San Marino", value: "SAN MARINO" },
  { id: 151, label: "Sao Tome and Principe", value: "SAO TOME AND PRINCIPE" },
  { id: 152, label: "Saudi Arabia", value: "SAUDI ARABIA" },
  { id: 153, label: "Senegal", value: "SENEGAL" },
  { id: 154, label: "Serbia", value: "SERBIA" },
  { id: 155, label: "Seychelles", value: "SEYCHELLES" },
  { id: 156, label: "Sierra Leone", value: "SIERRA LEONE" },
  { id: 157, label: "Singapore", value: "SINGAPORE" },
  { id: 158, label: "Slovakia", value: "SLOVAKIA" },
  { id: 159, label: "Slovenia", value: "SLOVENIA" },
  { id: 160, label: "Solomon Islands", value: "SOLOMON ISLANDS" },
  { id: 161, label: "Somalia", value: "SOMALIA" },
  { id: 162, label: "South Africa", value: "SOUTH AFRICA" },
  { id: 163, label: "South Sudan", value: "SOUTH SUDAN" },
  { id: 164, label: "Spain", value: "SPAIN" },
  { id: 165, label: "Sri Lanka", value: "SRI LANKA" },
  { id: 166, label: "Sudan", value: "SUDAN" },
  { id: 167, label: "Suriname", value: "SURINAME" },
  { id: 168, label: "Sweden", value: "SWEDEN" },
  { id: 169, label: "Switzerland", value: "SWITZERLAND" },
  { id: 170, label: "Syria", value: "SYRIA" },
  { id: 171, label: "Taiwan", value: "TAIWAN" },
  { id: 172, label: "Tajikistan", value: "TAJIKISTAN" },
  { id: 173, label: "Tanzania", value: "TANZANIA" },
  { id: 174, label: "Thailand", value: "THAILAND" },
  { id: 175, label: "Timor-Leste", value: "TIMOR-LESTE" },
  { id: 176, label: "Togo", value: "TOGO" },
  { id: 177, label: "Tonga", value: "TONGA" },
  { id: 178, label: "Trinidad and Tobago", value: "TRINIDAD AND TOBAGO" },
  { id: 179, label: "Tunisia", value: "TUNISIA" },
  { id: 180, label: "Turkey", value: "TURKEY" },
  { id: 181, label: "Turkmenistan", value: "TURKMENISTAN" },
  { id: 182, label: "Tuvalu", value: "TUVALU" },
  { id: 183, label: "Uganda", value: "UGANDA" },
  { id: 184, label: "Ukraine", value: "UKRAINE" },
  { id: 185, label: "United Arab Emirates", value: "UNITED ARAB EMIRATES" },
  { id: 186, label: "United Kingdom", value: "UNITED KINGDOM" },
  { id: 187, label: "United States", value: "UNITED STATES" },
  { id: 188, label: "Uruguay", value: "URUGUAY" },
  { id: 189, label: "Uzbekistan", value: "UZBEKISTAN" },
  { id: 190, label: "Vanuatu", value: "VANUATU" },
  { id: 191, label: "Vatican City", value: "VATICAN CITY" },
  { id: 192, label: "Venezuela", value: "VENEZUELA" },
  { id: 193, label: "Vietnam", value: "VIETNAM" },
  { id: 194, label: "Yemen", value: "YEMEN" },
  { id: 195, label: "Zambia", value: "ZAMBIA" },
  { id: 196, label: "Zimbabwe", value: "ZIMBABWE" },
];
