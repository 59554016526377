import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import ModalComponent from "../components/Modal";
import moment from "moment";
import cross_icon from "../assets/Images/MasterDataManagement/cross_icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox, TimePicker, Switch } from "antd";
import toast from "react-hot-toast";
import OverrideAvailability from "../components/OverrideAvailability";
import add_icon from "../assets/Images/MasterDataManagement/add_icon.svg";
import Breadcrumb from "../components/Breadcrumb";
import { updateDoctor } from "../services/operations/masterDataAPI";
import { useDispatch, useSelector } from "react-redux";

function MaintainAvailability() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();
  const doctor = location.state || {};
  const [errors, setErrors] = useState({});
  const weekdays = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
  const [timeIntervals, setTimeIntervals] = useState(
    weekdays.reduce((acc, day) => {
      acc[day] = {
        available: true,
        intervals: [{ from: null, to: null }],
      };
      return acc;
    }, {})
  );
  const [applyToAll, setApplyToAll] = useState(false);
  const [globalFromTime, setGlobalFromTime] = useState(null);
  const [globalToTime, setGlobalToTime] = useState(null);
  const [totalWorkingHours, setTotalWorkingHours] = useState(0);
  const [timeErrors, setTimeErrors] = useState({});
  const [globalTimeError, setGlobalTimeError] = useState(false);

  const validateTimeInterval = (fromTime, toTime) => {
    if (fromTime && toTime) {
      const from = moment(fromTime, "h:mm a");
      const to = moment(toTime, "h:mm a");
      return to.isAfter(from);
    }
    return true;
  };

  const isTimeEqual = (time1, time2) => {
    if (!time1 || !time2) return false;
    return moment(time1).format("HH:mm") === moment(time2).format("HH:mm");
  };

  // Toggle Button
  const handleAvailabilityChange = (day, checked) => {
    const newIntervals = { ...timeIntervals };
    newIntervals[day].available = checked;

    if (!checked) {
      newIntervals[day].intervals = [];
    } else {
      if (applyToAll && globalFromTime && globalToTime) {
        newIntervals[day].intervals = [
          { from: globalFromTime, to: globalToTime },
        ];
      } else if (newIntervals[day].intervals.length === 0) {
        newIntervals[day].intervals = [{ from: null, to: null }];
      }
    }

    // Remove the corresponding errors for the day
    const newTimeErrors = { ...timeErrors };
    Object.keys(newTimeErrors).forEach((key) => {
      if (key.startsWith(`${day}-`)) {
        delete newTimeErrors[key];
      }
    });
    setTimeErrors(newTimeErrors);

    setTimeIntervals(newIntervals);
  };

  const handleTimeChange = (day, index, field, time) => {
    const newIntervals = { ...timeIntervals };
    newIntervals[day].intervals[index][field] = time;

    let isValid = true;
    if (field === "from") {
      isValid = validateTimeInterval(
        time,
        newIntervals[day].intervals[index].to
      );
    } else {
      isValid = validateTimeInterval(
        newIntervals[day].intervals[index].from,
        time
      );
    }

    setTimeIntervals(newIntervals);
    setApplyToAll(false);

    const newTimeErrors = { ...timeErrors };
    if (!isValid) {
      newTimeErrors[`${day}-${index}`] = true;
      toast.error("Invalid time interval");
    } else {
      delete newTimeErrors[`${day}-${index}`];
    }
    setTimeErrors(newTimeErrors);

    // Check if the changed time is different from the global time
    if (
      applyToAll &&
      ((field === "from" && !isTimeEqual(time, globalFromTime)) ||
        (field === "to" && !isTimeEqual(time, globalToTime)))
    ) {
      setApplyToAll(false);
    }
  };

  const addTimeSlot = (day) => {
    const newIntervals = { ...timeIntervals };
    newIntervals[day].intervals.push({ from: null, to: null });
    setTimeIntervals(newIntervals);
    setApplyToAll(false);
  };

  const removeTimeSlot = (day, index) => {
    const newIntervals = { ...timeIntervals };
    newIntervals[day].intervals.splice(index, 1);
    setTimeIntervals(newIntervals);

    // Remove the corresponding error
    const newTimeErrors = { ...timeErrors };
    delete newTimeErrors[`${day}-${index}`];
    setTimeErrors(newTimeErrors);
  };

  const handleApplyToAllChange = (checked) => {
    if (!globalFromTime || !globalToTime) {
      setGlobalTimeError(true);
      toast.error("Please select global time first");
      return;
    }
    if (globalTimeError) {
      toast.error("Time interval is invalid.");
      return;
    }
    setApplyToAll(checked);
    if (checked) {
      applyGlobalTime(globalFromTime, globalToTime);
    }
  };

  const applyGlobalTime = (fromTime, toTime) => {
    const newIntervals = { ...timeIntervals };
    weekdays.forEach((day) => {
      newIntervals[day].available = true; // Ensure the day is marked as available
      newIntervals[day].intervals = [{ from: fromTime, to: toTime }];
    });
    setTimeIntervals(newIntervals);
    setTimeErrors({});
  };

  const handleGlobalTimeChange = (field, time) => {
    // Set the time first
    if (field === "from") {
      setGlobalFromTime(time);
      // Validate the time interval
      const isValid = validateTimeInterval(time, globalToTime);
      // Set the error state
      setGlobalTimeError(!isValid);
      // Apply the global time if valid and applyToAll is true
      if (isValid && applyToAll) {
        applyGlobalTime(time, globalToTime);
      }
    } else {
      setGlobalToTime(time);
      // Validate the time interval
      const isValid = validateTimeInterval(globalFromTime, time);
      // Set the error state
      setGlobalTimeError(!isValid);
      // Apply the global time if valid and applyToAll is true
      if (isValid && applyToAll) {
        applyGlobalTime(globalFromTime, time);
      }
    }
  };

  const calculateTotalWorkingHours = (timeIntervals) => {
    const totalHours = Object.keys(timeIntervals).reduce((acc, day) => {
      if (timeIntervals[day].available) {
        const dailyHours = timeIntervals[day].intervals.reduce(
          (dayAcc, interval) => {
            if (interval.from && interval.to) {
              const fromTime = moment(interval.from, "h:mm a");
              const toTime = moment(interval.to, "h:mm a");
              const duration = moment.duration(toTime.diff(fromTime));
              const hours = duration.asHours();
              return dayAcc + hours;
            }
            return dayAcc;
          },
          0
        );
        return acc + dailyHours;
      }
      return acc;
    }, 0);
    return totalHours;
  };

  // Add this new function to validate time intervals
  const validateTimeIntervals = () => {
    for (const day in timeIntervals) {
      if (timeIntervals[day].available) {
        if (timeIntervals[day].intervals.length === 0) {
          return `${day} is marked as available but has no time intervals set.`;
        }
        for (const interval of timeIntervals[day].intervals) {
          if (!interval.from || !interval.to) {
            return `${day} has an incomplete time interval.`;
          }
          if (!validateTimeInterval(interval.from, interval.to)) {
            return `${day} has an invalid time interval.`;
          }
        }
      }
    }

    if (Object.values(timeErrors).some((error) => error)) {
      return "There are time-related errors. Please correct them before submitting.";
    }

    return null; // No errors found
  };

  const submitHandler = async () => {
    // Validate time intervals
    const timeValidationError = validateTimeIntervals();
    if (timeValidationError) {
      toast.error(timeValidationError);
      return;
    }

    const availability = Object.keys(timeIntervals).reduce((acc, day) => {
      if (timeIntervals[day].available) {
        acc[day.toUpperCase()] = timeIntervals[day].intervals.map(
          (interval) => {
            const fromTime = moment(interval.from, "h:mm a").isValid()
              ? moment(interval.from, "h:mm a").format("HH:mm:ss")
              : null;
            const untilTime = moment(interval.to, "h:mm a").isValid()
              ? moment(interval.to, "h:mm a").format("HH:mm:ss")
              : null;

            return {
              Available_From: fromTime,
              Available_Until: untilTime,
            };
          }
        );
      } else {
        acc[day.toUpperCase()] = [];
      }
      return acc;
    }, {});

    const data = {
      TimeZone: moment.tz.guess(),
      Availability: availability,
    };

    dispatch(updateDoctor(token, doctor.DID, data, navigate));
  };

  useEffect(() => {
    if (doctor?.DID) {
      const newTimeIntervals = weekdays.reduce((acc, day) => {
        const dayAvailability = doctor?.AvailabilityTimes?.[day];
        acc[day] = {
          available: !!dayAvailability && dayAvailability.length > 0,
          intervals: dayAvailability?.map((interval) => {
            return {
              from: interval.Available_From,
              to: interval.Available_Until,
            };
          }) || [{ from: null, to: null }],
        };
        return acc;
      }, {});
      setTimeIntervals(newTimeIntervals);
    }
  }, [doctor?.DID]);

  useEffect(() => {
    const totalHours = calculateTotalWorkingHours(timeIntervals);
    setTotalWorkingHours(totalHours);
  }, [timeIntervals]);

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        Maintain Availability
      </div>
      <Breadcrumb />

      <div className="flex gap-8 p-8">
        <div className="w-1/2">
          <div className="bg-white rounded-lg shadow-md p-6 border border-sky-500">
            <div className="text-zinc-900 text-sm font-semibold font-poppins leading-none">
              Current Working hours
            </div>
            {/* Global Time */}
            <div className="flex items-center space-x-4">
              <Checkbox
                checked={applyToAll}
                onChange={(e) => handleApplyToAllChange(e.target.checked)}
                className="mr-4"
              >
                <div className=" text-black text-sm font-normal font-poppins leading-tight">
                  Apply to all
                </div>
              </Checkbox>
              <p className="text-black text-xs font-normal font-poppins leading-none">
                From
              </p>
              <TimePicker
                use12Hours
                format="h:mm a"
                value={globalFromTime ? moment(globalFromTime, "h:mm a") : null}
                onChange={(time, timeString) =>
                  handleGlobalTimeChange("from", timeString)
                }
                onOk={(time) =>
                  handleGlobalTimeChange("from", time.format("h:mm a"))
                }
                size="large"
                minuteStep={30}
                changeOnScroll={false}
                status={globalTimeError ? "error" : ""}
              />
              <p className="text-black text-xs font-normal font-poppins leading-none">
                To
              </p>
              <TimePicker
                use12Hours
                format="h:mm a"
                value={globalToTime ? moment(globalToTime, "h:mm a") : null}
                onChange={(time, timeString) =>
                  handleGlobalTimeChange("to", timeString)
                }
                onOk={(time) =>
                  handleGlobalTimeChange("to", time.format("h:mm a"))
                }
                size="large"
                minuteStep={30}
                changeOnScroll={false}
                status={globalTimeError ? "error" : ""}
              />
            </div>

            <div className="h-px bg-gray-200 my-4"></div>
            {weekdays.map((day) => (
              <div key={day} className="flex items-baseline mb-4">
                <div className="flex items-center mb-2 w-28">
                  <Switch
                    checked={timeIntervals[day].available}
                    onChange={(checked) =>
                      handleAvailabilityChange(day, checked)
                    }
                    className="mr-2"
                  />
                  <div className="text-black text-sm font-normal font-poppins">
                    {day}
                  </div>
                </div>
                <div className="flex flex-col">
                  {timeIntervals[day].available ? (
                    timeIntervals[day].intervals.map((interval, index) => (
                      <div
                        key={index}
                        className="flex items-center space-x-4 mb-2 ml-8"
                      >
                        <p className="w-8 text-black text-xs font-normal font-poppins leading-none">
                          {index === 0 ? "From" : ""}
                        </p>
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          value={
                            interval.from
                              ? moment(interval.from, "h:mm a")
                              : null
                          }
                          onChange={(time, timeString) =>
                            handleTimeChange(day, index, "from", timeString)
                          }
                          onOk={(time) =>
                            handleTimeChange(
                              day,
                              index,
                              "from",
                              time.format("h:mm a")
                            )
                          }
                          size="large"
                          minuteStep={30}
                          changeOnScroll={false}
                          status={timeErrors[`${day}-${index}`] ? "error" : ""}
                        />

                        <p className="text-black text-xs font-normal font-poppins leading-none">
                          To
                        </p>
                        <TimePicker
                          use12Hours
                          format="h:mm a"
                          value={
                            interval.to ? moment(interval.to, "h:mm a") : null
                          }
                          onChange={(time, timeString) =>
                            handleTimeChange(day, index, "to", timeString)
                          }
                          onOk={(time) =>
                            handleTimeChange(
                              day,
                              index,
                              "to",
                              time.format("h:mm a")
                            )
                          }
                          size="large"
                          minuteStep={30}
                          changeOnScroll={false}
                          status={timeErrors[`${day}-${index}`] ? "error" : ""}
                        />
                        {timeIntervals[day].intervals.length > 1 && (
                          <button onClick={() => removeTimeSlot(day, index)}>
                            <img src={cross_icon} alt="remove" />
                          </button>
                        )}
                        {index === timeIntervals[day].intervals.length - 1 && (
                          <button onClick={() => addTimeSlot(day)}>
                            <img src={add_icon} alt="remove" />
                          </button>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="ml-20 w-80 p-4 bg-neutral-50 rounded-lg text-neutral-600 text-sm font-normal font-poppins">
                      Not available
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-row items-center justify-center mt-6 space-x-4">
            <button
              onClick={() => navigate(-1)}
              className="flex-1 px-5 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
            >
              <div className="text-sky-500 text-base font-semibold font-poppins leading-normal">
                Cancel
              </div>
            </button>
            <button
              onClick={submitHandler}
              className="flex-1 px-5 py-3 flex items-center justify-center bg-sky-500 rounded-lg border border-sky-500 active:scale-95"
            >
              <div className="text-white text-base font-semibold font-poppins leading-normal">
                Update
              </div>
            </button>
          </div>
        </div>
        <div className="w-1/2">
          <div className="mb-6 flex items-center space-x-2">
            <label className="flex-1">
              <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                Hours per Week (Auto Calculated)
              </p>
              <input
                type="text"
                name="HoursPerWeek"
                maxLength={3}
                value={totalWorkingHours + " Hrs"}
                disabled={true}
                placeholder="Auto Calculated"
                className={`w-2/3 cursor-not-allowed text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                  errors.HoursPerWeek ? "border-red-500" : ""
                }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
            <div className="flex-1">
              <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                Time Zone
              </p>
              <p
                className={`w-2/3 cursor-not-allowed text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              >
                {moment.tz.guess()}
              </p>
            </div>
          </div>
          <OverrideAvailability doc={doctor} />
        </div>
      </div>
    </div>
  );
}

export default MaintainAvailability;
