import React, { useState } from "react";
import ModalComponent from "./Modal";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { changeUserPassword } from "../services/operations/profileAPI";

function ChangePasswordModal({ showChangePassword, setShowChangePassword }) {
  const { user, loading: profileLoading } = useSelector(
    (state) => state.profile
  );
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!formData.oldPassword) {
      newErrors.oldPassword = "Old password is required";
      valid = false;
    }

    if (!formData.newPassword) {
      newErrors.newPassword = "New password is required";
      valid = false;
    }

    if (!formData.confirmPassword) {
      newErrors.confirmPassword = "Confirm password is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error("Please fill all the required fields");
      return;
    }

    if (formData.oldPassword === formData.confirmPassword) {
      setErrors({
        confirmPassword: "New password must be different from old password",
      });
      toast.error("New password must be different from old password");
      return;
    }

    if (formData.newPassword !== formData.confirmPassword) {
      setErrors({
        confirmPassword: "Passwords do not match",
      });
      toast.error("Passwords do not match");
      return;
    }

    if (formData.confirmPassword.length < 8) {
      setErrors({
        confirmPassword: "Password must be at least 8 characters long",
      });
      toast.error("Password must be at least 8 characters long");
      return;
    }

    try {
      const body = {
        DCID: user.DCID,
        OldPassword: formData.oldPassword,
        NewPassword: formData.confirmPassword,
      };

      const response = await changeUserPassword(body);
      if (response) {
        toast.success(response?.message);
        setShowChangePassword(false);
      }
    } catch (error) {
      console.log("CHANGE_PASSWORD API ERROR............", error);
    }
  };

  return (
    <ModalComponent
      show={showChangePassword}
      handleClose={() => setShowChangePassword(false)}
      outSideModalPress={false}
    >
      <div className="text-zinc-900 text-lg font-semibold leading-snug font-poppins">
        Change Login Password
      </div>

      <div className="grid grid-cols-2 gap-4 mt-6 w-[30vw]">
        {/* Old Password Field */}
        <div className="space-y-2">
          <label className="text-zinc-900 text-sm font-medium font-poppins">
            Old Password
          </label>
          <div className="relative">
            <input
              name="oldPassword"
              type={showOldPassword ? "text" : "password"}
              value={formData.oldPassword}
              onChange={handleOnChange}
              className={`w-full ${
                errors.oldPassword ? "border-red-500" : ""
              } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-3 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              placeholder="Enter Password"
            />
            <button
              type="button"
              onClick={() => setShowOldPassword(!showOldPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
            >
              {showOldPassword ? (
                <AiOutlineEye className="w-5 h-5" />
              ) : (
                <AiOutlineEyeInvisible className="w-5 h-5" />
              )}
            </button>
          </div>
        </div>

        <div></div>

        {/* New Password Field */}
        <div className="space-y-2">
          <label className="text-zinc-900 text-sm font-medium font-poppins">
            New Password
          </label>
          <div className="relative">
            <input
              name="newPassword"
              type={showNewPassword ? "text" : "password"}
              value={formData.newPassword}
              onChange={handleOnChange}
              className={`w-full ${
                errors.newPassword ? "border-red-500" : ""
              } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-3 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              placeholder="Enter Password"
            />
            <button
              type="button"
              onClick={() => setShowNewPassword(!showNewPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
            >
              {showNewPassword ? (
                <AiOutlineEye className="w-5 h-5" />
              ) : (
                <AiOutlineEyeInvisible className="w-5 h-5" />
              )}
            </button>
          </div>
        </div>

        {/* Confirm New Password Field */}
        <div className="space-y-2">
          <label className="text-zinc-900 text-sm font-medium font-poppins">
            Confirm New Password
          </label>
          <div className="relative">
            <input
              name="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              value={formData.confirmPassword}
              onChange={handleOnChange}
              className={`w-full ${
                errors.confirmPassword ? "border-red-500" : ""
              } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-3 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              placeholder="Enter Password"
            />
            <button
              type="button"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700"
            >
              {showConfirmPassword ? (
                <AiOutlineEye className="w-5 h-5" />
              ) : (
                <AiOutlineEyeInvisible className="w-5 h-5" />
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-row items-center justify-center mt-6 space-x-4">
        <button
          disabled={profileLoading}
          onClick={handleSubmit}
          className="flex-1 py-2.5 flex items-center justify-center bg-sky-500 rounded-lg border border-sky-400 active:scale-95"
        >
          <div className="text-white text-base font-semibold font-poppins leading-normal">
            Update Password
          </div>
        </button>
        <button
          disabled={profileLoading}
          onClick={() => setShowChangePassword(false)}
          className="flex-1 py-2.5 flex items-center justify-center bg-white rounded-lg border border-sky-400 active:scale-95"
        >
          <div className="text-sky-500 text-base font-semibold font-poppins leading-normal">
            Cancel
          </div>
        </button>
      </div>
    </ModalComponent>
  );
}

export default ChangePasswordModal;
