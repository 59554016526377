import React from "react";
import { Carousel } from "antd";
import '../App.css';

const AntdCarousel = () => {
  return (
    <div className="w-full md:w-[45rem] ml-0 md:ml-20">
      <Carousel autoplay>
        <div className="text-left mb-5 md:mb-10">
          <h5 className="w-full md:w-[95%] lg:w-[44rem] text-white text-xl md:text-[2.5rem] leading-6 md:leading-[62px] font-poppins font-bold">
            Streamline Patient Care: Enhance Efficiency and Accuracy
          </h5>
        </div>
        <div className="text-left mb-5">
          <h5 className="w-full md:w-[95%] lg:w-[44rem] text-white text-xl md:text-[2.5rem] leading-6 md:leading-[62px] font-poppins font-bold">
            Revolutionise TeleHealth for your Patients and Clinic
          </h5>
        </div>
        <div className="text-left mb-5">
          <h5 className="w-full md:w-[95%] lg:w-[44rem] text-white text-xl md:text-[2.5rem] leading-6 md:leading-[62px] font-poppins font-bold">
            Offer Healthcare Services throughout the Country
          </h5>
        </div>
      </Carousel>
    </div>
  );
};

export default AntdCarousel;
