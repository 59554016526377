import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import { CiSearch } from "react-icons/ci";
import RewardCard from "../components/RewardCard";
import { getRewardOffers } from "../services/operations/rewardManagementAPI";
import { useDispatch, useSelector } from "react-redux";

function RewardManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { rewardOffers, loading: rewardOfferLoading } = useSelector(
    (state) => state.reward
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("all");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  useEffect(() => {
    if (token) {
      dispatch(getRewardOffers(token));
    }
  }, [token]);

  if (rewardOfferLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  const filteredRewardOffers = rewardOffers.filter((offer) => {
    if (filter === "all") return true;
    return offer.Status === filter;
  });

  const searchedRewardOffers = filteredRewardOffers.filter((offer) =>
    offer.Title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-white min-h-full p-6 scroll-smooth">
      {/* Header */}
      <div className="flex flex-row items-center justify-between">
        <div className="text-zinc-900 text-2xl font-medium font-poppins">
          Reward Management
        </div>
        <button
          onClick={() => navigate("createReward")}
          className="px-4 py-2 bg-sky-400 rounded-lg shadow justify-center items-center gap-2.5 inline-flex cursor-pointer active:scale-95"
        >
          <div className="text-white text-sm font-normal font-poppins">
            Create Reward
          </div>
        </button>
      </div>
      <Breadcrumb />

      {/* Search and Button  */}
      <div className="flex flex-row items-center justify-between mt-5">
        <label className="w-2/3 flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2">
          <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
          <input
            type="text"
            placeholder="Search for rewards"
            value={searchTerm}
            onChange={handleSearchChange}
            className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
          />
        </label>

        <div className="flex flex-row items-center">
          <button
            onClick={() => handleFilterChange("all")}
            className={`px-4 py-2.5 ${
              filter === "all"
                ? "bg-sky-100 text-sky-700 border-blue-200"
                : "bg-white text-neutral-600 border-neutral-300"
            }  rounded-tl-lg rounded-bl-lg border-l border-t border-b text-sm font-medium font-poppins leading-tight`}
          >
            All
          </button>
          <button
            onClick={() => handleFilterChange("ACTIVE")}
            className={`px-4 py-2.5 ${
              filter === "ACTIVE"
                ? "bg-sky-100 text-sky-700 border-blue-200"
                : "bg-white text-neutral-600 border-neutral-300"
            }  border text-sm font-medium font-poppins leading-tight`}
          >
            Active
          </button>
          <button
            onClick={() => handleFilterChange("DEACTIVE")}
            className={`px-4 py-2.5 ${
              filter === "DEACTIVE"
                ? "bg-sky-100 text-sky-700 border-blue-200"
                : "bg-white text-neutral-600 border-neutral-300"
            }  border text-sm font-medium font-poppins leading-tight`}
          >
            Inactive
          </button>
          <button
            onClick={() => handleFilterChange("DRAFT")}
            className={`px-4 py-2.5 ${
              filter === "DRAFT"
                ? "bg-sky-100 text-sky-700 border-blue-200"
                : "bg-white text-neutral-600 border-neutral-300"
            }  rounded-tr-lg rounded-br-lg border-r border-t border-b text-sm font-medium font-poppins leading-tight`}
          >
            Draft
          </button>
        </div>
      </div>

      <div className="grid grid-cols-5 gap-4 mt-5">
        {searchedRewardOffers.map((offer) => (
          <RewardCard key={offer.RewardID} offer={offer} />
        ))}
      </div>
    </div>
  );
}

export default RewardManagement;
