import React, { useState } from "react";
import RadioIcon from "./RadioIcon";
import toast from "react-hot-toast";

const NotificationSettings = () => {
  const [appointmentNotification, setAppointmentNotification] =
    useState("immediately");
  const [callbackNotification, setCallbackNotification] =
    useState("immediately");

  return (
    <div className="max-w-3xl mt-6">
      <div className="text-slate-800 text-lg font-semibold font-poppins leading-snug tracking-tight">
        Notifications Settings
      </div>
      <div className="p-6 rounded-lg shadow-md mt-6">
        <h2 className="text-gray-900 text-sm font-medium font-poppins leading-tight">
          Appointment Notifications
        </h2>
        <p className="text-zinc-500 text-sm font-normal font-poppins leading-tight mt-1">
          Trigger notification to health managers for booked appointments
        </p>

        <div className="space-y-2 my-4">
          {[
            "immediately",
            "3 hours after booking",
            "6 hours after booking",
          ].map((option) => (
            <label key={option} className="flex items-center cursor-pointer">
              <input
                type="radio"
                className="hidden"
                checked={appointmentNotification === option}
                onChange={() => setAppointmentNotification(option)}
              />
              <RadioIcon selected={appointmentNotification === option} />
              <span className="text-zinc-900 text-sm font-normal font-poppins leading-none">
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </span>
            </label>
          ))}
        </div>

        <div className="border border-b-1 border-gray-100 my-3" />

        <h2 className="text-gray-900 text-sm font-medium font-poppins leading-tight">
          Callback Request Notifications
        </h2>
        <p className="text-zinc-500 text-sm font-normal font-poppins leading-tight mt-1">
          Trigger notification to health managers for callback requests
        </p>

        <div className="space-y-2 my-4">
          {[
            "immediately",
            "3 hours after booking",
            "6 hours after booking",
          ].map((option) => (
            <label key={option} className="flex items-center cursor-pointer">
              <input
                type="radio"
                className="hidden"
                checked={callbackNotification === option}
                onChange={() => setCallbackNotification(option)}
              />
              <RadioIcon selected={callbackNotification === option} />
              <span className="text-zinc-900 text-sm font-normal font-poppins leading-none">
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </span>
            </label>
          ))}
        </div>

        <div className="border border-b-1 border-gray-100 my-3" />

        <button
          onClick={() => toast.success("Settings updated successfully")}
          className="w-64 px-4 py-2 bg-sky-400 rounded-lg text-white text-base font-semibold font-poppins leading-tight active:scale-95"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default NotificationSettings;
