import { toast } from "react-hot-toast";
import {
  setLoading,
  setRewardOffers,
  setRewardSalesCustomerList,
} from "../../slices/rewardSlice";
import { apiConnector } from "../apiConnector";
import { rewardManagementEndpoints } from "../apis";
import { getBucketURL } from "../../utils/regex";
import moment from "moment";

const {
  GET_ALL_REWARD_OFFERS,
  ADD_REWARD,
  UPDATE_REWARD,
  GET_REWARD_CUSTOMER_LIST,
  GET_REWARD_SALES_REPORT,
  MARK_AS_COMPLETED,
  UPLOAD_REWARD_IMAGE,
} = rewardManagementEndpoints;

// GET REWARD OFFERS
export function getRewardOffers(token) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_REWARD_OFFERS, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      const offersWithImages = await Promise.all(
        response?.data?.RewardOffers.map(async (offer) => {
          let Image = null;
          if (offer?.RewardImageURL) {
            Image = await getBucketURL(offer?.RewardImageURL);
          }
          return { ...offer, Image };
        })
      );

      console.log("GET_ALL_REWARD_OFFERS API RESPONSE............", response);
      dispatch(setRewardOffers(offersWithImages || []));
    } catch (error) {
      console.log("GET_ALL_REWARD_OFFERS API ERROR............", error);
    }
    // dispatch(setLoading(false));
  };
}

// ADD REWARD OFFER
export function addRewardOffer(token, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_REWARD, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Reward Offer Created Successfully");
      dispatch(getRewardOffers(token));
      navigate(-1);
    } catch (error) {
      console.log("ADD_REWARD API ERROR............", error);
      toast.error(
        error?.response?.data?.message || "Error while adding reward offer"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// UPDATE REWARD OFFER
export function updateRewardOffer(token, ID, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_REWARD + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Updated Successfully");
      dispatch(getRewardOffers(token));
      navigate && navigate(-1);
    } catch (error) {
      console.log("UPDATE_REWARD api ERROR............", error);
      toast.error("Could Not Update Reward Offer");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// GET REWARD CUSTOMER LIST
export function getRewardCustomerList(
  token,
  ID,
  year = moment().year(),
  month = moment().month() + 1
) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        `${GET_REWARD_CUSTOMER_LIST}/${ID}?Year=${year}&Month=${month}`,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (response.status !== 200) {
        throw new Error(response.data.message);
      }

      const patient = response?.data?.CustomerList.map(async (patient) => {
        const Image = await getBucketURL(patient?.ProfileImageUrl);
        patient.Image = Image;
        return patient;
      });

      const updatedUserList = await Promise.all(patient);
      dispatch(setRewardSalesCustomerList(updatedUserList));
    } catch (error) {
      dispatch(setRewardSalesCustomerList([]));
      console.log("GET_REWARD_CUSTOMER_LIST API ERROR............", error);
      toast.error("Could Not Get Reward Customer List");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

//PUT UPDATE REWARD OFFER
export function markSaleAsComplete(token, ID, body, RewardID, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        MARK_AS_COMPLETED + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(getRewardCustomerList(token, RewardID));
    } catch (error) {
      console.log("MARK_AS_COMPLETED API ERROR............", error);
      toast.error("Could Not Update Reward Sales");
    }
    dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// GET REWARD SALES REPORT
export async function getRewardSalesReport(token, ID, year) {
  let result = [];
  try {
    const response = await apiConnector(
      "GET",
      GET_REWARD_SALES_REPORT + "/" + ID + "?Year=" + year,
      null,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (!response?.status === 200) {
      throw new Error(response?.data?.message);
    }

    result = response?.data;
  } catch (error) {
    console.log("GET_REWARD_SALES_REPORT API ERROR............", error);
  }
  return result;
}

// UPLOAD REWARD IMAGE
export async function uploadRewardImage(token, formData) {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector(
      "POST",
      UPLOAD_REWARD_IMAGE,
      formData,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    result = response?.data;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("UPLOAD IMAGE API ERROR:", error);
    toast.error("Oops! Something went wrong. Please try again.");
  }
  toast.dismiss(toastId);
  return result;
}