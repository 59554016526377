import styles from "./styles.module.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import { FaList } from "react-icons/fa6";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import PatientListView from "../components/PatientListView";
import PatientCardView from "../components/PatientCardView";
import noPatientFound from "../assets/Images/PatientDashboard/noPatientFound.png";
import { deletePatientAppointment, getPatientProfile, getPatientsUpcomingTelehealthSession, updatePatientProfile } from "../services/operations/patientManagementAPI";
import UserDetails from "../components/UserDetails";
import videoCallIcon from "../assets/Images/BookAppointment/videoCallIcon.png";
import inPersonIcon from "../assets/Images/BookAppointment/inPersonIcon.png";
import SuccessCancel from "../assets/Images/PatientDashboard/SuccessCancel.png";
import ModalComponent from "../components/Modal";
import RadioButton from "../components/RadioButton/RadioButton";
import toast from "react-hot-toast";
import moment from "moment";
import breadcrumbsArrow from "../assets/Images/breadcrumbsArrow.png";
import Breadcrumb from "../components/Breadcrumb";
import deleteModalIcon from "../assets/Images/OurServices/deleteModalIcon.png";

function ManagePatient() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let patient;
  if (location.state.patient) {
    patient = location?.state?.patient || {};
  } else {
    patient = location.state || {};
  }
  const [upcomingAppointment, setUpcomingAppointment] = useState(null);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [cancellationCharge, setCancellationCharge] = useState("50");
  const [charge, setCharge] = useState("50");
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSuccessCancelModal, setShowSuccessCancelModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [showComplianceSummaryModal, setShowComplianceSummaryModal] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const { doctorDetails } = useSelector((state) => state.profile);
  const {
    loading: patientLoading,
    patientProfile,
  } = useSelector((state) => state.patient);

  const handleAppointmentClick = (appointment) => {
    setSelectedAppointment(appointment);
  };

  const handleConfirm = () => {
    if (selectedAppointment) {
      setShowCancelModal(false);
      deleteAppointment();
    }
    else {
      toast.error("Please Select an Appointment")
    }
  };

  const handleBack = () => {
    setShowCancelModal(false);
  };

  const fetchPatientProfile = () => {
    let body = {
      AHUID: patient?.AHUID,
    };
    dispatch(getPatientProfile(token, body));
  };

  const deleteAppointment = async () => {
    let body = {
      "SessionID": selectedAppointment?.SessionID,
      "AHUID": selectedAppointment?.AHUID,
      "CancellationReason": ""
    };

    try {
      await dispatch(deletePatientAppointment(token, body));
      fetchPatientProfile();
      fetchUpcomingAppointments();
      toast.success("Appointment Deleted")
    } catch (error) {
      console.error("Failed to delete patient appointment", error);
    }
    setSelectedAppointment(null);
    setShowSuccessCancelModal(true);
  }

  const fetchUpcomingAppointments = async () => {
    let id = patient?.AHUID;
    try {
      const res = await dispatch(getPatientsUpcomingTelehealthSession(token, id));
      setUpcomingAppointment(res);
    } catch (error) {
      setUpcomingAppointment([]);
      console.error("Failed to fetch Patients Upcoming Telehealth Sessions", error);
    }
  }

  const deleteHandler = async (event, id) => {
    event.preventDefault();
    try {
      let data = {
        "Status": "Deactive",
        "DeletedAt": moment().format('YYYY-MM-DD HH:mm:ss').toString(),
      };
      let ID = id;
      let navigateTo = "/patientDashboard";

      dispatch(updatePatientProfile(token, ID, data, navigate, patient, navigateTo));
    } catch (error) {
      console.error("Error Deleting the Patient:", error);
    }
    finally {
      setDeleteUserModal(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchPatientProfile(),
        fetchUpcomingAppointments(),
      ]);
    };

    fetchData();
  }, [patient]);

  if (patientLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <div className="bg-white h-screen py-2 px-2 sm:py-5 sm:px-10 scroll-smooth">
      <div className="bg-white p-6">
        {/* Header Section */}
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-col items-start gap-4">
            <h1 className="text-black text-xl font-semibold font-poppins leading-3 tracking-wide">
              Manage Patient
            </h1>

            <Breadcrumb currentState={patient} />
          </div>
        </div>

        <div className="flex flex-row justify-center items-start space-x-3 mt-10">
          <div className="w-1/2 flex flex-col justify-center items-start">
            {/* Patient Card */}
            <div className="w-60 bg-white rounded-lg shadow py-7 flex flex-col items-center justify-center">
              <div
                className={`${patient?.IsMedicalConciergeMember &&
                  " bg-gradient-to-r from-[#F6C86E] to-[#FFEE54] rounded-full p-0.5"
                  }`}
              >
                <img
                  src={patient.Image}
                  alt="PatientPic"
                  className="w-20 h-20 rounded-full"
                />
              </div>
              {patient?.IsMedicalConciergeMember === 1 && (
                <div className="w-full py-1 bg-yellow-50 flex items-center justify-center mt-4">
                  <div className="w-48 text-center text-yellow-600 text-sm font-semibold font-poppins leading-tight">
                    Conceirge Member since{" "}
                    {moment(patient?.MedicalConciergeJoiningDate).format(
                      "DD MMMM YYYY"
                    )}
                  </div>
                </div>
              )}
              <div className="text-center text-black text-base font-semibold font-poppins mt-4">
                {patient.Name || patient.FirstName + " " + patient.LastName}
              </div>
              <div className="text-center text-zinc-500 text-xs font-normal font-poppins my-2">
                No : {patient.AHUID}
              </div>

              {patient.Age !== null && patient.Age !== undefined ? (
                <div className="text-center text-zinc-900 text-xs font-normal font-poppins">
                  {patient.Age} Years Old
                </div>
              ) : (
                <div className="text-center text-zinc-900 text-xs font-normal font-poppins">
                  {new Date().getFullYear() -
                    new Date(patientProfile.DOB).getFullYear()}{" "}
                  Years Old
                </div>
              )}
            </div>
            {/* <div className="bg-white shadow rounded-lg p-8 w-[85%] flex flex-row items-center justify-start space-x-4">
              <img
                src={patient.Image}
                alt="PatientPic"
                className="w-20 h-20 rounded-full"
              />
              <div className="text-center flex-col justify-center items-center ml-4">
                <div className="text-black text-base font-semibold font-poppins text-left">
                  {patient.Name || patient.FirstName + " " + patient.LastName}
                </div>
                <div className="text-[#1c1c1c] text-xs font-medium mt-1 font-poppins text-left">
                  {patient.Gender}
                </div>
                <div className="text-[#1c1c1c] text-xs font-normal mt-1 font-poppins text-left">
                  DOB : {moment(patient.DOB).format("DD.MM.YYYY")}
                </div>
                <div className="text-zinc-500 text-xs font-normal mt-1 font-poppins text-left">
                  No : {patient.AHUID}
                </div>
                {patient.Age !== null && patient.Age !== undefined ? (
                  <>
                    <div className="text-black text-xs font-normal font-poppins text-left mt-1">
                      {patient.Age} Years Old
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-black text-xs font-normal font-poppins text-left mt-1">
                      {new Date().getFullYear() - new Date(patient.DOB).getFullYear()}{" "}
                      Years Old
                    </div>
                  </>
                )}
              </div>
            </div> */}

            {/* User Detail */}
            <div className={`flex bg-white shadow rounded-lg p-4 mt-6`}>
              <UserDetails patient={patient} patientProfile={patientProfile} navigateTo={"/patientDashboard/managePatient"} />
            </div>

            {/* Delete user */}
            <button onClick={() => { setDeleteUserModal(true) }} className="flex self-start flex-row justify-start items-center mt-10 gap-2">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 text-[#D92D20]"
              >
                <path
                  d="M19.3008 5.5L18.6811 15.5251C18.5227 18.0864 18.4436 19.3671 17.8016 20.2879C17.4841 20.7431 17.0755 21.1273 16.6015 21.416C15.6429 22 14.3598 22 11.7935 22C9.2239 22 7.93908 22 6.97983 21.4149C6.50558 21.1257 6.09678 20.7408 5.77946 20.2848C5.13766 19.3626 5.06023 18.0801 4.90539 15.5152L4.30078 5.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M2.80078 5.5H20.8008M15.8565 5.5L15.1739 4.09173C14.7204 3.15626 14.4936 2.68852 14.1025 2.39681C14.0158 2.3321 13.9239 2.27454 13.8278 2.2247C13.3947 2 12.8749 2 11.8353 2C10.7696 2 10.2368 2 9.79646 2.23412C9.69888 2.28601 9.60576 2.3459 9.51807 2.41317C9.12242 2.7167 8.90141 3.20155 8.45939 4.17126L7.8537 5.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M9.30078 16.5V10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M14.3008 16.5V10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  stroke-linecap="round"
                />
              </svg>
              <h1 className="text-[#D92D20] text-sm font-semibold font-poppins">
                Delete Patient Data
              </h1>
            </button>

            {/* Compliance Summary MODAL */}
            <ModalComponent
              show={showComplianceSummaryModal}
              handleClose={() => { setShowComplianceSummaryModal(false); setDeleteUserModal(true); }}
              outSideModalPress={false}
            >
              <div className="w-[875px] relative">
                <div className="flex flex-col items-center justify-between">
                  <div className="text-[#263238] text-base font-semibold font-poppins text-center mt-5">
                    GDPR Compliance Rules & Regulations
                  </div>

                  <div className="h-[0.34px] w-full bg-[#3CA5DC] my-6"></div>

                  <div className="overflow-y-scroll max-h-[55vh] scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                    {data.map((item, index) => (
                      <div key={index} style={{ marginBottom: '20px' }}>
                        {/* Add index + 1 to show numbers */}
                        <h2 className="text-[11px] font-normal font-poppins text-black leading-[16.5px]">
                          {/* Display number before title */}
                          {index + 1}. {item.title}
                        </h2>
                        <ul className="list-disc list-inside pl-4">
                          {/* Display bullet points for subitems */}
                          {item.subItems.map((subItem, subIndex) => (
                            <li style={{ listStyle: 'disc' }} key={subIndex} className="text-[11px] font-normal font-poppins list-disc text-black leading-[16.5px]">
                              {subItem}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>

                  <div className="flex flex-row items-center justify-center mt-12 space-x-4 w-full">
                    <button
                      onClick={() => { setShowComplianceSummaryModal(false); setDeleteUserModal(true); }}
                      className="w-44 px-2 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
                    >
                      <div className="text-[#007AAE] text-sm font-semibold font-poppins leading-normal">
                        Go Back
                      </div>
                    </button>
                    <button
                      onClick={(e) => { setShowComplianceSummaryModal(false); setDeleteUserModal(true); window.open("https://gdpr-info.eu/", "_blank"); }}
                      className="w-44 px-2 py-3 flex items-center justify-center bg-[#3CA5DC] rounded-lg border border-[#3CA5DC] active:scale-95"
                    >
                      <div className="text-white text-sm font-semibold font-poppins leading-normal">
                        See full Policy
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </ModalComponent>

            {/* Delete user MODAL */}
            <ModalComponent
              show={deleteUserModal}
              handleClose={() => { setDeleteUserModal(false); }}
              outSideModalPress={false}
            >
              <div className="w-[691px] relative">
                <div className="flex flex-col items-center justify-between">
                  <img
                    src={deleteModalIcon}
                    alt="Delete Icon"
                    className="w-14 h-14 object-cover"
                  />
                  <div className="text-black text-[16px] font-semibold font-poppins text-center mt-6">
                    Are You Sure?
                  </div>
                  <button onClick={() => { setShowComplianceSummaryModal(true); setDeleteUserModal(false); }} className="absolute top-0 right-0 text-[#007AAE] text-xs font-normal font-poppins underline leading-5">
                    Compliance Summary
                  </button>
                  <h2 className="text-[#263238] text-xs font-normal font-poppins mt-8">
                    You are about to delete patient data. This action is irreversible and will be completed in full compliance with GDPR regulations. Please ensure this request is necessary, as all related data will be permanently removed within the legal time frame. Although some data might be retained for legal purposes. Before proceeding, please go through the following:
                  </h2>
                  <ul className="mt-6 ml-5 flex flex-col gap-1">
                    <li className="text-[#263238] text-xs font-normal font-poppins leading-[18px] list-disc"><span className="text-[#263238] text-xs font-poppins font-semibold">No New Records: </span>Deleting these records will prevent the creation of any new records for this patient with the clinic.</li>
                    <li className="text-[#263238] text-xs font-normal font-poppins leading-[18px] list-disc"><span className="text-[#263238] text-xs font-poppins font-semibold">Retention of Previous Records: </span>All existing records, including diagnoses, treatments, prescriptions, financials, billings, and other relevant medical information, will be retained for up to 10 years. This is in accordance with GDPR guidelines.</li>
                    <li className="text-[#263238] text-xs font-normal font-poppins leading-[18px] list-disc"><span className="text-[#263238] text-xs font-poppins font-semibold">Appointment Cancellations: </span>Any upcoming appointments for this patient will be canceled.</li>
                    <li className="text-[#263238] text-xs font-normal font-poppins leading-[18px] list-disc"><span className="text-[#263238] text-xs font-poppins font-semibold">Legal and Regulatory Retention: </span>Certain information may be retained for legal, regulatory, or safety purposes, where applicable.</li>
                  </ul>
                  <div className="flex flex-row items-center justify-center mt-12 space-x-4 w-full">
                    <button
                      onClick={() => { setDeleteUserModal(false); }}
                      className="w-44 px-2 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
                    >
                      <div className="text-[#007AAE] text-sm font-semibold font-poppins leading-normal">
                        Go Back
                      </div>
                    </button>
                    <button
                      onClick={(e) => { deleteHandler(e, patientProfile?.AHUID) }}
                      className="w-44 px-2 py-3 flex items-center justify-center bg-[#DF535F] rounded-lg border border-[#B42318] active:scale-95"
                    >
                      <div className="text-white text-sm font-semibold font-poppins leading-normal">
                        Delete Patient Data
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </ModalComponent>
          </div>

          {/* Patient Appointments */}
          <div className="w-1/2 flex flex-col justify-center items-start">
            <h1 className="text-black text-base text-lg font-normal font-poppins leading-3 tracking-wide mb-5">
              Patient's Appointment(s)
            </h1>

            {!upcomingAppointment || upcomingAppointment?.length === 0 && (
              <div className="flex flex-col justify-center items-center w-full">
                <h1 className="text-[#ACACAC] text-sm font-semibold font-poppins mb-5">
                  No Data
                </h1>
              </div>
            )}

            {upcomingAppointment?.map((appointment) => (
              <div
                key={appointment.SessionID}
                className={`flex flex-col justify-center items-center shadow rounded-lg w-full mb-5 px-10 py-3 cursor-pointer ${selectedAppointment?.SessionID === appointment.SessionID
                  ? "border-2 border-[#3CA5DC] bg-[#E5F2FF]"
                  : "bg-white"
                  }`}
                onClick={() => handleAppointmentClick(appointment)}
              >
                <div className="grid grid-cols-3 w-full gap-2">
                  <div className="flex flex-col justify-center items-start gap-1">
                    <h1 className="text-[#8A8A8A] text-base text-xs font-normal font-poppins">
                      Scheduled Time
                    </h1>
                    <h1 className="text-black text-base text-sm font-semibold font-poppins">
                      {appointment.Start_time}
                    </h1>
                  </div>

                  <div className="flex flex-col justify-center items-start gap-1">
                    <h1 className="text-[#8A8A8A] text-base text-xs font-normal font-poppins">
                      Scheduled Date
                    </h1>
                    <h1 className="text-black text-base text-sm font-semibold font-poppins">
                      {moment(appointment.Date).format("DD MMM YYYY")}
                    </h1>
                  </div>

                  <div className="flex flex-row justify-center items-start">
                    {appointment.AppointmentType === "TeleHealth" ? (
                      <div className="flex flex-col justify-center items-center w-[35px] h-[35px] rounded-full bg-[#3CA5DC] p-2">
                        <img
                          src={videoCallIcon}
                          className="w-full h-full object-contain text-white"
                          alt="Video Call Icon"
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center w-[35px] h-[35px] rounded-full bg-[#F7A025] p-2">
                        <img
                          src={inPersonIcon}
                          className="w-full h-full object-contain text-white"
                          alt="In person Icon"
                        />
                      </div>
                    )}
                    <div className="flex flex-col justify-center items-start gap-1 ml-3">
                      <h1 className="text-[#8A8A8A] text-base text-xs font-normal font-poppins">
                        Type Appointment
                      </h1>
                      <h1 className="text-black text-base text-sm font-semibold font-poppins">
                        {appointment.AppointmentType === "TeleHealth" ? "Video Call" : appointment.AppointmentType}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* Btns */}
            {selectedAppointment && (
              <div className="flex flex-row justify-center items-center w-full gap-4 mt-4">
                <button
                  style={{ backgroundColor: "#3CA5DC" }}
                  className="mt-4 w-1/2 bg-sky-400 rounded-lg text-center text-white text-sm font-semibold font-poppins p-4 py-3 hover:bg-sky-400 transition duration-200 ease-in active:bg-[#3CA5DC] focus:outline-none focus:ring focus:ring-[#3CA5DC]"
                  onClick={() => {
                    if (selectedAppointment)
                      setShowCancelModal(true);
                    else toast.error("Please Select an Appointment")
                  }}
                >
                  Cancel Appointment
                </button>
                <button
                  style={{ backgroundColor: "#E5F2FF" }}
                  className="mt-4 w-1/2 bg-sky-400 rounded-lg text-center text-[#3CA5DC] text-sm font-semibold font-poppins p-4 py-3 hover:bg-sky-400 transition duration-200 ease-in active:bg-[#3CA5DC] focus:outline-none focus:ring focus:ring-[#3CA5DC]"
                  onClick={() => {
                    if (selectedAppointment)
                      navigate("/patientDashboard/managePatient/reschedulePatient", { state: { selectedAppointment: selectedAppointment, patient: patient } });
                    else
                      toast.error("Please Select an Appointment")
                  }}
                >
                  Reschedule Appointment
                </button>

                {/* Cancel Appointment Modal */}
                <ModalComponent
                  show={showCancelModal}
                  handleClose={() => setShowCancelModal(false)}
                  outSideModalPress={false}
                >
                  <div className="bg-[#FFFFFF] p-4">
                    {/* Widget HEADER */}
                    <div className="flex flex-row justify-center items-center">
                      {/* TITLE */}
                      <h1 className="text-black text-lg font-semibold font-poppins rounded-lg">
                        Are you Sure?
                      </h1>
                    </div>

                    <div className="w-full bg-[#F3F5F6] h-[1px] my-5"></div>

                    <div className="flex flex-col justify-center items-center w-full">
                      <h1 className="w-3/4 text-black text-md font-semibold font-poppins rounded-lg text-center">
                        {`This will Cancel your Appointment on ${moment(selectedAppointment?.Date).format("DD MMM YYYY")}, ${selectedAppointment?.Start_time} CET`}
                      </h1>

                      <div className="flex flex-col justify-center items-start w-full my-10">
                        <div className="flex flex-row justify-between items-center w-full mb-5">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="cancellationCharge"
                              value="50"
                              checked={cancellationCharge === charge}
                              onChange={() => setCancellationCharge(charge)}
                              className="mr-2"
                            />
                            <span className="font-poppins">Cancellation Charge</span>
                          </label>
                          <span className="text-[#3CA5DC] font-poppins">
                            {charge} CHF
                          </span>
                        </div>
                        <div className="flex flex-row justify-between items-center w-full">
                          <label className="flex items-center">
                            <input
                              type="radio"
                              name="cancellationCharge"
                              value="0"
                              checked={cancellationCharge === "0"}
                              onChange={() => setCancellationCharge("0")}
                              className="mr-2"
                            />
                            <span className="font-poppins">Cancellation Charge</span>
                          </label>
                          <span className="text-[#3CA5DC] font-poppins">Free: 0 CHF</span>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-row justify-between items-center w-full gap-2">
                      <button
                        className="bg-[#EC685D] text-white font-normal text-xs font-poppins py-4 px-2 rounded-lg w-1/2"
                        onClick={handleConfirm}
                      >
                        Confirm & Cancel Appointment
                      </button>
                      <button
                        className="bg-[#D9D9D9] text-black font-normal text-xs font-poppins py-4 px-2 rounded-lg w-1/2"
                        onClick={handleBack}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </ModalComponent>
              </div>
            )}
            {/* Successfull Cancellation Modal */}
            <ModalComponent
              show={showSuccessCancelModal}
              handleClose={() => setShowSuccessCancelModal(false)}
              outSideModalPress={false}
            >
              <div className="bg-[#FFFFFF] p-4">
                {/* Widget HEADER */}
                <div className="flex flex-row justify-center items-center">
                  {/* TITLE */}
                  <h1 className="text-black text-lg font-bold font-poppins rounded-lg">
                    Appointment Cancelled Successfully
                  </h1>
                </div>

                <div className="w-full bg-[#F3F5F6] h-[1px] my-5"></div>

                <div className="flex flex-col justify-center items-center mb-4 w-96">
                  <img
                    src={SuccessCancel}
                    alt="Successfull Cancel Image"
                    className="w-72 h-60"
                  />
                </div>

                <div className="flex flex-row justify-between items-center w-full gap-2">
                  <button
                    className="bg-[#3CA5DC] text-white font-normal font-poppins text-xs py-4 px-2 rounded-lg w-1/2"
                    onClick={() => {
                      setShowSuccessCancelModal(false);
                    }}
                  >
                    Issue Invoice
                  </button>
                  <button
                    className="bg-[#D9D9D9] text-black font-normal font-poppins text-xs py-4 px-2 rounded-lg w-1/2"
                    onClick={() => {
                      setShowSuccessCancelModal(false);
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            </ModalComponent>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagePatient;

const data = [
  {
    title: "Lawfulness, Fairness, and Transparency",
    subItems: [
      "Lawful Processing: Personal data must be processed lawfully, fairly, and transparently.",
      "Consent: Obtain clear and explicit consent from individuals before processing their data, except in cases of lawful exemptions.",
      "Transparency: Provide clear information about data collection, processing purposes, and individuals' rights at the time of data collection."
    ]
  },
  {
    title: "Purpose Limitation",
    subItems: [
      "Specified Purposes: Data must be collected for specified, explicit, and legitimate purposes.",
      "Restricted Use: Do not process data in a manner incompatible with the purposes for which it was collected, unless additional consent is obtained."
    ]
  },
  {
    title: "Data Minimization",
    subItems: [
      "Adequacy: Ensure that the personal data collected is adequate, relevant, and limited to what is necessary for the intended purposes.",
      "Minimized Data Collection: Collect only the data necessary to fulfill the specified purpose."
    ]
  },
  {
    title: "Accuracy",
    subItems: [
      "Accuracy of Data: Personal data must be accurate and, where necessary, kept up to date.",
      "Rectification: Take reasonable steps to erase or rectify inaccurate data promptly."
    ]
  },
  {
    title: "Storage Limitation",
    subItems: [
      "Retention Period: Store personal data in a form that permits identification of individuals for no longer than necessary for the purposes for which the data was collected.",
      "Data Deletion: Implement procedures for timely deletion or anonymization of data that is no longer needed."
    ]
  },
  {
    title: "Integrity and Confidentiality",
    subItems: [
      "Security Measures: Implement appropriate technical and organizational measures to protect personal data from unauthorized access, disclosure, alteration, or destruction.",
      "Confidentiality: Ensure that all personnel who process personal data are bound by confidentiality agreements."
    ]
  },
  {
    title: "Accountability",
    subItems: [
      "Data Protection Officer (DPO): Appoint a DPO, if required, to oversee GDPR compliance within the organization.",
      "Documentation: Maintain records of data processing activities, including the purposes of processing, data categories, and recipients.",
      "Impact Assessments: Conduct Data Protection Impact Assessments (DPIAs) for high-risk processing activities."
    ]
  },
  {
    title: "Individual Rights",
    subItems: [
      "Right to Access: Individuals have the right to access their personal data and obtain information about its processing.",
      "Right to Rectification: Individuals can request correction of inaccurate or incomplete data.",
      "Right to Erasure (Right to be Forgotten): Individuals can request the deletion of their data under certain circumstances.",
      "Right to Restrict Processing: Individuals can request the restriction of processing under specific conditions.",
      "Right to Data Portability: Individuals can request a copy of their data in a structured, commonly used, and machine-readable format.",
      "Right to Object: Individuals can object to the processing of their data for certain purposes, such as direct marketing.",
      "Rights Related to Automated Decision-Making: Individuals have rights concerning automated decision-making and profiling, including the right to human intervention."
    ]
  },
  {
    title: "Data Breach Notification",
    subItems: [
      "Notification Obligation: Notify the relevant supervisory authority of a personal data breach within 72 hours of becoming aware of it, unless the breach is unlikely to result in a risk to individuals' rights and freedoms.",
      "Communication to Individuals: If the breach poses a high risk, inform the affected individuals without undue delay."
    ]
  },
  {
    title: "International Data Transfers",
    subItems: [
      "Adequate Safeguards: Ensure that data transferred outside the European Economic Area (EEA) is protected by adequate safeguards, such as standard contractual clauses or binding corporate rules.",
      "Third-Country Transfers: Only transfer data to countries outside the EEA if they provide an adequate level of data protection."
    ]
  },
  {
    title: "Data Processing Agreements",
    subItems: [
      "Processor Contracts: Ensure that data processing agreements are in place with third-party processors, outlining their GDPR obligations and responsibilities.",
      "Sub-processors: Obtain consent from the data controller before engaging sub-processors, and ensure they adhere to GDPR requirements."
    ]
  },
  {
    title: "Children's Data",
    subItems: [
      "Parental Consent: Obtain parental consent before processing the personal data of children under the age of 16 (or as defined by national law).",
      "Clear Language: Ensure that privacy notices for children are written in clear and age-appropriate language."
    ]
  },
  {
    title: "Record-Keeping and Audits",
    subItems: [
      "Record-Keeping: Maintain detailed records of all data processing activities to demonstrate compliance with GDPR.",
      "Audits and Reviews: Regularly audit data protection practices and policies to ensure ongoing compliance with GDPR."
    ]
  },
  {
    title: "Data Protection by Design and by Default",
    subItems: [
      "Design Principles: Implement data protection measures from the outset of any new project or process involving personal data.",
      "Default Settings: Ensure that privacy settings are set to the most protective level by default."
    ]
  },
  {
    title: "Training and Awareness",
    subItems: [
      "Staff Training: Provide regular GDPR training to all employees who handle personal data.",
      "Awareness Campaigns: Promote awareness of data protection principles and the importance of GDPR compliance within the organization."
    ]
  }
];
