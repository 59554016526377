// components/PrivateRoute.js
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);

  if (token !== null) return children;
  else return <Navigate to="/login" />;
}

export default PrivateRoute;
